import { Tooltip } from "@homebound/beam";
import { DropCodeCellItem_TliFragment, DropCodeCellItem_TlivFragment } from "src/generated/graphql-types";

export function DropCodeCell({ scope }: { scope: DropCodeCellItem_TliFragment | DropCodeCellItem_TlivFragment }) {
  return (
    <Tooltip
      placement="top"
      title={scope.drop ? `${scope.item.costCode.name} - ${scope.drop.name}` : scope.item.costCode.name}
    >
      {scope.drop?.code || scope.item.costCode.number}
    </Tooltip>
  );
}
