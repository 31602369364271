import { useDevelopmentBidPackagesPageMetadataQuery } from "src/generated/graphql-types";
import { BidPackagesPageView } from "src/routes/bids/BidPackagesPage";
import { queryResult } from "src/utils";

export function BidPackagesPage() {
  const query = useDevelopmentBidPackagesPageMetadataQuery();
  return queryResult(query, {
    data: ({ costCodes }) => <BidPackagesPageView costCodes={costCodes} />,
  });
}
