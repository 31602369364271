import { Css, Icon, IconButton, Palette, useTestIds } from "@homebound/beam";
import { camelCase } from "change-case";
import { ReactNode, useMemo } from "react";
import { ProgressPill } from "src/components";
import { GlobalOptionTypeDrawerFragment, ReleaseToTradesTabLotConfigFragment } from "src/generated/graphql-types";
import { OptionTypes } from "src/routes/developments/lot-summary/sequence-sheet/components/utils";
import { ICON_CONFIG } from "src/routes/projects/components/LotConfigurationDetailsStaticField";
import { LotConfigurationSection } from "src/routes/projects/components/LotConfigurationSection";
import { createProjectUrl } from "src/RouteUrls";

type CohortProjectCardProps = {
  lot: ReleaseToTradesTabLotConfigFragment;
  globalOptionTypes: GlobalOptionTypeDrawerFragment[];
  right?: ReactNode;
};

export function CohortProjectCard(props: CohortProjectCardProps) {
  const { lot, globalOptionTypes, right } = props;
  const tid = useTestIds(props, lot.project.name.replace(/\s/g, ""));
  const otherOptionTypes = globalOptionTypes
    .filter((type) => !type.isElevation && !type.isCatalogV2)
    .sortBy(({ order }) => order);
  const image = lot.project.coverImage?.previewUrl;
  const grouped = useMemo(
    () => lot.options?.groupBy(({ readyPlanOption }) => readyPlanOption.globalOption.type.name) ?? {},
    [lot],
  );

  return (
    <div css={Css.df.aiStretch.tal.gap4.fg1.br8.bgWhite.$} {...tid}>
      <div css={Css.w100.dg.gtc("96px 190px auto").gap3.aic.$}>
        <div css={Css.ml3.wPx(72).hPx(72).br8.bgGray100.oh.if(!image).p1.$}>
          <img src={image || "/images/cover-image-fallback.svg"} alt="" css={Css.w100.h100.if(!!image).objectCover.$} />
        </div>
        <div>
          <div css={Css.df.ais.jcsb.baseBd.gray900.$} {...tid.name}>
            <p>{lot.project.name}</p>
            <span css={Css.$}>
              <IconButton
                color={Palette.Blue700}
                icon="linkExternal"
                inc={2}
                onClick={createProjectUrl(lot.project.id)}
                openInNew
              />
            </span>
          </div>
          <div css={Css.tinyMd.mb2.$}>
            <span css={Css.gray500.dib.mr("4px").mb("-1.8px").$}>
              <Icon inc={1.5} icon="house" />
            </span>
            <span {...tid.plan}>
              {lot.readyPlan ? (
                <>
                  {lot.readyPlan?.name} ({lot.readyPlan?.displayCode})
                </>
              ) : (
                ""
              )}
            </span>
            {!!lot.readyPlan && !!lot.specOption && " - "}
            <span {...tid.specOption}>{lot.specOption?.readyPlanOption.name}</span>
          </div>
          <ProgressPill progress={lot.project.stage.percentReleased} text="Released" />
        </div>
        <div css={Css.h100.bcGray200.bl.p3.pl4.dg.gtc("auto 255px").$}>
          <div css={Css.df.fdc.gap2.$}>
            {otherOptionTypes.map((option) => (
              <LotConfigurationSection
                key={option.id}
                {...tid[camelCase(option.name)]}
                compact
                icon={ICON_CONFIG[option.name] || "customize"}
                optionType={option.name}
                data={
                  option.name === OptionTypes.ExteriorScheme
                    ? [...(grouped[OptionTypes.Elevation] ?? []), ...(grouped[OptionTypes.ExteriorScheme] ?? [])]
                    : grouped[option.name]
                }
                title={
                  option.name === OptionTypes.AddOn
                    ? "Add-ons"
                    : option.name === OptionTypes.ExteriorScheme
                      ? "Exterior Options"
                      : `${option.name} Options`
                }
              />
            ))}
          </div>
          {right}
        </div>
      </div>
    </div>
  );
}
