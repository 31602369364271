import {
  BoundSelectField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { useCallback } from "react";
import {
  BidPackageDetailPage_TradeFragment,
  BidPackageDetailRequestFragment,
  SaveBidPackageRequestInput,
  useEditTradeContactsModalQuery,
  useSaveBidPackageRequestMutation,
} from "src/generated/graphql-types";
import { noop, queryResult } from "src/utils";

export function EditTradeContactsModal({ bidRequest }: { bidRequest: BidPackageDetailRequestFragment }) {
  const query = useEditTradeContactsModalQuery({ variables: { id: bidRequest.tradePartner.id } });

  // Query the bid request's trade to get contact details
  return queryResult(query, ({ tradePartner }) => (
    <EditTradeContactsModalView bidRequest={bidRequest} trade={tradePartner} />
  ));
}

function EditTradeContactsModalView({
  bidRequest,
  trade,
}: {
  bidRequest: BidPackageDetailRequestFragment;
  trade: BidPackageDetailPage_TradeFragment;
}) {
  const { closeModal } = useModal();
  const [saveBidPackageRequest] = useSaveBidPackageRequestMutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: bidRequest,
      map: (bidRequest) => ({
        primaryContactId: bidRequest.primaryContact?.id,
        secondaryContactId: bidRequest.secondaryContact?.id,
      }),
    },
  });
  const showSecondaryDetails = useComputed(
    // Show secondary info if request has an existing secondary, or if one is selected
    () => bidRequest.secondaryContact || formState.secondaryContactId.value,
    [formState.secondaryContactId.value, bidRequest.secondaryContact],
  );
  // The primary and secondary's contact details from the trade query
  const { primary, secondary } = useComputed(
    () => ({
      primary: trade.contacts.find((c) => formState.primaryContactId.value === c.id),
      secondary: trade.contacts.find((c) => formState.secondaryContactId.value === c.id),
    }),
    [formState.primaryContactId.value, formState.secondaryContactId.value, trade.contacts],
  );

  const saveAndExit = useCallback(async () => {
    await saveBidPackageRequest({
      variables: {
        input: { id: bidRequest.id, ...formState.value },
      },
    });
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidRequest.id, formState.value]);

  return (
    <div data-testid="editTradeContactModal">
      <ModalHeader>Edit Contact</ModalHeader>
      <ModalBody>
        <FormLines>
          {/* Readonly fields could also use the `StaticField` labels but design wants to use disabled `Textfields` per figma
           * So we use the `TextField` with noop onChanges and disabled set */}
          <TextField value={bidRequest.tradePartner.name} label="Company Name" onChange={noop} disabled />
          <BoundSelectField field={formState.primaryContactId} label="Contact (Primary)" options={trade.contacts} />
          <TextField value={primary?.email || ""} label="Email" onChange={noop} disabled />
          <TextField value={primary?.mobilePhone || ""} label="Phone" onChange={noop} disabled />

          <BoundSelectField
            field={formState.secondaryContactId}
            label="Contact (Secondary)"
            options={trade.contacts.concat([{ id: "", name: "None", isPrimary: false }])}
          />
          {showSecondaryDetails && (
            <>
              <TextField value={secondary?.email || ""} label="Email" onChange={noop} disabled />
              <TextField value={secondary?.mobilePhone || ""} label="Phone" onChange={noop} disabled />
            </>
          )}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="tertiary" />
        <Button label="Save" onClick={saveAndExit} />
      </ModalFooter>
    </div>
  );
}

type BidTradesFormValue = SaveBidPackageRequestInput;
const formConfig: ObjectConfig<BidTradesFormValue> = {
  primaryContactId: { type: "value" },
  secondaryContactId: { type: "value" },
};
