import { Css } from "@homebound/beam";
import { FormattedDate } from "src/components";
import {
  UpdatesAvailableProductOfferingFragment,
  UpdatesAvailableSourceRpavFragment,
} from "src/generated/graphql-types";
import { AggregateMemberVersionChangeType } from "src/routes/libraries/design-catalog/design-package-configurator/components/DesignUpdatesAvailableButton";
import { renderListItem } from "src/routes/libraries/versioning/VersionHistoryUtils";
import { pluralize, sanitizeHtml } from "src/utils";

export function ReadyPlanSourceSection(props: {
  update: UpdatesAvailableProductOfferingFragment;
  source: UpdatesAvailableSourceRpavFragment;
}) {
  const { update, source } = props;
  const totalItemSlotCount = source.tliVersions.count((tliv) => !tliv.identity.isPlaceholderMaterial);
  const { newTlivCount, removedTlivCount, updatedTlivCount } = getTlivChangeCounts(source);
  const { newRplvCount, removedRplvCount, updatedRplvCount } = getRplvChangeCounts(source);
  return (
    <div>
      <div data-testid="planInfo" css={Css.sm.df.fdr.gap1.$}>
        <span css={Css.smMd.$}>{source.readyPlan.name} </span>
        <span css={Css.gray700.$}>v{source.version} - </span>
        <span css={Css.gray700.$}>
          <FormattedDate date={update.createdAt} dateFormatStyle="long" />
        </span>
      </div>
      {source.versionNotes && (
        <div
          data-testid="versionNotes"
          css={Css.gray700.sm.$}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(source.versionNotes || ""),
          }}
        />
      )}
      <ul css={Css.m0.gray700.$}>
        {update.forPlanPackageUpdate && (
          <>
            {/* Takeoff Items */}
            {renderListItem(newTlivCount, "Added", "takeoff item")}
            {renderListItem(removedTlivCount, "Removed", "takeoff item")}
            {renderListItem(updatedTlivCount, "Modified", "takeoff item")}
            {/* Plan Locations */}
            {renderListItem(newRplvCount, "Added", "location")}
            {renderListItem(removedRplvCount, "Removed", "location")}
            {renderListItem(updatedRplvCount, "Modified", "location")}
          </>
        )}
        {update.forDesignPackageUpdate && (
          <li>
            {totalItemSlotCount} item-slot {pluralize(totalItemSlotCount, "update")}
          </li>
        )}
      </ul>
    </div>
  );
}

export function DropSourceSection({ update }: { update: UpdatesAvailableProductOfferingFragment }) {
  return (
    <div data-testid="dropInfo" css={Css.sm.df.fdr.gap1.$}>
      <span css={Css.smMd.$}>{update.source.name} - </span>
      <span css={Css.gray700.$}>
        <FormattedDate date={update.createdAt} dateFormatStyle="long" />
      </span>
    </div>
  );
}

function getTlivChangeCounts(source: UpdatesAvailableSourceRpavFragment) {
  const tlivs = source.tliVersions.filter((tliv) => !tliv.identity.isPlaceholderMaterial);
  const newTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.REMOVED);
  const updatedTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.UPDATED);

  return { newTlivCount, removedTlivCount, updatedTlivCount };
}

function getRplvChangeCounts(source: UpdatesAvailableSourceRpavFragment) {
  const rplVersions = source.rplVersions;
  const newRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.REMOVED);
  const updatedRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.UPDATED);
  return { newRplvCount, removedRplvCount, updatedRplvCount };
}
