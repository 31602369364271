import { Css, ScrollableParent, Step, useComputed } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { StepperProvider, stepperBarHeight, useStepperContext } from "src/components/stepper";
import {
  AddBidPackagePageQuery,
  AddBidPackagePage_BidPackagesFragment,
  Maybe,
  SaveBidPackageInput,
  useAddBidPackagePageQuery,
} from "src/generated/graphql-types";
import { BidPackageParams } from "src/routes/routesDef";
import { isDefined, isEmptyObject, queryResult } from "src/utils";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { BidPackageDetailsStep } from "./components/1.BidPackageDetailsStep";
import { ReviewScopeStep } from "./components/2.ReviewScopeStep";
import { ReviewUnitBidScopeStep } from "./components/2.ReviewUnitBidScopeStep";
import { SelectBiddersStep } from "./components/3.SelectBiddersStep";
import { AssignTradeContactsStep } from "./components/4.AssignTradeContactsStep";
import { ReviewAndSendStep } from "./components/5.ReviewAndSendStep";
import { BidPackageStepActions } from "./components/BidPackageStepActions";
import { BidPackageStep } from "./utils";

export function AddBidPackagePage() {
  const { bidPackageGroupId } = useParams<BidPackageParams>();
  const query = useAddBidPackagePageQuery({
    variables: { id: bidPackageGroupId || "" },
    skip: !bidPackageGroupId,
  });

  if (!bidPackageGroupId) {
    return (
      <StepperProvider steps={initialSteps(true)}>
        <AddBidPackageView />
      </StepperProvider>
    );
  }

  // Dont disable steps in `edit` mode on stepper initialization
  // Our formState useComputed validation will still check required fields if edits are made per step
  return queryResult(query, ({ bidPackageGroup }) => (
    <StepperProvider steps={initialSteps(!bidPackageGroup)}>
      <AddBidPackageView bidPackageGroup={bidPackageGroup} />
    </StepperProvider>
  ));
}

function AddBidPackageView({ bidPackageGroup }: { bidPackageGroup?: AddBidPackagePageQuery["bidPackageGroup"] }) {
  const { bidPackageGroupId: savedGroupId } = useParams<BidPackageParams>();
  const [{ projectId, devId, isHistoricalBid }] = useQueryParams({
    projectId: StringParam,
    devId: StringParam,
    isHistoricalBid: BooleanParam,
  });
  const { currentStep, setSteps } = useStepperContext();
  const [stepIndex, setStepIndex] = useState(0);
  const formConfig = useMemo(
    () =>
      buildFormConfig(
        bidPackageGroup?.bidPackages[0]?.isHistoricalBid ?? isHistoricalBid ?? false,
        bidPackageGroup?.bidPackages[0]?.isUnitBased ?? false,
      ),
    [isHistoricalBid, bidPackageGroup?.bidPackages],
  );
  const formState = useFormState({
    config: formConfig,
    init: {
      input: bidPackageGroup,
      map: (bidPackageGroup) => mapBidPackagesToForm(bidPackageGroup.bidPackages, devId, isHistoricalBid),
      ifUndefined: {
        development: devId,
        projects: projectId ? [projectId] : [],
        costType: null,
        productOfferings: [],
        bidPackages: [{ delete: undefined }],
        dueDate: null,
        estimatedAwardDate: null,
        sendReminderDate: null,
        estimatedConstructionDate: null,
        isHistoricalBid: isHistoricalBid ?? false,
      },
    },
  });
  const useProjectHierarchy = !(isDefined(devId) || bidPackageGroup?.bidPackages[0]?.developments?.length);

  // This updates the continue/enable/disable state of the stepper based on required formstate
  useComputed(() => {
    if (formState.dirty && !isEmptyObject(formState.changedValue)) {
      const coreFieldsEmpty =
        !(formState.development.value || formState.projects.value?.length) ||
        formState.bidPackages.value?.isEmpty ||
        (!formState.isUnitBased.value && formState.productOfferings.value?.isEmpty) ||
        !formState.costType.value ||
        // For a historical bid, we don't require dueDate, estimatedAwardDate, invitationText, or bidInstructionsText
        (isHistoricalBid
          ? false
          : !formState.dueDate.value ||
            !formState.estimatedAwardDate.value ||
            !formState.invitationText.value ||
            !formState.bidInstructionsText.value);

      setSteps((prevState) => [
        { ...prevState[0], disabled: false },
        {
          ...prevState[1],
          disabled: coreFieldsEmpty,
        },
        {
          ...prevState[2],
          // Todo: disable if no line items
          disabled: false,
        },
        {
          ...prevState[3],
          // A bid category has been selected and has at least one trade partner
          disabled:
            formState.bidPackages.value.isEmpty ||
            formState.bidPackages.value.some((bc) => !bc.requests || bc.requests.isEmpty),
        },
        {
          ...prevState[4],
          // A bid category has been selected and every bid category has least one trade partner with a primary contract
          disabled:
            formState.bidPackages.value.isEmpty ||
            formState.bidPackages.value.some((bc) => !bc.requests || bc.requests.isEmpty) ||
            formState.bidPackages.rows.flatMap((bp) => bp.requests.value).some((r) => !r?.primaryContactId),
        },
      ]);
    }
  }, [formState]);

  useEffect(() => {
    const currentStepIndex = initialSteps(!isDefined(savedGroupId)).findIndex(
      (step) => step.value === currentStep.value,
    );
    if (stepIndex === currentStepIndex) return;
    setStepIndex(currentStepIndex);
    setSteps((prevState) => [
      { ...prevState[0], state: currentStepIndex > 0 ? "complete" : prevState[0].state },
      { ...prevState[1], state: currentStepIndex > 1 ? "complete" : prevState[1].state },
      { ...prevState[2], state: currentStepIndex > 2 ? "complete" : prevState[2].state },
      { ...prevState[3], state: currentStepIndex > 3 ? "complete" : prevState[3].state },
      { ...prevState[4], state: currentStepIndex > 3 ? "complete" : prevState[4].state },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const step = useMemo(() => {
    switch (currentStep.value) {
      case BidPackageStep.PACKAGE_DETAILS:
        return <BidPackageDetailsStep formState={formState} useProjectHierarchy={useProjectHierarchy} />;
      case BidPackageStep.REVIEW_SCOPE:
        return formState.isUnitBased.value ? (
          <ReviewUnitBidScopeStep formState={formState} />
        ) : (
          <ReviewScopeStep formState={formState} />
        );
      case BidPackageStep.SELECT_BIDDERS:
        return <SelectBiddersStep formState={formState} />;
      case BidPackageStep.ASSIGN_TRADE_CONTACTS:
        return <AssignTradeContactsStep formState={formState} />;
      case BidPackageStep.REVIEW_AND_SEND:
        return <ReviewAndSendStep formState={formState} />;
      default:
        return <BidPackageDetailsStep formState={formState} useProjectHierarchy={useProjectHierarchy} />;
    }
  }, [currentStep.value, formState, useProjectHierarchy]);

  return (
    <ScrollableParent xss={Css.mbPx(stepperBarHeight).pxPx(60).$}>
      {step}
      <BidPackageStepActions formState={formState} />
    </ScrollableParent>
  );
}

const INCOMPLETE = "incomplete" as const;
export const initialSteps: (isCreateMode: boolean) => Step[] = (isCreateMode = true) => [
  { value: BidPackageStep.PACKAGE_DETAILS, label: "Package Details", state: INCOMPLETE, disabled: isCreateMode },
  { value: BidPackageStep.REVIEW_SCOPE, label: "Review Scope", state: INCOMPLETE, disabled: isCreateMode },
  { value: BidPackageStep.SELECT_BIDDERS, label: "Select Bidders", state: INCOMPLETE, disabled: isCreateMode },
  {
    value: BidPackageStep.ASSIGN_TRADE_CONTACTS,
    label: "Assign Trade Contacts",
    state: INCOMPLETE,
    disabled: isCreateMode,
  },
  { value: BidPackageStep.REVIEW_AND_SEND, label: "Review + Send", state: INCOMPLETE, disabled: isCreateMode },
];

export type BidPackageFormState = Pick<
  SaveBidPackageInput,
  | "productOfferings"
  | "invitationText"
  | "bidInstructionsText"
  | "bidPackageGroupId"
  | "isHistoricalBid"
  | "costType"
  | "planRoomLink"
  | "isUnitBased"
  | "projects"
> & {
  development?: Maybe<string>;
  dueDate: Maybe<Date>;
  estimatedAwardDate: Maybe<Date>;
  estimatedConstructionDate: Maybe<Date>;
  sendReminderDate: Maybe<Date>;
  bidPackages: Pick<SaveBidPackageInput, "requests" | "id" | "costCodes" | "delete">[];
};

export const buildFormConfig = (
  isHistoricalBid?: boolean,
  isUnitBased?: boolean,
): ObjectConfig<BidPackageFormState> => ({
  development: { type: "value" },
  projects: { type: "value" },
  productOfferings: {
    type: "value",
    rules: isUnitBased ? [] : [required],
  },
  isUnitBased: { type: "value" },
  dueDate: { type: "value", rules: isHistoricalBid ? [] : [required] },
  estimatedAwardDate: { type: "value", rules: isHistoricalBid ? [] : [required] },
  estimatedConstructionDate: { type: "value" },
  sendReminderDate: { type: "value" },
  planRoomLink: { type: "value" },
  invitationText: { type: "value", rules: isHistoricalBid ? [] : [required] },
  bidInstructionsText: { type: "value", rules: isHistoricalBid ? [] : [required] },
  bidPackageGroupId: { type: "value" },
  isHistoricalBid: { type: "value" },
  costType: { type: "value", rules: [required] },
  bidPackages: {
    type: "list",
    config: {
      id: { type: "value" },
      costCodes: { type: "value", rules: [required] },
      delete: { type: "value" },
      requests: {
        type: "list",
        config: {
          id: { type: "value" },
          tradePartnerId: { type: "value" },
          primaryContactId: { type: "value", rules: [required] },
          secondaryContactId: { type: "value" },
        },
      },
    },
    rules: [required],
  },
});

export function mapBidPackagesToForm(
  bidPackages: AddBidPackagePage_BidPackagesFragment[],
  devId?: Maybe<string>,
  isHistoricalParam?: Maybe<boolean>,
) {
  const {
    bidPackageGroup,
    // Deconstruct date vals due to strict typing on formState config
    dueDate,
    estimatedAwardDate,
    estimatedConstructionDate,
    sendReminderDate,
    // Deconstruct dev and offerings to reformat for formState
    developments,
    projects,
    productOfferings,
    isHistoricalBid,
    costType,
  } = bidPackages?.first ?? {};
  return {
    bidPackageGroupId: bidPackageGroup?.id,
    ...bidPackages?.first,
    costType: costType?.code,
    dueDate: dueDate ? new Date(dueDate) : null,
    estimatedAwardDate: estimatedAwardDate ? new Date(estimatedAwardDate) : null,
    estimatedConstructionDate: estimatedConstructionDate ? new Date(estimatedConstructionDate) : null,
    sendReminderDate: sendReminderDate ? new Date(sendReminderDate) : null,
    development: developments?.first?.id ?? devId,
    projects: projects?.map((p) => p.id),
    productOfferings: productOfferings?.map((po) => po.id),
    bidPackages: (bidPackages ?? []).map((bp) => ({
      id: bp.id,
      isUnitBased: bp.isUnitBased ?? false,
      costCodes: bp.costCodes.map((cc) => cc.id),
      isHistoricalBid: isHistoricalBid ? isHistoricalBid : isHistoricalParam ? isHistoricalParam : false,
      delete: false,
      requests: bp.requests.map((r) => ({
        id: r.id,
        tradePartnerId: r.tradePartner.id,
        primaryContactId: r.primaryContact?.id,
        secondaryContactId: r.secondaryContact?.id,
      })),
    })),
  };
}
