import { ExtractRouteParams } from "react-router";
import { generatePath } from "react-router-dom";
import { CommitmentType, Maybe, Stage } from "src/generated/graphql-types";
import {
  bidCommitmentChangeOrderPdfPath,
  bidCommitmentPdfPath,
  bidItemCreatePath,
  bidItemDetailsPath,
  bidPackageDetailsPath,
  bidPackagePath,
  bidPackagePublishPath,
  bidPackagesEditPath,
  bidPackagesPath,
  billPdfPath,
  billsPath,
  catalogTaskFolderPath,
  catalogTaskLaborCosts,
  catalogTaskPath,
  catalogTasksPath,
  changeLogPaths,
  commitmentChangeOrderPdfPath,
  commitmentPdfPath,
  constraintItemsCatalogPath,
  costMappingPath,
  createBillPath,
  designCatalogPath,
  designCatalogsPath,
  designPackageAddProductPath,
  designPackageAddToSlotPath,
  designPackageCompareModePath,
  designPackageFinishSchedulePath,
  designPackageNewSlotPath,
  designPackageProductSearchPath,
  designPackageSlotDetailPath,
  designPackageVersionHistoryPath,
  developmentCommitmentPdfPath,
  developmentCommitmentsPaths,
  developmentContractPdfPath,
  developmentLotConfigPaths,
  developmentPaths,
  developmentsPath,
  dynamicSchedulesPath,
  estimateExportPath,
  expensesPath,
  finishScheduleVisualModePdfPath,
  globalOptionPath,
  globalOptionsLegacyPath,
  globalOptionsPath,
  globalProductOfferingConfigsPath,
  globalProductOfferingsPath,
  homeownerChangeOrderPath,
  installTasksPath,
  invoicePandaDocPdfPath,
  invoicePdfPath,
  invoiceTermPath,
  invoiceTermsPath,
  itemCatalogPath,
  itemTemplateItemPath,
  itemTemplatePath,
  itemTemplatesPath,
  lienWaiverPdfPath,
  lotSequenceSheetPdfPath,
  lotSummaryDetailsPdfPath,
  materialCostPath,
  materialCreatePath,
  materialDetailsPath,
  materialsCatalogPath,
  milestoneCatalogPath,
  milestonesCatalogPath,
  paymentTermsPath,
  personalDashboardPaths,
  planPackageDetailsActivity,
  planPackageDetailsOptionData,
  planPackageDetailsOverview,
  planPackageEditPath,
  planPackagePath,
  planPackageTakeoffsPath,
  planPackageVersionHistoryPath,
  planPackagesPath,
  productCatalogPath,
  productCreatePath,
  productDetailsPath,
  productOfferingConfigEditPath,
  productOfferingConfigPath,
  productOfferingConfigsComparePath,
  productOfferingEditPath,
  productOfferingPath,
  productOfferingScopePath,
  projectFinishSchedulePdfPath,
  projectFinishScheduleVisualModePdfPath,
  projectItemScopeOfWorkPath,
  projectPaths,
  purchaseOrdersPath,
  reassignPurchaseOrdersPaths,
  scheduleExportPath,
  scheduleOfValuesPath,
  scheduleTemplatePath,
  scheduleTemplatesPath,
  toDoPdfPath,
  tradePartnerPaths,
  tradePartnersPath,
  tradeScopeOfWorkPath,
  tradeSpecificAgreementPath,
  tradesPunchlistPdfPath,
  tradesPurchaseOrdersPath,
  tradesTradePartnersPath,
  warrantyPaths,
} from "src/routes/routesDef";
import { getSlugFromStage } from "src/utils";
import { EnumMapping, mapEnum } from "ts-enum-mapper";
import { fail, maybeDeTagId } from "./utils";

/** Present in place of an ID in URLs when creating items on CRUD pages */
export const addEntityParam = "add";

type CommitmentTypeSlug = "purchase-order" | "subcontract";
/** Maps commitment type to/from query string values. */
const commitmentTypeValues: EnumMapping<typeof CommitmentType, CommitmentTypeSlug> = mapEnum(CommitmentType, {
  PurchaseOrder: "purchase-order",
  Subcontract: "subcontract",
});

export function createProjectToDoModalUrl(projectId: string, id: string) {
  return generatePath(projectPaths.toDoModal, { projectId, toDoId: id });
}

export function createDashboardTodoModalUrl(id: string) {
  return generatePath(personalDashboardPaths.toDoModal, { toDoId: id });
}

export function createBudgetUrl(projectId: string, stage?: Stage) {
  if (!stage) {
    return generatePath(projectPaths.budgetBase, { projectId });
  }
  return generatePath(projectPaths.budget, { projectId, stageSlug: getSlugFromStage(stage) });
}

export function createCommitmentsUrl(projectId: string) {
  return generatePath(projectPaths.commitments, { projectId });
}

export function createCommitmentUrl(
  projectId: string,
  idOrAdd: string = addEntityParam,
  unformattedType?: CommitmentType,
) {
  const basePath = generatePath(projectPaths.commitment, { projectId, idOrAdd });

  if (idOrAdd === addEntityParam) {
    if (!unformattedType) {
      fail("A 'type' must be passed when generating a URL for a new commitment");
    }
    const type = commitmentTypeValues.map(unformattedType);
    return `${basePath}${generatePath("?type=:type", { type })}`;
  }

  return basePath;
}

export function createCommitmentChangeOrderUrl(
  projectId: string,
  commitmentId: string,
  idOrAdd: string = addEntityParam,
) {
  return generatePath(projectPaths.commitmentChangeOrder, { projectId, commitmentId, idOrAdd });
}

export function createInvoiceTermsUrl() {
  return generatePath(invoiceTermsPath);
}

export function createInvoiceTermUrl(idOrAdd: string = addEntityParam) {
  return generatePath(invoiceTermPath, { idOrAdd });
}

export function createBillsAndCreditsPageUrl() {
  return generatePath(billsPath);
}

export function createBillPageUrl({
  idOrAdd = addEntityParam,
  commitmentLikeId,
  credit,
  edit,
}: {
  idOrAdd?: string;
  commitmentLikeId?: string;
  credit?: boolean;
  edit?: boolean;
} = {}) {
  const baseUrl = generatePath(createBillPath, { idOrAdd });
  const editsuffix = edit ? "&edit=1" : "";

  if (commitmentLikeId) {
    const creditsuffix = credit ? "&credit=1" : "";

    return generatePath(`${baseUrl}?commitmentLikeId=:commitmentLikeId${creditsuffix}${editsuffix}`, {
      commitmentLikeId,
    });
  }
  return `${baseUrl}${editsuffix.replace("&", "?")}`;
}

export function createBillUrl(billId: string) {
  return `${billsPath}/${billId}`;
}

export function createBillUrlFromCommitmentOrChangeOrder(
  projectId: string,
  params: {
    id?: string;
    commitmentId?: string;
    changeOrderId?: string;
    stage?: Stage;
    credit?: boolean;
  },
) {
  const { commitmentId, changeOrderId, id: billId, stage, credit = false } = params;
  const billPath = generatePath(projectPaths.bill, { projectId, billId });

  if (billId) {
    return createBillUrl(billId);
  } else if (stage) {
    const stageSlug = getSlugFromStage(stage);
    const creditsuffix = credit ? "&credit=1" : "";
    if (commitmentId) {
      // 'as any' because `generatePath` has some fancy "parse the keys in this string" that is buggy
      return `${billPath}${generatePath(`?commitmentId=:commitmentId&stage=:stageSlug${creditsuffix}`, {
        commitmentId,
        stageSlug,
      } as any)}`;
    } else if (changeOrderId) {
      // 'as any' because `generatePath` has some fancy "parse the keys in this string" that is buggy
      return `${billPath}${generatePath(`?changeOrderId=:changeOrderId&stage=:stageSlug${creditsuffix}`, {
        changeOrderId,
        stageSlug,
      } as any)}`;
    }
    fail("commitmentId or changeOrderId must be defined when generating the URL for a new bill");
  }
  fail("stage and commitmentId or changeOrderId must be defined to generate bill URL");
}

export function createHomeownerContractsUrl(projectId: string, contractId?: string) {
  if (contractId) {
    return generatePath(projectPaths.contract, { projectId, contractId });
  }
  return generatePath(projectPaths.contracts, { projectId });
}

export function createHomeownerContractChangeOrderUrl(projectId: string, changeOrderId?: string) {
  return generatePath(projectPaths.contractChangeOrder, { projectId, changeOrderId });
}

export function createHomeownerContractChangeOrderDrawsUrl(projectId: string, changeOrderId?: string) {
  return generatePath(projectPaths.contractChangeOrderDraws, { projectId, changeOrderId });
}

export function createHomeownerContractDrawsUrl(projectId: string, contractId: string) {
  return generatePath(projectPaths.contractDraws, { projectId, contractId });
}

export function createHomeownerContractInvoiceScheduleUrl(projectId: string, contractId: string) {
  return generatePath(projectPaths.contractInvoiceSchedule, { projectId, contractId });
}

export function createProjectUrl(projectId: string): string {
  return generatePath(projectPaths.project, { projectId });
}

export function createDevelopmentsUrl() {
  return generatePath(developmentsPath);
}

export function createDevelopmentUrl(developmentId: string): string {
  return generatePath(developmentPaths.development, { developmentId });
}

export function createDevelopmentLotSchedulesUrl(developmentId: string): string {
  return generatePath(developmentPaths.lotSchedules, { developmentId });
}

export function createDevelopmentScheduleUrl(developmentId: string): string {
  return generatePath(developmentPaths.developmentSchedule, { developmentId });
}

export function createDevelopmentDocumentsUrl(developmentId: string) {
  return generatePath(developmentPaths.documents, { developmentId });
}

export function createDevelopmentTeamMembersUrl(developmentId: string) {
  return generatePath(developmentPaths.team, { developmentId });
}

export function createDevelopmentDropsUrl(developmentId: string) {
  return generatePath(developmentPaths.drops, { developmentId });
}

export function createDevelopmentJobLogsUrl(developmentId: string): string {
  return generatePath(developmentPaths.jobLogs, { developmentId });
}

export function createDevelopmentJobLogsGalleryUrl(developmentId: string): string {
  return generatePath(developmentPaths.jobLogsGallery, { developmentId });
}

export function createDevelopmentJobLogNoteDrawerUrl(developmentId: string, jobLogId: string, jobLogNoteId: string) {
  return generatePath(developmentPaths.jobLogNoteDrawer, { developmentId, jobLogId, jobLogNoteId });
}

export function createDevelopmentLotSummaryUrl(developmentId: string) {
  return generatePath(developmentPaths.lotSummary, { developmentId });
}

export function createDevelopmentLotSequenceSheetUrl(developmentId: string) {
  return generatePath(developmentPaths.lotSequenceSheet, { developmentId });
}

export function createDevelopmentLotDetailsUrl(developmentId: string, projectReadyPlanConfigId: string) {
  return generatePath(developmentPaths.lotDetails, { developmentId, projectReadyPlanConfigId });
}

export function createDevelopmentLotSummaryReleaseUrl(developmentId: string) {
  return generatePath(developmentPaths.lotSummaryRelease, { developmentId });
}

export function createDevelopmentAllLotsUrl(developmentId: string): string {
  return generatePath(developmentPaths.allLots, { developmentId });
}

export function createDevelopmentProcurementUrl(developmentId: string): string {
  return generatePath(developmentPaths.procurement, { developmentId });
}

export function createDevelopmentPurchaseOrdersUrl(developmentId: string): string {
  return generatePath(developmentPaths.purchaseOrders, { developmentId });
}

export function createDevelopmentReassignPurchaseOrdersSelectUrl(developmentId: string): string {
  return generatePath(reassignPurchaseOrdersPaths.select, { developmentId });
}

export function createDevelopmentReassignPurchaseOrdersReleaseUrl(developmentId: string): string {
  return generatePath(reassignPurchaseOrdersPaths.release, { developmentId });
}

export function createDevelopmentCostMapPageUrl(developmentId: string): string {
  return generatePath(costMappingPath, { developmentId });
}

export function createDevelopmentLotConfigUrl(developmentId: string) {
  return generatePath(developmentPaths.lotConfig, { developmentId });
}

export function createDevelopmentPlanAndOptionsUrl(developmentId: string) {
  return generatePath(developmentPaths.planAndOptions, { developmentId });
}

export function createDevelopmentProductOfferingUrl(
  developmentId: string,
  productOfferingId: string,
  productOfferingVersionId: string | "latest",
) {
  return generatePath(developmentPaths.productOffering, {
    developmentId,
    productOfferingId,
    productOfferingVersionId: productOfferingVersionId === "latest" ? undefined : productOfferingVersionId,
  });
}

export function createProjectProductOfferingUrl(
  projectId: string,
  productOfferingId: string,
  productOfferingVersionId: string | "latest",
) {
  return generatePath(projectPaths.productOffering, {
    projectId,
    productOfferingId,
    productOfferingVersionId: productOfferingVersionId === "latest" ? undefined : productOfferingVersionId,
  });
}

export function createDevelopmentSettingsUrl(developmentId: string) {
  return generatePath(developmentPaths.settings, { developmentId });
}

export function createProductOfferingScopeUrl(
  productOfferingId: string,
  rpavId: string,
  developmentId?: Maybe<string>,
  projectId?: Maybe<string>,
) {
  return `${generatePath(productOfferingScopePath, { productOfferingId, rpavId })}${developmentId ? `?devId=${developmentId}` : ""}${projectId ? `?projectId=${projectId}` : ""}`;
}

export function createProductOfferingUrl(
  idOrAdd: string = addEntityParam,
  developmentId?: Maybe<string>,
  projectId?: Maybe<string>,
) {
  if (developmentId) {
    return idOrAdd === "add"
      ? `${generatePath(productOfferingPath, { idOrAdd })}?devId=${developmentId}`
      : generatePath(developmentPaths.productOffering, { productOfferingId: idOrAdd, developmentId });
  }
  if (projectId) {
    return idOrAdd === "add"
      ? `${generatePath(productOfferingPath, { idOrAdd })}?projectId=${projectId}`
      : generatePath(projectPaths.productOffering, { productOfferingId: idOrAdd, projectId });
  }
  return generatePath(productOfferingPath, { idOrAdd });
}

export function createProductOfferingApplyToProjectsUrl(
  productOfferingId: string,
  productOfferingVersionId: string,
  developmentId?: string,
  projectId?: string,
) {
  if (developmentId) {
    return generatePath(developmentPaths.productOfferingApplyToProject, {
      productOfferingId,
      productOfferingVersionId,
      developmentId,
    });
  }
  return generatePath(projectPaths.productOfferingApplyToProject, {
    productOfferingId,
    productOfferingVersionId,
    projectId,
  });
}

export function createProductOfferingEditUrl(
  productOfferingId: string,
  developmentId?: Maybe<string>,
  projectId?: Maybe<string>,
) {
  return `${generatePath(productOfferingEditPath, { productOfferingId })}${
    developmentId ? `?devId=${developmentId}` : ""
  }${projectId ? `?projectId=${projectId}` : ""}`;
}

export function createDevelopmentBidPackagesUrl(developmentId: string) {
  return generatePath(developmentPaths.bidPackages, { developmentId });
}

export function createDevelopmentBidPackageUrl(developmentId: string, bidPackageId: string) {
  return generatePath(developmentPaths.bidPackage, { developmentId, bidPackageId });
}

export function createBidPackageUrl(
  idOrAdd: string = addEntityParam,
  params?: { developmentId?: string; projectId?: string; isHistorical?: boolean },
) {
  const { developmentId, projectId, isHistorical } = params || {};

  if (developmentId) {
    return `${generatePath(bidPackagePath, { idOrAdd })}${`?devId=${developmentId}${isHistorical ? "&isHistoricalBid=1" : ""}`}`;
  }
  if (projectId) {
    return `${generatePath(bidPackagePath, { idOrAdd })}${`?projectId=${projectId}${isHistorical ? "&isHistoricalBid=1" : ""}`}`;
  }
  return `${generatePath(bidPackagePath, { idOrAdd })}${isHistorical ? "?isHistoricalBid=1" : ""}`;
}

export function createBidPackagesEditUrl(
  bidPackageGroupId: string,
  developmentId?: Maybe<string>,
  projectId?: Maybe<string>,
) {
  return `${generatePath(bidPackagesEditPath, { bidPackageGroupId })}${developmentId ? `?devId=${developmentId}` : ""}${projectId ? `?projectId=${projectId}` : ""}`;
}

export function createBidPackagesDetailUrl(bidPackageGroupId: string, bidPackageId: string) {
  return generatePath(bidPackageDetailsPath, { bidPackageGroupId, bidPackageId });
}

export function createBidPackagePublishUrl(bidPackageGroupId: string, bidPackageId: string) {
  return generatePath(bidPackagePublishPath, { bidPackageGroupId, bidPackageId });
}

export function createDevelopmentPlanAndOptionsDetailsUrl(developmentId: string, readyPlanId: string) {
  return generatePath(developmentPaths.planDetails, { developmentId, readyPlanId });
}

export function createDevelopmentPlanAndOptionsDetailsPlanDataUrl(developmentId: string, readyPlanId: string) {
  return generatePath(developmentPaths.planData, { developmentId, readyPlanId });
}

export function createDevelopmentPlanAndOptionsDetailsOptionDataUrl(developmentId: string, readyPlanId: string) {
  return generatePath(developmentPaths.optionData, { developmentId, readyPlanId });
}

export function createDevelopmentPlanAndOptionsDetailsActivityUrl(developmentId: string, readyPlanId: string) {
  return generatePath(developmentPaths.activity, { developmentId, readyPlanId });
}

export function createDevelopmentScopeTemplateUrl(developmentId: string, itemTemplateId: string): string {
  return generatePath(developmentPaths.scopeTemplate, { developmentId, itemTemplateId });
}

export function createDevelopmentReviewTemplatesUrl(developmentId: string) {
  return generatePath(developmentPaths.reviewTemplates, { developmentId });
}

export function createDevelopmentReviewCostsUrl(developmentId: string) {
  return generatePath(developmentPaths.reviewTemplateCosts, { developmentId });
}

export function createDevelopmentScopeTemplatesUrl(developmentId: string): string {
  return generatePath(developmentPaths.scopeTemplates, { developmentId });
}

export function createDevelopmentScopeTemplateCostReportUrl(developmentId: string, itemTemplateId: string): string {
  return generatePath(developmentPaths.scopeTemplateCostReport, { developmentId, itemTemplateId });
}

export function createDevelopmentScopeTemplateItemUrl(
  developmentId: string,
  itemTemplateId: string,
  itemTemplateItemId: string,
): string {
  return generatePath(developmentPaths.scopeTemplateItem, { developmentId, itemTemplateId, itemTemplateItemId });
}

export function createProjectBidContractsUrl(projectId: string): string {
  return generatePath(projectPaths.bidContracts, { projectId });
}

export function createProjectBidContractOverviewUrl(projectId: string, bidContractRevisionId: string): string {
  return generatePath(projectPaths.bidContractOverview, { projectId, bidContractRevisionId });
}

export function createProjectBidContractPlanPricingUrl(projectId: string, bidContractRevisionId: string): string {
  return generatePath(projectPaths.bidContractPlanPricing, { projectId, bidContractRevisionId });
}

export function createProjectBidContractUnitPricingUrl(projectId: string, bidContractRevisionId: string): string {
  return generatePath(projectPaths.bidContractUnitPricing, { projectId, bidContractRevisionId });
}

export function createProjectBidContractPurchaseOrdersUrl(projectId: string, bidContractRevisionId: string): string {
  return generatePath(projectPaths.bidContractPurchaseOrders, { projectId, bidContractRevisionId });
}

export function createDevelopmentContractUrl(developmentId: string, bidContractRevisionId: string): string {
  return generatePath(developmentPaths.contract, { developmentId, bidContractRevisionId });
}

export function createDevelopmentContractOverviewUrl(developmentId: string, bidContractRevisionId: string): string {
  return generatePath(developmentPaths.contractOverview, { developmentId, bidContractRevisionId });
}

export function createDevelopmentContractLineItemsUrl(developmentId: string, bidContractRevisionId: string): string {
  return generatePath(developmentPaths.contractLineItems, { developmentId, bidContractRevisionId });
}

export function createDevelopmentContractUnitPricingUrl(developmentId: string, bidContractRevisionId: string): string {
  return generatePath(developmentPaths.unitPricing, { developmentId, bidContractRevisionId });
}

export function createDevelopmentContractPurchaseOrdersUrl(
  developmentId: string,
  bidContractRevisionId: string,
): string {
  return generatePath(developmentPaths.contractPurchaseOrders, { developmentId, bidContractRevisionId });
}

export function createDevelopmentTaskLookaheadReportUrl(developmentId: string): string {
  return generatePath(developmentPaths.taskLookaheadReport, { developmentId });
}

export function createPlanDetailsUrl(developmentId: string, readyPlanId: string): string {
  return generatePath(developmentPaths.planDetails, { developmentId, readyPlanId });
}

export function createReadyPlanEditOptionsUrl(developmentId: string, readyPlanId: string): string {
  return generatePath(developmentPaths.editOptions, { developmentId, readyPlanId });
}

export function createGlobalOptionsUrl() {
  return generatePath(globalOptionsPath);
}

export function createGlobalOptionsLegacyUrl() {
  return generatePath(globalOptionsLegacyPath);
}

export function createGlobalOptionsFormUrl(idOrAdd: string = addEntityParam) {
  return generatePath(globalOptionPath, { idOrAdd });
}

export function createProductCatalogUrl() {
  return generatePath(productCatalogPath);
}

export function createMaterialCatalogUrl() {
  return generatePath(materialsCatalogPath);
}

export function createMaterialPageUrl() {
  return generatePath(materialCreatePath);
}

export function createMaterialDetailsUrl(variantId: string) {
  return generatePath(materialDetailsPath, { variantId });
}

export function createMaterialCostUrl(variantId: string) {
  return generatePath(materialCostPath, { variantId });
}

export function createInstallTasksUrl() {
  return generatePath(installTasksPath);
}

export function createPurchaseOrdersUrl() {
  return generatePath(purchaseOrdersPath);
}

export function createProductDetailsUrl(productId: string) {
  return generatePath(productDetailsPath, { productId });
}

export function createProductPageUrl() {
  return generatePath(productCreatePath);
}

export function createItemCatalogUrl() {
  return generatePath(itemCatalogPath);
}

export function createBidItemPageUrl() {
  return generatePath(bidItemCreatePath);
}

export function createBidItemDetailsUrl(bidItemId: string) {
  return generatePath(bidItemDetailsPath, { bidItemId });
}

export function createProjectScheduleUrl(projectId: string, stage?: Stage, taskId?: string): string {
  if (!stage) {
    return generatePath(projectPaths.scheduleBase, { projectId });
  }

  const path = generatePath(projectPaths.schedule, { projectId, stageSlug: getSlugFromStage(stage) });
  if (taskId) {
    return `${path}${generatePath("?taskId=:taskId", { taskId })}`;
  }
  return path;
}

export function createTeamMembersUrl(projectId: string) {
  return generatePath(projectPaths.team, { projectId });
}

export function createToDosPdfUrl(toDoIds: string[]) {
  return generateUrlWithQueries(toDoPdfPath, { toDoIds });
}

export function createFinishSchedulVisualModePdfUrl(params: Record<string, string | string[]>) {
  return generateUrlWithQueries(finishScheduleVisualModePdfPath, params);
}

export function createProjectFinishSchedulVisualModePdfUrl(params: Record<string, string | string[]>) {
  return generateUrlWithQueries(projectFinishScheduleVisualModePdfPath, params);
}

export function createProjectFinishSchedulPdfUrl(params: Record<string, string | string[]>) {
  return generateUrlWithQueries(projectFinishSchedulePdfPath, params);
}

export function createTradesPunchListPdfUrl(params: Record<string, string | string[]>) {
  return generateUrlWithQueries(tradesPunchlistPdfPath, params);
}

type TradePartnerPageTabs =
  | "details"
  | "contacts"
  | "commitments"
  | "development-contracts"
  | "agreements"
  | "reviews"
  | "requirements";
export function createTradePartnerUrl(idOrAdd = addEntityParam, tab?: TradePartnerPageTabs) {
  return `${generatePath(tradePartnerPaths.tradePartner, { idOrAdd })}${tab ? `/${tab}` : ""}`;
}

export function createTradePartnersUrl() {
  return generatePath(tradePartnersPath);
}

export function createPaymentTermsPageUrl() {
  return generatePath(paymentTermsPath);
}

type ProjectSettingsTab = "invoices" | "setup" | "teamMembers" | "homeowners" | "schedule";
export function createProjectSettingsUrl(projectId: string, tab: ProjectSettingsTab | null = null) {
  return `${generatePath(projectPaths.settings, { projectId })}${tab ? `/${tab}` : ""}`;
}

/**
 * Legacy helper which generates URLs for the new /pre-con-services and /specs-and-selection paths.
 * This reduces refactoring required for generic specs and selections components which accepts a stage as a prop:
 * - SpecsAndSelectionsPage
 * - SpecsAndSelectionsTable
 */
export function createProjectSelectionsUrl(projectId: string, stage: Stage, projectItemId?: string) {
  if (stage === Stage.PreConExecution || stage === Stage.PreConPlanning) {
    if (!projectItemId) return createPreConServicesUrl(projectId);
    return createPreConServicesDetailUrl(projectId, projectItemId);
  } else if (stage === Stage.Construction) {
    if (!projectItemId) return createSpecsAndSelectionsUrl(projectId);
    return createSpecsAndSelectionsDetailUrl(projectId, projectItemId);
  }

  throw new Error(`createProjectSelectionUrl cannot create route for stage ${stage}`);
}

/**
 * Legacy helper which generates URLs for the new /pre-con-services/:projectItemId
 * and /specs-and-selection/:projectItemId paths.
 * This reduces refactoring required for generic specs and selections components which accepts a stage as a prop:
 * - SpecsAndSelectionsPage
 * - SpecsAndSelectionsTable
 */
export function createProjectSelectionDetailsUrl(projectId: string, stage: Stage, projectItemId: string) {
  return createProjectSelectionsUrl(projectId, stage, projectItemId);
}

export function createPreConServicesUrl(projectId: string) {
  return generatePath(projectPaths.preConServices, { projectId });
}

export function createPreConServicesDetailUrl(projectId: string, projectItemId: string) {
  return generatePath(projectPaths.preConServicesDetail, { projectId, projectItemId });
}

export function createProjectLotSummaryUrl(projectId: string) {
  return generatePath(projectPaths.lotSummary, { projectId });
}

export function createSpecsAndSelectionsUrl(projectId: string) {
  return generatePath(projectPaths.specsAndSelections, { projectId });
}

export function createSpecsAndSelectionsDetailUrl(projectId: string, projectItemId: string) {
  return generatePath(projectPaths.specsAndSelectionsDetail, { projectId, projectItemId });
}

export function createInvoicesUrl(projectId: string) {
  return generatePath(projectPaths.invoices, { projectId });
}

export function createInvoiceUrl(projectId: string, idOrAdd: string = addEntityParam, stage?: Stage) {
  const path = generatePath(projectPaths.invoice, { projectId, idOrAdd });
  if (stage) {
    return `${path}${generatePath(`?stageSlug=:stageSlug`, { stageSlug: getSlugFromStage(stage) })}`;
  }

  return path;
}

export function createInvoiceOldUrl(projectId: string, idOrAdd: string = addEntityParam) {
  return generatePath(projectPaths.invoiceOld, { projectId, idOrAdd });
}

export function createEstimatesUrl(projectId: string) {
  return generatePath(projectPaths.estimates, { projectId });
}

// Unlike most CRUD screens, we don't create estimates via the `fooIdOrAdd` convention
// because they're created from a dedicated modal on the specs & selections screen.
export function createEstimateUrl(projectId: string, estimateId: string) {
  return generatePath(projectPaths.estimate, { projectId, estimateId });
}

export function createIndexExpensesUrl() {
  return generatePath(expensesPath);
}

export function createProjectExpensesUrl(projectId: string) {
  return generatePath(projectPaths.expenses, { projectId });
}

export function createProjectToDosUrl(projectId: string) {
  return generatePath(projectPaths.toDos, { projectId });
}

export function createProjectJobLogsUrl(projectId: string) {
  return generatePath(projectPaths.jobLogs, { projectId });
}

export function createProjectJobLogsGalleryUrl(projectId: string) {
  return generatePath(projectPaths.jobLogsGallery, { projectId });
}

export function createProjectJobLogNoteDrawerUrl(projectId: string, jobLogId: string, jobLogNoteId: string) {
  return generatePath(projectPaths.jobLogNoteDrawer, { projectId, jobLogId, jobLogNoteId });
}

export function createProjectDocumentsUrl(projectId: string) {
  return generatePath(projectPaths.documents, { projectId });
}

export function createProjectHomeownerNotesUrl(projectId: string, homeownerNoteId?: string) {
  const homeownerNotesPath = generatePath(projectPaths.homeownerNotes, { projectId });
  return homeownerNoteId
    ? `${homeownerNotesPath}${generatePath("?homeownerNoteId=:homeownerNoteId", { homeownerNoteId })}`
    : homeownerNotesPath;
}

export function createItemTemplatesUrl() {
  return generatePath(itemTemplatesPath);
}

export function createItemTemplateItemUrl(itemTemplateId: string, itemTemplateItemId: string) {
  return generatePath(itemTemplateItemPath, { itemTemplateId, itemTemplateItemId });
}

export function createItemTemplateUrl(id: string = addEntityParam) {
  return generatePath(itemTemplatePath, { idOrAdd: id });
}

export function createProjectDocumentUrl(projectId: string, documentId?: string | undefined | null) {
  const documentsPath = createProjectDocumentsUrl(projectId);
  return documentId ? `${documentsPath}${generatePath("?documentId=:documentId", { documentId })}` : documentsPath;
}

export function createDevelopmentDocumentUrl(developmentId: string, documentId?: string | undefined | null) {
  const documentsPath = createDevelopmentDocumentsUrl(developmentId);
  return documentId ? `${documentsPath}${generatePath("?documentId=:documentId", { documentId })}` : documentsPath;
}

export function createChangeEventsUrl(projectId: string) {
  return generatePath(projectPaths.changeEvents, { projectId });
}

export function createChangeEventUrl(projectId: string, changeEventId: string) {
  return generatePath(projectPaths.changeEvent, { projectId, changeEventId });
}

export function createChangeEventLineItemsUrl(projectId: string, changeEventId: string) {
  return generatePath(projectPaths.changeEventLineItems, { projectId, changeEventId });
}

export function createChangeEventOverviewUrl(projectId: string, changeEventId: string) {
  return generatePath(projectPaths.changeEventOverview, { projectId, changeEventId });
}

export function createChangeEventEstimatesUrl(projectId: string, changeEventId: string) {
  return generatePath(projectPaths.changeEventEstimates, { projectId, changeEventId });
}

export function createChangeEventCommitmentsUrl(projectId: string, changeEventId: string) {
  return generatePath(projectPaths.changeEventCommitments, { projectId, changeEventId });
}

export function createChangeEventHistoryUrl(projectId: string, changeEventId: string) {
  return generatePath(projectPaths.changeEventHistory, { projectId, changeEventId });
}

export function createGlobalChangeLogUrl() {
  return generatePath(changeLogPaths.base);
}

export function createGlobalChangeLogDetailsUrl(changeRequestId: string) {
  return generatePath(changeLogPaths.details, { changeRequestId });
}

export function createProjectChangeLogUrl(projectId: string) {
  return generatePath(projectPaths.changeLog, { projectId });
}

export function createProjectChangeLogDetailsUrl(projectId: string, changeRequestId: string) {
  return generatePath(projectPaths.changeLogDetails, { projectId, changeRequestId });
}

export function createDevelopmentChangeLogUrl(developmentId: string) {
  return generatePath(developmentPaths.changeLog, { developmentId });
}

export function createDevelopmentChangeLogDetailsUrl(developmentId: string, changeRequestId: string) {
  return generatePath(developmentPaths.changeLogDetails, { developmentId, changeRequestId });
}

export function createBusinessFunctionsUrl() {
  return generatePath(changeLogPaths.businessFunctions);
}

export function createChangeTypesUrl() {
  return generatePath(changeLogPaths.changeTypes);
}

export function createBusinessFunctionDetailsUrl(businessFunctionId: string) {
  return generatePath(changeLogPaths.businessFunctionDetails, { businessFunctionId });
}

function createUrlWithUntaggedIds(path: string, params: Record<string, string | string[]>) {
  // Since we may be using potentially hundreds of ids, we need to save all the space we can in our urls
  // so as not to exceed browser length limitations. As such, we need to strip out tags from ids and use our own
  // formatting for the query string instead of using `use-query-params`'s formatting.
  const queryString = Object.entries(params)
    .map(([key, ids]) => `${key}=${(Array.isArray(ids) ? ids : [ids]).map(maybeDeTagId).join(",")}`)
    .join("&");
  return `${path}?${queryString}`;
}

/** Generates a path with queries for the given object with safe de-tagging Id handling */
function generateUrlWithQueries(path: string, params: Record<string, string | string[] | undefined>) {
  const queryString = Object.entries(params)
    .map(([key, ids]) => {
      if (Array.isArray(ids)) {
        // Improved URL array notation to match use-query-params acceptable pattern of
        // ?filters=a&filters=b&filters=c
        // https://www.npmjs.com/package/use-query-params
        return ids.map((id) => `${key}=${maybeDeTagId(id)}`).join("&");
      } else if (ids) {
        return `${key}=${maybeDeTagId(ids)}`;
      }
      return ids;
    })
    // Filtering undefined values
    .filter((query) => !!query)
    .join("&");
  return `${path}?${queryString}`;
}

export function createProjectItemScopeOfWorkUrl(projectItemIds: string[]) {
  return createUrlWithUntaggedIds(projectItemScopeOfWorkPath, { projectItemIds });
}

export function createTradeScopeOfWorkUrl(changeEventLineItemIds: string[]) {
  return createUrlWithUntaggedIds(tradeScopeOfWorkPath, { changeEventLineItemIds });
}

export function createScheduleOfValuesUrl(projectItemIds: string[]) {
  return createUrlWithUntaggedIds(scheduleOfValuesPath, { projectItemIds });
}

export function createEstimateExportUrl(estimateId: string) {
  return createUrlWithUntaggedIds(estimateExportPath, { estimate: [estimateId] });
}

export function createChangeOrderExhibitUrl(estimateId: string) {
  return createUrlWithUntaggedIds(homeownerChangeOrderPath, { estimateId: [estimateId] });
}

export function createDevelopmentLotConfigConfirmUrl(developmentId: string, projectId: string) {
  return generatePath(developmentLotConfigPaths.confirm, { developmentId, projectId });
}

export function createDevelopmentLotConfigReviewUrl(developmentId: string, projectId: string) {
  return generatePath(developmentLotConfigPaths.review, { developmentId, projectId });
}

/**
 * Generates a URL that includes the start and end date of a schedule export
 * with optional traderPartners or activities to include in the schedule.
 */
export function createScheduleExportUrl(
  scheduleId: string,
  startDate: Date,
  endDate: Date,
  tradePartnerIds?: string[],
  scheduleSubPhaseIds?: string[],
  withGantt: boolean = false,
) {
  return generateUrlWithQueries(generatePath(scheduleExportPath, { scheduleId }), {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    tradePartnerIds,
    scheduleSubPhaseIds,
    withGantt: withGantt ? "1" : "0",
    limit: "2000",
  });
}

export function createProjectFinishScheduleUrl(projectId: string) {
  return generatePath(projectPaths.finishSchedule, { projectId });
}

export function createTradeSpecificAgreementUrl(costDivisionId: string, tradePartnerId: string) {
  return (
    generatePath(tradeSpecificAgreementPath) + `?costDivisionId=${costDivisionId}&tradePartnerId=${tradePartnerId}`
  );
}

export function createDevelopmentCommitmentPdfUrl(developmentCommitmentId: string) {
  return generatePath(developmentCommitmentPdfPath) + `?developmentCommitmentId=${developmentCommitmentId}`;
}

export function createCommitmentPdfUrl(commitmentId: string) {
  return generatePath(commitmentPdfPath) + `?commitmentId=${commitmentId}`;
}

export function createInvoicePandaDocPdfUrl(invoiceId: string) {
  return generatePath(invoicePandaDocPdfPath) + `?invoiceId=${invoiceId}`;
}

export function createInvoicePdfUrl(invoiceId: string) {
  return generatePath(invoicePdfPath) + `?invoiceId=${invoiceId}`;
}

export function createLienWaiverPdf(lienWaiverId: string) {
  return generatePath(lienWaiverPdfPath) + `?lienWaiverId=${lienWaiverId}`;
}

export function createDevelopmentCommitmentsUrl() {
  return generatePath(developmentCommitmentsPaths.developmentCommitments);
}

export function createDevelopmentCommitmentUrl(developmentCommitmentId: string) {
  return generatePath(developmentCommitmentsPaths.developmentCommitment, { developmentCommitmentId });
}

export function createCommitmentChangeOrderPdfUrl(commitmentChangeOrderId: string) {
  return generatePath(commitmentChangeOrderPdfPath) + `?commitmentChangeOrderId=${commitmentChangeOrderId}`;
}

export function createDevelopmentContractPdfUrl(bidContractId: string) {
  return generatePath(developmentContractPdfPath) + `?bidContractId=${bidContractId}`;
}

export function createBidCommitmentPdfUrl(bidCommitmentId: string) {
  return generatePath(bidCommitmentPdfPath) + `?bidCommitmentId=${bidCommitmentId}`;
}

export function createBidCommitmentChangeOrderPdfUrl(bidCommitmentChangeOrderId: string) {
  return generatePath(bidCommitmentChangeOrderPdfPath) + `?bidCommitmentChangeOrderId=${bidCommitmentChangeOrderId}`;
}

export function createBillPdfUrl(billId: string) {
  return generatePath(billPdfPath) + `?billId=${billId}`;
}

export function createScheduleTemplateUrl(scheduleTemplateId = addEntityParam) {
  return generatePath(scheduleTemplatePath.scheduleTemplate, { scheduleTemplateId });
}

export function createScheduleTemplatesUrl() {
  return generatePath(scheduleTemplatesPath);
}

export function createLotSequenceSheetPdfUrl(developmentId: string, filter: string | null) {
  const path = `${lotSequenceSheetPdfPath}?developmentId=${developmentId}`;
  return filter ? `${path}&filter=${filter}` : path;
}

export function createLotSummaryDetailsPdfPath(projectReadyPlanConfigId: string): string {
  return lotSummaryDetailsPdfPath + `?projectReadyPlanConfigId=${projectReadyPlanConfigId}`;
}

type DashboardTab = "financials" | "activity" | "pre-con-expectations";
export function createProjectDashboardUrl(projectId: string, tab: DashboardTab | null = null) {
  return `${generatePath(projectPaths.dashboard, { projectId })}${tab ? `/${tab}` : ""}`;
}

export function createWarrantyTicketDetailsUrl(warrantyTicketId: string, tab: string | null = null) {
  return `${generatePath(warrantyPaths.details, { warrantyTicketId })}${tab ? `?tab=${tab}` : ""}`;
}

export function createApprovalDashboardUrl(approvalId: string, tab: string | null = null) {
  return personalDashboardPaths.approvals + `?approvalId=${approvalId}`;
}

export function createTaskCatalogUrl() {
  return generatePath(catalogTasksPath);
}

export function createTaskCatalogFormUrl(idOrAdd: string = addEntityParam, copyFromTaskId?: string) {
  if (copyFromTaskId) {
    return `${generatePath(catalogTaskPath, { idOrAdd })}?copyFromTaskId=${copyFromTaskId}`;
  }
  return generatePath(catalogTaskPath, { idOrAdd });
}

export function createTaskCatalogLaborCostsPageUrl(globalPlanTaskId: string) {
  return generatePath(catalogTaskLaborCosts, { globalPlanTaskId });
}

export function createDesignPackagesUrl() {
  return generatePath(designCatalogsPath);
}

/** Links to the design package configurator, requires versionId to make sure callers don't forget it. */
export function createDesignPackageUrl(
  designPackageId: string,
  versionId: string | "latest",
  acceptUpdates: boolean = false,
) {
  return generatePathWithQueryStrings(
    designCatalogPath,
    { designPackageId, designPackageVersionId: versionId === "latest" ? undefined : versionId },
    acceptUpdates ? { acceptUpdates: "true" } : {},
  );
}

export function createDesignPackageFinishScheduleUrl(designPackageId: string, designPackageVersionId: string) {
  return generatePath(designPackageFinishSchedulePath, { designPackageId, designPackageVersionId });
}

export function createDesignPackageCompareModeUrl(designPackageId: string) {
  return generatePath(designPackageCompareModePath, { designPackageId });
}

export function createDesignPackageNewSlotUrl(designPackageId: string, designPackageVersionId: string) {
  return generatePath(designPackageNewSlotPath, { designPackageId, designPackageVersionId });
}

export function createDesignPackageAddToSlotUrl(designPackageId: string, designPackageVersionId: string) {
  return generatePath(designPackageAddToSlotPath, { designPackageId, designPackageVersionId });
}

export function createDesignPackageAddProductUrl(
  designPackageId: string,
  designPackageVersionId: string,
  placeholderTliId: string,
  productTliId: string,
) {
  return generatePath(designPackageAddProductPath as string, {
    designPackageId,
    designPackageVersionId,
    placeholderTliId,
    productTliId,
  });
}

export function createDesignPackageSlotDetailUrl(
  designPackageId: string,
  designPackageVersionId: string,
  placeholderTliId: string,
  productTliId?: string,
  bidItemId?: string | "noBidItem",
) {
  return generatePathWithQueryStrings(
    designPackageSlotDetailPath,
    { designPackageId, designPackageVersionId, placeholderTliId, productTliId },
    { bidItemId },
  );
}

export function createDesignPackageProductSearchUrl(
  designPackageId: string,
  designPackageVersionId: string,
  placeholderTliId: string,
  productTliId?: string,
) {
  return generatePath(designPackageProductSearchPath, {
    designPackageId,
    designPackageVersionId,
    placeholderTliId,
    productTliId,
  });
}

export function createDesignPackageVersionHistoryUrl(designPackageId: string) {
  return generatePath(designPackageVersionHistoryPath, { designPackageId });
}

export function createTaskCatalogFolderUrl(folderId: string) {
  return generatePath(catalogTaskFolderPath, { folderId });
}

export function createPlanPackagesUrl() {
  return generatePath(planPackagesPath);
}

export function createPlanPackageUrl(planPackageId: string, planPackageVersionId: string): string {
  return generatePath(planPackagePath, {
    planPackageId: planPackageId,
    planPackageVersionId: planPackageVersionId,
  });
}

export function createPlanPackageDetailsOverviewUrl(maybePlanPackageId?: string, maybePlanPackageVersionId?: string) {
  return maybePlanPackageId
    ? generatePath(planPackageDetailsOverview, {
        planPackageId: maybePlanPackageId,
        planPackageVersionId: maybePlanPackageVersionId,
      })
    : generatePath(planPackagesPath);
}

export function createPlanPackageDetailsOptionDataUrl(maybePlanPackageId?: string, maybePlanPackageVersionId?: string) {
  return maybePlanPackageId
    ? generatePath(planPackageDetailsOptionData, {
        planPackageId: maybePlanPackageId,
        planPackageVersionId: maybePlanPackageVersionId,
      })
    : generatePath(planPackagesPath);
}

export function createPlanPackageDetailsActivityUrl(maybePlanPackageId?: string) {
  return maybePlanPackageId
    ? generatePath(planPackageDetailsActivity, { planPackageId: maybePlanPackageId })
    : generatePath(planPackagesPath);
}

export function createPlanPackageCreateUrl(): string {
  return generatePath(planPackageEditPath as string, {});
}

export function createPlanPackageEditUrl(maybePlanPackageId: string, maybePlanPackageVersionId: string): string {
  return generatePath(planPackageEditPath as string, {
    planPackageId: maybePlanPackageId,
    planPackageVersionId: maybePlanPackageVersionId,
  });
}

export function createPlanPackageTakeoffUrl(planPackageId: string, rpavId: string) {
  return generatePath(planPackageTakeoffsPath, { planPackageId, rpavId });
}

export function createPlanPackageVersionHistoryUrl(planPackageId: string) {
  return generatePath(planPackageVersionHistoryPath, { planPackageId });
}

export function createConstraintItemCatalogUrl() {
  return generatePath(constraintItemsCatalogPath);
}

export function createTaskDetailsPageUrl(projectId: string, planTaskId: string, query?: { scrollIntoView?: string }) {
  const { scrollIntoView } = query ?? {};
  const queryString = scrollIntoView ? `?scrollIntoView=${scrollIntoView}` : "";
  return `${generatePath(dynamicSchedulesPath.details, { projectId, planTaskId })}${queryString}`;
}

export function createTaskMaterialsPageUrl(projectId: string, planTaskId: string) {
  return `${generatePath(dynamicSchedulesPath.materials, { projectId, planTaskId })}`;
}
export function createTaskMaterialsPageVariantDetailsUrl(projectId: string, planTaskId: string, variantId: string) {
  return `${generatePath(dynamicSchedulesPath.materialsDetails, { projectId, planTaskId, variantId })}`;
}

export function createMilestoneDetailsPageUrl(projectId: string, planMilestoneId: string) {
  return `${generatePath(dynamicSchedulesPath.milestoneDetails, { projectId, planMilestoneId })}`;
}

export function createDraftScheduleUrl(projectId: string) {
  return generatePath(dynamicSchedulesPath.draftMode, { projectId });
}

export function createDraftScheduleNewTaskUrl(projectId: string) {
  return generatePath(dynamicSchedulesPath.draftNewTask, { projectId });
}

export function createDraftSchedulePublishUrl(projectId: string) {
  return generatePath(dynamicSchedulesPath.draftPublish, { projectId });
}

export function createDraftCalendarUrl(projectId: string) {
  return generatePath(dynamicSchedulesPath.draftCalendar, { projectId });
}

export function createDraftMilestoneUrl(projectId: string) {
  return generatePath(dynamicSchedulesPath.draftMilestone, { projectId });
}

export function createDraftGanttUrl(projectId: string) {
  return generatePath(dynamicSchedulesPath.draftGantt, { projectId });
}

export function createDraftLookaheadUrl(projectId: string) {
  return generatePath(dynamicSchedulesPath.draftLookahead, { projectId });
}

export function createTradesTradePartnersUrl() {
  return generatePath(tradesTradePartnersPath);
}

export function createTradesPurchaseOrdersUrl() {
  return generatePath(tradesPurchaseOrdersPath);
}

export function createBidPackagesUrl(developmentId?: Maybe<string>, projectId?: Maybe<string>) {
  if (developmentId) {
    return generatePath(developmentPaths.bidPackages, { developmentId });
  }
  if (projectId) {
    return generatePath(projectPaths.bidPackages, { projectId });
  }
  return generatePath(bidPackagesPath);
}

export function createMilestoneCatalogUrl() {
  return generatePath(milestonesCatalogPath);
}

export function createMilestoneCatalogFormUrl(idOrAdd: string = addEntityParam) {
  return generatePath(milestoneCatalogPath, { idOrAdd });
}

export function createProductOfferingsUrl(developmentId?: Maybe<string>, projectId?: Maybe<string>) {
  return developmentId
    ? generatePath(developmentPaths.productOfferings, { developmentId })
    : projectId
      ? generatePath(projectPaths.productOfferings, { projectId })
      : generatePath(globalProductOfferingsPath);
}

export function createProductOfferingConfigEditUrl(params: {
  productOfferingConfigId: string;
  productOfferingId: string;
  developmentId?: string;
  projectId?: string;
  configIds?: string[];
}) {
  const { developmentId, productOfferingConfigId, productOfferingId, configIds, projectId } = params;
  // support returning to the compare page with the same configs selected
  // after editing a config
  const compareConfigIds = configIds ? new URLSearchParams({ configIds: JSON.stringify(configIds) }) : ""; // --> ?configIds=["poc:1","poc:2"]
  return `${generatePath(productOfferingConfigEditPath, { productOfferingId, productOfferingConfigId })}${
    developmentId
      ? `?devId=${developmentId}&${compareConfigIds}`
      : projectId
        ? `?projectId=${projectId}&${compareConfigIds}`
        : `?${compareConfigIds}`
  }`;
}

export function createProductOfferingConfigUrl(params: {
  idOrAdd: string;
  productOfferingId: string;
  developmentId?: string;
  projectId?: string;
}) {
  const { developmentId, projectId, idOrAdd = addEntityParam, productOfferingId } = params;
  let queryString: string = "";
  if (developmentId) queryString += `?devId=${developmentId}`;
  if (projectId) queryString += `?projectId=${projectId}`;
  return `${generatePath(productOfferingConfigPath, { productOfferingId, idOrAdd })}${queryString}`;
}

export function createProductOfferingConfigsUrl(
  developmentId?: Maybe<string>,
  projectId?: Maybe<string>,
  productOfferingId?: string,
) {
  if (developmentId) {
    return productOfferingId
      ? generatePath(developmentPaths.productOfferingSpecificConfigs, { developmentId, productOfferingId })
      : generatePath(developmentPaths.productOfferingConfigs, { developmentId });
  }
  if (projectId) {
    return productOfferingId
      ? generatePath(projectPaths.productOfferingSpecificConfigs, { projectId, productOfferingId })
      : generatePath(projectPaths.productOfferingConfigs, { projectId });
  }
  return generatePath(globalProductOfferingConfigsPath);
}

export function createProductOfferingConfigsCompareUrl(
  configIds: string[],
  productOfferingId?: string,
  developmentId?: Maybe<string>,
  projectId?: Maybe<string>,
) {
  const params = new URLSearchParams({ configIds: JSON.stringify(configIds) }); // --> ?configIds=["poc:1","poc:2"]
  if (developmentId) {
    return productOfferingId
      ? `${generatePath(developmentPaths.productOfferingSpecificConfigsCompare, {
          productOfferingId,
          developmentId,
        })}?${params}`
      : `${generatePath(developmentPaths.productOfferingConfigsCompare, { developmentId })}?${params}`;
  }
  if (projectId) {
    return productOfferingId
      ? `${generatePath(projectPaths.productOfferingSpecificConfigsCompare, {
          productOfferingId,
          projectId,
        })}?${params}`
      : `${generatePath(projectPaths.productOfferingConfigsCompare, { projectId })}?${params}`;
  }
  return `${generatePath(productOfferingConfigsComparePath)}?${params}`;
}

export function createProductOfferingVersionHistoryUrl(productOfferingId: string, developmentId: string) {
  return generatePath(developmentPaths.productOfferingVersionHistory, { developmentId, productOfferingId });
}

export function createProjectProductOfferingVersionHistoryUrl(productOfferingId: string, projectId: string) {
  return generatePath(projectPaths.productOfferingVersionHistory, { projectId, productOfferingId });
}

function generatePathWithQueryStrings<S extends string>(
  path: S,
  params: ExtractRouteParams<S>,
  qs: Record<string, string | undefined>,
): string {
  const search = new URLSearchParams(
    qs
      .toEntries()
      .filter(([, value]) => !!value)
      .toObject() as Record<string, string>,
  );
  return search.size ? [generatePath(path, params), search].join("?") : generatePath(path, params);
}
