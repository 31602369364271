import { useParams } from "react-router";
import { BidContractsTable } from "src/components/bid-contracts/BidContractsTable";
import { useDevelopmentContractQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { GenerateNewBidRequestNotice } from "./components/GenerateNewBidRequestNotice";

export function DevelopmentContractsTable() {
  const { developmentId } = useParams<{ developmentId: string }>();
  const query = useDevelopmentContractQuery({ variables: { developmentId } });
  return queryResult(query, {
    data: ({ development }) => (
      <>
        <GenerateNewBidRequestNotice developmentId={development.id} />
        <BidContractsTable bidContracts={development.bidContracts} />
      </>
    ),
  });
}
