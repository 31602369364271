import { possibleTypes, ProjectStatus, ProjectTypeName, Stage } from "src/generated/graphql-types";

export const contractualStages = [Stage.PreConExecution, Stage.Construction];
export function isContractualStage(stage: Stage): boolean {
  return contractualStages.includes(stage);
}

export const nonClosedProjects = [ProjectStatus.Active, ProjectStatus.Completed, ProjectStatus.UnderWarranty];

export function isProjectType(value: { __typename?: string } | undefined): value is { __typename: ProjectTypeName } {
  if (!value || !value.__typename) return false;
  return possibleTypes.Project.includes(value.__typename);
}
