import {
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  FormHeading,
  FormLines,
  ScrollableContent,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { useParams } from "react-router";
import {
  LotType,
  ProjectInput,
  SetUpTabProjectFragment,
  SetUpTabQuery,
  useSaveProjectSetUpTabMutation,
  useSetUpTabQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { basisPointsToPercentage } from "src/utils/basisPoints";

export function SetUpTab() {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useSetUpTabQuery({ variables: { projectId } });

  return queryResult(query, {
    data: (data) => <SetUpTabDataView data={data} />,
  });
}

type SetUpTabDataViewProps = {
  data: SetUpTabQuery;
};

export function SetUpTabDataView({ data }: SetUpTabDataViewProps) {
  const { project, productTypes, preconContractTypes, lotRatings } = data;
  const undecidedOption = { id: undefined, name: "Undecided" };
  const undecidedLotRating = { id: undefined, grade: "Incomplete", description: "" };

  const [saveProjectSetUpTab] = useSaveProjectSetUpTabMutation();

  const formState = useFormState({
    config: formConfig,
    init: { input: project, map: mapToFormState },
    autoSave: async ({ changedValue }) => {
      const projectInput = mapFormToInput(changedValue);
      await saveProjectSetUpTab({ variables: { input: projectInput } });
      formState.commitChanges();
    },
  });

  return (
    <ScrollableContent>
      <FormLines width="sm">
        <FormHeading title="Project Types" />
        <BoundNumberField
          label="Sales Tax Rate"
          type="basisPoints"
          field={formState.salesTaxOverrideInBasisPoints}
          placeholder={`${basisPointsToPercentage(project.cohort?.development?.salesTaxInBasisPoints ?? 0)}%`}
        />
        <BoundSelectField
          label="Product Type"
          field={formState.productTypeId}
          options={[undecidedOption, ...productTypes]}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id!}
        />
        <BoundSelectField
          label="Pre-Con Contract Type"
          field={formState.preconContractTypeId}
          options={[undecidedOption, ...preconContractTypes]}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id!}
        />
        <BoundSelectField
          label="Lot Rating"
          field={formState.lotRatingId}
          options={[undecidedLotRating, ...lotRatings]}
          getOptionLabel={({ grade, description }) => `${grade}${description && " - "}${description}`}
          getOptionValue={({ id }) => id!}
        />
        <BoundTextField label="Checkout Customer Configuration ID" field={formState.checkoutCustomerConfigId} />
        <FormHeading title="Design Options" />
        <BoundTextAreaField label="Initial intake style notes" field={formState.intakeStyleNotes} />
        <BoundNumberField
          label="BOOL Direct Cost Markup"
          field={formState.directCostMarkupOverrideBasisPoints}
          type="basisPoints"
          helperText="The direct cost markup for this project"
          readOnly={formState.lotType.value !== LotType.Bool}
        />
        <BoundNumberField
          label="BOOL Indirect Cost Markup"
          field={formState.indirectCostMarkupOverrideBasisPoints}
          type="basisPoints"
          helperText="The indirect cost markup for this project"
          readOnly={formState.lotType.value !== LotType.Bool}
        />
      </FormLines>
    </ScrollableContent>
  );
}
type FormValue = Pick<
  ProjectInput,
  | "id"
  | "lotType"
  | "productTypeId"
  | "preconContractTypeId"
  | "lotRatingId"
  | "intakeStyleNotes"
  | "checkoutCustomerConfigId"
  | "salesTaxOverrideInBasisPoints"
  | "indirectCostMarkupOverrideBasisPoints"
  | "directCostMarkupOverrideBasisPoints"
>;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  lotType: { type: "value" },
  productTypeId: { type: "value" },
  preconContractTypeId: { type: "value" },
  lotRatingId: { type: "value" },
  intakeStyleNotes: { type: "value" },
  checkoutCustomerConfigId: { type: "value" },
  salesTaxOverrideInBasisPoints: { type: "value" },
  indirectCostMarkupOverrideBasisPoints: { type: "value" },
  directCostMarkupOverrideBasisPoints: { type: "value" },
};

function mapToFormState(project: SetUpTabProjectFragment): FormValue {
  if (!project) return {} as FormValue;

  const {
    id,
    lotType,
    productType,
    preconContractType,
    lotRating,
    intakeStyleNotes,
    checkoutCustomerConfigId,
    salesTaxOverrideInBasisPoints,
    directCostMarkupOverrideBasisPoints,
    indirectCostMarkupOverrideBasisPoints,
  } = project;

  return {
    id,
    lotType: lotType?.code,
    productTypeId: productType?.id,
    preconContractTypeId: preconContractType?.id,
    lotRatingId: lotRating?.id,
    intakeStyleNotes,
    checkoutCustomerConfigId,
    salesTaxOverrideInBasisPoints,
    indirectCostMarkupOverrideBasisPoints,
    directCostMarkupOverrideBasisPoints,
  };
}

export function mapFormToInput(project: FormValue): ProjectInput {
  const { ...projectFields } = project;
  const {
    id,
    lotType,
    productTypeId,
    preconContractTypeId,
    lotRatingId,
    intakeStyleNotes,
    checkoutCustomerConfigId,
    salesTaxOverrideInBasisPoints,
    indirectCostMarkupOverrideBasisPoints,
    directCostMarkupOverrideBasisPoints,
  } = projectFields;

  const projectInput: ProjectInput = {
    id,
    lotType,
    productTypeId,
    preconContractTypeId,
    lotRatingId,
    intakeStyleNotes,
    checkoutCustomerConfigId,
    salesTaxOverrideInBasisPoints,
    indirectCostMarkupOverrideBasisPoints,
    directCostMarkupOverrideBasisPoints,
  };

  return projectInput;
}
