import { Accordion, Button, Checkbox, Css, SelectField, Tag, useTestIds } from "@homebound/beam";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { createProductOfferingScopeUrl, createProductOfferingUrl } from "src/RouteUrls";
import {
  BasicFilterOptions,
  FilterLocationSectionData,
  FilterSectionData,
  LocationsFilterSelectTree,
} from "src/components/LocationFilterSelectTree";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  CostType,
  InputMaybe,
  ProductOfferingScopeMetaQuery,
  TakeoffLineItemFilter,
  useProductOfferingScopePageSideBarQuery,
} from "src/generated/graphql-types";
import { PLAN_FALLBACK_IMG } from "src/routes/libraries/plan-package/takeoffs/utils";
import { itemTemplateStatusToTagType, pluralize } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";
import { useProductOfferingContext, useProductOfferingStore } from "./ProductOfferingScopeContext";

export type ProductOfferingScopePageSideBarProps = ProductOfferingScopeMetaQuery;

// Most of this is copied from the
// PlanPackageTakeoffSideBar component - src/routes/libraries/plan-package/takeoffs/PlanPackageTakeoffSideBar.tsx
// Since need to support the same views and subFilter functionality of that table, for offering scope
export function ProductOfferingScopePageSideBar(props: ProductOfferingScopePageSideBarProps) {
  const { productOffering } = props;
  const [{ devId, projectId }] = useQueryParams({
    devId: StringParam,
    projectId: StringParam,
  });
  const { data, loading, refetch } = useProductOfferingScopePageSideBarQuery({
    variables: { productOfferingId: productOffering.id, versionId: productOffering.version.id },
  });
  const [allCollapsed, setAllCollapsed] = useState(true);
  const history = useHistory();
  const filter = useProductOfferingStore((state) => state.filter);
  const setFilter = useProductOfferingStore((state) => state.setFilter);
  const store = useProductOfferingContext();
  const tid = useTestIds(props, "ProductOfferingScopePageSideBar");

  const pof = data?.productOffering;
  const { options: allOptions = [], costCodes = [], itemLocations = [] } = pof ?? {};

  const [elevations, options] = useMemo(
    () =>
      allOptions
        ?.sortByKey("order")
        .partition(({ type }) => type.isElevation)
        .map((options) =>
          options.map(({ id, displayName, type }, index) => ({
            id,
            name: displayName,
            isBase: type.isElevation && index === 0,
          })),
        ),
    [allOptions],
  );

  // Update the store with the refetch functionality so we can trigger it when takeoffLineItems are modified
  useEffect(() => {
    store.setState({ refetchFilters: refetch });
  }, [store, refetch]);

  // Prune out any filters that are no longer valid
  useEffect(() => {
    if (!loading) {
      let updated = false;

      function prune(
        arr: InputMaybe<string>[] | undefined | null,
        validValues: InputMaybe<string>[],
      ): InputMaybe<string>[] | undefined | null {
        if (!arr) return arr;
        const prunedArray = arr.filter((item) => validValues.includes(item));
        if (prunedArray.length !== arr.length) {
          updated = true;
          return prunedArray;
        }
        return arr;
      }

      const currentFilter = store.getState().filter;
      const validLocationIds = itemLocations.map(({ id }) => id);
      const validCostCodeIds = costCodes.map(({ id }) => id);
      const validOptionIds = allOptions.map(({ id }) => id);

      // Use `compact` to remove `null` and `undefined` values from `location` as it is not supported
      const prunedLocations = prune(currentFilter.location, validLocationIds)?.compact();
      const prunedCostCodes = prune(currentFilter.costCode, validCostCodeIds)?.compact();
      const prunedOptions = prune(currentFilter.options, validOptionIds);

      // We don't want to call `set` unless we have to, since it will trigger a re-render
      if (updated) {
        setFilter({
          ...currentFilter,
          location: prunedLocations?.length ? prunedLocations : undefined,
          costCode: prunedCostCodes?.length ? prunedCostCodes : undefined,
          options: prunedOptions?.length ? prunedOptions : undefined,
        });
      }
    }
  }, [allOptions, itemLocations, costCodes, store, loading, setFilter]);

  const filters: (FilterSectionData | FilterLocationSectionData)[] = useMemo(() => {
    function sortByNameProp(a: { name: string }, b: { name: string }) {
      const lowerA = a.name.toLowerCase(),
        lowerB = b.name.toLowerCase();
      if (lowerA < lowerB) return -1;
      if (lowerA > lowerB) return 1;
      return 0;
    }

    return [
      { label: "Elevations", options: elevations.sort(sortByNameProp), delta: true, filterField: "options" as const },
      { label: "Options", options: options.sort(sortByNameProp), delta: true, filterField: "options" as const },
      { label: "Cost Codes", options: [...costCodes].sort(sortByNameProp), filterField: "costCode" as const },
      {
        label: "Item Type",
        options: [
          // NOTE: This filter group is a little strange, since the UX is grouping together essentially different concepts
          { id: CostType.Labor, name: "Labor (Tasks)" },
          { id: CostType.Materials, name: "Materials" },
        ],
        filterField: "costType" as const,
      },
      { label: "Location" as const, options: [...itemLocations].sort(sortByNameProp) },
    ];
  }, [elevations, options, itemLocations, costCodes]);

  const navigateToRpav = useCallback(
    (rpavId: string) => {
      history.push(createProductOfferingScopeUrl(productOffering.id, rpavId, devId, projectId));
    },
    [productOffering.id, history, devId, projectId],
  );

  return (
    <div css={Css.bgWhite.$}>
      <div css={Css.p3.df.fdc.gap2.$}>
        <Button
          variant="text"
          onClick={createProductOfferingUrl(productOffering.id, devId, projectId)}
          label="Back"
          icon="arrowBack"
        />
        <div css={Css.bgGray100.p2.$}>
          <img
            src={productOffering.coverAsset?.downloadUrl ?? PLAN_FALLBACK_IMG}
            loading="lazy"
            alt="Product Offering cover"
            {...tid.coverPhoto}
          />
        </div>
        <div css={Css.dg.gtc("50% 50%").rg1.cg2.aic.$}>
          <div css={Css.baseBd.fg1.$} {...tid.planName}>
            {productOffering.name}
          </div>
          <div css={Css.wPx(90).$}>
            <SelectField
              {...tid.planVersion}
              label="Version"
              labelStyle="hidden"
              sizeToContent
              compact
              value={productOffering.version.id}
              options={productOffering.aggregateVersions}
              getOptionMenuLabel={({ id, status, displayVersion }) => (
                <div css={Css.w100.df.jcsb.$} key={id}>
                  <div css={Css.mwPx(115).df.fdc.$}>
                    <span css={Css.sm.$}>{displayVersion}</span>
                  </div>
                  <Tag type={itemTemplateStatusToTagType(status.code)} text={status.name} data-testid="statusTag" />
                </div>
              )}
              getOptionLabel={({ displayVersion }) => `${displayVersion}`}
              getOptionValue={({ id }) => id}
              onSelect={(rpavId) => navigateToRpav(rpavId!)}
            />
          </div>
          <div css={Css.df.aic.$}>
            <Button
              variant="text"
              onClick={createProductOfferingUrl(productOffering.id, devId, projectId)}
              label={productOffering.name}
              {...tid.planCode}
            />
          </div>
          <div css={Css.df.aic.gap1.$}>
            <StatusIndicator status={productOffering.status.code} type="ReadyPlan" />
            <div css={Css.hPx(32).df.aic.$} {...tid.planStatus}>
              {productOffering.status.name}
            </div>
          </div>
          <div css={Css.xs.$} {...tid.programData}>
            {productOffering.programData.bedrooms ?? 0} bed, {productOffering.programData.fullBaths ?? 0} bath
            <br />
            {productOffering.programData.sellableSqft} sqft
          </div>
          <div css={Css.xs.$} {...tid.optionsCount}>
            {elevations.length} {pluralize(elevations.length, "Elevation")}
            <br />
            {options.length} {pluralize(options.length, "Option")}
          </div>
        </div>
      </div>

      <div css={Css.p2.pt1.df.fdc.gap2.$} {...tid.basePlanSection}>
        <div css={Css.df.jcsb.$}>
          <div css={Css.xsSb.$}>Base Plan</div>
          <div css={Css.xs.$}>
            <Button
              variant="text"
              label={allCollapsed ? "Expand All" : "Collapse All"}
              onClick={() => setAllCollapsed((collapsed) => !collapsed)}
              {...tid.expandToggle}
            />
          </div>
        </div>
        <div css={Css.df.fdc.gap1.$}>
          <label css={Css.gray700.df.aic.gap1.$}>
            <Checkbox
              label=""
              checkboxOnly
              withLabelElement={false}
              onChange={updateFilterWithOption(null, "options", setFilter)}
              selected={(filter["options"] as any[])?.includes(null)}
              {...tid[`null_checkbox`]}
            />
            <span css={Css.tiny.$} {...tid[`null_label`]}>
              {productOffering.name}
            </span>
          </label>
        </div>
      </div>
      <div {...tid.filterSection}>
        {filters.map((section, i) => {
          const { label } = section;
          return isLocationFilterSection(section) ? (
            <LocationsFilterSelectTree
              key={label}
              label="Location"
              options={section.options}
              collapsed={allCollapsed}
              topBorder={i === 0}
              filter={filter}
              setFilter={setFilter}
            />
          ) : (
            <ProductOfferingFilterSection
              key={label}
              label={label}
              options={section.options as ItivTliRetrofit}
              delta={section.delta}
              filterField={section.filterField}
              collapsed={allCollapsed}
              topBorder={i === 0}
            />
          );
        })}
      </div>
    </div>
  );
}

function isLocationFilterSection(
  section: FilterSectionData | FilterLocationSectionData,
): section is FilterLocationSectionData {
  return section.label === "Location";
}

type ProductOfferingFilterSectionProps<T> = {
  label: string;
  delta?: boolean;
  options?: T[];
  filterField: keyof TakeoffLineItemFilter;
  collapsed?: boolean;
  topBorder?: boolean;
};

function ProductOfferingFilterSection(props: ProductOfferingFilterSectionProps<BasicFilterOptions>) {
  const { label, delta, options = [], filterField, collapsed, topBorder = false } = props;
  const filter = useProductOfferingStore((state) => state.filter);
  const setFilter = useProductOfferingStore((state) => state.setFilter);
  const selected = options
    .filter(({ id, filterField: optionFilterField }) =>
      ((filter as ItivTliRetrofit)[optionFilterField ?? filterField] as any[])?.includes(id),
    )
    .map(({ id }) => id);
  const tid = useTestIds(props, "productOfferingFilterSection");

  return (
    <Accordion
      title={
        <div css={Css.df.jcsb.$} {...tid[`${label.replaceAll(" ", "")}_label`]}>
          <div css={Css.xsSb.$}>
            {label}
            {delta ? " △" : ""}
          </div>
          <div css={Css.xs.gray700.$}>
            {selected.length}/{options.length}
          </div>
        </div>
      }
      bottomBorder
      topBorder={topBorder}
      defaultExpanded={!collapsed}
      {...tid[label.replaceAll(" ", "")]}
      xss={{ paddingBottom: "8px" }}
    >
      <div css={Css.df.fdc.gap1.$}>
        {options.map(({ id, name, filterField: optionFilterField, isBase }) => (
          <label key={id} css={Css.df.aic.gap1.if(selected.includes(id)).color("black").else.gray700.$}>
            <Checkbox
              label=""
              checkboxOnly
              withLabelElement={false}
              onChange={updateFilterWithOption(id, optionFilterField ?? (filterField as ItivTliRetrofit), setFilter)}
              selected={selected.includes(id)}
              {...tid[`${id}_checkbox`]}
            />
            <span css={Css.tiny.df.gap1.$} {...tid[`${id}_label`]}>
              {name}
              {isBase ? (
                <span>
                  <span css={Css.bgGray200.color("black").px1.py("2px").borderRadius("100px").$}>Base</span>
                </span>
              ) : (
                <></>
              )}
            </span>
          </label>
        ))}
      </div>
    </Accordion>
  );
}

function updateFilterWithOption(
  id: string | null,
  filterField: keyof TakeoffLineItemFilter,
  setFilter: React.Dispatch<React.SetStateAction<TakeoffLineItemFilter>>,
) {
  return (selected: boolean) => {
    setFilter((filter) => {
      const newFilter = {
        ...filter,
        [filterField]: selected
          ? [...((filter[filterField] ?? []) as any[]), id]
          : (filter[filterField] as any[])?.filter((v) => v !== id),
      };

      if ((newFilter[filterField] as any[])?.isEmpty) {
        delete newFilter[filterField];
      }

      return newFilter;
    });
  };
}

/**
 * This file is the lone instance of the ITIV->TLI cutover occuring in './plan-package/takeoffs' leaking out
 * of those files, both touching LocationFilterSelectTree, and impacting something not in those files. I don't
 * want to lose track of these as any's but their inputs haven't really change, so cast them to this type for
 * now and we can/should remove (revert to inference) when we cutover POFs to TLI
 */
type ItivTliRetrofit = any;
