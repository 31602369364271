import {
  BoundSelectField,
  BoundTextField,
  BoundTreeSelectField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import {
  DevelopmentDropDetailsFragment,
  DisplayNamedFragment,
  DropLocationFragment,
  DropLocationTreeFragment,
  LocationType,
  NamedFragment,
  SaveDropInput,
  useDevelopmentDropModalMetadataQuery,
  useSaveDevelopmentDropMutation,
} from "src/generated/graphql-types";
import { createProductOfferingsUrl } from "src/RouteUrls";
import { isDefined, queryResult } from "src/utils";
import { openNewTab } from "src/utils/window";

type DevelopmentDropModalProps = {
  developmentId: string;
  existingDrop?: DevelopmentDropDetailsFragment;
};

export function DevelopmentDropModal(props: DevelopmentDropModalProps) {
  const query = useDevelopmentDropModalMetadataQuery({
    variables: { developmentId: props.developmentId },
  });

  return queryResult(query, {
    data: ({ costCodes, locations, globalPlanTasks, development }) => {
      const marketSpecificGlobalPlanTasks = globalPlanTasks.entities.map((gpt) => ({
        id: gpt.id,
        name: gpt.nameForMarket,
      }));
      return (
        <DevelopmentDropModalView
          costCodes={costCodes}
          costCodesWithDraws={development.costCodesWithDraws}
          globalPlanTasks={marketSpecificGlobalPlanTasks}
          locations={locations}
          {...props}
        />
      );
    },
  });
}

type DevelopmentDropModalViewProps = {
  costCodes: DisplayNamedFragment[];
  costCodesWithDraws: NamedFragment[];
  globalPlanTasks: NamedFragment[];
  locations: DropLocationTreeFragment[];
} & DevelopmentDropModalProps;

export function DevelopmentDropModalView({
  costCodes,
  costCodesWithDraws,
  globalPlanTasks,
  locations,
  developmentId,
  existingDrop,
}: DevelopmentDropModalViewProps) {
  const { closeModal } = useModal();

  const [saveDevelopmentDrop, { loading }] = useSaveDevelopmentDropMutation({
    refetchQueries: ["DevelopmentDropsPage"],
  });
  const { triggerNotice } = useSnackbar();

  const treeLocations = useMemo(
    () =>
      locations.filter(filterOutScopeLocations).map((l) => ({
        ...l,
        children: l.locations.filter(filterOutScopeLocations).map((lo) => ({
          ...lo,
          children: lo.locations.filter(filterOutScopeLocations),
        })),
      })),
    [locations],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: existingDrop,
      map: (d) => ({
        id: d.id,
        name: d.name,
        costCodeId: d.costCode.id,
        locationIds: selectChildLocations(
          d.locations.map((l) => l.id),
          locations,
        ),
        globalPlanTaskId: d.globalPlanTask.id,
      }),
      ifUndefined: { locationIds: [] },
    },
  });

  const onSubmit = useCallback(async () => {
    const { locationIds, ...values } = formState.value;
    const { id, name: changedName, ...otherChangedVals } = formState.changedValue;
    const isOnlyNameChange =
      existingDrop &&
      existingDrop.name !== changedName &&
      Object.entries(otherChangedVals).every(([_, value]) => !value || value.length === 0);

    await saveDevelopmentDrop({
      variables: {
        input: {
          developmentId,
          locationIds: isDefined(locationIds) ? removeChildLocations(locationIds, locations) : undefined,
          ...values,
        },
      },
    });

    if (!isOnlyNameChange) {
      triggerNotice({
        message: (
          <span>
            Drop changes to product offerings can be accepted on the &nbsp;
            <span css={Css.tdu.$} onClick={() => openNewTab(createProductOfferingsUrl(developmentId))}>
              Offerings page
            </span>
            &nbsp; now.
          </span>
        ),
      });
    }
    closeModal();
  }, [
    formState.value,
    formState.changedValue,
    existingDrop,
    saveDevelopmentDrop,
    developmentId,
    locations,
    closeModal,
    triggerNotice,
  ]);

  return (
    <>
      <ModalHeader>{`${existingDrop ? "Edit" : "Create a"} Drop`}</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField field={formState.name} label="Drop Name" compact={true} disabled={loading} />
          <BoundSelectField
            options={costCodes}
            field={formState.costCodeId}
            disabled={loading || !!existingDrop}
            disabledOptions={costCodesWithDraws.map((c) => c.id)}
            compact
          />
          <BoundTreeSelectField field={formState.locationIds} options={treeLocations} />
          <BoundSelectField
            options={globalPlanTasks}
            field={formState.globalPlanTaskId}
            label="Drop Task"
            disabled={loading}
            compact
          />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>{() => <Button label="Save" onClick={onSubmit} disabled={!formState.valid} />}</Observer>
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<Pick<SaveDropInput, "id" | "name" | "costCodeId" | "locationIds" | "globalPlanTaskId">> =
  {
    id: { type: "value" },
    name: { type: "value", rules: [required] },
    costCodeId: { type: "value", rules: [required] },
    locationIds: { type: "value", rules: [required] },
    globalPlanTaskId: { type: "value", rules: [required] },
  };

// remove all child locations from the tree when saving to the BE
export function removeChildLocations(locationIds: string[], locations: DropLocationTreeFragment[]) {
  // Only the first two levels have children that need to be removed
  const flatLocations = [...locations, ...locations.flatMap((l) => l.locations ?? [])];

  // If a location in the selected list has children, put them in the list to remove
  const childrenToRemove = locationIds.flatMap((id) => flatLocations.find((l) => l.id === id)?.locations ?? []);

  return locationIds.filter((id) => !childrenToRemove.some((l) => l.id === id));
}

// select all child locations when filling out the form
export function selectChildLocations(locationIds: string[], locations: DropLocationTreeFragment[]) {
  const flatLocations = [
    // If the highest level has been selected we want to select their children and their children's children
    ...locations.map((l) => ({
      ...l,
      locations: [...(l.locations ?? []), ...l.locations.flatMap((lo) => lo.locations ?? [])],
    })),
    ...locations.flatMap((l) => l.locations ?? []),
  ];

  return [
    ...locationIds,
    ...locationIds.flatMap((id) => flatLocations.find((l) => l.id === id)?.locations?.map((l) => l.id) ?? []),
  ].unique();
}

function filterOutScopeLocations(dl: DropLocationFragment) {
  return dl.type.code !== LocationType.Scope;
}
