import { Css } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { Maybe } from "graphql/jsutils/Maybe";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { UploaderProvider } from "src/contexts/UploaderContext";
import { AssetInfoFragment } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { AssetGallery } from "../assetGallery/AssetGallery";
import { WithActions } from "../to-dos/WithActions";
import { UppyUploader, UppyUploaderProps } from "../uploader/UppyUploader";
import { SaveAttachmentModel } from "./BoundAttachments";

type BoundSingleAttachmentProps = {
  field: FieldState<SaveAttachmentModel | undefined>;
  title: string;
  uppyUploaderProps?: Pick<UppyUploaderProps, "dragDropText" | "maxNumberOfFiles" | "dragDropWidth" | "dragDropHeight">;
  shouldDeleteField?: FieldState<boolean | undefined>;
};

export function BoundSingleAttachment({
  field,
  title,
  uppyUploaderProps,
  shouldDeleteField,
}: BoundSingleAttachmentProps) {
  const { dragDropText, maxNumberOfFiles, dragDropWidth, dragDropHeight } = uppyUploaderProps ?? {};
  const saveAttachment = useCallback(
    async (file: UppyFile) => {
      const { name, size, meta, type } = file;
      const downloadUrl = meta.downloadUrl as string;
      field.set({
        asset: {
          fileName: name,
          s3Key: meta.s3Key as string,
          sizeInBytes: size,
          downloadUrl: downloadUrl as string,
          attachmentUrl: downloadUrl as string,
          createdAt: new Date(),
          contentType: type!,
        },
      });
      shouldDeleteField?.set(false);
    },
    [field, shouldDeleteField],
  );

  // clear the form state when removing an asset
  const deleteAttachment = useCallback(
    (attachment: FieldState<SaveAttachmentModel | undefined>) => {
      if (attachment.value?.asset.id) {
        shouldDeleteField?.set(true);
      }
      field.set(undefined);
    },
    [field, shouldDeleteField],
  );

  return (
    <UploaderProvider>
      <Observer>
        {() => (
          <div css={Css.df.gap1.aie.$}>
            <div css={Css.df.fdc.gap1.maxw100.$}>
              <span>{title}</span>
              <UppyUploader
                onFinish={saveAttachment}
                dragDropText={dragDropText || ""}
                maxNumberOfFiles={maxNumberOfFiles || 1}
                dragDropWidth={dragDropWidth || 100}
                dragDropHeight={dragDropHeight || 100}
              />
            </div>
            {field.value?.asset && (
              // AssetGallery only takes an array of assets, so we need to wrap the single asset in an array
              <AssetGallery assets={[field.value?.asset as AssetInfoFragment]} display="vertical">
                {(openGallery) => (
                  <>
                    {[field].map((a) => {
                      const asset = a.value?.asset as AssetInfoFragment;
                      return (
                        <div key={a.value?.id} css={Css.df.relative.mr1.mt1.$}>
                          <WithActions
                            left={{
                              icon: "trash",
                              tooltip: "delete",
                              action: () => deleteAttachment(a),
                            }}
                            right={{ icon: "download", tooltip: "download", action: asset.attachmentUrl }}
                            footer={asset.fileName ? { text: asset.fileName } : undefined}
                            onClick={() => openGallery(asset)}
                          >
                            <AssetPreview asset={asset} dimensions={{ width: 100, height: 100 }} />
                          </WithActions>
                        </div>
                      );
                    })}
                  </>
                )}
              </AssetGallery>
            )}
          </div>
        )}
      </Observer>
    </UploaderProvider>
  );
}

// helper function to format the formState's "attachment" into an asset shape
export function formatAttachmentAssets(
  attachment: Maybe<SaveAttachmentModel>,
  maybeRemoveAsset: boolean | undefined,
  maybeAssetId: string | undefined,
) {
  // remove fields that are not included in AssetInput
  const { attachmentUrl, createdAt, delete: shouldDelete, downloadUrl, ...others } = attachment?.asset ?? {};
  // only delete an asset if we're not swapping it out for a new one
  if (maybeRemoveAsset && maybeAssetId) {
    return { asset: { id: maybeAssetId, delete: true } };
  }

  return { asset: { ...others } };
}
