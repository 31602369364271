import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

/** A custom hook for navigating to a new ReadyPlan aggregate version ID by updating the URL. */
export function useNavigateToRpav() {
  const history = useHistory();
  const location = useLocation();
  return useCallback(
    (
      /**
       * `null | undefined` because callers often pass in `rp.aggregateDraft?.id` and aggregateDraft is nullable. Also one
       * caller is a Select dropdown with `onSelect={thisFunc}` and select fields can technically pass out null | undefined.
       */
      rpavId: string | null | undefined,
    ) => {
      if (!rpavId) return;

      const { pathname, search } = location;

      // Update the path with the new rpavId
      const pathSegments = pathname.split("/");
      const updatedPathSegments = pathSegments.map((segment) => (segment.startsWith("rpav:") ? rpavId : segment));
      // Anyone navigating to DP with `createDpUrl(dpId, "latest")` will only make it as far as
      // `/design-packages/rp:1424/` with no RPAV selected. We should backfill it but in the event
      // we dropped the ball, passing in an RPAV to this Hook should dig the user out of it by manually
      // generating `/design-packages/rp:1424/rpav:4547/`
      if (!updatedPathSegments.some((segment) => segment.includes("rpav:"))) {
        if (updatedPathSegments.last === "") {
          updatedPathSegments.pop();
          updatedPathSegments.push(rpavId, "");
        } else {
          updatedPathSegments.push(rpavId);
        }
      }
      const newPathname = updatedPathSegments.join("/");

      history.push(`${newPathname}${search}`);
    },
    [history, location],
  );
}
