import { Button, Css, Icon, Palette } from "@homebound/beam";
import { Link } from "react-router-dom";
import { ProgressPill } from "src/components";
import { createBidPackagesUrl } from "src/RouteUrls";

type BidOutCellProps = {
  bidOutPercentage: number;
  developmentId?: string;
  projectId?: string;
};

export function BidOutCell({ bidOutPercentage, developmentId, projectId }: BidOutCellProps) {
  const url = createBidPackagesUrl(developmentId, projectId);
  if (bidOutPercentage === 0)
    return (
      <div css={Css.df.gap1.aic.$}>
        <Button label="Get Estimate" onClick={url} variant="text" />
        <Icon icon="dollar" color={Palette.Green500} inc={2} />
      </div>
    );

  if (bidOutPercentage === 100)
    return (
      <div css={Css.df.gap1.aic.$}>
        <Button label="Awarded" onClick={url} variant="text" />
        <Icon icon="checkCircle" color={Palette.Green500} inc={2} />
      </div>
    );

  return (
    <Link href={url} to={url}>
      <ProgressPill progress={bidOutPercentage} changeColorOnCompleted text="bid" />
    </Link>
  );
}
