import { Button, Css, IconButton, ModalBody, ModalFooter, ModalHeader, Palette, useModal } from "@homebound/beam";
import {
  ProductOfferingPageDocument,
  ProductOfferingsDetailFragment,
  useAcceptProductOfferingUpdatesMutation,
  usePofAvailableUpdatesQuery,
} from "src/generated/graphql-types";
import { pluralize, queryResult } from "src/utils";
import { DropSourceSection, ReadyPlanSourceSection } from "./UpdatesSourceSection";

type UpdatesAvailableButtonProps = {
  productOfferingId: string;
  updates: ProductOfferingsDetailFragment["availableUpdates"];
  isArchived: boolean;
  copyInProgress: boolean;
  showText?: boolean;
};

export function UpdatesAvailableButton({
  copyInProgress,
  updates,
  productOfferingId,
  isArchived,
  showText = true,
}: UpdatesAvailableButtonProps) {
  const { openModal } = useModal();

  if (copyInProgress || updates.isEmpty || isArchived) {
    return null;
  }

  if (!showText) {
    return (
      <IconButton
        inc={2}
        label="Updates available"
        icon="arrowFromBottom"
        bgColor={Palette.Yellow200}
        tooltip={updates
          .map((u) =>
            u.source.__typename === "ReadyPlanAggregateVersion"
              ? `${u.source.readyPlan.name} - v${u.source.version}`
              : `${u.source.name}`,
          )
          .join(", ")}
        onClick={() =>
          openModal({ content: <UpdatesAvailableModal updates={updates} productOfferingId={productOfferingId} /> })
        }
      />
    );
  }

  return (
    <Button
      variant="caution"
      icon="arrowFromBottom"
      label="Updates available"
      tooltip={updates
        .map((u) =>
          u.source.__typename === "ReadyPlanAggregateVersion"
            ? `${u.source.readyPlan.name} - v${u.source.version}`
            : `${u.source.name}`,
        )
        .join(", ")}
      onClick={() =>
        openModal({ content: <UpdatesAvailableModal updates={updates} productOfferingId={productOfferingId} /> })
      }
    />
  );
}

type UpdatesAvailableModalProps = Pick<UpdatesAvailableButtonProps, "updates" | "productOfferingId">;

export function UpdatesAvailableModal({ updates, productOfferingId }: UpdatesAvailableModalProps) {
  const [acceptProductOfferingUpdates] = useAcceptProductOfferingUpdatesMutation();
  const { closeModal } = useModal();

  const query = usePofAvailableUpdatesQuery({ variables: { id: productOfferingId } });

  async function onAcceptUpdate() {
    await acceptProductOfferingUpdates({
      variables: { input: { productOfferingId, productOfferingUpdateIds: updates.map((u) => u.id) } },
      refetchQueries: [ProductOfferingPageDocument],
    });
    closeModal();
  }
  return (
    <>
      <ModalHeader>Updates Available</ModalHeader>
      <ModalBody>
        <div css={Css.mb3.$}>
          Would you like to pull {pluralize(updates.length, "this update", "these updates")} into a new draft for you to
          review and edit?
        </div>
        <div css={Css.df.fdc.gap2.$}>
          {queryResult(query, (data) => (
            <>
              {data.productOffering.availableUpdates.map((u) =>
                u.source.__typename === "ReadyPlanAggregateVersion" ? (
                  <ReadyPlanSourceSection key={u.id} update={u} source={u.source} />
                ) : (
                  <DropSourceSection key={u.id} update={u} />
                ),
              )}
            </>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="secondary" />
        <Button label={`Accept ${pluralize(updates.length, "Update")}`} onClick={onAcceptUpdate} />
      </ModalFooter>
    </>
  );
}
