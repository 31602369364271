import {
  Button,
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  IconButton,
  LoadingSkeleton,
  PageSettings,
  Pagination,
  ScrollableParent,
  Switch,
  column,
  multiFilter,
  selectColumn,
  simpleHeader,
  useComputed,
  useFilter,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import xor from "lodash/xor";
import { useCallback, useEffect, useState } from "react";
import { SearchBox } from "src/components";
import {
  BidItemFilter,
  BidMetaQuery,
  BidPackageLineItemModalQuery,
  BidPackageStatus,
  CostType,
  NamedFragment,
  Order,
  ReviewScopeStep_BidPackageFragment,
  useBidMetaQuery,
  useBidPackageLineItemModalQuery,
  useSaveBidPackagesMutation,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { TableActions } from "src/routes/layout/TableActions";
import { queryResult } from "src/utils";
import { z } from "zod";

export type BidPackageLineItemParams = Omit<BidItemFilter, "search"> & PageSettings;

type BidPackageLineItemModalProps = {
  costTypes: BidMetaQuery["costTypes"];
  items: NamedFragment[];
  bidPackage: ReviewScopeStep_BidPackageFragment;
};

export function BidPackageLineItemModal({ bidPackage }: { bidPackage: ReviewScopeStep_BidPackageFragment }) {
  // Fetch cost types and items (material category) for the bidItem filter options
  const metaQuery = useBidMetaQuery({
    variables: {
      itemFilter: {
        // Ensure the material category filter only shows items with matching bp costcodes
        costCode: bidPackage.costCodes
          .map((cc) => cc.id)
          .unique()
          .compact(),
        version: [1, 2],
      },
    },
  });

  return queryResult(metaQuery, ({ costTypes, items }) => (
    <BidPackageLineItemView costTypes={costTypes} items={items} bidPackage={bidPackage} />
  ));
}

export function BidPackageLineItemView(props: BidPackageLineItemModalProps) {
  const { costTypes, items, bidPackage } = props;
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const { closeModal, openModal } = useModal();
  const tableApi = useGridTableApi<Row>();
  const [saveBidPackages] = useSaveBidPackagesMutation({
    // Since we're in a modal overlay ensure we get the latest of any edits to the line items in the review step or details page
    refetchQueries: [
      "ReviewUnitBidScopeStep_BidPackageGroup",
      "ReviewScopeStep_BidPackageGroup",
      "BidPackageDetailPage",
    ],
  });
  const { setFilter, filter: bidItemsFilter } = useFilter<BidItemFilter>({
    filterDefs: filterDefs(costTypes, items),
  });
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [isUsedUpstream, setIsUsedUpstream] = useState<boolean>(false);

  const { data, loading } = useBidPackageLineItemModalQuery({
    variables: {
      filter: {
        bidPackageId: bidPackage.id,
        isUsedUpstream,
        bidItemsFilter: { ...bidItemsFilter, search: searchFilter },
      },
      order: { name: Order.Desc },
      offset: pageSettings.offset,
      first: pageSettings.limit,
    },
  });
  const selectedBidItemIds = useComputed(() => tableApi.getSelectedRows("data").map((r) => r.id), [tableApi]);
  const hasNoEdits = useComputed(() => {
    const selectedBis = tableApi.getSelectedRows("data").map((r) => r.id);
    return (
      xor(
        // Compare if line items have changed
        bidPackage.lineItems.map((li) => li.id),
        bidPackage.lineItems.filter((li) => selectedBis.includes(li.bidItem.id)).map((li) => li.id),
        // Compare if new bid items have been added
        bidPackage.lineItems.map((li) => li.bidItem.id),
        selectedBis,
      ).isEmpty && "No new edits made"
    );
  }, [tableApi]);

  useEffect(() => {
    // Since the query is refetched based on filter/search changes, ensure we only intiate selected rows for the first load
    // Otherwise any un-saved selected rows get blown away when refetch occurs from filter changes
    if (data?.potentialBidItemsPage.bidItems.nonEmpty && selectedBidItemIds.isEmpty) {
      // initiate selection of line items that are already in the bid package
      const bplis = new Set(bidPackage.lineItems.map((li) => li.bidItem.id));
      data.potentialBidItemsPage.bidItems.forEach((bi) => {
        if (bplis.has(bi.id)) {
          tableApi.selectRow(bi.id);
        }
      });
      // For unit bids, auto toggle `on` the `isUsedUpstream` switch, after the initial load of all bid items
      // This allows us to load the full set of selected BP bid items on render (including those selected that were not yet used upstream)
      // After the intial load is complete switch to the prefered filtered view of `isUsedUpstream`
      bidPackage.isUnitBased && setIsUsedUpstream(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.potentialBidItemsPage.bidItems]);

  const saveAndExit = useCallback(async () => {
    await saveBidPackages({
      variables: {
        input: [
          {
            id: bidPackage.id,
            lineItems: [
              // Persist line item ids that weren't deselected
              ...bidPackage.lineItems
                .filter((li) => selectedBidItemIds.includes(li.bidItem.id))
                .map((li) => ({
                  id: li.id,
                })),
              // Add line items that were newly selected
              ...selectedBidItemIds
                .filter((id) => !bidPackage.lineItems.some((li) => li.bidItem.id === id))
                .map((id) => ({
                  bidItemId: id,
                })),
            ],
          },
        ],
      },
    });
    closeModal();
  }, [bidPackage.id, bidPackage.lineItems, saveBidPackages, selectedBidItemIds, closeModal]);

  const handleSave = useCallback(async () => {
    if (bidPackage.status.code !== BidPackageStatus.Draft) {
      openModal({
        content: (
          <ConfirmationModal
            title="Save Line Item Changes"
            confirmationMessage="Editing line items on a package will create a new version. Are you sure you want to continue?"
            onConfirmAction={saveAndExit}
            label="Yes"
          />
        ),
      });
    } else {
      await saveAndExit();
    }
  }, [bidPackage.status.code, openModal, saveAndExit]);

  return (
    <ScrollableParent xss={Css.h("calc(100% - 144px)").$}>
      <header data-testid="bidLineItemsModal" css={Css.pb4.pt2.bb.bw1.bcGray200.bgWhite.$}>
        <div css={Css.df.fdc.$}>
          <div css={Css.ml("97%").$}>
            <IconButton icon="x" onClick={closeModal} />
          </div>
          <div css={Css.df.fdc.aic.jcc.$}>
            <h1 css={Css.xl3Bd.$}>{bidPackage.name}</h1>
            <div css={Css.py1.$}>You can toggle to view items used upstream by plans or design packages</div>
          </div>
        </div>
      </header>
      <div css={Css.my1.mxPx(178).$}>
        <div css={Css.df.fdc.$}>
          {bidPackage.isUnitBased && (
            <div css={Css.df.bb.bw1.bcGray200.pb2.pt1.mb3.$}>
              <Switch
                label={
                  bidPackage.latestVersion.version === 1
                    ? "Show items used by upstream plan and design packages"
                    : "Show items used by offerings"
                }
                selected={isUsedUpstream}
                onChange={setIsUsedUpstream}
              />
            </div>
          )}
          <TableActions>
            <div css={Css.df.fdr.cg1.if(!bidPackage.isUnitBased).my2.$}>
              <Filters<BidItemFilter>
                filter={bidItemsFilter}
                filterDefs={filterDefs(costTypes, items)}
                onChange={setFilter}
                numberOfInlineFilters={8}
              />
            </div>
            <div>
              <SearchBox onSearch={setSearchFilter} clearable debounceDelayInMs={500} />
            </div>
          </TableActions>
        </div>
        {loading ? (
          <>
            <LoadingSkeleton rows={1} columns={1} />
            <LoadingSkeleton rows={5} columns={5} />
          </>
        ) : (
          <GridTable
            columns={createColumns(bidPackage)}
            rows={createRows(data?.potentialBidItemsPage.bidItems)}
            api={tableApi}
            style={{ bordered: true, allWhite: true }}
            stickyHeader
          />
        )}
        <Pagination
          page={[pageSettings, setPageSettings]}
          totalCount={data?.potentialBidItemsPage.pageInfo.totalCount || 0}
        />
      </div>
      <div css={Css.fixed.bottom0.w100.px6.py3.bshHover.df.bgWhite.$}>
        <div css={Css.mla.$}>
          <Button label="Save" onClick={handleSave} size="lg" disabled={hasNoEdits} />
        </div>
      </div>
    </ScrollableParent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = {
  kind: "data";
  data: { costCode: string | undefined; materialCode: string | undefined; materialVariantName: string; uom: string };
};
type Row = HeaderRow | DataRow;

function createRows(
  bidItems: BidPackageLineItemModalQuery["potentialBidItemsPage"]["bidItems"] | undefined,
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(bidItems || []).map((bi) => ({
      id: bi.id,
      kind: "data" as const,
      data: {
        costCode: bi.items[0].costCode.displayName,
        materialCode: bi.parentMaterialVariant?.code,
        materialVariantName:
          bi.costType.code === CostType.Labor
            ? (bi.parentTask?.name ?? bi.name)
            : (bi.parentMaterialVariant?.displayName ?? bi.displayName),
        uom: bi.unitOfMeasure.abbreviation,
        id: bi.id,
      },
    })),
  ];
}

function createColumns(bidPackage: ReviewScopeStep_BidPackageFragment): GridColumn<Row>[] {
  return [
    selectColumn<Row>(),
    column<Row>({
      header: "Cost Code",
      data: (bi, { row, api }) => ({
        content: bi.costCode,
        css: Css.if(shouldHighlightRow(row, api, bidPackage)).bgYellow50.$,
      }),
      w: 1,
    }),
    column<Row>({
      header: "Name",
      data: (bi, { row, api }) => ({
        content: bi.materialVariantName,
        css: Css.if(shouldHighlightRow(row, api, bidPackage)).bgYellow50.$,
      }),
      w: 1.5,
    }),
    column<Row>({
      header: "Uom",
      data: (bi, { row, api }) => ({
        content: bi.uom,
        css: Css.if(shouldHighlightRow(row, api, bidPackage)).bgYellow50.$,
      }),
      w: 0.5,
    }),
    column<Row>({
      header: "Material code",
      data: (bi, { row, api }) => ({
        content: bi.materialCode,
        css: Css.if(shouldHighlightRow(row, api, bidPackage)).bgYellow50.$,
      }),
      w: 1,
    }),
  ];
}

// We should highlight the row if it's selected and it wasn't in the last version that exists
function shouldHighlightRow(
  row: GridDataRow<Row>,
  api: GridTableApi<Row>,
  bidPackage: ReviewScopeStep_BidPackageFragment,
): boolean {
  return (
    !!bidPackage.latestVersion.previous &&
    !bidPackage.latestVersion.previous.lineItems.some(({ bidItem }) => bidItem.id === row.id) &&
    api.getSelectedRowIds().includes(row.id)
  );
}

function filterDefs(costTypes: BidMetaQuery["costTypes"], items: NamedFragment[]): FilterDefs<BidItemFilter> {
  return {
    costType: multiFilter({
      label: "Cost Type",
      options: costTypes,
      getOptionValue: (o) => o.code,
      getOptionLabel: (o) => o.name,
    }),
    items: multiFilter({
      label: "Material Category",
      options: items,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),
  };
}

const pageSchema = z.object({
  offset: z.coerce.number().int().default(0),
  limit: z.coerce.number().int().positive().default(500),
});
