import {
  Button,
  ButtonMenu,
  Chips,
  column,
  Css,
  defaultPage,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  Icon,
  multiFilter,
  Palette,
  RowStyles,
  ScrollableContent,
  selectColumn,
  simpleDataRows,
  singleFilter,
  useComputed,
  useGridTableApi,
  usePersistedFilter,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { QueryTable, SearchBox } from "src/components";
import { baseDownloadUrl } from "src/context";
import {
  TimeFrequency,
  TradePartnerFilter,
  TradePartnerOnboardingStatus,
  TradePartnersMetadataQuery,
  TradePartnersPage_TradeCategoriesFragment,
  TradePartnersPageQuery,
  TradePartnersPageTradePartnerFragment,
  useTradePartnersMetadataQuery,
  useTradePartnersPageQuery,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { iconFilter } from "src/routes/trade-partners/components/IconFilter";
import { createTradePartnerUrl } from "src/RouteUrls";
import { queryResult } from "src/utils/queryResult";
import { openInSelf } from "src/utils/window";
import { paymentTermsPath, tradeCategoryLeadTimesPath } from "../routesDef";
import { formatLeadTime } from "./trade-category-lead-times/utils";

export function TradePartnersPage() {
  const metadataQuery = useTradePartnersMetadataQuery({ fetchPolicy: "cache-first" });
  useDocumentTitle("Trade Partners");
  return queryResult(metadataQuery, {
    data: ({ markets, tradeCategories, developments, cohorts }) => (
      <TradePartnersDataView
        markets={markets}
        tradeCategories={tradeCategories}
        developments={developments}
        cohorts={cohorts}
      />
    ),
  });
}

function TradePartnersDataView({
  markets: allMarkets,
  tradeCategories: allTradeCategories,
  developments: allDevelopments,
  cohorts: allCohorts,
}: TradePartnersMetadataQuery) {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const tableApi = useGridTableApi<Row>();

  const filterDefs: FilterDefs<PageFilter> = useMemo(() => {
    const markets = multiFilter({
      options: allMarkets,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const developmentIds = multiFilter({
      options: allDevelopments,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const cohortIds = multiFilter({
      options: allCohorts,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const onboardingStatus = multiFilter({
      label: "Onboarding",
      options: [
        { id: TradePartnerOnboardingStatus.InProgress, name: "In Progress" },
        { id: TradePartnerOnboardingStatus.Complete, name: "Complete" },
        { id: TradePartnerOnboardingStatus.DoNotUse, name: "Do Not Use" },
        { id: TradePartnerOnboardingStatus.Inactive, name: "Inactive" },
      ],
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const isSweetheart = iconFilter({ label: "Favorite", defaultValue: false });

    const averageScore = singleFilter({
      label: "Average Rating",
      options: [
        { id: "1", name: ratingLabel(1) },
        { id: "2", name: ratingLabel(2) },
        { id: "3", name: ratingLabel(3) },
        { id: "4", name: ratingLabel(4) },
      ],
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const tradeCategories = multiFilter({
      options: allTradeCategories,
      getOptionLabel: (o) => o.name,
      getOptionValue: (o) => o.id,
    });

    return { markets, onboardingStatus, averageScore, isSweetheart, tradeCategories, developmentIds, cohortIds };
  }, [allMarkets, allTradeCategories, allDevelopments, allCohorts]);

  const selectedTradePartners = useComputed(() => tableApi.getSelectedRowIds("data"), [tableApi]);
  const actionMenuItems = useMemo(
    () => [
      {
        label: "Export Contacts to CSV",
        disabled: !selectedTradePartners.length,
        onClick: () =>
          openInSelf(`${baseDownloadUrl()}/csv?type=tradePartnersContacts&tpIds=${selectedTradePartners.join()}`),
      },
    ],
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTradePartners, tableApi],
  );

  const { setFilter, filter } = usePersistedFilter<PageFilter>({
    storageKey: "tradePartnerFilter",
    filterDefs,
  });

  const query = useTradePartnersPageQuery({
    variables: {
      filter: {
        ...filter,
        // If no onboarding status is selected, fetch all statuses including `DoNotUse` in the trades page
        // We do this manually client side because the tradePartners resolver is reused various places where we don't want to include `DoNotUse`
        // TODO: Add a BE filter `includeDoNotUse` to the tradePartners resolver that we can pass to all tradePartners queries to include `DoNotUse` status in a fetch all or query by id
        ...(!filter.onboardingStatus && { onboardingStatus: Object.values(TradePartnerOnboardingStatus) }),
        search: searchFilter || undefined,
        averageScore: { gte: parseInt(filter.averageScore || "0") },
      },
      page: defaultPage,
    },
  });

  const maybeFetchNextPage = useCallback(async () => {
    if (query.data?.tradePartnersPage.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: { page: { offset: query.data?.tradePartnersPage.entities?.length, limit: defaultPage.limit } },
      });
    }
  }, [query]);

  return (
    <>
      <PageHeader
        title="Trade Partners"
        right={
          <div css={Css.df.gap1.$}>
            <Button label="Manage Lead Times" onClick={tradeCategoryLeadTimesPath} variant="secondary" />
            <Button label="Manage Payment Terms" onClick={paymentTermsPath} variant="secondary" />
            <Button label="Create Trade Partner" onClick={createTradePartnerUrl()} variant="secondary" />
          </div>
        }
      />
      <TableActions>
        <Filters<PageFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <div css={Css.df.gap1.$}>
          <ButtonMenu trigger={{ label: "Actions" }} items={actionMenuItems} />
          <SearchBox onSearch={setSearchFilter} />
        </div>
      </TableActions>
      <ScrollableContent virtualized>
        <QueryTable
          api={tableApi}
          as="virtual"
          stickyHeader
          query={query}
          createRows={createRows}
          columns={columns}
          rowStyles={createRowStyles()}
          infiniteScroll={{ onEndReached: maybeFetchNextPage }}
          emptyFallback="There are no Trade Partners"
        />
      </ScrollableContent>
    </>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: TradePartnersPageTradePartnerFragment };
type Row = HeaderRow | DataRow;

const columns: GridColumn<Row>[] = [
  selectColumn<Row>(),
  column<Row>({ header: "Trade Partner", data: (tp) => tp.name }),
  column<Row>({
    header: emptyCell,
    data: (tp) => (tp.isSweetheart ? <Icon icon="star" color={Palette.Gray700} /> : emptyCell),
  }),
  column<Row>({ header: "Onboarding Status", data: (tp) => tp.onboardingStatus.name }),
  column<Row>({ header: "Average Rating", data: (tp) => tp.averageScore }),
  column<Row>({ header: "Markets", data: (tp) => tp.markets.map((m) => m.name).join(", ") }),
  column<Row>({
    header: "Trade Categories",
    data: (tp) => (
      <div>
        <Chips values={tp.tradeCategories.map((tc) => tc.name)} />
      </div>
    ),
  }),
  column<Row>({
    header: "Lead Time",
    data: (tp) => getLongestTradeCategoryLeadTime(tp.tradeCategories, tp),
  }),
];

function createRowStyles(): RowStyles<Row> {
  return {
    header: {},
    data: {
      rowLink: (row) => createTradePartnerUrl(row.data.id),
    },
  };
}

function createRows(data: TradePartnersPageQuery | undefined): GridDataRow<Row>[] {
  return simpleDataRows(data?.tradePartnersPage.entities);
}

type PageFilter = Omit<TradePartnerFilter, "averageScore"> & { averageScore: string };

function ratingLabel(numStars: number) {
  const total = 5;
  const numEmpty = total - numStars;
  const starb = "\u2605"; // black
  const starw = "\u2606"; // white

  return `${starb.repeat(numStars)}${starw.repeat(numEmpty)} & up`;
}

// helper function to grab the longest trade category lead times
function getLongestTradeCategoryLeadTime(
  tradeCategories: TradePartnersPage_TradeCategoriesFragment[],
  tradeParter: TradePartnersPageTradePartnerFragment,
) {
  const tradePartnerMarketIds = tradeParter.markets.map((m) => m.id);
  // find the trade category with the longest lead time
  const longestLeadTime = tradeCategories
    .flatMap((tc) => tc.leadTimes)
    .filter((lt) => tradePartnerMarketIds.includes(lt.market.id))
    .sort((a, b) => b.leadTimeInDays - a.leadTimeInDays)[0];

  // if we don't have a lead time from our trade categories, then use our default lead time config
  if (!longestLeadTime) {
    return formatLeadTime(defaultLeadTime.leadTimeInDays, defaultLeadTime.leadTimeFrequency);
  }
  return formatLeadTime(longestLeadTime.leadTimeInDays, longestLeadTime.leadTimeFrequency);
}

// creating a default config to stay in sync with graphql service (tradeCategoryLeadTimesResolver - line 28)
const defaultLeadTime = { leadTimeInDays: 14, leadTimeFrequency: { code: TimeFrequency.Weeks, name: "Weeks" } };
