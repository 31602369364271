import {
  Autocomplete,
  BoundMultiSelectField,
  BoundRadioGroupField,
  BoundTextField,
  Css,
  Icon,
  IconButton,
  Palette,
  Tag,
  useComputed,
} from "@homebound/beam";
import { useState } from "react";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  DesignPackageLocation,
  ReadyPlanStatus,
  useNewDesignPackagePlanPackagesQuery,
} from "src/generated/graphql-types";
import { useDebounce, useMarketFilter } from "src/hooks";
import { readyPlanStatusToTagType } from "src/utils";
import { NewDesignPackageWizardPage } from "./NewDesignPackageAtoms";
import { useNewDesignPackageContext } from "./NewDesignPackageContext";

export function NewDesignPackageDetails() {
  const { formState, isEditMode } = useNewDesignPackageContext();
  const { options: markets } = useMarketFilter();

  const readyToProgress = useComputed(
    () => formState.name.valid && (isEditMode || formState.location.valid),
    [formState],
  );
  return (
    <NewDesignPackageWizardPage>
      <div css={Css.xl3.$}>Link your Design Package</div>
      <div css={Css.base.$}>
        Link your Design Package to an existing Plan Package. Linking will populate your Design Package with all the
        slots you need to select products for all Plans! It will also make sure your Design Package gets linked to all
        the correct plans and projects down the line.
      </div>
      <div css={Css.df.fdr.jcfs.aifs.bgBlue50.gap2.br4.p2.$}>
        <Icon icon="infoCircle" inc={4} color={Palette.Blue600} />
        <div>
          You may also proceed without linking a Plan Package now and your empty Design Package will be populated from a
          plan you can edit as needed.
        </div>
      </div>
      <PlanPackageSelector />
      <BoundMultiSelectField label="Market(s)" field={formState.markets} options={markets} />
      {!isEditMode && (
        <>
          <div css={Css.xl3.$}>Specify Package Type</div>
          <div css={Css.base.$}>
            What kind of package are you looking to build? This choice informs which slots are imported from your Plan
            Package(s) and the products or materials you'll be selecting.
          </div>
          <BoundRadioGroupField
            label="Location"
            labelStyle="hidden"
            options={[
              {
                value: DesignPackageLocation.Interior,
                label: "Interior",
                description: "Any finish products inside the home",
              },
              {
                value: DesignPackageLocation.Exterior,
                label: "Exterior",
                description: "Any finish materials on or around the exterior of the home",
              },
            ]}
            field={formState.location}
          />
        </>
      )}
      <div css={Css.xl3.$}>Give your Design Package a name</div>
      <div css={Css.base.$}>
        Give your package whatever name you'd like so you can find it more easily in the future! You package will also
        be assigned a unique Design Package ID.
      </div>
      <BoundTextField field={formState.name} label="Name" placeholder="Marshall Fire" />
      <NextStepButton label="Choose Package Options" disabled={!readyToProgress} exitButton />
    </NewDesignPackageWizardPage>
  );
}

/**
 * Isolates conversion of Plan Package IDs to a list of Plan Packages cards,
 * including Search, Add to FormState, and Delete from FormState
 */
function PlanPackageSelector() {
  const { formState } = useNewDesignPackageContext();
  const { data: selectedData } = useNewDesignPackagePlanPackagesQuery({
    variables: { filter: { ids: formState.planPackageIds.value } },
    skip: !formState.planPackageIds.value?.nonEmpty,
  });

  const [search, setSearch] = useState<string>();
  const debouncedName = useDebounce(search);
  const {
    data: searchData,
    loading: searching,
    previousData: previousSearchData,
  } = useNewDesignPackagePlanPackagesQuery({
    variables: { filter: { name: debouncedName, status: [ReadyPlanStatus.Active, ReadyPlanStatus.Draft] } },
    nextFetchPolicy: "cache-first",
  });

  const hydratedSelectedPlanPackages = useComputed(
    () => selectedData?.planPackages.entities.filter((pp) => formState.planPackageIds.value?.includes(pp.id)) ?? [],
    [formState, selectedData],
  );

  return (
    <>
      <Autocomplete<NonNullable<typeof searchData>["planPackages"]["entities"][number]>
        label={"Search Plan Packages" + (searching ? " (Searching...)" : "")}
        placeholder="Search by ID, nickname, region, market, etc..."
        value={search}
        onInputChange={setSearch}
        options={searchData?.planPackages.entities ?? previousSearchData?.planPackages.entities ?? []}
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.id}
        onSelect={(selected) => {
          formState.planPackageIds.set(formState.planPackageIds.value?.concat(selected.id));
          setSearch(undefined);
        }}
        disabledOptions={formState.planPackageIds.value ?? []}
        fullWidth
      />
      <div css={Css.w100.df.fdc.gap1.$}>
        {hydratedSelectedPlanPackages.map((pp) => (
          <div key={pp.id} css={Css.df.fdr.jcsb.aic.w100.bshBasic.p2.br8.bgWhite.$}>
            <div css={Css.df.fdc.$}>
              <div css={Css.baseBd.df.gap1.$}>
                {pp.name}
                {pp.status.code === ReadyPlanStatus.Archived && (
                  <Tag text="archived" type={readyPlanStatusToTagType[ReadyPlanStatus.Archived]} />
                )}
              </div>
              {pp.code && <div>{pp.code}</div>}
            </div>
            <IconButton
              icon="trash"
              onClick={() => formState.planPackageIds.set(formState.planPackageIds.value?.filter((id) => id !== pp.id))}
            />
          </div>
        ))}
        {hydratedSelectedPlanPackages.isEmpty && <div css={Css.gray600.smMd.asc.$}>Nothing selected</div>}
      </div>
    </>
  );
}
