import {
  Accordion,
  BoundDateField,
  BoundMultiSelectField,
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  SuperDrawerContent,
  SuperDrawerHeader,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import pick from "lodash/pick";
import { useMemo } from "react";
import {
  BoundAttachments,
  SaveAttachmentModel,
  attachmentConfig,
} from "src/components/boundAttachments/BoundAttachments";
import {
  LotCheckFormDrawer_InternalUserFragment,
  LotCheckFormDrawer_ProjectFragment,
  Maybe,
  ProjectRole,
  SaveProjectSiteConditionInput,
  useLotCheckFormDrawerMetadataQuery,
  useLotCheckFormDrawerQuery,
  useSaveProjectSiteConditionsMutation,
  useSendProjectSiteConditionsNotificationMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";
import { LotCheckTable } from "./LotCheckTable";

type LotCheckFormDrawerProps = {
  projectId: string;
  developmentId: string;
};

export function LotCheckFormDrawer({ projectId, developmentId }: LotCheckFormDrawerProps) {
  const { data: metadata } = useLotCheckFormDrawerMetadataQuery({
    variables: { developmentId },
    fetchPolicy: "cache-first",
  });

  const query = useLotCheckFormDrawerQuery({ variables: { projectId } });

  const recipientSettings = useMemo<LotCheckFormDrawerContentProps["recipientSettings"]>(
    () => ({
      options: metadata?.internalUsers ?? [],
      defaultSelected:
        metadata?.development.teamMembers
          .filter((dtm) => [ProjectRole.PurchasingManager, ProjectRole.AreaManager].includes(dtm.projectRole.code))
          .map((dtm) => dtm.internalUser) ?? [],
    }),
    [metadata],
  );

  return queryResult(query, ({ project }) => (
    <LotCheckFormDrawerContent project={project} recipientSettings={recipientSettings} />
  ));
}

type LotCheckFormDrawerContentProps = {
  project: LotCheckFormDrawer_ProjectFragment;
  recipientSettings: {
    defaultSelected: LotCheckFormDrawer_InternalUserFragment[];
    options: LotCheckFormDrawer_InternalUserFragment[];
  };
};

function LotCheckFormDrawerContent({ project, recipientSettings }: LotCheckFormDrawerContentProps) {
  const { triggerNotice } = useSnackbar();
  const { projectSiteCondition, siteSpecificInfos, id: projectId } = project;

  const [saveSiteConditions] = useSaveProjectSiteConditionsMutation();
  const [notifyRecipients] = useSendProjectSiteConditionsNotificationMutation();

  const input = useMemo(() => ({ ...projectSiteCondition!, projectId }), [projectId, projectSiteCondition]);
  const formState = useFormState({
    config: formConfig,
    init: {
      input,
      map: (psc) => {
        const { attachments, ...others } = psc;
        return {
          recipientIds: recipientSettings.defaultSelected.map((w) => w.id),
          attachments: attachments as SaveAttachmentModel[],
          ...others,
        };
      },
    },
    autoSave: async (os) => {
      // Remove recipientIds since it is not going to be saved
      const { expectedSlabPourDate, recipientIds, attachments, ...others } = os.changedValue;
      await saveSiteConditions({
        variables: {
          input: {
            ...(expectedSlabPourDate ? { expectedSlabPourDate: new DateOnly(expectedSlabPourDate) } : {}),
            attachments: attachments?.map(({ asset, ...props }) => ({
              ...props,
              asset: pick(asset, [
                // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
                "contentType",
                "fileName",
                "id",
                "s3Key",
                "sizeInBytes",
                "delete",
              ]),
            })),
            ...others,
          },
        },
      });
    },
  });

  const watcherDisabled =
    projectSiteCondition === null ? "Project site condition must be set before assigning watchers" : false;

  return (
    <SuperDrawerContent>
      <SuperDrawerHeader title={project.name} hideControls />
      <div>
        <div css={Css.mb3.df.jcfe.w100.$}>
          <div css={Css.w25.mr2.$}>
            <BoundMultiSelectField
              compact
              placeholder="Watchers"
              labelStyle="hidden"
              disabled={watcherDisabled}
              field={formState.recipientIds}
              options={recipientSettings.options}
              fieldDecoration={internalUserAvatar}
              getOptionMenuLabel={internalUserMenuLabel}
            />
          </div>
          <Button
            label="Notify watchers"
            disabled={watcherDisabled || (formState.recipientIds.value?.isEmpty ? "No watchers to notify" : false)}
            variant="secondary"
            labelInFlight="Sending..."
            onClick={async () => {
              await notifyRecipients({
                variables: {
                  input: {
                    projectSiteConditionId: projectSiteCondition!.id,
                    internalUserIds: formState.value.recipientIds!,
                  },
                },
              });
              const totalRecipients = formState.recipientIds.value!.length;
              triggerNotice({
                message: `${totalRecipients} watcher${totalRecipients > 1 ? "s" : ""} notified`,
                icon: "success",
              });
            }}
          />
        </div>
        <div css={Css.mb4.$}>
          <Accordion defaultExpanded title="Extraordinary Site Conditions">
            <FormLines width="lg">
              <BoundDateField
                field={formState.expectedSlabPourDate}
                label="Expected slab pour date"
                labelStyle="left"
              />
              <BoundSelectField
                field={formState.drivewaySwing}
                options={[
                  { id: "Right", name: "Right" },
                  { id: "Left", name: "Left" },
                ]}
                unsetLabel="Not selected"
                label="Driveway swing"
                labelStyle="left"
              />
              <BoundTextField field={formState.gasLocation} label="Gas location" labelStyle="left" />
              <BoundTextField field={formState.electricLocation} label="Product Name" labelStyle="left" />
              <BoundTextField field={formState.waterLocation} label="Water location" labelStyle="left" />
              <BoundNumberField field={formState.curbGutterLinearFoot} label="Curb and gutter LFT" labelStyle="left" />
              <BoundTextAreaField field={formState.additionalNotes} label="Additional Notes" labelStyle="left" />
            </FormLines>
          </Accordion>
          <Accordion defaultExpanded bottomBorder title="Upload photos">
            <BoundAttachments field={formState.attachments} />
          </Accordion>
        </div>

        <LotCheckTable projectId={projectId} projectSiteSpecificInfos={siteSpecificInfos} />
      </div>
    </SuperDrawerContent>
  );
}

type FormValue = Omit<SaveProjectSiteConditionInput, "expectedSlabPourDate"> & {
  expectedSlabPourDate?: Date | null;
  recipientIds: Maybe<string[]>;
  attachments: SaveAttachmentModel[];
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  drivewaySwing: { type: "value" },
  gasLocation: { type: "value" },
  electricLocation: { type: "value" },
  waterLocation: { type: "value" },
  curbGutterLinearFoot: { type: "value" },
  projectId: { type: "value" },
  expectedSlabPourDate: { type: "value" },
  recipientIds: { type: "value" },
  additionalNotes: { type: "value" },
  attachments: { type: "list", config: attachmentConfig },
};
