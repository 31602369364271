import { Icon, Palette, RouteTabWithContent, TabsWithContent } from "@homebound/beam";
import {
  createDevelopmentContractLineItemsUrl,
  createDevelopmentContractOverviewUrl,
  createDevelopmentContractPurchaseOrdersUrl,
  createDevelopmentContractUnitPricingUrl,
} from "src/RouteUrls";
import { BidContractProrationPage } from "src/components/bid-contracts/BidContractProrationPage";
import { BidContractPurchaseOrdersPage } from "src/components/bid-contracts/BidContractPurchaseOrdersPage";
import { BidContractUnitPricingPage } from "src/components/bid-contracts/BidContractUnitPricingPage";
import { BidContractRevisionFragment, BidContractType } from "src/generated/graphql-types";
import { useTabParam } from "src/hooks/useTabParam";
import { DevelopmentContractOverviewPage } from "src/routes/developments/procurement/DevelopmentContractOverviewPage";
import { developmentPaths } from "src/routes/routesDef";
import { DevelopmentContractLineItemsPage } from "../procurement/DevelopmentContractLineItemsPage";

type DevelopmentContractTabsProp = {
  selectedRevision: BidContractRevisionFragment;
};

export function DevelopmentContractTabs({ selectedRevision }: DevelopmentContractTabsProp) {
  const [selectedTab, setSelectedTab] = useTabParam("Overview");
  const developmentId = selectedRevision.bidContract.developments[0].id;
  const isProrated =
    selectedRevision.bidContract.type &&
    [BidContractType.GroupedByMaterial, BidContractType.GroupedByPlanAndOption].includes(
      selectedRevision.bidContract.type.code,
    );
  const showPlanPricing =
    !selectedRevision.bidContract.type || selectedRevision.bidContract.type.code !== BidContractType.UnitBased;
  const showUnitPricing =
    !selectedRevision.bidContract.type || selectedRevision.bidContract.type.code === BidContractType.UnitBased;
  const tabs: RouteTabWithContent[] = [
    {
      href: createDevelopmentContractOverviewUrl(developmentId, selectedRevision.id),
      path: developmentPaths.contractOverview,
      name: "Overview",
      render: () => <DevelopmentContractOverviewPage />,
    },
    ...(showPlanPricing
      ? [
          {
            href: createDevelopmentContractLineItemsUrl(developmentId, selectedRevision.id),
            path: developmentPaths.contractLineItems,
            name: "Plan-based Pricing",
            ...(selectedRevision.hasOutdatedScope
              ? { endAdornment: <Icon icon="errorCircle" inc={2} color={Palette.Gray900} /> }
              : {}),
            render: () => (isProrated ? <BidContractProrationPage /> : <DevelopmentContractLineItemsPage />),
          },
        ]
      : []),
    ...(showUnitPricing
      ? [
          {
            href: createDevelopmentContractUnitPricingUrl(developmentId, selectedRevision.id),
            path: developmentPaths.unitPricing,
            name: "Unit Pricing",
            render: () => <BidContractUnitPricingPage />,
          },
        ]
      : []),
    ...(!selectedRevision.bidContract.isInternalEstimate
      ? [
          {
            href: createDevelopmentContractPurchaseOrdersUrl(developmentId, selectedRevision.id),
            path: developmentPaths.contractPurchaseOrders,
            name: "Purchase Orders",
            render: () => <BidContractPurchaseOrdersPage />,
          },
        ]
      : []),
  ];

  return <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setSelectedTab} />;
}
