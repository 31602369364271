import { BoundTextAreaField, Button, Css, TabContent, Tabs, useTestIds } from "@homebound/beam";
import { useState } from "react";
import { Icon } from "src/components";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { PublishBidPackageStepProps } from "../PublishBidPackagePage";
import { onDownloadTemplate } from "./1.SelectTradesStep";

export function ReviewAndPublishStep({ formState, bidPackage }: PublishBidPackageStepProps) {
  const testIds = useTestIds({});
  const [selectedTab, setSelectedTab] = useState("existing-trade");
  const tabs = [
    {
      name: "Existing Trade",
      value: "existing-trade",
      render: () => <PreviewTradeEmail formState={formState} bidPackage={bidPackage} existingTrade={true} />,
    },
    {
      name: "New Trade",
      value: "new-trade",
      render: () => <PreviewTradeEmail formState={formState} bidPackage={bidPackage} existingTrade={false} />,
    },
  ];

  return (
    <div css={Css.wPx(554).mxa.$}>
      <div {...testIds.header} css={Css.tac.mb5.$}>
        <div css={Css.xl3Sb.mt6.tac.mb2.$}>Preview and Send to Trades</div>
        <div css={Css.fw4.$}>Be sure to review the information below is correct before sending to trades.</div>
      </div>
      <div css={Css.plPx(175).mb5.$}>
        <Tabs tabs={tabs} onChange={setSelectedTab} selected={selectedTab} />
      </div>
      <TabContent tabs={tabs} selected={selectedTab} />
    </div>
  );
}

type PreviewTradeEmailProps = PublishBidPackageStepProps & {
  existingTrade: boolean;
};

const PreviewTradeEmail = ({ formState, bidPackage, existingTrade }: PreviewTradeEmailProps) => {
  const testIds = useTestIds({});
  const locationName = bidPackage.developments.first?.name ?? bidPackage.projects.first?.name;

  return (
    <div>
      <div css={Css.tac.df.fdc.mb3.$}>
        <div css={Css.mxa.$}>
          <img src="/images/Homebound.png" alt="Homebound logo" />
        </div>
        <div css={Css.baseSb.mt2.$}>Homebound</div>
        <div css={Css.tiny.my1.$}>
          {new Date().toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })} • {locationName}
        </div>
      </div>
      <div css={Css.bgWhite.$}>
        <div {...testIds.invitationHeader} css={Css.lgSb.py3.df.gap2.bb.bcGray200.jcc.$}>
          <div css={Css.mya.$}>
            <Icon icon="bell" inc={3} />
          </div>
          <div>{existingTrade ? "Bid Updates" : `Invitation to Bid: ${bidPackage.name}`}</div>
        </div>
        <div css={Css.px5.$}>
          <div css={Css.bb.py2.bcGray200.$}>
            {
              <BoundTextAreaField
                field={existingTrade ? formState.existingInvitationText : formState.invitationText}
                labelStyle="hidden"
                readOnly
              />
            }
          </div>
          {!existingTrade && (
            <div {...testIds.bidInstructions} css={Css.bb.py3.bcGray200.$}>
              <div css={Css.lgSb.$}>Bid Instructions</div>
              <BoundTextAreaField field={formState.bidInstructionsText} labelStyle="hidden" readOnly />
            </div>
          )}
          <div {...testIds.bidTimeline} css={Css.bb.bcGray200.py3.$}>
            <div css={Css.lgSb.mb2.$}>Bid Timeline</div>
            <div css={Css.df.fdr.$}>
              <div css={Css.df.fdc.w100.$}>
                <div css={Css.smSb.mb1.$}>Bids Due</div>
                <div css={Css.sm.gray700.$}>{formState.dueDate.value?.toLocaleDateString("en-us", formatOptions)}</div>
              </div>
              <div css={Css.df.fdc.w100.$}>
                <div css={Css.smSb.mb1.$}>Bids Awarded</div>
                <div css={Css.sm.gray700.$}>
                  {formState.estimatedAwardDate.value?.toLocaleDateString("en-us", formatOptions)}
                </div>
              </div>
              <div css={Css.df.fdc.w100.$}>
                <div css={Css.smSb.mb1.$}>Est. Construction Start</div>
                <div css={Css.sm.gray700.$}>
                  {formState.estimatedConstructionDate.value?.toLocaleDateString("en-us", formatOptions)}
                </div>
              </div>
            </div>
          </div>
          <div {...testIds.planRoomLink} css={Css.bb.py3.bcGray200.$}>
            <div css={Css.lgSb.mb1.$}>Plan Room Link</div>
            <div css={Css.gray700.mb2.$}>
              The link below contains all document attachments as well as all line items to help give context in
              bidding.
            </div>
            <div>
              {formState.planRoomLink.value && (
                <a css={Css.fw5.$} href={formState.planRoomLink.value} target="_blank" rel="noreferrer">
                  {formState.planRoomLink.value}
                </a>
              )}
            </div>
          </div>
          <div {...testIds.bidTemplatesLink} css={Css.bb.py3.bcGray200.$}>
            <div css={Css.lgSb.$}>Bid Templates Link</div>
            <div css={Css.gray700.mt1.mb2.$}>
              The link below is the bid templates for you to complete and send back to us.
            </div>
            <Button
              variant="text"
              onClick={() =>
                onDownloadTemplate(
                  bidPackage.isUnitBased,
                  formState.versionRequests.rows[0].bidPackageRequestId.value || "",
                )
              }
              data-testid="csvTemplateLink"
              label={`HomeboundBidPackage-${formatWithYear(new DateOnly(new Date())).replaceAll("/", "-")}.xlsx`}
            />
          </div>
          <div {...testIds.needHelp} css={Css.py3.$}>
            <div css={Css.lgSb.mb1.$}>Need help?</div>
            <div css={Css.gray700.$}>
              Reply to this email directly with questions you have and a purchasing agent will get back to you as
              quickly as they can.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const formatOptions: Intl.DateTimeFormatOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
