import {
  collapseColumn,
  column,
  dateRangeFilter,
  DateRangeFilterValue,
  emptyCell,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  ScrollableContent,
  selectColumn,
  simpleHeader,
  singleFilter,
  useGridTableApi,
  useGroupBy,
} from "@homebound/beam";
import { isWithinInterval } from "date-fns";
import pick from "lodash/pick";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { chipCell, dateCell, linkHeader, priceCell, SearchBox, tagCell } from "src/components";
import {
  BidContractPurchaseOrdersPage_CommitmentFragment,
  BidContractPurchaseOrdersPage_CommitmentLikeFragment,
  BidContractPurchaseOrdersPage_ProjectTeamMemberFragment,
  BidContractPurchaseOrdersPage_RevisionFragment,
  ProjectRole,
  useBidContractPurchaseOrdersPageQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { createDevelopmentContractOverviewUrl, createProjectBidContractOverviewUrl } from "src/RouteUrls";
import { commitmentStatusToDevelopmentTagTypeMapper, foldEnum, groupBy, queryResult } from "src/utils";

export function BidContractPurchaseOrdersPage() {
  const { bidContractRevisionId } = useParams<{ bidContractRevisionId: string }>();
  const result = useBidContractPurchaseOrdersPageQuery({ variables: { id: bidContractRevisionId } });
  return queryResult(result, (data) => (
    <BidContractPurchaseOrdersView bidContractRevisions={data.bidContractRevision.bidContract.revisions} />
  ));
}

type ContractPurchaseOrdersViewProps = {
  bidContractRevisions: BidContractPurchaseOrdersPage_RevisionFragment[];
};

function BidContractPurchaseOrdersView({ bidContractRevisions }: ContractPurchaseOrdersViewProps) {
  // This component can be used to render either project or development bid contracts
  const { developmentId, projectId } = useParams<{ developmentId?: string; projectId?: string }>();
  const tableApi = useGridTableApi<Row>();
  const [filter, setFilter] = useState<FilterOptions>({});
  const [search, setSearch] = useState("");

  const groupBy = useGroupBy<GroupByKeys>({
    project: "Project",
    version: "Version",
    status: "Status",
  });

  const filterDefs = useMemo(
    () => ({
      version: singleFilter({
        options: bidContractRevisions,
        getOptionLabel: (o) => o.version,
        getOptionValue: (o) => o.version,
      }),
      releasedAt: dateRangeFilter<string>({
        label: "Released",
        testFieldLabel: "Released",
      }),
    }),
    [bidContractRevisions],
  );

  const devContractCommitmentLikes = commitmentLikes(bidContractRevisions) as CommitmentLikeRowType[];

  const rows = useMemo(
    () => {
      return createRows(devContractCommitmentLikes, groupBy.value, filter);
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter.version, groupBy.value, devContractCommitmentLikes],
  );

  const columns = useMemo(() => {
    return createColumns(groupBy.value, developmentId, projectId);
  }, [groupBy.value, developmentId, projectId]);

  return (
    <>
      <TableActions>
        <Filters groupBy={groupBy} filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        <SearchBox onSearch={setSearch} />
      </TableActions>
      <ScrollableContent>
        <GridTable
          columns={columns}
          rows={rows}
          style={{ grouped: true, rowHeight: "fixed" }}
          stickyHeader
          filter={search}
          fallbackMessage={`No Commitments or Change Orders for ${developmentId ? "Development" : "Project"}`}
          sorting={{ on: "client" }}
          api={tableApi}
        />
      </ScrollableContent>
    </>
  );
}

enum GroupByKeys {
  project = "project",
  version = "version",
  status = "status",
}

type HeaderRow = { kind: "header" };
type GroupRow = { kind: "group"; data: CommitmentLikeRowType };
type CommitmentLikeRow = { kind: "clike"; data: CommitmentLikeRowType };
type Row = HeaderRow | GroupRow | CommitmentLikeRow;

type CommitmentLikeRowType = BidContractPurchaseOrdersPage_CommitmentLikeFragment & {
  project: BidContractPurchaseOrdersPage_CommitmentFragment["project"];
  bidContractRevision: BidContractPurchaseOrdersPage_CommitmentFragment["bidContractRevision"];
};

type FilterOptions = Partial<{
  version: string;
  releasedAt: DateRangeFilterValue<string>;
}>;

function createColumns(groupByKey: GroupByKeys, developmentId?: string, projectId?: string): GridColumn<Row>[] {
  return [
    collapseColumn<Row>(),
    selectColumn<Row>(),
    column<Row>({
      header: "PO #",
      clike: ({ accountingNumber, blueprintUrl }) => linkHeader(String(accountingNumber), blueprintUrl.path),
      group: (row) => displayGroupName(groupByKey, row),
      w: "150px",
    }),
    column<Row>({
      header: "PO Type",
      group: emptyCell,
      clike: ({ __typename }) => (__typename === "Commitment" ? "PO" : "CO"),
      w: "105px",
    }),
    column<Row>({
      header: "Address",
      group: emptyCell,
      clike: ({ project }) => project.buildAddress.street1,
      w: "150px",
    }),
    column<Row>({
      header: "Cost Codes",
      group: emptyCell,
      clike: ({ lineItems }) => chipCell(lineItems.map((li) => li.costCode.number)),
      mw: "248px",
    }),
    column<Row>({
      header: "Released on",
      group: emptyCell,
      clike: ({ executionDate }) => dateCell(executionDate),
      align: "right",
      w: "110px",
    }),
    column<Row>({
      header: "Released by",
      group: emptyCell,
      clike: ({ project }) => homeboundSignatoryName(project.teamMembers),
      w: "155px",
    }),
    numericColumn<Row>({
      header: "Version",
      clike: (row) => {
        return row.bidContractRevision?.id
          ? linkHeader(
              row?.bidContractRevision.version,
              developmentId
                ? createDevelopmentContractOverviewUrl(developmentId, row.bidContractRevision?.id)
                : createProjectBidContractOverviewUrl(projectId!, row.bidContractRevision?.id),
            )
          : undefined;
      },
      group: emptyCell,
      align: "right",
      w: "125px",
    }),
    numericColumn<Row>({
      header: "Committed Cost",
      clike: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
      group: emptyCell,
      w: "140px",
    }),
    column<Row>({
      header: "Status",
      clike: ({ status, statusText }) => tagCell(commitmentStatusToDevelopmentTagTypeMapper[status], statusText),
      group: emptyCell,
      clientSideSort: false,
      w: "130px",
    }),
  ];
}

function commitmentLikes(bidContractRevisions: BidContractPurchaseOrdersPage_RevisionFragment[]) {
  const commitments = bidContractRevisions.flatMap((revision) => revision.commitments);

  return commitments.flatMap((commitment) => [
    commitment,
    ...commitment?.changeOrders?.map((co) => ({
      ...co,
      ...pick(commitment, ["bidContractRevision", "project"]),
    })),
  ]);
}

function createRows(
  cLikes: CommitmentLikeRowType[],
  groupByKey: GroupByKeys,
  filter: FilterOptions,
): GridDataRow<Row>[] {
  const filteredVersionLikes = cLikes.filter((cl) => {
    if (filter.version && cl.bidContractRevision?.version !== filter.version) {
      return false;
    }
    if (
      filter.releasedAt?.value &&
      (!cl.executionDate ||
        !isWithinInterval(cl.executionDate, {
          start: filter.releasedAt.value.from!,
          end: filter.releasedAt.value.to!,
        }))
    ) {
      return false;
    }
    return true;
  });
  const groupedCommitmentLikes = groupCommitmentLikesBy(filteredVersionLikes, groupByKey);
  return [simpleHeader, ...groupedCommitmentLikes];
}

function createGroupRow([itId, cLikes]: [itId: string, cLikes: CommitmentLikeRowType[]]) {
  return {
    kind: "group" as const,
    id: itId,
    data: cLikes.first!,
    children: cLikes.map((cLike) => ({
      kind: "clike" as const,
      id: cLike.id,
      data: cLike,
    })),
  };
}

function groupCommitmentLikesBy(clikes: CommitmentLikeRowType[], groupByKey: GroupByKeys) {
  const groupedItems = clikes
    ? groupBy(clikes, ({ project, status, bidContractRevision }) =>
        foldEnum(groupByKey, {
          status,
          project: () => project.id,
          version: () => bidContractRevision?.version ?? "",
        }),
      )
    : [];

  return Object.entries(groupedItems).map(createGroupRow);
}

function displayGroupName(groupBy: GroupByKeys, commitmentLike: CommitmentLikeRowType): string {
  return foldEnum(groupBy, {
    project: commitmentLike.project.name,
    version: commitmentLike?.bidContractRevision?.version ?? "",
    else: commitmentLike.statusText,
  });
}

function homeboundSignatoryName(teamMembers: BidContractPurchaseOrdersPage_ProjectTeamMemberFragment[]) {
  const signatory = teamMembers.find((member) => member.role.code === ProjectRole.HomeboundAuthorizedSignatory);
  return signatory?.user?.name ?? "";
}
