import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { BidContractsTable } from "src/components/bid-contracts/BidContractsTable";
import { useProjectBidContractsPageQuery } from "src/generated/graphql-types";
import { CreateDevelopmentContractButton } from "src/routes/developments/procurement/components/CreateDevelopmentContractButton";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProjectParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";

export function ProjectBidContractsPage() {
  return (
    <>
      <PageHeader
        title="Bid Contracts"
        right={
          <div css={Css.df.gap1.$}>
            {/* TODO: right now this is just copied from developments but will be updated in the future to support creating the correct project contracts */}
            <CreateDevelopmentContractButton variant="primary" />
          </div>
        }
      />
      <ProjectContractsTable />
    </>
  );
}

function ProjectContractsTable() {
  const { projectId } = useParams<ProjectParams>();
  const query = useProjectBidContractsPageQuery({ variables: { projectId } });
  return queryResult(query, {
    data: ({ project }) => <BidContractsTable bidContracts={project.bidContracts} />,
  });
}
