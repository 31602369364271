import { Css, SubmitButton, useSnackbar } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import omit from "lodash/omit";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { createMaterialCatalogUrl, createMaterialDetailsUrl } from "src/RouteUrls";
import {
  MaterialCatalog_ItemFragment,
  MaterialCatalog_MaterialBrandFragment,
  MaterialCatalogDocument,
  useMaterialCatalogMetadataQuery,
  useSaveMaterialListingMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils";
import { MaterialListingForm } from "./components/material-listing-form/MaterialListingForm";
import { materialListingFormConfig } from "./components/material-super-drawer/MaterialSuperDrawerContext";

/**
 * The "Add Material" page in the Material Catalog.
 *
 * Editing is done via a super-drawer, but we share `MaterialListingForm`s.
 */
export function MaterialPage() {
  const query = useMaterialCatalogMetadataQuery();
  return queryResult(query, ({ items, materialBrands }) => <MaterialEditor items={items} brands={materialBrands} />);
}

type MaterialEditorProps = {
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
};

function MaterialEditor({ items, brands }: MaterialEditorProps) {
  const [saveMaterialListing, { loading }] = useSaveMaterialListingMutation();
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const formConfig = useMemo(() => materialListingFormConfig({ canEditItem: true, canEditType: true }), []);
  const formState = useFormState({
    config: formConfig,
    init: { input: { variants: [{}] }, onlyOnce: true, map: (input) => input },
    loading,
  });

  const handleSave = async () => {
    const { data } = await saveMaterialListing({
      variables: {
        input: {
          ...formState.value,
          variants: formState.variants.value?.map((mv) => ({
            ...omit(mv, "code"),
            images: mv.images?.map((im) => omit(im, "downloadUrl", "attachmentUrl")),
            components: mv.components?.map(({ componentId, componentItemId, componentValues, ...otherOpts }) => ({
              ...otherOpts,
              ...(componentItemId ? { componentItemId, componentValues } : { componentId }), // Take out the component Id since it references the placeholder used as base for the new MV
            })),
          })),
        },
      },
      refetchQueries: [MaterialCatalogDocument],
    });

    const savedMaterial = data?.saveMaterialListing;
    if (savedMaterial) {
      triggerNotice({
        message: `Materials saved successfully`,
        icon: "success",
      });
      history.push(createMaterialDetailsUrl(savedMaterial.materialVariants.first!.id));
    }
  };

  return (
    <div>
      <PageHeader title="Add a New Material" backButton={createMaterialCatalogUrl()} />
      <div css={Css.df.jcc.$}>
        <div css={Css.w100.maxwPx(1600).h("calc(100vh - 260px)").oya.bgWhite.py2.px4.$}>
          <MaterialListingForm
            isNew={true}
            formState={formState}
            canEditItem={undefined}
            items={items}
            brands={brands}
          />
        </div>
      </div>
      <footer
        css={Css.df.fixed.z999.bottom0.left0.right0.bgGray100.p3.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$}
      >
        <div css={Css.mla.$}>
          <SubmitButton form={formState} label="Add Material" onClick={handleSave} size="lg" />
        </div>
      </footer>
    </div>
  );
}
