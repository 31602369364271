import {
  Button,
  Checkbox,
  Css,
  GridColumn,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SimpleHeaderAndData,
  column,
  simpleDataRows,
  useModal,
} from "@homebound/beam";
import { useRef } from "react";
import {
  DesignPackageItemSlotEditorDocument,
  DesignPackageTliCardFragment,
  RegroupTliPlanLinesInput,
  useRegroupTliPlanLinesMutation,
} from "src/generated/graphql-types";
import useSetState from "src/hooks/useSetState";
import { fail } from "src/utils";
import {
  DesignPackageConfiguratorContext,
  useDesignPackageConfiguratorContext,
} from "../DesignPackageConfiguratorContext";

type TakeoffLocationsModalProps = { placeholderTliId: string };

export function TakeoffLocationsModal({ placeholderTliId }: TakeoffLocationsModalProps) {
  const { closeModal } = useModal();
  const { selectedSlots, readOnly } = useDesignPackageConfiguratorContext();
  const [save] = useRegroupTliPlanLinesMutation({ refetchQueries: [DesignPackageItemSlotEditorDocument] });
  const allLoadedTlis = selectedSlots?.flatMap((dpSlot) => dpSlot.items) ?? [];
  const placeholder = allLoadedTlis?.find((tli) => tli.id === placeholderTliId) ?? fail("Placeholder not found");
  const matchingPlaceholders = allLoadedTlis
    .filter((tli) => !tli.placeholder)
    .filter((tli) => tli.dpSignature === placeholder.dpSignature);
  const locations = simplifyLocationPaths(
    matchingPlaceholders
      ?.flatMap((tli) => tli.planLines)
      .map((ppTli) => ppTli.location)
      .uniqueByKey("id")
      .sortByKey("id") ?? [],
  );
  const [ppLocToDpTli, setLocToTli] = useSetState(() => {
    return locations.mapToObject((loc) => [
      loc.id,
      matchingPlaceholders.find((tli) =>
        tli.planLines.flatMap((ppTli) => ppTli.location).some((loc2) => loc2.id === loc.id),
      )?.id ?? fail("location not found in any tli"),
    ]);
  });
  const ref = useRef<string[]>(matchingPlaceholders.map((tli) => tli.customPlaceholderName ?? tli.name));
  const columns = useCreateColumns(matchingPlaceholders, ppLocToDpTli, setLocToTli, ref);
  return (
    <>
      <ModalHeader>Product Groupings Manager</ModalHeader>
      <ModalBody>
        {locations.length <= 1 ? (
          <div css={Css.smBd.red400.$}>Not enough locations to regroup. Nothing to do here. Please close.</div>
        ) : (
          <GridTable columns={columns} rows={simpleDataRows(locations)} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button label="Close" onClick={closeModal} variant={locations.length <= 1 ? "primary" : "secondary"} />
        {locations.length > 1 && (
          <Button
            label="Save"
            disabled={locations.length <= 1 || readOnly}
            onClick={async () => {
              const locTliTuples = Object.entries(ppLocToDpTli);
              /**
               * Find all ppTlis at this Location and save them all to the dpTli. Sibling ppTlis may "only differ"
               * on Quantity which DP shouldn't really know about, so for this modal we generalized those 2 ppTlis
               * to their shared Location, but now we need to gather them both back up and save them to dpTli;
               */
              const allPpTlis = matchingPlaceholders.flatMap((tli) => tli.planLines);
              const inputs = matchingPlaceholders.map<RegroupTliPlanLinesInput>((tli, ix) => ({
                id: tli.id,
                customPlaceholderName: ref.current[ix],
                planLines: locTliTuples
                  .filter(([, tliId]) => tliId === tli.id)
                  .flatMap(([locId]) => allPpTlis.filter((ppTli) => ppTli.location.id === locId))
                  .map((ppTli) => ppTli.id),
              }));

              const locsForMaybeNew = locTliTuples
                .filter(([, tliId]) => tliId === "new")
                .flatMap(([locId]) => allPpTlis.filter((ppTli) => ppTli.location.id === locId))
                .map((ppTli) => ppTli.id);
              if (locsForMaybeNew.nonEmpty) {
                inputs.push({
                  id: undefined!,
                  planLines: locsForMaybeNew,
                  customPlaceholderName: ref.current.last,
                });
              }

              await save({ variables: { inputs } });
              closeModal();
            }}
          />
        )}
      </ModalFooter>
    </>
  );
}

type PlanLocation = DesignPackageTliCardFragment["planLines"][number]["location"];
type SimplifiedPlanLocation = { simplifiedPath: string } & PlanLocation;
type Row = SimpleHeaderAndData<SimplifiedPlanLocation>;

function useCreateColumns(
  placeholders: DesignPackageTliCardFragment[],
  locToTli: Record<string, string>,
  setLocToTli: (locToTli: Record<string, string>) => void,
  ref: React.MutableRefObject<string[]>,
): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Location",
      data: (loc) => loc.simplifiedPath,
    }),
    ...placeholders.map((tli, ix) =>
      column<Row>({
        header: () => (
          <EditableDiv onInput={(i) => (ref.current[ix] = i.currentTarget.innerText)}>{ref.current[ix]}</EditableDiv>
        ),
        data: (loc) => (
          <Checkbox
            label=""
            checkboxOnly
            selected={locToTli[loc.id] === tli.id}
            onChange={() => setLocToTli({ [loc.id]: tli.id })}
          />
        ),
        w: "140px",
      }),
    ),
    column<Row>({
      header: () => (
        <EditableDiv onInput={(i) => (ref.current[placeholders.length] = i.currentTarget.innerText)}>New</EditableDiv>
      ),
      data: (loc) => (
        <Checkbox
          label=""
          checkboxOnly
          selected={locToTli[loc.id] === "new"}
          onChange={() => setLocToTli({ [loc.id]: "new" })}
        />
      ),
      w: "100px",
    }),
  ];
}

/**
 * We don't need all the extra styling from a normal input. Just a basic div that's editable. We're doing this without
 * figma/ux, so subject to change.
 */
function EditableDiv({
  children,
  onInput,
}: React.PropsWithChildren<{ onInput: React.FormEventHandler<HTMLDivElement> }>) {
  return (
    <div css={Css.cursorPointer.hmaxc.$} contentEditable suppressContentEditableWarning onInput={onInput}>
      {children}
    </div>
  );
}

export function useTakeoffLocationsModal() {
  const { openModal } = useModal();
  const context = useDesignPackageConfiguratorContext();

  return (placeholderTliId: string) => {
    openModal({
      size: "xl",
      content: (
        <DesignPackageConfiguratorContext.Provider value={context}>
          <TakeoffLocationsModal placeholderTliId={placeholderTliId} />
        </DesignPackageConfiguratorContext.Provider>
      ),
    });
  };
}

/**
 * Location pathnames can get very long and verbose, and notably have much repetition when viewed side by side.
 * This can take a collection of Locations with an {id,path} and remove from {path} the common prefix of all paths.
 * i.e. given:
 * [
 *  "Building~Level 1~Kitchen~Nook~Cabinet~Top Drawer~Pull",
 *  "Building~Level 1~Kitchen~Nook~Cabinet~Bottom Drawer~Pull",
 * ]
 * this can detect "Building~Level 1~Kitchen~Nook~Cabinet~" as the common prefix and remove it, resulting in:
 * ["Top Drawer~Pull", "Bottom Drawer~Pull"]
 */
export function simplifyLocationPaths(locations: PlanLocation[]): SimplifiedPlanLocation[] {
  const locTuples = locations.map((loc) => Array.from(loc.pathArray));
  while (locTuples.everyHasSame((tup) => tup.first) && locTuples.first?.nonEmpty) {
    locTuples.forEach((tup) => tup.shift());
  }
  return locTuples.map((tup, ix) => ({
    ...locations[ix],
    simplifiedPath:
      tup.join(" > ").trim() ||
      // if we cleared out everything (maybe there was only 1?), just show the whole original path
      locations[ix].pathArray.join(" > ").trim(),
  }));
}
