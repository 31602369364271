import { Css, RightPaneLayout, ScrollableContent, Switch } from "@homebound/beam";
import { useState } from "react";
import { SearchBox } from "src/components";
import { BidContractRevisionStatus, PageBidPackageDetailFragment } from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { PlanBasedBidsTable } from "./PlanBasedBidsTable";
import { UnitBasedBidsTable } from "./UnitBasedBidsTable";

type BidsTabProps = {
  bidPackage: PageBidPackageDetailFragment;
};

export function BidsTab({ bidPackage }: BidsTabProps) {
  const [searchFilter, setSearchFilter] = useState<string | undefined>("");
  const [showPlanUsage, setShowPlanUsage] = useState(!bidPackage.isUnitBased);

  const bidPackageBidItems = new Set(bidPackage.lineItems.map((li) => li.bidItem?.id).compact());
  // Get all requests without contracts or with draft contracts with incomplete scope
  const requestsWithIncompleteBids = bidPackage.activeRequests.filter((bpr) => {
    const bidContractRevision = bidPackage.latestVersion.latestBidContractRevisions.find(
      (bcr) => bcr.bidContract.tradePartner?.id === bpr.tradePartner.id,
    );
    const bcrBidItems = new Set(bidContractRevision?.lineItems.map((li) => li.bidItem?.id).compact());

    let hasIncompleteScope: boolean = false;
    for (const biId of bidPackageBidItems) {
      if (!bcrBidItems?.has(biId)) {
        hasIncompleteScope = true;
        break;
      }
    }

    return (
      !bidContractRevision ||
      (hasIncompleteScope && bidContractRevision.status.code === BidContractRevisionStatus.Draft)
    );
  });

  return (
    <>
      <TableActions>
        <SearchBox onSearch={setSearchFilter} />
        {bidPackage.isUnitBased && (
          <Switch label="Show Plan Usage" selected={showPlanUsage} onChange={setShowPlanUsage} />
        )}
      </TableActions>
      <div css={Css.h100.$}>
        <ScrollableContent virtualized>
          <RightPaneLayout>
            <div css={Css.h100.$}>
              {!showPlanUsage ? (
                <UnitBasedBidsTable
                  bidPackage={bidPackage}
                  searchFilter={searchFilter}
                  requestsWithIncompleteBids={requestsWithIncompleteBids}
                />
              ) : (
                <PlanBasedBidsTable
                  bidPackage={bidPackage}
                  searchFilter={searchFilter}
                  requestsWithIncompleteBids={requestsWithIncompleteBids}
                />
              )}
            </div>
          </RightPaneLayout>
        </ScrollableContent>
      </div>
    </>
  );
}

export function getOptionsKey(
  opt: {
    __typename?: "ReadyPlanOption" | undefined;
    id: string;
  }[],
) {
  return opt
    .sortByKey("id")
    .map((o) => o.id)
    .join(",");
}
