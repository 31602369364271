import { Button } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { createBidPackagesEditUrl, createBidPackagesUrl } from "src/RouteUrls";
import { StepActions, useStepperContext } from "src/components/stepper";
import { BidPackageStatus, Maybe, SaveBidPackageInput, useSaveBidPackagesMutation } from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { StringParam, useQueryParams } from "use-query-params";
import { BidPackageFormState, mapBidPackagesToForm } from "../AddBidPackagePage";

export function BidPackageStepActions({ formState }: { formState: ObjectState<BidPackageFormState> }) {
  const { currentStep, nextStep, steps, isLastStep } = useStepperContext();
  const [{ devId, projectId }] = useQueryParams({
    devId: StringParam,
    projectId: StringParam,
  });
  const [saveBidPackages] = useSaveBidPackagesMutation();
  const currentStepIndex = steps.findIndex((step) => step.value === currentStep.value);
  const nextStepDisabled = steps[currentStepIndex + 1]?.disabled;
  const history = useHistory();
  const saveBids = useCallback(
    async (exiting = false) => {
      const result = await saveBidPackages({ variables: { input: mapToSaveBidPackagesInput(formState) } });

      const bidPackages = result.data?.saveBidPackages.bidPackages;
      if (bidPackages && !exiting) {
        // Reinitialize the formState
        formState.set(mapBidPackagesToForm(bidPackages));

        // Update the url to be edit
        const groupId = bidPackages[0].bidPackageGroup?.id;
        if (groupId) {
          // Use window.history to prevent a rerendering from happening
          window.history.replaceState(null, "", createBidPackagesEditUrl(groupId, devId, projectId));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState],
  );

  const sendToTrades = useCallback(
    async () => {
      const { bidPackages } = formState.value;
      await saveBidPackages({
        variables: {
          input: bidPackages.map((bp) => ({
            id: bp.id,
            status: BidPackageStatus.Open,
          })),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState],
  );

  return (
    <StepActions>
      <>
        <Button
          variant="secondary"
          label="Save Draft & Exit"
          size="lg"
          onClick={async () => {
            await saveBids(true);
            history.push(createBidPackagesUrl(devId, projectId));
          }}
        />
        {!isLastStep ? (
          <Button
            label="Continue"
            size="lg"
            onClick={async () => {
              await saveBids();
              nextStep();
            }}
            disabled={nextStepDisabled}
          />
        ) : (
          <Button
            label={formState.value.isHistoricalBid ? "Save" : "Send to Trades"}
            size="lg"
            onClick={async () => {
              await sendToTrades();
              history.push(createBidPackagesUrl(devId, projectId));
            }}
            disabled={!formState.valid}
          />
        )}
      </>
    </StepActions>
  );
}

function mapToSaveBidPackagesInput(
  formState: ObjectState<BidPackageFormState>,
  isHistoricalBid?: Maybe<boolean>,
): SaveBidPackageInput[] {
  const { bidPackages, development, ...formStateVals } = formState.value;
  return (bidPackages || []).map((bidPackage) => ({
    ...formStateVals,
    ...bidPackage,
    developments: development ? [development] : undefined,
    dueDate: isHistoricalBid ? undefined : validateFormDate(formState.dueDate.value),
    estimatedAwardDate: isHistoricalBid ? undefined : validateFormDate(formState.estimatedAwardDate.value),
    estimatedConstructionDate: isHistoricalBid
      ? undefined
      : validateFormDate(formState.estimatedConstructionDate.value),
    sendReminderDate: isHistoricalBid ? undefined : validateFormDate(formState.sendReminderDate.value),
  }));
}

function validateFormDate(date: Date | null | undefined) {
  if (!(date instanceof Date)) return date;
  return new DateOnly(date);
}
