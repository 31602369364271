import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { BidContractHeader } from "src/components/bid-contracts/BidContractHeader";
import { BidContractRevisionFragment, useBidContractRevisionQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ProjectBidContractTabs } from "./ProjectBidContractTabs";

export function ProjectBidContractPage() {
  const { bidContractRevisionId } = useParams<{ bidContractRevisionId: string }>();
  const query = useBidContractRevisionQuery({ variables: { bidContractRevisionId } });
  return queryResult(query, {
    data: ({ bidContractRevision }) => {
      return <ProjectContractView bidContractRevision={bidContractRevision} />;
    },
  });
}

type ContractProp = {
  bidContractRevision: BidContractRevisionFragment;
};

function ProjectContractView({ bidContractRevision }: ContractProp) {
  return (
    <>
      <BidContractHeader selectedRevision={bidContractRevision} />
      <div css={Css.mt3.$}>
        <ProjectBidContractTabs selectedRevision={bidContractRevision} />
      </div>
    </>
  );
}
