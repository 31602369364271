import { Css, useModal } from "@homebound/beam";
import { Link } from "react-router-dom";
import { FormattedDate } from "src/components";
import {
  BidPackagesPage_AvailableUpdateFragment,
  BidPackagesPage_AvailableUpdateProductOfferingFragment,
} from "src/generated/graphql-types";
import { AggregateMemberVersionChangeType } from "src/routes/libraries/design-catalog/design-package-configurator/components/DesignUpdatesAvailableButton";
import { renderListItem } from "src/routes/libraries/versioning/VersionHistoryUtils";
import { createDevelopmentProductOfferingUrl, createProjectProductOfferingUrl } from "src/RouteUrls";
import { sanitizeHtml } from "src/utils";

export function ReadyPlanSourceSection({ update }: { update: BidPackagesPage_AvailableUpdateFragment }) {
  const { newTlivCount, removedTlivCount, updatedTlivCount } = getTlivChangeCounts(update);
  const { newRplvCount, removedRplvCount, updatedRplvCount } = getRplvChangeCounts(update);
  const { closeModal } = useModal();
  // The source or a bid update will always be a product offering
  const productOffering = update.source.readyPlan as BidPackagesPage_AvailableUpdateProductOfferingFragment;
  return (
    <div>
      <div data-testid="planInfo" css={Css.sm.df.fdr.gap1.$}>
        <span css={Css.smMd.$}>{update.source?.readyPlan.name} </span>
        <Link
          to={
            productOffering.development
              ? createDevelopmentProductOfferingUrl(
                  productOffering.development.id,
                  update.source.readyPlan.id,
                  update.source.id,
                )
              : createProjectProductOfferingUrl(
                  productOffering.project!.id,
                  update.source.readyPlan.id,
                  update.source.id,
                )
          }
          onClick={closeModal}
        >
          <span>v{update.source?.version} - </span>
        </Link>
        <span css={Css.gray700.$}>
          <FormattedDate date={update.createdAt} dateFormatStyle="long" />
        </span>
      </div>
      {update.source?.versionNotes && (
        <div
          data-testid="versionNotes"
          css={Css.gray700.sm.$}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(update.source.versionNotes || ""),
          }}
        />
      )}
      <ul css={Css.m0.gray700.$}>
        <>
          {/* Takeoff Items */}
          {renderListItem(newTlivCount, "Added", "takeoff item")}
          {renderListItem(removedTlivCount, "Removed", "takeoff item")}
          {renderListItem(updatedTlivCount, "Modified", "takeoff item")}
          {/* Plan Locations */}
          {renderListItem(newRplvCount, "Added", "location")}
          {renderListItem(removedRplvCount, "Removed", "location")}
          {renderListItem(updatedRplvCount, "Modified", "location")}
        </>
      </ul>
    </div>
  );
}

function getTlivChangeCounts(update: BidPackagesPage_AvailableUpdateFragment) {
  const tlivs = update.source?.tliVersions.filter((tliv) => !tliv.identity.isPlaceholderMaterial) || [];
  const newTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.REMOVED);
  const updatedTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.UPDATED);

  return { newTlivCount, removedTlivCount, updatedTlivCount };
}

function getRplvChangeCounts(update: BidPackagesPage_AvailableUpdateFragment) {
  const rplVersions = update.source?.rplVersions || [];
  const newRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.REMOVED);
  const updatedRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.UPDATED);

  return { newRplvCount, removedRplvCount, updatedRplvCount };
}
