import {
  column,
  dateColumn,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  multiFilter,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  Tag,
} from "@homebound/beam";
import { capitalCase } from "change-case";
import { useMemo, useState } from "react";
import { chipCell, dateCell, SearchBox } from "src/components";
import { getTagTypeFromStatus } from "src/components/bid-contracts/BidContractHeader";
import {
  BidContractRevisionStatus,
  TradePartnerDevelopmentContractFragment,
  useTradePartnerDevelopmentContractsQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { createDevelopmentContractOverviewUrl, createProjectBidContractOverviewUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

type TradePartnerDevelopmentContractsTabProps = {
  tradePartnerId: string;
};

export function TradePartnerDevelopmentContractsTab(props: TradePartnerDevelopmentContractsTabProps) {
  const { tradePartnerId } = props;
  const query = useTradePartnerDevelopmentContractsQuery({ variables: { tradePartnerId } });
  return queryResult(query, {
    data: ({ tradePartners }) => <DevelopmentContracts bidContracts={tradePartners.first!.bidContracts} />,
  });
}

type TradePartnerDevelopmentContractsProp = {
  bidContracts: TradePartnerDevelopmentContractFragment[];
};

function DevelopmentContracts({ bidContracts }: TradePartnerDevelopmentContractsProp) {
  const [filter, setFilter] = useState<FilterOptions>({});
  const [search, setSearch] = useState("");

  const rows: GridDataRow<Row>[] = useMemo(
    () =>
      simpleDataRows(
        bidContracts?.filter((row) => {
          let result = true;
          if (filter?.primary)
            result =
              (filter?.primary.includes("primary") && row.isPrimary) ||
              (filter?.primary.includes("secondary") && !row.isPrimary);
          if (filter?.status) result = result && filter?.status.includes(row.latestRevision.status.code);
          return result;
        }) || [],
      ),
    [bidContracts, filter],
  );

  const rowStyles: RowStyles<Row> = {
    data: {
      rowLink: ({ data }) =>
        // A bid contract will always be redirected to its latest revision
        data.developments.length
          ? createDevelopmentContractOverviewUrl(data.developments[0].id, data.latestRevision.id!)
          : createProjectBidContractOverviewUrl(data.projects[0].id, data.latestRevision.id!),
    },
  };

  const filterDefs = useMemo(() => {
    return {
      primary: multiFilter({
        options: [
          { value: "primary" as const, label: "Primary" },
          { value: "secondary" as const, label: "Secondary" },
        ],
        getOptionLabel: (tp) => tp.label,
        getOptionValue: (tp) => tp.value,
        label: "Preference",
      }),
      status: multiFilter({
        options: Object.values(BidContractRevisionStatus).map((cs) => ({ label: cs, value: cs })),
        getOptionLabel: (cs) => capitalCase(cs.label),
        getOptionValue: (cs) => cs.value,
        label: "Status",
      }),
    };
  }, []);

  return (
    <>
      <TableActions>
        <Filters<FilterOptions> filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        <SearchBox onSearch={setSearch} />
      </TableActions>
      <GridTable
        columns={createColumns()}
        rows={rows}
        filter={search}
        style={{ bordered: true, allWhite: true }}
        fallbackMessage="No Info"
        stickyHeader
        sorting={{ on: "client" }}
        rowStyles={rowStyles}
      />
    </>
  );
}

type Row = SimpleHeaderAndData<TradePartnerDevelopmentContractFragment>;

const createColumns = (): GridColumn<Row>[] => [
  column<Row>({
    header: () => `Developments/Communities`,
    data: (data) =>
      data.developments.length
        ? data.developments.map((d) => d.name).join(", ")
        : data.projects.map((d) => d.name).join(", "),
  }),
  column<Row>({
    header: () => `Preference`,
    data: (data) => (data.isPrimary ? "Primary" : "Secondary"),
  }),
  column<Row>({
    header: () => `Version`,
    data: (data) => ({
      content: data.latestRevision.version,
      value: parseInt(data.latestRevision.version || "0"),
    }),
  }),
  column<Row>({
    header: "Cost Codes",
    data: (data) => chipCell(data.latestRevision.costCodes.map((cc) => cc.number)),
    w: 2,
  }),
  dateColumn<Row>({
    header: () => `Effective On`,
    data: (data) => dateCell(data.latestRevision.startDate),
  }),
  dateColumn<Row>({
    header: () => `Expires On`,
    data: (data) => dateCell(data.latestRevision.endDate),
  }),
  column<Row>({
    header: () => `Status`,
    data: (data) => ({
      content: (
        <>
          <Tag
            text={data.latestRevision.status?.name || "Draft"}
            type={getTagTypeFromStatus(data.latestRevision.status?.code)}
          />
        </>
      ),
      value: data.latestRevision.status?.code,
    }),
  }),
];

type FilterOptions = Partial<{
  primary: ("primary" | "secondary")[];
  status: BidContractRevisionStatus[];
}>;
