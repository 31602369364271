import {
  actionColumn,
  BoundDateField,
  BoundNumberField,
  BoundSelectField,
  ButtonMenu,
  collapseColumn,
  CollapseToggle,
  column,
  Css,
  dateColumn,
  emptyCell,
  getTableStyles,
  GridDataRow,
  GridRowLookup,
  GridTable,
  Icon,
  IconButton,
  InputStylePalette,
  Loader,
  maybeTooltip,
  Palette,
  Placement,
  RightPaneLayout,
  RowStyles,
  ScrollableContent,
  TextFieldXss,
  Tooltip,
  useComputed,
  useGridTableApi,
  useModal,
  useRightPane,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { differenceInBusinessDays, isBefore, isSameDay, subYears } from "date-fns";
import debounce from "lodash/debounce";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useEffectOnce, useUpdateEffect } from "react-use";
import { createMilestoneCatalogFormUrl, createMilestoneDetailsPageUrl } from "src/RouteUrls";
import { CommentCountBubble, emptyCellDash, formatDate, Icon as LegacyBpIcon, ProgressPill } from "src/components";
import { StatusIndicator } from "src/components/StatusIndicator";
import { BoundTradePartnerSelectField } from "src/components/autoPopulateSelects/TradePartnerSelectField";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  DayOfWeek,
  DraftModeEnumDetails_EnumDetailsFragment,
  DraftPlanTaskInput,
  FeatureFlagType,
  InputMaybe,
  Maybe,
  PlanTasksFilter,
  ScheduleDraftMode_PlanMilestoneFragment,
  ScheduleDraftMode_PlanTaskFragment,
  SchedulingExclusionDatesFragment,
  TaskStatus,
  TaskStatusDetail,
  TradePartnerAvailabilityRequestStatus,
  TradePartnerTaskStatus,
  TradePartnerTaskStatusDetail,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { DynamicScheduleSidePane } from "src/routes/projects/dynamic-schedules/components/DynamicScheduleSidePane";
import { SendTradePartnerAvailabilityRequestEmailModal } from "src/routes/projects/dynamic-schedules/components/SendTradePartnerAvailabilityRequestEmailModal";
import { ScheduleDraftModePlanTaskSetDaysModal } from "src/routes/projects/dynamic-schedules/draft-mode/ScheduleDraftModePlanTaskSetDaysModal";
import { TaskDetailCardType } from "src/routes/projects/dynamic-schedules/task-details/TaskDetailCard";
import {
  assertNever,
  isDefined,
  mapTradePartnerTaskStatusToReducedStatus,
  newComparator,
  noop,
  pluralize,
  ReducedTradePartnerTaskStatuses,
  ReducedTradePartnerTaskStatusOrder,
} from "src/utils";
import { DateOnly } from "src/utils/dates";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { TaskStatusSelect } from "../../schedule-v2/components/TaskStatusSelect";
import { CustomDynamicSchedulesFilter, mapToFilter } from "../components/DynamicSchedulesFilterModal";
import { TaskGroupBy } from "../components/DynamicSchedulesGroupBy";
import {
  createCostCodeRows,
  createStageRows,
  createTaskAndMilestoneRows,
  DayOfWeekDayPicker,
  DynamicSchedulesMilestoneRow,
  DynamicSchedulesRow,
  DynamicSchedulesTaskRow,
  getBusinessDaysOptions,
  ScheduleContainer,
} from "../utils";
import { DebugTooltip } from "./DebugTooltip";
import { ScheduleDraftModeDelayFlagModal } from "./ScheduleDraftModeDelayFlagModal";
import { ScheduleDraftModeDeleteFlagModal } from "./ScheduleDraftModeDeleteFlagModal";
import { useDraftScheduleStore } from "./scheduleDraftStore";

type DraftScheduleTableProps = {
  planTasks: ScheduleDraftMode_PlanTaskFragment[];
  planMilestones: ScheduleDraftMode_PlanMilestoneFragment[];
  enumDetails: DraftModeEnumDetails_EnumDetailsFragment;
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
  loading: boolean;
  isLookaheadView: boolean;
  groupBy: TaskGroupBy;
  scheduleParentId: string;
  hasDraftChanges: boolean;
  allTasks?: ScheduleDraftMode_PlanTaskFragment[];
};

export function DraftScheduleTable(props: DraftScheduleTableProps) {
  const {
    planTasks,
    planMilestones,
    enumDetails,
    schedulingExclusionDates,
    loading,
    isLookaheadView,
    groupBy = TaskGroupBy.None,
    scheduleParentId,
    hasDraftChanges,
    allTasks,
  } = props;
  const { openRightPane } = useRightPane();

  const isTradeCommsEnabled = useFeatureFlag(FeatureFlagType.DynamicSchedulesTradeComms);
  const [{ debug, paneTaskId, scrollIntoView }, setQueryParams] = useQueryParams({
    debug: BooleanParam,
    paneTaskId: StringParam,
    scrollIntoView: StringParam,
  });
  const lastUpdatedTaskId = useDraftScheduleStore((state) => state.lastUpdatedTaskId);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const history = useHistory();

  const onTaskDetailLink = useCallback(
    (taskData: ScheduleDraftMode_PlanTaskFragment, scrollIntoView?: TaskDetailCardType | null) => {
      setQueryParams({ paneTaskId: taskData.id, scrollIntoView });

      openRightPane({
        content: (
          <DynamicScheduleSidePane
            onClose={() => {
              setQueryParams({ paneTaskId: null, scrollIntoView: null });
            }}
            draftTaskId={taskData.id}
            scheduleParentId={scheduleParentId}
          />
        ),
      });
    },
    [openRightPane, setQueryParams, scheduleParentId],
  );

  const onMilestoneDetailLink = useCallback(
    (milestoneId: string) => {
      history.push(createMilestoneDetailsPageUrl(scheduleParentId, milestoneId));
    },
    [history, scheduleParentId],
  );

  const api = useGridTableApi<DynamicSchedulesRow>();

  const { getFormState } = useFormStates<FormInput, ScheduleDraftMode_PlanTaskFragment>({
    config: formConfig,
    map: (task) => mapToForm(task),
    getId: (t) => t.id,
    autoSave: async (fs) => {
      // Though we have implemented our own draft-mode specific autoSave logic, we still want the formState to reset the "dirty"/"touched" states
      // as if we were using the default autoSave behavior. This ensures we don't get caught will stale input values when user updates an input which later
      // gets overridden by a subsequent change/server response.
      fs.commitChanges();
    },
  });

  const styles = useMemo(() => {
    const tableStyles = getTableStyles({
      grouped: groupBy !== TaskGroupBy.None,
      allWhite: true,
      rowHeight: "fixed",
      inlineEditing: true,
    });
    return {
      ...tableStyles,
      rootCss: {
        ...tableStyles.rootCss,
        // Add an extra row worth of padding to the bottom of the table to show end of list with overscroll
        // tbd if this becomes a new standard for all tables
        ...Css.pbPx(50).$,
      },
    };
  }, [groupBy]);

  const activeRowId = useMemo(
    () => (lastUpdatedTaskId ? `task_${lastUpdatedTaskId}` : paneTaskId ? `task_${paneTaskId}` : undefined),
    [lastUpdatedTaskId, paneTaskId],
  );
  const lookup = useRef<GridRowLookup<DynamicSchedulesRow>>();

  const rowStyles: RowStyles<DynamicSchedulesRow> = useMemo(
    () => ({
      lookahead: {
        cellCss: Css.bgGray100.$,
      },
      milestone: {
        cellCss: (milestone: DynamicSchedulesMilestoneRow) => Css.if(milestone.data.progress === 100).bgGray200.$,
      },
      task: {
        cellCss: (task: DynamicSchedulesTaskRow) => Css.if(task.data.status.code === TaskStatus.Complete).bgGray200.$,
      },
    }),
    [],
  );

  const rows = useMemo(() => {
    return createRows(planTasks, planMilestones, isLookaheadView, groupBy, allTasks ?? []);
  }, [planTasks, planMilestones, isLookaheadView, groupBy, allTasks]);

  const columns = useMemo(
    () =>
      createColumns(
        getFormState,
        debug,
        loading,
        enumDetails,
        schedulingExclusionDates,
        planTasks,
        groupBy,
        isTradeCommsEnabled,
        onTaskDetailLink,
        onMilestoneDetailLink,
        hasDraftChanges,
        setDraftTaskChanges,
        isLookaheadView,
      ),
    [
      getFormState,
      debug,
      loading,
      enumDetails,
      schedulingExclusionDates,
      planTasks,
      groupBy,
      isTradeCommsEnabled,
      onTaskDetailLink,
      onMilestoneDetailLink,
      hasDraftChanges,
      setDraftTaskChanges,
      isLookaheadView,
    ],
  );

  const maybeScrollTaskIntoView = useCallback(
    (rowId?: string) => {
      const targetTaskIndex = rowId
        ? rows.findIndex((r) => r.id === rowId)
        : rows.findIndex((r) => r.id === lastUpdatedTaskId);
      // Wait some ticks before attempting to scroll as stated in scrollToIndex docs
      targetTaskIndex !== -1 && setTimeout(() => api.scrollToIndex(targetTaskIndex), 100);
    },
    [api, lastUpdatedTaskId, rows],
  );

  // On initial load, if user has deep linked or given a filter that includes completed tasks, scroll to that task or
  // first completed row;
  useEffectOnce(() => {
    if (paneTaskId) {
      const linkedPlanTask = planTasks.find((t) => t.id === paneTaskId);
      if (linkedPlanTask) {
        // Casting to TaskDetailCardType because use-query-params doesn't support enums
        maybeScrollTaskIntoView(paneTaskId);
        onTaskDetailLink(linkedPlanTask, scrollIntoView ? (scrollIntoView as TaskDetailCardType) : undefined);
      }
    } else {
      const targetTaskIndex = rows.find(
        (r) =>
          (r.kind === "task" && r.data.status.code !== TaskStatus.Complete) ||
          (r.kind === "milestone" && (!r.data.progress || r.data.progress < 100)),
      );
      maybeScrollTaskIntoView(targetTaskIndex?.id);
    }
  });

  // Note: If we can hook into grid tables onSortChange for client side sort, we could remove this use effect by attaching
  // maybeScrollTaskIntoView to the onSortChange callback
  useUpdateEffect(() => {
    !loading && maybeScrollTaskIntoView();
  }, [loading, maybeScrollTaskIntoView]);

  return (
    <ScrollableContent virtualized>
      <ScheduleContainer>
        <RightPaneLayout>
          <GridTable
            key={isLookaheadView ? "lookahead-schedule" : "list-schedule"}
            rowStyles={rowStyles}
            as="virtual"
            api={api}
            columns={columns}
            rows={rows}
            stickyHeader={true}
            activeRowId={activeRowId}
            style={styles}
            rowLookup={lookup}
            sorting={{
              on: "client",
              initial: ["start-date-column", "ASC"],
            }}
          />
        </RightPaneLayout>
      </ScheduleContainer>
    </ScrollableContent>
  );
}

// Slams `!!!!!` in front of the name to force completed task/milestones to onto one side of the list
function nameSortHelper({
  status,
  progress,
  name,
}: {
  status?: Omit<TaskStatusDetail, "sortOrder">;
  progress?: number;
  name: string;
}) {
  return status?.code === TaskStatus.Complete || progress === 100 ? `!!!!!${name}` : name;
}

// If the task/milestone is complete it subtract a hundred years from the date to force it to one side of the list
function dateSortHelper({
  status,
  progress,
  date,
}: {
  status?: Omit<TaskStatusDetail, "sortOrder">;
  progress?: number;
  date?: Date | null;
}) {
  if (!date) return undefined;

  return status?.code === TaskStatus.Complete || progress === 100
    ? new DateOnly(subYears(date, 100)) // We'll let our descendants fix this bug
    : new DateOnly(date);
}

// If the task/milestone is complete jack up the sort value to force it to one side of the list.
function tradeStatusSortHelper({
  status,
  progress,
  tradePartnerStatus,
}: {
  status?: Omit<TaskStatusDetail, "sortOrder">;
  progress?: number;
  tradePartnerStatus?: TradePartnerTaskStatusDetail;
}) {
  if (tradePartnerStatus) {
    return ReducedTradePartnerTaskStatusOrder[mapTradePartnerTaskStatusToReducedStatus(tradePartnerStatus.code)];
  }

  return status?.code === TaskStatus.Complete || progress === 100 ? Infinity : -Infinity;
}

// If the task/milestone is complete jack up the sort value to force it to one side of the list.
function durationSortHelper({
  status,
  progress,
  durationInDays,
}: {
  status?: Omit<TaskStatusDetail, "sortOrder">;
  progress?: number;
  durationInDays: number;
}) {
  return durationInDays + (status?.code === TaskStatus.Complete || progress === 100 ? -Infinity : Infinity);
}

function createColumns(
  getFormState: GetFormState,
  debugMode: Maybe<boolean>,
  loading: boolean,
  enumDetails: DraftModeEnumDetails_EnumDetailsFragment,
  schedulingExclusionDates: SchedulingExclusionDatesFragment[],
  tasks: ScheduleDraftMode_PlanTaskFragment[],
  groupBy: TaskGroupBy,
  isTradeCommsEnabled: boolean,
  onTaskDetailLink: (task: ScheduleDraftMode_PlanTaskFragment, scrollIntoView?: TaskDetailCardType) => void,
  onMilestoneDetailLink: (milestoneId: string) => void,
  hasDraftChanges: boolean,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
  // Note: Needed to force headers to re-render and prevent sorting a lookahead view that's not meant to be sorted.
  //   Changing the `key` prop on Gridtable alongside a memoized `sorting` is not enough
  isLookaheadView: boolean,
) {
  return [
    ...(groupBy !== TaskGroupBy.None
      ? [
          collapseColumn<DynamicSchedulesRow>({
            header: (_, { row }) => <CollapseToggle row={row} />,
            clientSideSort: false,
            w: "40px",
            group: (_, { row }) => ({
              content: () => <CollapseToggle row={row} />,
            }),
            task: emptyCell,
          }),
        ]
      : []),
    actionColumn<DynamicSchedulesRow>({
      clientSideSort: false,
      w: "48px",
      sticky: "left",
      header: emptyCell,
      lookahead: emptyCell,
      group: emptyCell,
      milestone: ({ id }) => ({
        content: () => (
          <ClickableCell onClick={() => onMilestoneDetailLink(id)}>
            {loading ? (
              <Loader size="xs" />
            ) : (
              <Tooltip title="Milestone">
                <LegacyBpIcon icon="signpost" color={Palette.Gray900} />
              </Tooltip>
            )}
          </ClickableCell>
        ),
      }),
      task: (task) => ({
        content: () => (
          <ClickableCell onClick={() => onTaskDetailLink(task)}>
            <IconCell task={task} loading={loading} />
          </ClickableCell>
        ),
      }),
    }),
    column<DynamicSchedulesRow>({
      header: "Name",
      clientSideSort: !isLookaheadView,
      mw: "200px",
      w: 2,
      sticky: "left",
      lookahead: ({ name, startDate, endDate }) => ({
        content: () => name,
        css: Css.lgSb.gray900.$,
        tooltip:
          name === "6 Weeks"
            ? `Tasks set to start on or after ${formatDate(startDate, "weekDayMonthShort")}`
            : `Tasks set to start between ${formatDate(startDate, "weekDayMonthShort")} - ${formatDate(endDate, "weekDayMonthShort")}`,
        value: name,
      }),
      group: ({ name, progress }) => ({
        content: () => (
          <div css={Css.df.gap2.$}>
            <span>{name}</span>
            {groupBy === TaskGroupBy.Stage && isDefined(progress) && (
              <Tooltip title="This percentage reflects all tasks within the stage, not just those currently visible within the table.">
                <ProgressPill progress={progress} changeColorOnCompleted />
              </Tooltip>
            )}
          </div>
        ),
        typeScale: "smSb",
        colspan: 4,
        value: name,
      }),
      milestone: ({ name, progress, id }) => ({
        content: () => (
          <ClickableCell onClick={() => onMilestoneDetailLink(id)}>
            <TextTruncatedTooltip text={name} />
          </ClickableCell>
        ),
        typeScale: "xsSb",
        value: name,
        sortValue: () => nameSortHelper({ name, progress }),
      }),
      task: (task) => ({
        content: () => (
          <ClickableCell onClick={() => onTaskDetailLink(task)}>
            <TextTruncatedTooltip text={task.name} />
          </ClickableCell>
        ),
        value: task.name,
        sortValue: () => nameSortHelper({ name: task.name, status: task.status }),
      }),
    }),
    column<DynamicSchedulesRow>({
      header: "Trade",
      clientSideSort: !isLookaheadView,
      mw: "205px",
      lookahead: emptyCell,
      group: emptyCell,
      milestone: ({ id, progress }) => ({
        colspan: 2,
        revealOnRowHover: true,
        content: () => (
          <ClickableCell onClick={() => onMilestoneDetailLink(id)}>
            <div css={Css.df.gap3.w100.$}>
              <div css={Css.df.$}>Milestone Completion:</div>
              <div>
                <ProgressPill fixedWidthPx={150} changeColorOnCompleted progress={progress} />
              </div>
            </div>
          </ClickableCell>
        ),
        value: undefined,
        sortValue: () => (progress === 100 ? "!" : "~"),
      }),
      task: (task) => ({
        content: () => <TradePartnerCell task={task} getFormState={getFormState} />,
        value: task.tradePartner?.name,
        sortValue: () => nameSortHelper({ name: task.tradePartner?.name ?? "-", status: task.status }),
      }),
    }),
    column<DynamicSchedulesRow>({
      header: "Trade Status",
      clientSideSort: !isLookaheadView,
      mw: "150px",
      lookahead: emptyCell,
      group: emptyCell,
      milestone: ({ id, progress }) => ({
        content: () => <ClickableCell onClick={() => onMilestoneDetailLink(id)} />,
        value: "",
        sortValue: () => tradeStatusSortHelper({ progress }),
      }),
      task: (task) => ({
        content: () =>
          task?.tradePartner ? (
            <TradeStatusCell task={task} getFormState={getFormState} enumDetails={enumDetails} />
          ) : (
            <ClickableCell onClick={() => onTaskDetailLink(task)} />
          ),
        value: task.tradePartnerStatus.code,
        sortValue: () => tradeStatusSortHelper({ tradePartnerStatus: task.tradePartnerStatus, status: task.status }),
      }),
    }),
    column<DynamicSchedulesRow>({
      header: "Task Status",
      clientSideSort: !isLookaheadView,
      mw: "150px",
      lookahead: emptyCell,
      group: emptyCell,
      milestone: ({ id, progress }) => ({
        content: () => <ClickableCell onClick={() => onMilestoneDetailLink(id)} />,
        value: "",
        sortValue: () =>
          enumDetails?.taskStatus?.find(({ code }) => code === TaskStatus.Complete)!.sortOrder *
          (progress === 100 ? 1 : -1),
      }),
      task: (task) => ({
        content: () => (
          <TaskStatusInput task={task} getFormState={getFormState} loading={loading} enumDetails={enumDetails} />
        ),
        sortValue: () => enumDetails?.taskStatus?.find(({ code }) => code === task?.status?.code)!.sortOrder,
      }),
    }),
    column<DynamicSchedulesRow>({
      header: "Start",
      id: "start-date-column",
      clientSideSort: !isLookaheadView,
      w: "120px",
      group: emptyCell,
      lookahead: ({ startDate }) => ({
        content: <></>,
        value: startDate,
        // Ensure the lookahead rows get correctly sorted by the grouping startDate
        // vs just returning `emptyCell` here (since we apply a default sort on `start-date-column`)
        sortValue: startDate,
      }),
      milestone: ({ id, estStartDate, progress }) => ({
        content: () => (
          <ClickableCell onClick={() => onMilestoneDetailLink(id)}>
            {estStartDate?.date ? (
              <Tooltip title={formatDate(estStartDate?.date, "long")}>
                <span>{formatDate(estStartDate?.date, "weekDayMonthShort")}</span>
              </Tooltip>
            ) : (
              emptyCellDash
            )}
          </ClickableCell>
        ),
        value: estStartDate?.date,
        sortValue: () => dateSortHelper({ date: estStartDate, progress }),
      }),
      task: (task) => ({
        content: () => (
          <StartDateInput
            task={task}
            getFormState={getFormState}
            loading={loading}
            schedulingExclusionDates={schedulingExclusionDates}
            setDraftTaskChanges={setDraftTaskChanges}
            tasks={tasks}
          />
        ),
        value: task.startDate.date,
        sortValue: () => dateSortHelper({ date: task.startDate.date, status: task.status }),
      }),
    }),
    dateColumn<DynamicSchedulesRow>({
      header: "End",
      clientSideSort: !isLookaheadView,
      w: "120px",
      group: emptyCell,
      lookahead: emptyCell,
      milestone: ({ id, estEndDate, progress }) => ({
        content: () => (
          <ClickableCell onClick={() => onMilestoneDetailLink(id)}>
            <Tooltip title={formatDate(estEndDate?.date, "long")}>{formatDate(estEndDate?.date, "monthShort")}</Tooltip>
          </ClickableCell>
        ),
        value: estEndDate?.date,
        sortValue: () => dateSortHelper({ date: estEndDate, progress }),
      }),
      task: (task) => ({
        content: () => (
          <EndDateInput
            task={task}
            getFormState={getFormState}
            loading={loading}
            schedulingExclusionDates={schedulingExclusionDates}
          />
        ),
        value: task.endDate.date,
        sortValue: () => dateSortHelper({ date: task.endDate.date, status: task.status }),
      }),
    }),
    column<DynamicSchedulesRow>({
      header: "Duration",
      clientSideSort: !isLookaheadView,
      // Note: 85px is absolute minimum w for col to fully render `Duration` title & `sort` icon shown on hover
      w: "85px",
      lookahead: emptyCell,
      group: emptyCell,
      milestone: ({ id, progress, durationInDays }) => ({
        content: () => (
          <ClickableCell onClick={() => onMilestoneDetailLink(id)}>
            {durationInDays} {pluralize(durationInDays, "day", "days")}
          </ClickableCell>
        ),
        value: durationInDays,
        sortValue: () => durationSortHelper({ durationInDays, progress }),
      }),
      task: (task) => ({
        content: () => <DurationInput task={task} getFormState={getFormState} loading={loading} />,
        value: task.knownDurationInDays,
        sortValue: () =>
          durationSortHelper({ durationInDays: task?.knownDurationInDays ?? task.durationInDays, status: task.status }),
      }),
    }),
    actionColumn<DynamicSchedulesRow>({
      header: emptyCell,
      clientSideSort: false,
      align: "center",
      w: "80px",
      sticky: "right",
      group: emptyCell,
      lookahead: emptyCell,
      milestone: (milestone) => <MilestoneActionCell milestone={milestone} hasDraftChanges={hasDraftChanges} />,
      task: (task) => (
        <>
          <DebugTooltip debugMode={debugMode ?? false} task={task} />
          <TaskIconCell
            task={task}
            isTradeCommsEnabled={isTradeCommsEnabled}
            onTaskDetailLink={onTaskDetailLink}
            hasDraftChanges={hasDraftChanges}
            getFormState={getFormState}
          />
        </>
      ),
    }),
  ];
}

export function MilestoneActionCell({
  milestone,
  hasDraftChanges,
}: {
  milestone: ScheduleDraftMode_PlanMilestoneFragment;
  hasDraftChanges: boolean;
}) {
  const { openModal } = useModal();
  const history = useHistory();

  return (
    <IconButton
      icon="linkExternal"
      inc={2.3}
      disabled={hasDraftChanges && "Save schedule changes first before editing global milestones"}
      onClick={() => {
        openModal({
          content: (
            <ConfirmationModal
              confirmationMessage={
                <div css={Css.sm.$}>
                  This milestone is used in all other projects. Editing this milestone will update it everywhere. Would
                  you like to proceed with editing the milestone?
                </div>
              }
              label="Yes, Edit Milestone"
              onConfirmAction={() => history.push(createMilestoneCatalogFormUrl(milestone.id))}
              title="Edit Milestone"
            />
          ),
        });
      }}
      tooltip="Edit Global Milestone"
    />
  );
}

function TaskIconCell({
  task,
  isTradeCommsEnabled,
  onTaskDetailLink,
  hasDraftChanges,
  getFormState,
}: {
  task: ScheduleDraftMode_PlanTaskFragment;
  isTradeCommsEnabled: boolean;
  onTaskDetailLink: (task: ScheduleDraftMode_PlanTaskFragment, scrollIntoView?: TaskDetailCardType) => void;
  hasDraftChanges: boolean;
  getFormState: GetFormState;
}) {
  const { streams, tradePartnerAvailabilityRequests } = task;
  const { openModal } = useModal();
  const { closeRightPane } = useRightPane();
  const { triggerNotice } = useSnackbar();
  const latestRequest = tradePartnerAvailabilityRequests?.last;
  const taskHasPendingTpar =
    latestRequest?.status.code === TradePartnerAvailabilityRequestStatus.RescheduleNeeded &&
    latestRequest.rescheduleDates?.nonEmpty;
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setUserAddedDelayFlags = useDraftScheduleStore((state) => state.setUserAddedScheduleFlags);

  const onTaskDelete = useCallback(
    (task: ScheduleDraftMode_PlanTaskFragment) => {
      const doDelete = () => {
        closeRightPane(); // close pane just in case its open on this task
        setDraftTaskChanges([{ id: task.id, delete: true }]);
        triggerNotice({ message: "1 task deleted" });
      };

      if (task.canDeleteWithWarning.allowed) {
        return doDelete();
      }

      const warning = task.canDeleteWithWarning.disabledReasons.last?.message;

      openModal({
        content: (
          <ConfirmationModal
            title="Warning"
            confirmationMessage={`${warning} Are you sure you want to delete this task?`}
            label="Yes, Delete"
            onConfirmAction={doDelete}
          />
        ),
      });
    },
    [closeRightPane, openModal, setDraftTaskChanges, triggerNotice],
  );

  return (
    <div css={Css.df.jcsb.aic.$}>
      {/* TODO: Wrapping `CommentCountBubble` in a tooltip prevents it from working in any way. Need to find out why our
          internal beam components do not have this issue. Bonus points if we can apply that fix to FullCalendar events.
       */}
      {taskHasPendingTpar ? (
        <IconButton
          tooltip="Pending Trade Partner Availability Request"
          onClick={() => onTaskDetailLink(task)}
          icon="commentFilled"
          inc={2.2}
          color={Palette.Gray900}
        />
      ) : (
        <CommentCountBubble
          // Comment bubble is ancient and doesn't increment the same way as our standard icons so we add some padding
          xss={Css.pxPx(5).$}
          streams={streams}
          // Note: we don't really care about scrolling comments into view since their at the top already
          onClick={() => onTaskDetailLink(task)}
          size={2.3}
        />
      )}
      {isTradeCommsEnabled && (
        <Tooltip
          disabled={!hasDraftChanges}
          title={hasDraftChanges && "Save schedule changes first before communicating with trades"}
        >
          <IconButton
            icon="email"
            onClick={() =>
              openModal({
                content: <SendTradePartnerAvailabilityRequestEmailModal planTasks={[task]} />,
                size: "xxl",
              })
            }
            disabled={hasDraftChanges || !task.tradePartner || task.status.code === TaskStatus.Complete}
            inc={2.3}
          />
        </Tooltip>
      )}
      <ButtonMenu
        data-testid="draftTaskActions"
        trigger={{ icon: "verticalDots", inc: 2.3 }}
        items={[
          {
            label: "Scheduling Settings",
            onClick: () => {
              openModal({
                content: (
                  <ScheduleDraftModePlanTaskSetDaysModal planTask={task} setDraftTaskChanges={setDraftTaskChanges} />
                ),
              });
            },
          },
          {
            label: "Delete",
            disabled: disableBasedOnPotentialOperation(task.canDelete),
            onClick: () => onTaskDelete(task),
          },
          {
            label: "Add Delay Flag",
            onClick: () =>
              openModal({
                content: (
                  <ScheduleDraftModeDelayFlagModal
                    planTask={task}
                    getFormState={getFormState}
                    setUserAddedScheduleFlags={setUserAddedDelayFlags}
                  />
                ),
              }),
            disabled: task.status.code === TaskStatus.Complete && "Task is marked as complete",
          },
        ]}
      />
    </div>
  );
}

function createRows(
  tasks: ScheduleDraftMode_PlanTaskFragment[],
  milestones: ScheduleDraftMode_PlanMilestoneFragment[],
  isLookaheadView: boolean,
  groupBy: TaskGroupBy,
  allTasks: ScheduleDraftMode_PlanTaskFragment[],
): GridDataRow<DynamicSchedulesRow>[] {
  switch (groupBy) {
    case TaskGroupBy.Stage:
      return createStageRows(sortTasks(tasks), allTasks);
    case TaskGroupBy.None:
      return createTaskAndMilestoneRows(tasks, milestones, isLookaheadView);
    case TaskGroupBy.CostCode:
      return createCostCodeRows(sortTasks(tasks));
    default:
      assertNever(groupBy);
  }
}

export type FormInput = Pick<
  DraftPlanTaskInput,
  "id" | "knownDurationInDays" | "status" | "customWorkableDaysOfWeek" | "tradePartnerStatus" | "tradePartnerId"
> & {
  isManuallyScheduled: boolean;
  earliestStartDate: Maybe<Date>;
  endDate: Maybe<Date>;
  reasonId: InputMaybe<string>;
  category: InputMaybe<string>;
};

export type GetFormState = (row: ScheduleDraftMode_PlanTaskFragment) => ObjectState<FormInput>;
type InputFieldProps = {
  getFormState: GetFormState;
  task: ScheduleDraftMode_PlanTaskFragment;
  loading: boolean;
  enumDetails?: DraftModeEnumDetails_EnumDetailsFragment;
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
};

export const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  isManuallyScheduled: { type: "value" },
  earliestStartDate: { type: "value" },
  knownDurationInDays: {
    type: "value",
    rules: [({ value }) => (value && value > 0 ? undefined : "Task duration must be greater than 0")],
  },
  endDate: { type: "value" },
  reasonId: { type: "value" },
  tradePartnerStatus: { type: "value" },
  status: { type: "value" },
  customWorkableDaysOfWeek: { type: "value", strictOrder: false },
  tradePartnerId: { type: "value" },
  category: { type: "value" },
};

function IconCell({ task, loading }: Pick<InputFieldProps, "task" | "loading">) {
  const { openModal } = useModal();
  const userAddedDelayFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const removeUserAddedScheduleFlags = useDraftScheduleStore((state) => state.removeUserAddedScheduleFlags);

  if (loading) return <Loader size="xs" />;

  const hasUserAddedDelayFlags = userAddedDelayFlags.some((flag) => flag.taskId === task.id);

  if (!hasUserAddedDelayFlags)
    return (
      task.isCriticalPath && (
        <Tooltip title="Critical Path Task">
          <div css={Css.pbPx(2).$}>
            <Icon icon="criticalPath" color={Palette.Gray900} inc={2.5} />
          </div>
        </Tooltip>
      )
    );

  return (
    <IconButton
      tooltip={task.isCriticalPath && "Critical Path Task has delay flag(s)"}
      icon={task.isCriticalPath ? "criticalPath" : "flag"}
      color={Palette.Red700}
      onClick={() =>
        openModal({
          content: (
            <ScheduleDraftModeDeleteFlagModal
              removeUserAddedScheduleFlags={removeUserAddedScheduleFlags}
              userAddedDelayFlags={userAddedDelayFlags.filter((flag) => flag.taskId === task.id)}
            />
          ),
        })
      }
    />
  );
}

type StartDateInputProps = InputFieldProps & {
  tasks: ScheduleDraftMode_PlanTaskFragment[];
  // We have to pass this in because the change can trigger a modal that also uses StartDateInput and relies on the store
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void;
};

/* Note: It is known that this component triggers a react warning for a bad setState action. This is a TODO FIXME but it does not cause issues on the page */
function StartDateInput({
  getFormState,
  task,
  loading,
  schedulingExclusionDates,
  setDraftTaskChanges,
}: StartDateInputProps) {
  const [isHovered, setIsHovered] = useState(false);
  const onPointerEnter = useCallback(() => setIsHovered(true), []);
  const onPointerLeave = useCallback(() => setIsHovered(false), []);

  const disabledDays = useMemo(() => {
    const holidayExcludedDates = schedulingExclusionDates.map((exclusionDate) => exclusionDate.date);
    return [{ dayOfWeek: getCustomDisabledWorkingDays(task.customWorkableDays) }, ...holidayExcludedDates];
  }, [schedulingExclusionDates, task.customWorkableDays]);
  const os = useMemo(() => getFormState(task), [getFormState, task]);

  // Use the value from formState within the component to ensure the UI updates immediately on click
  const isManuallyScheduled = useComputed(() => os.isManuallyScheduled.value, [os.isManuallyScheduled]);

  const { triggerNotice } = useSnackbar();
  const tids = useTestIds({}, "startDateInput");

  const { iconTooltip, showIcon, iconColor } = useMemo(() => {
    return {
      iconTooltip: isManuallyScheduled
        ? "Unpinning the task date will allow it to auto-move when other tasks are moved. If predecessors have already moved, the task may move immediately when unpinned."
        : "Pin Current Start Date",
      showIcon: task.status.code !== TaskStatus.Complete && (isManuallyScheduled || isHovered),
      iconColor: isManuallyScheduled ? Palette.Blue600 : Palette.Gray700,
    };
  }, [isManuallyScheduled, isHovered, task.status.code]);

  const onDateChange = useCallback(
    (newValue?: Date) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value
      if (isSameDay(newValue, task.startDate)) return;

      // Update the form state so we show the change to the user immediately
      os.earliestStartDate.set(newValue);
      os.tradePartnerStatus.set(TradePartnerTaskStatus.NotSent);

      setDraftTaskChanges([
        {
          id: task.id,
          earliestStartDate: new DateOnly(newValue),
          // Current ask is to auto "pin" the task whenever a date change occurs, we may in the future bring back the more
          // nuanced behavior that was originally spec'd that allows a user to set "earliestStart" without fixing that date
          isManuallyScheduled: true,
          // if the start date has changed, we should automatically reset the trade partner status to "not sent"
          tradePartnerStatus: TradePartnerTaskStatus.NotSent,
        },
      ]);

      // Note: We will likely revisit this feature soon, so for now we are only disabling it

      // const followingTwoWeekPinnedTasks = (tasks ?? []).filter(
      //   (t) =>
      //     isWithinInterval(t.startDate.date, { start: t.startDate.date, end: addWeeks(t.startDate.date, 2) }) &&
      //     t.isManuallyScheduled &&
      //     t.status.code !== TaskStatus.Complete,
      // );
      //
      // if (followingTwoWeekPinnedTasks.nonEmpty) {
      //   openModal({
      //     size: "lg",
      //     content: (
      //       <UnpinDependentTasksModal
      //         getFormState={getFormState}
      //         task={task}
      //         loading={loading}
      //         holidayExcludedDates={holidayExcludedDates}
      //         // TODO: Decouple this modal from the input so we dont have to pass setDraftTaskChanges all the way down
      //         //   from `createColumns()`
      //         setDraftTaskChanges={setDraftTaskChanges}
      //         followingPinnedTasks={followingTwoWeekPinnedTasks}
      //       />
      //     ),
      //   });
      // }
    },
    [task, os.earliestStartDate, setDraftTaskChanges],
  );

  /** Toggle between "pinning" the current start date and unsetting an existing pinned date */
  const onPinClick = useCallback(() => {
    os.isManuallyScheduled.set(!task.isManuallyScheduled);
    setDraftTaskChanges([
      {
        id: task.id,
        earliestStartDate: task.isManuallyScheduled ? null : task.startDate,
        isManuallyScheduled: !task.isManuallyScheduled,
      },
    ]);
    triggerNotice({
      message: `Task '${task.name}' has been ${task.isManuallyScheduled ? "unpinned" : "pinned"} `,
    });
  }, [task, setDraftTaskChanges, triggerNotice, os]);

  return (
    <div css={Css.w100.relative.$} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave} {...tids}>
      <BoundDateField
        field={os.earliestStartDate}
        onBlur={noop}
        hideCalendarIcon
        disabledDays={disabledDays}
        onChange={onDateChange}
        borderless
        readOnly={os.status.value === TaskStatus.Complete}
        disabled={loading}
      />
      <div css={Css.absolute.df.aic.jcc.right0.top0.h100.$}>
        {showIcon && (
          <IconButton
            icon="pin"
            onClick={onPinClick}
            color={iconColor}
            tooltip={iconTooltip}
            disabled={loading}
            {...tids.pinButton}
          />
        )}
      </div>
    </div>
  );
}

// type UnpinDependentTasksModalProps = Omit<StartDateInputProps, "tasks"> & {
//   followingPinnedTasks: ScheduleDraftMode_PlanTaskFragment[];
// };
//
// function UnpinDependentTasksModal({ task, followingPinnedTasks, ...otherProps }: UnpinDependentTasksModalProps) {
//   return (
//     <PresentationProvider fieldProps={{ labelStyle: "hidden" }}>
//       <ConfirmationModal
//         title="Unpin Dependent Tasks?"
//         confirmationMessage={
//           <div>
//             <span>
//               These are all of the pinned tasks in the next 2 weeks following this task <b>{task.name}</b>. Do you want
//               to unpin those tasks, or cancel and keep those tasks on their pinned days?
//             </span>
//             <div css={Css.dg.gap2.gtc("auto auto").mt3.$}>
//               <span css={Css.smSb.$}>Dependent Tasks</span>
//               <span css={Css.smSb.$}>Start Date</span>
//               {followingPinnedTasks.map((task) => (
//                 <Fragment key={task.id}>
//                   <span css={Css.smMd.$}>{task.name}</span>
//                   <StartDateInput task={task} {...otherProps} tasks={[]} />
//                 </Fragment>
//               ))}
//             </div>
//           </div>
//         }
//         label="Done"
//         onConfirmAction={() => {}}
//       />
//     </PresentationProvider>
//   );
// }

function DurationInput({ getFormState, task, loading }: Omit<InputFieldProps, "schedulingExclusionDates">) {
  const { id, knownDurationInDays } = task;
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setValidationErrors = useDraftScheduleStore((state) => state.setValidationErrors);

  useEffect(() => {
    setValidationErrors({ [task.id]: os.errors });
  }, [task.id, os.errors, setValidationErrors]);

  const debouncedOnChange = useMemo(
    () =>
      debounce((newValue: number) => {
        setDraftTaskChanges([{ id, knownDurationInDays: newValue }]);
      }, 500),
    [id, setDraftTaskChanges],
  );

  const onChange = useCallback(
    (newValue?: number) => {
      if (!isDefined(newValue) || newValue < 1) return;
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === knownDurationInDays) return;

      // Keep the form state updated immediately
      os.knownDurationInDays.set(newValue);
      // Debounce the change so multi-digit duration entries don't trigger multiple updates
      debouncedOnChange(newValue);
    },
    [knownDurationInDays, os.knownDurationInDays, debouncedOnChange],
  );

  return (
    <BoundNumberField
      xss={Css.tal.$}
      field={os.knownDurationInDays}
      onChange={onChange}
      type="days"
      readOnly={os.status.value === TaskStatus.Complete}
      disabled={loading}
    />
  );
}

/* Note: It is known that this component triggers a react warning for a bad setState action. This is a TODO FIXME but it does not cause issues on the page */
function EndDateInput({ getFormState, task, loading, schedulingExclusionDates }: InputFieldProps) {
  const disabledDays = useMemo(() => {
    const holidayExcludedDates = schedulingExclusionDates.map((exclusionDate) => exclusionDate.date);

    return [
      { dayOfWeek: getCustomDisabledWorkingDays(task.customWorkableDays) },
      { before: task.startDate.date },
      ...holidayExcludedDates,
    ];
  }, [schedulingExclusionDates, task.customWorkableDays, task.startDate.date]);

  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);

  const onDateChange = useCallback(
    (newValue?: Date) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value
      if (isSameDay(newValue, task.endDate)) return;
      // If something goes wrong and the datepicker doesn't disable an invalid selection, we can log the issue, but not fail for the user
      if (isBefore(newValue, task.startDate)) {
        console.error(`Task ${task.id} end date cannot be before start date`);
        return;
      }

      const businessDaysOptions = getBusinessDaysOptions(task.customWorkableDays, schedulingExclusionDates);
      const newKnownDuration = differenceInBusinessDays(newValue, task.startDate, businessDaysOptions) + 1;

      // Update the form state so we show the change to the user immediately
      os.endDate.set(newValue);

      setDraftTaskChanges([{ id: task.id, knownDurationInDays: newKnownDuration }]);
    },
    [setDraftTaskChanges, task.id, task.endDate, os, task.customWorkableDays, schedulingExclusionDates, task.startDate],
  );

  return (
    <BoundDateField
      field={os.endDate}
      onBlur={noop}
      hideCalendarIcon
      disabledDays={disabledDays}
      onChange={onDateChange}
      borderless
      readOnly={os.status.value === TaskStatus.Complete}
      disabled={loading}
    />
  );
}

function TaskStatusInput({
  getFormState,
  task,
  loading,
  enumDetails,
}: Omit<InputFieldProps, "schedulingExclusionDates">) {
  const { id, status } = task;
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const initialTaskValue = useDraftScheduleStore((state) => state.getInitialTaskValue(id));
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setValidationErrors = useDraftScheduleStore((state) => state.setValidationErrors);

  useEffect(() => {
    setValidationErrors({ [task.id]: os.errors });
  }, [task.id, os.errors, setValidationErrors]);

  const onSelect = useCallback(
    (newValue?: TaskStatus) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === status.code) return;

      // Keep the form state updated immediately
      os.status.set(newValue);

      const hasTradeNotCompleted =
        task.tradePartner && os.tradePartnerStatus.value !== TradePartnerTaskStatus.CompletedJob;

      // If we're marking it as complete also set the tradePartnerStatus to Complete if applicable
      if (hasTradeNotCompleted && newValue === TaskStatus.Complete) {
        os.tradePartnerStatus.set(TradePartnerTaskStatus.CompletedJob);
        setDraftTaskChanges([
          { id, status: newValue },
          { id, tradePartnerStatus: TradePartnerTaskStatus.CompletedJob },
        ]);
        // If we're marking it as in progress and the tradePartner is the same as it was on load, also set the tradePartnerStatus to Confirmed
      } else if (
        hasTradeNotCompleted &&
        newValue === TaskStatus.InProgress &&
        task.tradePartner?.id === initialTaskValue?.tradePartner?.id
      ) {
        os.tradePartnerStatus.set(TradePartnerTaskStatus.Confirmed);
        setDraftTaskChanges([
          { id, status: newValue },
          { id, tradePartnerStatus: TradePartnerTaskStatus.Confirmed },
        ]);
      } else {
        setDraftTaskChanges([{ id, status: newValue }]);
      }
    },
    [
      status.code,
      os.status,
      os.tradePartnerStatus,
      task.tradePartner,
      initialTaskValue?.tradePartner,
      setDraftTaskChanges,
      id,
    ],
  );

  return (
    <TaskStatusSelect
      hideLabel
      options={enumDetails?.taskStatus ?? []}
      canComplete={task.canComplete}
      canStart={task.canStart}
      statusField={os.status}
      onSelect={onSelect}
      disabled={loading}
    />
  );
}

function mapToForm(task: ScheduleDraftMode_PlanTaskFragment): FormInput {
  const {
    id,
    tradePartnerStatus,
    isManuallyScheduled,
    startDate,
    endDate,
    knownDurationInDays,
    status,
    customWorkableDays,
    tradePartner,
    committedTradePartners,
  } = task;
  return {
    id,
    isManuallyScheduled: isManuallyScheduled ?? false,
    earliestStartDate: new DateOnly(startDate),
    endDate: new DateOnly(endDate),
    knownDurationInDays: knownDurationInDays ?? task.durationInDays,
    reasonId: null,
    tradePartnerStatus: mapTradePartnerTaskStatusToReducedStatus(tradePartnerStatus.code),
    status: status.code,
    customWorkableDaysOfWeek: customWorkableDays,
    tradePartnerId: committedTradePartners.first?.id ?? tradePartner?.id,
    category: null,
  };
}

// Tasks are sorted by start date, however we may have multiple tasks starting on the same date
// So we then introduce a secondary sort by task name so the ordering is stable
function sortTasks(tasks: ScheduleDraftMode_PlanTaskFragment[]) {
  return [...tasks].sort((a, b) => {
    const compareKey = isSameDay(a.startDate, b.startDate) ? "name" : "startDate";
    const compareFunc = newComparator<ScheduleDraftMode_PlanTaskFragment>((t) => t[compareKey]);

    return compareFunc(a, b);
  });
}

/* NOTE: `planTask.customWorkableDays` resolves to default array of Mon-Fri when stored value in db is null */
function getCustomDisabledWorkingDays(planTaskCustomWorkingDays: DayOfWeek[]) {
  return DayOfWeek.toValues()
    .filter((day) => !planTaskCustomWorkingDays.includes(day))
    .map((day) => DayOfWeekDayPicker[day]);
}

// helper function to filter the tasks manually for draft schedule mode
// Assumes that `tasks` have NOT been filtered yet since we're pulling milestones from `task.allowsPlanMilestones`
export function filterPlanTasksAndMilestones(
  tasks: ScheduleDraftMode_PlanTaskFragment[],
  filter: CustomDynamicSchedulesFilter,
  search: string | null | undefined,
) {
  const mappedFilter = mapToFilter(filter);

  // creating a "default" list of milestones to show on the milestone view page
  const defaultMilestones = tasks
    .flatMap((task) => task.allowsPlanMilestones)
    .unique()
    .filter((milestone) => milestone.name.toLowerCase().includes(search?.toLowerCase() ?? ""));

  const filteredMilestones = !filter.includeMilestones
    ? []
    : tasks
        .flatMap((task) => task.allowsPlanMilestones)
        .unique()
        .filter(
          (milestone) =>
            milestone.name.toLowerCase().includes(search?.toLowerCase() ?? "") &&
            filterByStartDateRange(milestone, mappedFilter) &&
            filterByEndDateRange(milestone, mappedFilter) &&
            filterByMilestoneCompletion(milestone, mappedFilter),
        );

  const filteredTasks = tasks.filter(
    (task) =>
      (task.name.toLowerCase().includes(search?.toLowerCase() ?? "") ||
        task.tradePartner?.name.toLowerCase().includes(search?.toLowerCase() ?? "")) &&
      filterByStartDateRange(task, mappedFilter) &&
      filterByEndDateRange(task, mappedFilter) &&
      filterByTradePartnerStatus(task, mappedFilter) &&
      filterByTaskStatus(task, mappedFilter),
  );

  return { filteredTasks, filteredMilestones, defaultMilestones };
}

// Filter by start date range
function filterByStartDateRange(
  { startDate, estStartDate }: { startDate?: Maybe<DateOnly>; estStartDate?: Maybe<DateOnly> },
  { startDateRange }: PlanTasksFilter,
): boolean {
  // skip blank filter values
  if (!startDateRange || !startDateRange.value2 || !startDateRange.value) return true;

  if (startDate && !estStartDate) return startDate >= startDateRange.value && startDate <= startDateRange.value2;
  if (!startDate && estStartDate) return estStartDate >= startDateRange.value && estStartDate <= startDateRange.value2;

  return false;
}

// Filter by end date range
function filterByEndDateRange(
  { endDate, estEndDate }: { endDate?: Maybe<DateOnly>; estEndDate?: Maybe<DateOnly> },
  { endDateRange }: PlanTasksFilter,
): boolean {
  // skip blank filter values
  if (!endDateRange || !endDateRange.value2 || !endDateRange.value) return true;

  if (endDate && !estEndDate) return endDate >= endDateRange.value && endDate <= endDateRange.value2;
  if (!endDate && estEndDate) return estEndDate >= endDateRange.value && estEndDate <= endDateRange.value2;

  return false;
}

// Filter by trade partner status
function filterByTradePartnerStatus(
  task: ScheduleDraftMode_PlanTaskFragment,
  { tradePartnerStatus }: PlanTasksFilter,
): boolean {
  if (!tradePartnerStatus) return true;
  return tradePartnerStatus.includes(task.tradePartnerStatus.code);
}

// Filter by task status
function filterByTaskStatus(task: ScheduleDraftMode_PlanTaskFragment, { status }: PlanTasksFilter): boolean {
  if (!status) return true;
  return status.includes(task.status.code);
}

// Filter by milestone completion
function filterByMilestoneCompletion(
  milestone: ScheduleDraftMode_PlanMilestoneFragment,
  { status }: PlanTasksFilter,
): boolean {
  // UI is centered around the task status
  if (!status || status?.includes(TaskStatus.Complete)) return true;

  return milestone.progress !== 100;
}

function ClickableCell({ onClick, children }: { onClick: () => void; children?: ReactNode }) {
  return (
    <div css={Css.cursorPointer.df.aic.w100.h100.$} onClick={onClick}>
      {children}
    </div>
  );
}

function TradeStatusCell({
  task,
  getFormState,
  enumDetails,
}: Omit<InputFieldProps, "loading" | "schedulingExclusionDates">) {
  const uniqueCommittedTrades = task.committedTradePartners.uniqueBy((tp) => tp.id);

  return (
    <>
      <DraftTradeStatusSelectField task={task} enumDetails={enumDetails} getFormState={getFormState} />
      <div css={Css.df.gap1.aic.jcc.$}>
        {uniqueCommittedTrades.length > 1 && (
          <Icon
            icon="error"
            color={Palette.Red600}
            tooltip="Only 1 trade partner per task is recommended. The trade partner schedule status only applies to the first trade partner."
            inc={2.3}
          />
        )}
      </div>
    </>
  );
}

function DraftTradeStatusSelectField({
  task,
  getFormState,
  enumDetails,
}: Omit<InputFieldProps, "loading" | "schedulingExclusionDates">) {
  const { id, tradePartnerStatus } = task;
  const os = getFormState(task);

  const reducedTradePartnerStatusesDetail = useMemo(
    () =>
      enumDetails?.tradePartnerTaskStatus
        .filter(
          // Keep only the status that match ReducedTradePartnerTaskStatus type
          (tps) => ReducedTradePartnerTaskStatuses.includes(tps.code),
        )
        .sortBy((tps) => ReducedTradePartnerTaskStatusOrder[mapTradePartnerTaskStatusToReducedStatus(tps.code)]),
    [enumDetails?.tradePartnerTaskStatus],
  );

  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setValidationErrors = useDraftScheduleStore((state) => state.setValidationErrors);

  useEffect(() => {
    setValidationErrors({ [task.id]: os.errors });
  }, [task.id, os.errors, setValidationErrors]);

  const onSelect = useCallback(
    (newValue?: TradePartnerTaskStatus) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === tradePartnerStatus.code) return;

      // Keep the form state updated immediately
      os.tradePartnerStatus.set(newValue);
      setDraftTaskChanges([{ id, tradePartnerStatus: newValue }]);
    },
    [tradePartnerStatus.code, os.tradePartnerStatus, setDraftTaskChanges, id],
  );

  // Don't show the trade status option if there is no trade
  if (!task.tradePartner) return null;

  const requests = task.tradePartnerAvailabilityRequests;
  /**
   * This is a hack to determine if the task was confirmed by the trade partner via the trade portal.
   *
   * This works because our internal fronteend doesn't have a way to approve trade partner requests right now,
   * but this might change in the future.
   *
   * TODO: SC-61084 - Add a `confirmed_by` field to the `TradePartnerAvailabilityRequest` type
   */
  const wasConfirmedByTradePartner = requests?.some(
    (r) => r.status.code === TradePartnerAvailabilityRequestStatus.Confirmed && r.tradePartnerConfirmationDate,
  );
  const isConfirmed = tradePartnerStatus.code === TradePartnerTaskStatus.Confirmed;

  return maybeTooltip({
    title: isConfirmed
      ? wasConfirmedByTradePartner
        ? "Confirmed by Trade via Trade Portal"
        : "Confirmed manually"
      : "",
    placement: "top",
    children: (
      <BoundSelectField
        compact
        getOptionMenuLabel={(tps) => (
          <div css={Css.df.aic.$}>
            <StatusIndicator status={tps.code} />
            <span css={Css.ml1.$}>{tps.name}</span>
          </div>
        )}
        inputStylePalette={getTradePartnerTaskStyle(os.tradePartnerStatus.value)}
        fieldDecoration={(tps) => <StatusIndicator status={tps.code} />}
        getOptionValue={(o) => o.code}
        getOptionLabel={(o) => o.name}
        options={reducedTradePartnerStatusesDetail ?? []}
        onSelect={onSelect}
        field={os.tradePartnerStatus}
      />
    ),
  });
}

function TradePartnerCell({
  task,
  getFormState,
}: {
  task: ScheduleDraftMode_PlanTaskFragment;
  getFormState: GetFormState;
}) {
  const { id, tradePartner } = task;
  const os = getFormState(task);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);

  const onSelect = useCallback(
    (newValue?: string) => {
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === tradePartner?.id) return;

      // Keep the form state updated immediately
      // Note: setting to null so unsetting doesn't get swallowed up by GQL/Joist
      os.tradePartnerId.set(newValue ?? null);
      setDraftTaskChanges([{ id, tradePartnerId: newValue ?? null }]);
    },
    [tradePartner?.id, os.tradePartnerId, setDraftTaskChanges, id],
  );

  const firstCommitted = task.committedTradePartners.uniqueBy((tp) => tp.id).first;
  return task.canSetTraderPartner.allowed ? (
    <BoundTradePartnerSelectField
      allowUnassigned
      field={os.tradePartnerId}
      filter={{
        markets: [task.schedule.parent.market.id],
      }}
      onSelect={onSelect}
      readOnly={os.status.value === TaskStatus.Complete}
    />
  ) : (
    firstCommitted && <span data-testid="tradePartnerName">{firstCommitted.name}</span>
  );
}

function getTradePartnerTaskStyle(code?: TradePartnerTaskStatus | null): InputStylePalette | undefined {
  switch (code) {
    case TradePartnerTaskStatus.CompletedJob:
      return "info";
    case TradePartnerTaskStatus.Confirmed:
      return "success";
    case TradePartnerTaskStatus.NeedsConfirmation:
    case TradePartnerTaskStatus.NeedsReconfirmation:
      return "caution";
    case TradePartnerTaskStatus.Unavailable:
    case TradePartnerTaskStatus.NotSent:
      return "warning";
    default:
      return undefined;
  }
}

type TextTruncatedTooltipProps = {
  text: string;
  placement?: Placement;
  xss?: TextFieldXss;
};

/* Shows tooltip on hover when text is truncated */
function TextTruncatedTooltip({ text, placement = "top", xss = {} }: TextTruncatedTooltipProps) {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const { current } = textRef;
    if (current) {
      setIsTruncated(current.scrollWidth > current.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip title={text} placement={placement} disabled={!isTruncated}>
      <span ref={textRef} css={{ ...Css.truncate.$, ...xss }}>
        {text}
      </span>
    </Tooltip>
  );
}
