import { BoundSelectField, BoundSelectFieldProps } from "@homebound/beam";
import { UnitsOfMeasureDataFragment, useUnitsOfMeasureQuery } from "src/generated/graphql-types";

type UnitsOfMeasureFieldProps = Omit<
  BoundSelectFieldProps<UnitsOfMeasureDataFragment, string>,
  "options" | "multiselect" | "getOptionLabel" | "getOptionValue"
> & {
  getOptionLabel?: (o: UnitsOfMeasureDataFragment) => string;
  getOptionValue?: (o: UnitsOfMeasureDataFragment) => string;
  optionFilter?: (options: UnitsOfMeasureDataFragment[]) => UnitsOfMeasureDataFragment[];
};
export function UnitsOfMeasureBoundSelectField(props: UnitsOfMeasureFieldProps) {
  const {
    placeholder = "Select a Units Of Measure",
    getOptionLabel = (o) => o.name,
    getOptionValue = (o) => o.id,
    optionFilter,
    ...otherProps
  } = props;
  const { data, loading } = useUnitsOfMeasureQuery({ fetchPolicy: "cache-and-network" });
  const options = data?.unitsOfMeasure ?? [];
  return (
    <BoundSelectField
      {...otherProps}
      placeholder={loading ? "Loading..." : placeholder}
      options={optionFilter ? optionFilter(options) : options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
    />
  );
}
