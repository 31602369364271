import {
  Chip,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  selectColumn,
  SelectToggle,
  simpleHeader,
  useGridTableApi,
} from "@homebound/beam";
import { useEffect } from "react";
import { baseDownloadUrl } from "src/context";
import {
  PublishBidPackagePage_ActiveRequestFragment,
  PublishBidPackagePage_BidPackageFragment,
} from "src/generated/graphql-types";
import { useReaction } from "src/hooks";
import { isDefined } from "src/utils";
import { openInSelf } from "src/utils/window";
import { PublishBidPackageStepProps } from "../PublishBidPackagePage";

export function SelectTradesStep({ formState, bidPackage }: PublishBidPackageStepProps) {
  const tableApi = useGridTableApi<Row>();
  // Initialize the table with the rows that have notify set to true
  useEffect(() => {
    formState.versionRequests.rows.forEach((r) => {
      if (r.notify.value) {
        tableApi.selectRow(r.bidPackageRequestId.value!);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When the user selects a row, update the form state
  useReaction(
    () => tableApi.getSelectedRows("data").map((r) => r.data.id),
    (selectedTradePartnerIds) => {
      const rows = formState.versionRequests.rows;
      rows.forEach((r) => {
        r.notify.set(selectedTradePartnerIds.includes(r.bidPackageRequestId.value!));
      });
    },
    [tableApi],
  );

  return (
    <div css={Css.maxw50.mxa.$}>
      <div css={Css.tac.mb5.$}>
        <div css={Css.xl2Bd.mt4.mb2.$}>Select Trades to Send Email</div>
        <div>
          This update and version notes will be sent as an email to selected trades. The bid will revert to bidding
          open. Please ensure the trade contacts are up to date.
        </div>
      </div>
      <div css={Css.bgWhite.br8.p4.bshBasic.$}>
        <div css={Css.lgBd.mb3.$}>Select Trades</div>
        <GridTable
          columns={createColumns(bidPackage)}
          rows={createRows(bidPackage.activeRequests)}
          api={tableApi}
          style={{ bordered: true, allWhite: true }}
          stickyHeader
        />
      </div>
    </div>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = {
  kind: "data";
  data: { name: string; id: string; isNew: boolean };
};
type Row = HeaderRow | DataRow;

function createRows(activeRequests: PublishBidPackagePage_ActiveRequestFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...activeRequests.map((ar) => ({
      id: ar.id,
      kind: "data" as const,
      data: {
        name: ar.tradePartner.name,
        id: ar.id,
        isNew: !isDefined(ar.lastVersion),
      },
    })),
  ];
}

function createColumns(bidPackage: PublishBidPackagePage_BidPackageFragment): GridColumn<Row>[] {
  const { isUnitBased, scopeHasChanged } = bidPackage;
  return [
    selectColumn<Row>({
      header: emptyCell,
      data: (row) => <SelectToggle id={row.id} disabled={scopeHasChanged || row.isNew} />,
    }),
    column<Row>({
      header: "Trades",
      data: ({ name, isNew }) => (
        <>
          <div css={Css.mr1.$}>{name}</div>
          {isNew && <Chip type="caution" text="NEW" />}
        </>
      ),
      w: 1.5,
    }),
    column<Row>({
      header: "Bid Template Link",
      data: ({ id }) => <IconButton icon="linkExternal" inc={2} onClick={() => onDownloadTemplate(isUnitBased, id)} />,
      w: 1,
    }),
  ];
}

export const onDownloadTemplate = (isUnitBased: boolean, requestid: string) => {
  const params = new URLSearchParams({
    type: isUnitBased ? "unitBasedBidPackage" : "planBasedBidPackage",
    bidPackageRequestId: requestid,
  });
  openInSelf(`${baseDownloadUrl()}/xlsx?${params.toString()}`);
};
