import { Button, useSnackbar } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";

import { useHistory } from "react-router-dom";
import { createDevelopmentProductOfferingUrl, createProjectProductOfferingUrl } from "src/RouteUrls";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  ReadyPlanStatus,
  SaveProductOfferingInput,
  useReviewStep_SaveProductOfferingMutation,
} from "src/generated/graphql-types";

type ProductOfferingStepActionsProps = {
  disabled?: boolean;
  formState?: ObjectState<SaveProductOfferingInput>;
};

export function ProductOfferingStepActions({ disabled, formState }: ProductOfferingStepActionsProps) {
  const { currentStep, steps, nextStep, isLastStep } = useStepperContext();
  const history = useHistory();
  const { triggerNotice } = useSnackbar();

  const currentStepIndex = steps.findIndex((step) => step.value === currentStep.value);
  const nextStepDisabled = steps[currentStepIndex + 1]?.disabled;
  const [saveProductOffering] = useReviewStep_SaveProductOfferingMutation();

  return (
    <StepActions>
      <>
        {!isLastStep ? (
          <Button label="Continue" size="lg" onClick={nextStep} disabled={nextStepDisabled} />
        ) : (
          <Button
            label="Create Draft"
            size="lg"
            onClick={async () => {
              if (!formState) return;
              const res = await saveProductOffering({
                variables: { input: { ...formState.value, status: ReadyPlanStatus.Draft } },
              });
              if (res.data?.saveProductOffering.productOffering) {
                const productOfferingId = res.data?.saveProductOffering.productOffering.id;
                const versionId = res.data?.saveProductOffering.productOffering.version.id;
                const developmentId = formState.value.developmentId;
                const projectId = formState.value.projectId;
                triggerNotice({ message: "Offering successfully drafted! Procurement will review and start bidding" });
                if (developmentId) {
                  history.push(createDevelopmentProductOfferingUrl(developmentId, productOfferingId, versionId));
                } else if (projectId) {
                  history.push(createProjectProductOfferingUrl(projectId, productOfferingId, versionId));
                }
              }
            }}
            disabled={disabled}
          />
        )}
      </>
    </StepActions>
  );
}
