import { Button, Css, useTestIds } from "@homebound/beam";
import { useParams } from "react-router-dom";
import { BidContractOverview } from "src/components/bid-contracts/BidContractOverview";
import { CommentFeed2 } from "src/components/comments2/CommentFeed";
import { useProjectBidContractOverviewPageQuery } from "src/generated/graphql-types";
import { createTradePartnerUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { DateOnly, formatWithYear } from "src/utils/dates";

export function ProjectBidContractOverviewPage() {
  const { bidContractRevisionId } = useParams<{ bidContractRevisionId: string }>();
  const query = useProjectBidContractOverviewPageQuery({ variables: { bidContractRevisionId } });
  const testIds = useTestIds({}, "pbcOverview");
  return queryResult(query, ({ bidContractRevision }) => {
    const tradePartner = bidContractRevision.bidContract.tradePartner;
    return (
      <div css={Css.df.aifs.$}>
        <BidContractOverview selectedRevision={bidContractRevision} />
        {tradePartner && (
          <div css={Css.wPx(440).ba.bcGray300.br16.pxPx(17).ptPx(16).mr2.$}>
            <div css={Css.baseMd.df.fdr.mbPx(11).$} {...testIds.tradePartnerOverviewHeader}>
              <div css={Css.w75.$}>{tradePartner.name}</div>
              <div css={Css.mtPx(-4).mrPx(-6).$}>
                <Button variant="tertiary" label="Manage Trade" onClick={createTradePartnerUrl(tradePartner.id)} />
              </div>
            </div>
            <div {...testIds.tradeSinceData} css={Css.my3.$}>
              <div css={Css.df.sm.gray700.$}>Trade since</div>
              <div css={Css.smMd.asfs.$}>{formatWithYear(tradePartner.createdAt as DateOnly)}</div>
            </div>
          </div>
        )}
        <div>
          <CommentFeed2 commentable={bidContractRevision.bidContract} />
        </div>
      </div>
    );
  });
}
