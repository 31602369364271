import {
  BoundCheckboxField,
  BoundNumberField,
  Button,
  ButtonMenu,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  ScrollableContent,
  selectColumn,
  simpleHeader,
  Switch,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { useMemo } from "react";
import { useParams } from "react-router";
import { BidItemsModal } from "src/components/bid-contracts/BidItemsModal";
import { linkHeader, priceCell } from "src/components/gridTableCells";
import { baseDownloadUrl, getStage } from "src/context";
import {
  BidContractRevisionUnitPricingQuery,
  BidContractUnitPricingPage_BidContractLineItemFragment,
  BidContractUnitPricingPage_PotentialUsagesFragment,
  SaveBidContractLineItemInput,
  useBidContractRevisionUnitPricingQuery,
  useDeleteBidContractLineItemsMutation,
  useSaveBidContractUnitPricingMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { PriceAdjustModal } from "src/routes/developments/components/PriceAdjustModal";
import { ImportPackagePricingModal } from "src/routes/developments/procurement/components/ImportPackagePricingModal";
import { ImportPricingModal } from "src/routes/developments/procurement/components/ImportPricingModal";
import { DevelopmentContractUploadType } from "src/routes/developments/procurement/enums";
import { TableActions } from "src/routes/layout/TableActions";
import { createBidItemDetailsUrl } from "src/RouteUrls";
import { formatList, pluralize, queryResult } from "src/utils";
import { openInSelf } from "src/utils/window";

export function BidContractUnitPricingPage() {
  const { bidContractRevisionId } = useParams<{ bidContractRevisionId: string }>();
  const [showPlanUsage, togglePlanUsage] = useToggle();
  const query = useBidContractRevisionUnitPricingQuery({
    variables: { bidContractRevisionId, skipPotentialUsages: !showPlanUsage },
    fetchPolicy: "cache-first",
  });
  return queryResult(query, ({ bidContractRevision }) => (
    <BidContractUnitPricingTable
      selectedRevision={bidContractRevision}
      showPlanUsage={showPlanUsage}
      togglePlanUsage={togglePlanUsage}
    />
  ));
}

type BidContractLineItemsProps = {
  selectedRevision: BidContractRevisionUnitPricingQuery["bidContractRevision"];
  showPlanUsage: boolean;
  togglePlanUsage: () => void;
};

function BidContractUnitPricingTable(props: BidContractLineItemsProps) {
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const { selectedRevision, showPlanUsage, togglePlanUsage } = props;
  const hasBidPackage = !!selectedRevision.bidPackageVersion;
  const tableApi = useGridTableApi<Row>();
  const [saveBidContractUnitPricing] = useSaveBidContractUnitPricingMutation();
  const [deleteBidContractLineItems] = useDeleteBidContractLineItemsMutation();

  const { getFormState } = useFormStates<
    SaveBidContractLineItemInput,
    BidContractUnitPricingPage_BidContractLineItemFragment
  >({
    config: formConfig,
    getId: (v) => v.id,
    autoSave: async (os) => {
      await saveBidContractUnitPricing({
        variables: { bidContractRevisionId: selectedRevision.id, lineItems: [os.changedValue] },
      });
      os.commitChanges();
    },
    readOnly: !selectedRevision.canEdit.allowed,
  });

  const lineItems = useMemo(() => selectedRevision.lineItems.filter((bcli) => bcli.ofBi), [selectedRevision.lineItems]);
  const rows = useMemo(() => createRows(lineItems, showPlanUsage), [lineItems, showPlanUsage]);
  const columns = createColumns(getFormState, hasBidPackage);

  const selectedLineItemIds = useComputed(() => tableApi.getSelectedRowIds("lineItem"), [tableApi]);
  const actionMenuItems = useMemo(() => {
    if (hasBidPackage) {
      return [
        {
          label: "Bulk Cost Change",
          disabled: !selectedRevision.canEdit.allowed || selectedLineItemIds.isEmpty,
          onClick: () =>
            openModal({
              content: (
                <PriceAdjustModal
                  bidContractRevisionId={selectedRevision.id}
                  bclis={tableApi.getSelectedRows("lineItem").map((r) => r.data)}
                />
              ),
            }),
        },
      ];
    }
    return [
      {
        label: "Bulk Cost Change",
        disabled: !selectedRevision.canEdit.allowed || selectedLineItemIds.isEmpty,
        onClick: () =>
          openModal({
            content: (
              <PriceAdjustModal
                bidContractRevisionId={selectedRevision.id}
                bclis={tableApi.getSelectedRows("lineItem").map((r) => r.data)}
              />
            ),
          }),
      },
      {
        label: "Remove",
        disabled: !selectedRevision.canEdit.allowed || selectedLineItemIds.isEmpty,
        onClick: async () =>
          openModal({
            content: (
              <ConfirmationModal
                onConfirmAction={async () => {
                  await deleteBidContractLineItems({
                    variables: { bidContractRevisionId: selectedRevision.id, lineItemIds: selectedLineItemIds },
                  });
                  triggerNotice({
                    message: `${pluralize(
                      selectedLineItemIds,
                      "Item has",
                      `${selectedLineItemIds.length} items have`,
                    )} been deleted`,
                    icon: "success",
                  });
                }}
                title="Delete Items"
                label="Delete Items"
                confirmationMessage={
                  <div>
                    <p css={Css.smMd.mb2.$}>
                      {selectedLineItemIds.length} {pluralize(selectedLineItemIds, "item")} selected
                    </p>
                    <p>
                      Are you sure you want to delete {pluralize(selectedLineItemIds, "this item", "these items")}? This
                      action cannot be undone.
                    </p>
                  </div>
                }
              />
            ),
          }),
      },
      {
        label: "Download CSV",
        disabled: !selectedLineItemIds.length,
        onClick: () =>
          openInSelf(
            `${baseDownloadUrl()}/csv?type=unitBasedBidLineItems&bcrId=${
              selectedRevision.id
            }&bcliIds=${selectedLineItemIds.join()}`,
          ),
      },
    ];
  }, [
    deleteBidContractLineItems,
    openModal,
    selectedLineItemIds,
    selectedRevision.canEdit.allowed,
    selectedRevision.id,
    tableApi,
    triggerNotice,
    hasBidPackage,
  ]);

  return (
    <>
      <TableActions onlyRight>
        <div css={Css.df.gap2.$}>
          {hasBidPackage ? (
            <>
              <Button
                icon="upload"
                label="Import Pricing"
                onClick={() => {
                  if (selectedRevision.bidContract.bidRequest && selectedRevision.bidPackageVersion)
                    openModal({
                      content: (
                        <ImportPackagePricingModal
                          bidContractId={selectedRevision.bidContract.id}
                          bidPackageRequestId={selectedRevision.bidContract.bidRequest.id}
                          bidPackageVersionId={selectedRevision.bidPackageVersion.id}
                        />
                      ),
                    });
                }}
                size="sm"
                variant="text"
                disabled={selectedRevision.canEdit.allowed === false}
              />
              <ButtonMenu trigger={{ label: "Actions" }} items={actionMenuItems} />
            </>
          ) : (
            <>
              <Switch label="Show Plan Usage" selected={showPlanUsage} onChange={togglePlanUsage} />
              <ImportPricingButton bidContractId={selectedRevision.bidContract.id} />
              <Button
                label="Add Scope"
                variant="secondary"
                disabled={
                  selectedRevision.canEdit.allowed === false
                    ? formatList(selectedRevision.canEdit.disabledReasons.map((r) => r.message))
                    : undefined
                }
                onClick={() =>
                  openModal({
                    size: { width: "md", height: 600 },
                    content: (
                      <BidItemsModal
                        title="Add Bid Items"
                        confirmationButtonText="Add"
                        onSave={async (selectedBidItems) => {
                          // Create a new bcli for each selected bid item with a total cost of 0
                          await saveBidContractUnitPricing({
                            variables: {
                              bidContractRevisionId: selectedRevision.id,
                              lineItems: selectedBidItems.map((bi) => ({ bidItemId: bi.id, totalCostInCents: 0 })),
                            },
                            refetchQueries: ["BidContractRevisionUnitPricing"],
                          });
                        }}
                        disabledOptions={lineItems.map((bcli) => bcli.bidItem?.id).compact()}
                        subHeader={
                          <>
                            <div css={Css.mt1.mb1.baseMd.$}>Select bid items to include in this contract.</div>
                            <div css={Css.mb2.$}>Bid items that are included on this revision will not be listed.</div>
                          </>
                        }
                      />
                    ),
                  })
                }
              />
              <ButtonMenu trigger={{ label: "Actions" }} items={actionMenuItems} />
            </>
          )}
        </div>
      </TableActions>
      <ScrollableContent>
        <GridTable
          stickyHeader
          fallbackMessage="No bids for this contract"
          sorting={{ on: "client" }}
          style={{ rowHeight: "fixed", allWhite: true }}
          columns={columns}
          rows={rows}
          api={tableApi}
        />
      </ScrollableContent>
    </>
  );
}

function ImportPricingButton({ bidContractId }: { bidContractId: string }) {
  const { openModal } = useModal();
  return (
    <Button
      icon="upload"
      label="Import Pricing"
      onClick={() => {
        openModal({
          content: (
            <ImportPricingModal bidContractId={bidContractId} uploadType={DevelopmentContractUploadType.Itemized} />
          ),
        });
      }}
      size="sm"
      variant="text"
    />
  );
}

type HeaderRow = { kind: "header"; id: string; data: undefined };
type BcliRow = { kind: "lineItem"; id: string; data: BidContractUnitPricingPage_BidContractLineItemFragment };
type ItiRow = {
  kind: "item";
  id: string;
  data: BidContractUnitPricingPage_PotentialUsagesFragment & {
    bcli: BidContractUnitPricingPage_BidContractLineItemFragment;
  };
};
type Row = HeaderRow | BcliRow | ItiRow;

function createColumns(
  getFormState: (
    input: BidContractUnitPricingPage_BidContractLineItemFragment,
  ) => ObjectState<SaveBidContractLineItemInput>,
  hasBidPackage: boolean,
): GridColumn<Row>[] {
  return [
    selectColumn<Row>({ item: emptyCell }),
    column<Row>({
      header: hasBidPackage ? "Material Code" : "Bid item code",
      lineItem: (bcli) => linkHeader(bcli.bidItem!.code, createBidItemDetailsUrl(bcli.bidItem!.id)),
      item: (iti) => ({
        content: `${iti.displayName} (${iti.id}) - ${iti.first.displayName} (${iti.development?.name})`,
        colspan: 2,
      }),
    }),
    column<Row>({
      header: hasBidPackage ? "Material Name" : "Bid item description",
      lineItem: (bcli) =>
        hasBidPackage
          ? (bcli.bidItem!.parentMaterialVariant?.displayName ?? bcli.bidItem!.parentTask?.name)
          : bcli.bidItem!.name,
      item: () => emptyCell,
    }),
    column<Row>({
      header: "Unit",
      lineItem: (bcli) => bcli.bidItem?.unitOfMeasure.shortName.toUpperCase(),
      item: () => emptyCell,
    }),
    column<Row>({
      header: "Cost Type",
      lineItem: (bcli) => bcli.bidItem?.costType.name,
      item: (iti) => `QTY: ${iti.quantity ?? 0}`,
    }),
    // Disable tax exempt column in production for now
    ...(getStage() === "prod"
      ? []
      : [
          column<Row>({
            header: "Tax Exempt",
            lineItem: (row) => {
              const os = getFormState(row);
              return {
                content: () => <BoundCheckboxField field={os.isTaxExempt} label={""} />,
                value: os.isTaxExempt.value,
              };
            },
            item: () => emptyCell,
          }),
        ]),
    numericColumn<Row>({
      header: "Unit Cost",
      lineItem: (row) => {
        const os = getFormState(row);
        return {
          content: () => <BoundNumberField type="cents" compact field={os.totalCostInCents} />,
          value: row.totalCostInCents,
        };
      },
      item: (row) => {
        const os = getFormState(row.bcli);
        return priceCell({ valueInCents: (os.totalCostInCents.value ?? 0) * (row.quantity ?? 0) });
      },
    }),
  ];
}

function createRows(
  bclis: BidContractUnitPricingPage_BidContractLineItemFragment[],
  showPlanUsage: boolean,
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...bclis.map((bcli) => ({
      kind: "lineItem" as const,
      id: bcli.id,
      data: bcli,
      children: showPlanUsage
        ? bcli.potentialUsages?.map((iti) => ({
            kind: "item" as const,
            id: `${iti.id}-${bcli.id}`,
            data: {
              ...iti,
              bcli,
            },
          }))
        : [],
    })),
  ];
}

const formConfig: ObjectConfig<SaveBidContractLineItemInput> = {
  id: { type: "value" },
  isTaxExempt: { type: "value" },
  totalCostInCents: { type: "value" },
};
