import { Maybe } from "graphql/jsutils/Maybe";
import {
  ConfirmLineItemsHomeownerContractLineItemFragment,
  ContractLineItemsByProjectItemFragment,
  ContractType,
  CostClassificationType,
  HomeownerContractChangeOrder,
  HomeownerContractLineItem,
  InvoiceEditor_InvoiceLineItemFragment,
  InvoiceEditorInvoiceFragment,
  InvoiceHomeownerContractLineItemFragment,
  InvoiceProjectItemFragment,
  InvoiceStatus,
  InvoiceV2_HomeownerContractLineItemFragment,
  SaveInvoiceLineItemInput,
} from "src/generated/graphql-types";
import { calcPercent, fail, trimNumber } from "src/utils";
import { ObjectState, Rule } from "src/utils/formState";
import { InvoiceFormState } from "./InvoiceEditor";

const maxExternalInvoiceNumber = 125000;

export const invoiceNumberRule: Rule<Maybe<string>> = ({ value, originalValue }) => {
  const valueAsNum = Number(value);
  if (
    // If invoiceNumber is undefined or null consider it valid. This signals the graphql-service that we want a new invoice number generated
    value === undefined ||
    value === null ||
    // If the invoiceNumber is equal to its original value, then consider it valid.
    originalValue === value ||
    // If it is not a number then consider it valid, as we do not need to worry about collision with BP invoice numbers
    isNaN(valueAsNum) ||
    // If a new value has been entered, then that means the user is entering an external account number and that must fit between the defined bounds to be valid.
    (valueAsNum > 0 && valueAsNum < maxExternalInvoiceNumber)
  ) {
    return undefined;
  }
  // If it did not pass the above validation, then the user entered an external invoice number outside of the bounds.
  return "External Invoice Numbers cannot be a number greater than or equal to 125000";
};

/** Creates a FormLineItem for every contract line item related to the Project Item. */
export function buildLineItemsForProjectItem(
  projectItemId: string,
  contractLineItemsByProjectItem: ContractLineItemsByProjectItemFragment[],
  // Will be undefined for new invoices
  invoice: InvoiceEditorInvoiceFragment | undefined,
): ContractLineItem[] {
  // Get the list of all homeowner contract line items for this project item, then find associated invoice line items to populate data with.
  const { projectItem, lineItems } =
    contractLineItemsByProjectItem.find((lipi) => lipi.projectItem.id === projectItemId) ||
    fail(`${projectItemId} doesn't have any contract line items`);

  const onPrimaryContract = lineItems.find((li) => li.parent.__typename === "HomeownerContract") !== undefined;

  return [
    // Some line items may not be on primary contract. If that is the case, then we need a placeholder row in the UI.
    ...(!onPrimaryContract ? [initLineItemData(projectItem)] : []),
    ...lineItems.map((hcli) => {
      const invoiceLineItem = invoice?.lineItems.find((ili) => ili.homeownerContractLineItem.id === hcli.id);
      const isInvoiceApproved = invoice?.status.code === InvoiceStatus.Approved;
      return initLineItemData(projectItem, hcli, invoiceLineItem, isInvoiceApproved);
    }),
  ];
}

/** Initializes FormLineItem data */
export function initLineItemData(
  projectItem: InvoiceProjectItemFragment,
  contractLineItem?: InvoiceHomeownerContractLineItemFragment,
  invoiceLineItem?: InvoiceEditor_InvoiceLineItemFragment,
  isInvoiceApproved?: boolean,
  includeDisplayNameInCo = false,
): ContractLineItem {
  const lineItemData = {
    id: invoiceLineItem?.id,
    projectItemId: projectItem.id,
    isChangeOrder: contractLineItem?.parent.__typename === "HomeownerContractChangeOrder",
    homeownerContractLineItemId: undefined,
    name: projectItem.displayName,
    billIds: projectItem.bills.map(({ id }) => ({ id })),
    priceChangeInCents: undefined,
    otherInvoices: undefined,
    minPercentage: 0,
    percentComplete: undefined,
    amountInCents: undefined,
    uninvoiced: { price: 0, percentage: 0 },
    marginInCents: invoiceLineItem?.marginInCents || 0,
    costClassification: projectItem.item.costCode.costClassification?.code,
  };

  // Return early if there is no contract data for this line item. This is possible in the case where
  // a projectItem only exists on a change order, but we still need to render an empty Primary Contract line.
  if (!contractLineItem) {
    return lineItemData;
  }

  const thisInvoiceInCents = invoiceLineItem?.amountInCents;
  const priceChangeInCents = contractLineItem.priceChangeInCents || 0;
  const totalInvoicedAmount = contractLineItem.invoiceLineItems.reduce(
    (total, invoice) => total + invoice.amountInCents,
    0,
  );
  const totalInvoicePercentage = trimNumber(calcPercent(totalInvoicedAmount, priceChangeInCents));

  const hasOtherInvoices =
    contractLineItem.invoiceLineItems.filter(
      (ili) => !invoiceLineItem || (invoiceLineItem && invoiceLineItem.id !== ili.id),
    ).length > 0;

  const otherInvoicesInCents = totalInvoicedAmount - (thisInvoiceInCents || 0);
  const otherInvoicePercentage = trimNumber(calcPercent(otherInvoicesInCents, priceChangeInCents));

  const percentComplete = !thisInvoiceInCents
    ? otherInvoicePercentage
    : trimNumber(calcPercent(thisInvoiceInCents, priceChangeInCents)) + otherInvoicePercentage;

  const uninvoicedPrice = priceChangeInCents - totalInvoicedAmount;
  const uninvoicedPercentage = 100 - totalInvoicePercentage;

  // TODO: @bdow - validate below logic for minPercentage
  // Until the invoice is fully approved then allow user to lower this invoice's percentage to `otherInvoicePercentage`. Otherwise minPercentage is inclusive of this invoice's percentage.
  const minPercentage = isInvoiceApproved ? totalInvoicePercentage : otherInvoicePercentage;

  return Object.assign(lineItemData, {
    homeownerContractLineItemId: contractLineItem.id,
    name: includeDisplayNameInCo
      ? `${lineItemData.isChangeOrder ? "CO " : ""}${projectItem.displayName}`
      : lineItemData.isChangeOrder
        ? contractLineItem.parent.name
        : projectItem.displayName,
    priceChangeInCents,
    otherInvoices: !hasOtherInvoices ? undefined : { price: otherInvoicesInCents, percentage: otherInvoicePercentage },
    minPercentage,
    percentComplete,
    amountInCents: thisInvoiceInCents,
    uninvoiced: { price: uninvoicedPrice, percentage: uninvoicedPercentage },
  });
}

export function ownerContractType(hcli: InvoiceV2_HomeownerContractLineItemFragment): ContractType {
  // Whenever change order contract type is BudgetReallocation, we need to get the contract type from the parent contract
  if (
    hcli.owner.__typename === "HomeownerContractChangeOrder" &&
    hcli.owner.contractType === ContractType.BudgetReallocation
  ) {
    return (hcli.owner as HomeownerContractChangeOrder).contract.contractType;
  }

  return hcli.owner.contractType;
}

export function isFixedMarkupByContractType(contractType: ContractType) {
  return [ContractType.Fixed, ContractType.BudgetReallocation].includes(contractType);
}

export function calculateHCLIMarkup(hcli: ConfirmLineItemsHomeownerContractLineItemFragment) {
  return isFixedMarkupByContractType(hcli.owner.contractType) ? 1 : 1 + hcli.owner.costPlusMarkupBasisPoints! / 10000;
}

/** Returns if Homeowner contract line item is a Cost+ contract and there is no cost */
export function isCostPlusWithoutCost(hcli: InvoiceV2_HomeownerContractLineItemFragment) {
  return (
    ownerContractType(hcli) === ContractType.CostPlus &&
    hcli.budgetChangeInCents === 0 &&
    (hcli.priceChangeInCents ?? 0) > 0
  );
}

export type ContractLineItem = SaveInvoiceLineItemInput &
  Pick<HomeownerContractLineItem, "priceChangeInCents"> & {
    name: string;
    isChangeOrder: boolean;
    percentComplete: Maybe<number>;
    minPercentage: number;
    /** `projectItemId` allows us to identify and group all rows related to the same ProjectItem (i.e. prime contract & change order rows) */
    projectItemId: string;
    otherInvoices: PricePercentageType | undefined;
    uninvoiced: PricePercentageType;
    billIds: { id: string }[];
    costClassification?: CostClassificationType;
    marginInCents?: number;
  };

export type PricePercentageType = {
  price: number;
  percentage: number;
};

/** Removes both the original contract + any C/O line items for the given project item. */
export function removeLineItemsByProjectItemId(projectItemId: string, formState: InvoiceFormState, force?: boolean) {
  formState.lineItems.rows
    .filter((li) => li.projectItemId.value === projectItemId)
    .forEach((row) => {
      // If the row is new, just remove it all together, otherwise mark for deletion
      if (force || !row.id || row.id.value === undefined) {
        formState.lineItems.remove(row.value);
      } else {
        row.delete.value = true;
      }
    });
}

export function calculateUninvoiced(
  row: ObjectState<ContractLineItem>,
  amountInCents: number,
  percentage: number,
): PricePercentageType {
  const priceChangeInCents = row.priceChangeInCents.value || 0;
  const totalAmountInvoiced = amountInCents + (row.otherInvoices.value?.price || 0);
  const uninvoicedPrice = priceChangeInCents - totalAmountInvoiced;
  const uninvoicedPercentage = 100 - percentage;
  return { price: uninvoicedPrice, percentage: uninvoicedPercentage };
}

export function calculatePercentageCompleteByAmount(row: ObjectState<ContractLineItem>, amountInCents: number): number {
  const priceChangeInCents = row.priceChangeInCents.value || 0;
  let otherPrice = 0;
  let otherPercentage = 0;
  if (row.otherInvoices.value) {
    otherPrice = row.otherInvoices.value.price;
    otherPercentage = row.otherInvoices.value.percentage;
  }
  const totalAmountInvoiced = amountInCents + otherPrice;

  return !amountInCents
    ? // reset % Complete to be equal to previously invoiced percentage
      otherPercentage
    : trimNumber(calcPercent(totalAmountInvoiced, priceChangeInCents));
}

export function calculateAmountByPercentage(row: ObjectState<ContractLineItem>, percentage: number): number {
  // Our `percentage` field is the sum of all invoice %'s. To properly calculate this invoice's amount we first need to subtract the the other invoice's percentage.
  const thisPercentage = percentage - (row.otherInvoices.value?.percentage || 0);
  // If either the percentage or `priceChangeInCents` are 0 or not defined, then return 0 for the amount.
  return !thisPercentage || !row.priceChangeInCents.value
    ? 0
    : Math.round(row.priceChangeInCents.value * (thisPercentage / 100));
}
