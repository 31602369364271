import { EventApi, EventContentArg, EventInput } from "@fullcalendar/core";
import { Css, Palette, Tooltip, TriggerNoticeProps } from "@homebound/beam";
import { addDays, differenceInBusinessDays, subDays } from "date-fns";
import { useState } from "react";
import { formatDate } from "src/components";
import { Icon, IconKey } from "src/components/Icon";
import {
  DraftPlanTaskInput,
  InputMaybe,
  PlanTask,
  ScheduleDraftMode_PlanTaskFragment,
  SchedulingExclusionDatesFragment,
  TaskStatus,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { getBusinessDaysOptions, isDisabledDay, taskRequiresTradeConfirmation } from "../utils";

export type DateIconsProps = {
  delayFlag?: string;
  isCalendarView?: boolean;
  materialDrop?: string;
  taskIsComplete?: boolean;
  tradePartner?: string;
  workingDays?: string;
};

type CalendarPlanTask = ScheduleDraftMode_PlanTaskFragment;

// renders and styles our task "event" content
export function renderTaskEventContent(
  arg: EventContentArg,
  planTasksById: Record<string, CalendarPlanTask>,
  isEditMode?: boolean,
  lastUpdatedTaskId?: InputMaybe<string>,
) {
  const {
    event: { id: eventId, title, extendedProps },
  } = arg;

  if (extendedProps.type === "holiday") {
    return <HolidayEventContent title={title} clickable={!isEditMode} />;
  }

  const planTask = planTasksById[eventId] ?? [];
  const delayFlags = planTask.scheduleFlags ?? [];
  const materialDrop = planTask.globalPlanTask.drops.first;
  const workingDays = planTask.workDays.sortBy((wd) => wd.workDay.date);

  const delayFlagTooltip = delayFlags
    .map((d) => `[${d.reason?.title}] created on ${new Date(d.createdAt).toLocaleDateString()}`)
    .join(", ");
  const materialDropTooltip = materialDrop?.name ?? "";
  const workindDaysTooltip = workingDays.map((pt) => formatDate(pt.workDay.date, "short")).join(", ");
  const tradePartnersScheduledTooltip = planTask.tradePartner?.name ?? "";
  const isTaskManuallyScheduled = planTask.isManuallyScheduled;

  return (
    <div
      css={{
        ...Css.df.aic.cursorPointer.gap1.p1
          .hPx(32)
          .transition.if(lastUpdatedTaskId === eventId)
          .bgColor(extendedProps.lastUpdatedColor).$, // Highlight the task if it's been recently updated
        // Allow override for "drag" cursor to appear when the event is draggable
        ...(isEditMode && Css.cursor("unset").$),
      }}
    >
      {isTaskManuallyScheduled && <Icon icon="pin" color={Palette.Blue600} inc={2} />}
      {planTask.isCriticalPath && (
        <Tooltip title="Critical Path Task">
          <Icon icon="criticalPath" color={Palette.Gray900} inc={2} />
        </Tooltip>
      )}
      <div css={Css.br4.xsSb.$}>{title}</div>
      <DateIcons
        delayFlag={delayFlagTooltip}
        materialDrop={materialDropTooltip}
        isCalendarView
        taskIsComplete={planTask.status.code === TaskStatus.Complete}
        workingDays={workindDaysTooltip}
        tradePartner={tradePartnersScheduledTooltip}
      />
    </div>
  );
}

export function createTaskCalendarEvents(planTasks: CalendarPlanTask[]) {
  return planTasks.flatMap<EventInput>((pt) => {
    const tradeScheduleWarning = getTradeScheduleWarning(pt);
    const taskCompleted = pt.status.code === TaskStatus.Complete;
    return [
      {
        id: pt.id,
        start: pt.startDate,
        // We add one day to the end date since FullCalendar's endDates are not inclusive when using allday events
        end: addDays(pt.endDate, 1),
        allDay: true,
        title: pt.name,
        editable: !taskCompleted,
        backgroundColor: tradeScheduleWarning
          ? tradeScheduleWarning.rowPalette
          : taskCompleted
            ? Palette.Gray300
            : Palette.Blue100,
        textColor: tradeScheduleWarning
          ? tradeScheduleWarning.textPalette
          : taskCompleted
            ? Palette.Gray900
            : Palette.Blue600,
        extendedProps: {
          type: "planTask",
          lastUpdatedColor: tradeScheduleWarning ? tradeScheduleWarning.taskUpdatedPalette : Palette.Blue300,
          duration: pt.knownDurationInDays ?? pt.durationInDays,
        },
      },
    ];
  });
}

export function createHolidayCalendarEvents(schedulingExclusionDates: SchedulingExclusionDatesFragment[]) {
  return schedulingExclusionDates.flatMap<EventInput>((holiday) => {
    return [
      {
        id: holiday.id,
        start: holiday.date,
        // We add one day to the end date since FullCalendar's endDates are not inclusive when using allday events
        end: addDays(holiday.date, 1),
        allDay: true,
        title: holiday.name,
        backgroundColor: Palette.Gray300,
        textColor: Palette.Gray900,
        interactive: false,
        editable: false,
        extendedProps: { type: "holiday" },
      },
    ];
  });
}

export function getPlanTaskData(planTasks: CalendarPlanTask[]) {
  const events = createTaskCalendarEvents(planTasks);
  const planTasksById = planTasks.keyBy((pt) => pt.id);
  return { events, planTasksById };
}

export function DateIcons({
  delayFlag,
  isCalendarView,
  materialDrop,
  taskIsComplete,
  tradePartner,
  workingDays,
}: DateIconsProps) {
  const containerStyles = isCalendarView
    ? Css.df.jcc.aic.pPx(3).br100.wPx(18).hPx(18).$
    : Css.df.jcc.aic.pPx(3).br100.wPx(25).hPx(25).if(!!taskIsComplete).bgGray200.else.bgWhite.$;

  const icons = [
    ...(delayFlag ? [{ icon: "flag", title: `Delay flag for ${delayFlag}.`, color: Palette.Red600, inc: 2 }] : []),
    ...(materialDrop ? [{ icon: "truck", title: `${materialDrop}`, color: Palette.Gray800, inc: undefined }] : []),
    ...(tradePartner ? [{ icon: "hardHat", title: `${tradePartner}`, color: Palette.Gray800, inc: undefined }] : []),
    ...(workingDays
      ? [{ icon: "calendar", title: `Working days: [${workingDays}]`, color: Palette.Blue500, inc: undefined }]
      : []),
  ];

  return isCalendarView ? (
    <div css={Css.df.jcsb.gap1.bgWhite.pxPx(2).br12.$}>
      {icons.map((icon, index) => (
        <div key={index}>
          <Tooltip title={icon.title}>
            <div css={containerStyles}>
              <Icon icon={icon.icon as IconKey} color={icon.color} inc={icon.inc} />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  ) : (
    <div css={Css.df.fdrr.fww.h100.w100.jcsb.absolute.gap2.$}>
      {/* The first icon will be at the bottom right */}
      {icons.length > 0 && (
        <div css={Css.absolute.bottomPx(5).rightPx(5).$}>
          {
            <Tooltip title={icons[0].title}>
              <div css={containerStyles}>
                <Icon icon={icons[0].icon as IconKey} color={icons[0].color} inc={icons[0].inc} />
              </div>
            </Tooltip>
          }
        </div>
      )}
      {/* The second icon will be at the top right */}
      {icons.length > 1 && (
        <div css={Css.absolute.topPx(5).rightPx(5).$}>
          <div css={containerStyles}>
            {
              <Tooltip title={icons[1].title}>
                <div css={containerStyles}>
                  <Icon icon={icons[1].icon as IconKey} color={icons[1].color} inc={icons[1].inc} />
                </div>
              </Tooltip>
            }
          </div>
        </div>
      )}
      {/* The last icon will be at the top left */}
      {icons.length > 2 && (
        <div css={Css.absolute.topPx(5).leftPx(5).$}>
          <div css={containerStyles}>
            {
              <Tooltip title={icons[2].title}>
                <div css={containerStyles}>
                  <Icon icon={icons[2].icon as IconKey} color={icons[2].color} inc={icons[2].inc} />
                </div>
              </Tooltip>
            }
          </div>
        </div>
      )}
    </div>
  );
}

// TODO: Inform user if operation failed because of a holiday or task specific working day
export function onDraftTaskEventDrop(
  event: EventApi,
  planTask: CalendarPlanTask,
  schedulingExclusionDates: SchedulingExclusionDatesFragment[], // as of now these are holidays set at the development level
  revert: () => void,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
) {
  const newStartDate = event.start ? new DateOnly(event.start) : undefined;
  const isDisabled = checkDisabled({
    newStartDate,
    customWorkableDays: planTask?.customWorkableDays,
    schedulingExclusionDates,
  });

  // Show error message if the start date is moved to a non-working day (Saturday or Sunday)
  if (isDisabled) {
    revert();
    return triggerNotice({
      message: `Start Date of ${formatDate(newStartDate)} is not a working day for this schedule`,
      icon: "error",
    });
  }

  return setDraftTaskChanges([
    {
      id: planTask.id,
      earliestStartDate: newStartDate,
      isManuallyScheduled: true,
    },
  ]);
}

// TODO: Inform user if operation failed because of a holiday or task specific working day
export function onDraftTaskEventResize(
  event: EventApi,
  planTask: CalendarPlanTask,
  schedulingExclusionDates: SchedulingExclusionDatesFragment[], // as of now these are holidays set at the development level
  revert: () => void,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
) {
  const startDate = event.start ? new DateOnly(event.start) : undefined;
  // Subtract one day from the end date since we added one day when rendering the event
  const endDate = event.end ? new DateOnly(subDays(event.end, 1)) : undefined;

  const businessDaysOptions = getBusinessDaysOptions(planTask?.customWorkableDays, schedulingExclusionDates);
  // Add one day to the duration difference because the end date is not inclusive
  const durationInDays =
    (startDate && endDate && differenceInBusinessDays(endDate, startDate, businessDaysOptions) + 1) || undefined;
  const isDisabled = checkDisabled({
    newStartDate: startDate,
    endDate,
    customWorkableDays: planTask?.customWorkableDays,
    schedulingExclusionDates,
  });

  // Show error message if endDate is moved to a non-working day (Saturday or Sunday)
  if (isDisabled) {
    revert();
    return triggerNotice({
      message: `End Date of ${formatDate(endDate)} is not a working day for this schedule`,
      icon: "error",
    });
  }

  return setDraftTaskChanges([
    {
      id: planTask.id,
      knownDurationInDays: durationInDays,
    },
  ]);
}

type CheckDisabledAttributes = {
  newStartDate: DateOnly | undefined;
  endDate?: DateOnly | undefined;
  customWorkableDays: PlanTask["customWorkableDays"];
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
};

function checkDisabled(attrs: CheckDisabledAttributes) {
  const { newStartDate, endDate, customWorkableDays, schedulingExclusionDates } = attrs;

  const scheduleExcludedDates = schedulingExclusionDates?.map((sed) => sed.date);

  if (newStartDate && isDisabledDay({ date: newStartDate, customWorkableDays, scheduleExcludedDates })) {
    return true;
  }

  if (endDate && isDisabledDay({ date: endDate, customWorkableDays, scheduleExcludedDates })) {
    return true;
  }
}

type TaskTradeScheduleWarning = {
  /**
   * Note: Tooltip isn't being used atm because our tooltip wrapper doesn't play nice with non beam components that rely
   * on events, and we have the colors meaning defined in the legend anyway.
   */
  tooltip: string;
  rowPalette: Palette; // background color of event
  textPalette: Palette; // inner text color of event
  taskUpdatedPalette: Palette;
};

/**
 * Highlights task set to begin within 2 weeks based on trade partner tradePartnerAvailabilityRequests & tradePartnerStatus confirmation
 */
function getTradeScheduleWarning(planTask: CalendarPlanTask): TaskTradeScheduleWarning | undefined {
  const {
    status: { code: taskStatusCode },
    startDate,
    tradePartner: assignedTradePartner,
    tradePartnerStatus: { code: tradePartnerStatusCode },
    tradePartnerAvailabilityRequests,
  } = planTask;

  if (taskRequiresTradeConfirmation(taskStatusCode, startDate, tradePartnerStatusCode)) {
    return tradePartnerAvailabilityRequests?.filter((tpar) => tpar.tradePartner.id === assignedTradePartner?.id).isEmpty
      ? {
          tooltip: "Trade - Not Sent",
          rowPalette: Palette.Red100,
          textPalette: Palette.Red600,
          taskUpdatedPalette: Palette.Red300,
        }
      : {
          tooltip: "Trade - Sent and Unconfirmed",
          rowPalette: Palette.Yellow100,
          textPalette: Palette.Yellow600,
          taskUpdatedPalette: Palette.Yellow300,
        };
  }
}

export function HolidayEventContent({ title, clickable = false }: { title: string; clickable?: boolean }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      css={Css.df.aic.gap1.py1.px2.h100.w100.hPx(32).xsSb.bgGray300.gray700.br4.if(clickable).cursorPointer.$}
    >
      <Icon icon="calendarX" inc={2} color={Palette.Gray900} />
      <div css={Css.br4.gray900.$}>{title}</div>
      {clickable && isHovered && (
        <div css={Css.mla.$}>
          <Icon icon={"linkExternal"} inc={2} />
        </div>
      )}
    </div>
  );
}
