import { Css, ScrollableParent, Step, useComputed } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import { ConfirmLeaveModal } from "src/components";
import { StepperProvider, stepperBarHeight, useStepperContext } from "src/components/stepper";
import {
  PublishBidPackagePage_BidPackageFragment,
  SaveBidPackageInput,
  usePublishBidPackagePageQuery,
} from "src/generated/graphql-types";
import { BidPackageParams } from "src/routes/routesDef";
import { isEmptyObject, queryResult } from "src/utils";
import { SelectTradesStep } from "./components/1.SelectTradesStep";
import { UpdateBidInfoStep } from "./components/2.UpdateBidInfoStep";
import { AddInvitationTextStep } from "./components/3.AddInvitationTextStep";
import { ReviewAndPublishStep } from "./components/4.ReviewAndPublishStep";
import { PublishBidPackageStepActions } from "./components/PublishBidPackageStepActions";
import { BidPackagePublishStep } from "./utils";

export function PublishBidPackagePage() {
  const { bidPackageId } = useParams<BidPackageParams>();
  const query = usePublishBidPackagePageQuery({ variables: { id: bidPackageId } });

  return queryResult(query, ({ bidPackage }) => (
    <StepperProvider steps={initialSteps}>
      <PublishBidPackageView bidPackage={bidPackage} />
    </StepperProvider>
  ));
}

function PublishBidPackageView({ bidPackage }: { bidPackage: PublishBidPackagePage_BidPackageFragment }) {
  const { currentStep, setSteps } = useStepperContext();
  const [stepIndex, setStepIndex] = useState(0);
  const formState = useFormState({
    config: formConfig,
    init: { input: bidPackage, map: mapBidPackageToFormState },
  });

  useEffect(() => {
    const currentStepIndex = initialSteps.findIndex((step) => step.value === currentStep.value);
    if (stepIndex === currentStepIndex) return;
    setStepIndex(currentStepIndex);
    setSteps((prevState) => [
      { ...prevState[0], state: currentStepIndex > 0 ? "complete" : prevState[0].state },
      { ...prevState[1], state: currentStepIndex > 1 ? "complete" : prevState[1].state },
      { ...prevState[2], state: currentStepIndex > 2 ? "complete" : prevState[2].state },
      { ...prevState[3], state: currentStepIndex > 3 ? "complete" : prevState[3].state },
    ]);
  }, [currentStep, setSteps, stepIndex]);

  const step = useMemo(() => {
    switch (currentStep.value) {
      case BidPackagePublishStep.SELECT_TRADES:
        return <SelectTradesStep formState={formState} bidPackage={bidPackage} />;
      case BidPackagePublishStep.UPDATE_BID_INFO:
        return <UpdateBidInfoStep formState={formState} bidPackage={bidPackage} />;
      case BidPackagePublishStep.ADD_INVITATION_TEXT:
        return <AddInvitationTextStep formState={formState} bidPackage={bidPackage} />;
      case BidPackagePublishStep.REVIEW_AND_PUBLISH:
        return <ReviewAndPublishStep formState={formState} bidPackage={bidPackage} />;
      default:
        return <UpdateBidInfoStep formState={formState} bidPackage={bidPackage} />;
    }
  }, [currentStep.value, formState]);

  // This updates the continue/enable/disable state of the stepper based on required formState
  useComputed(() => {
    if (formState.dirty && !isEmptyObject(formState.changedValue)) {
      setSteps((prevState) => [
        { ...prevState[0], disabled: false },
        { ...prevState[1], disabled: !formState.versionRequests.rows.some((r) => r.notify.value) },
        { ...prevState[2], disabled: !formState.dueDate.value || !formState.estimatedAwardDate.value },
        {
          ...prevState[3],
          disabled:
            !formState.existingInvitationText.value ||
            !formState.invitationText.value ||
            !formState.bidInstructionsText.value,
        },
      ]);
    }
  }, [formState]);

  // We use a function b/c this gets passed to NavigationPrompt that probably needs the latest
  // value from our formState (i.e. after formState.commitChanges()) w/o waiting for mobx to reactively
  // through an updated props value.
  const isDirty = () => formState.dirty;

  return (
    <ScrollableParent xss={Css.mbPx(stepperBarHeight).pxPx(60).$}>
      {step}
      {/* This is copied from src/components/formFields/FormActions.tsx */}
      {/* React Router implementation of confirmation dialog. */}
      <NavigationPrompt
        // Only prompt if we're navigating away from the current page.
        when={(currentLocation, nextLocation) =>
          isDirty() && !nextLocation?.pathname.startsWith(currentLocation.pathname)
        }
      >
        {({ onConfirm, onCancel }) => (
          <ConfirmLeaveModal onConfirm={onConfirm} onCancel={onCancel} formState={formState as any} />
        )}
      </NavigationPrompt>
      <PublishBidPackageStepActions formState={formState} />
    </ScrollableParent>
  );
}

const INCOMPLETE = "incomplete" as const;
export const initialSteps: Step[] = [
  { value: BidPackagePublishStep.SELECT_TRADES, label: "Select Trades", state: INCOMPLETE },
  { value: BidPackagePublishStep.UPDATE_BID_INFO, label: "Update Bid Info", state: INCOMPLETE },
  { value: BidPackagePublishStep.ADD_INVITATION_TEXT, label: "Invitation Text", state: INCOMPLETE, disabled: true },
  { value: BidPackagePublishStep.REVIEW_AND_PUBLISH, label: "Preview", state: INCOMPLETE, disabled: true },
];

export type BidPackageFormState = Pick<
  SaveBidPackageInput,
  | "id"
  | "existingInvitationText"
  | "invitationText"
  | "bidInstructionsText"
  | "planRoomLink"
  | "dueDate"
  | "estimatedAwardDate"
  | "estimatedConstructionDate"
  | "versionNotes"
  | "versionRequests"
>;

export const formConfig: ObjectConfig<BidPackageFormState> = {
  id: { type: "value" },
  dueDate: { type: "value", rules: [required] },
  estimatedAwardDate: { type: "value", rules: [required] },
  existingInvitationText: { type: "value", rules: [required] },
  invitationText: { type: "value", rules: [required] },
  bidInstructionsText: { type: "value", rules: [required] },
  estimatedConstructionDate: { type: "value" },
  planRoomLink: { type: "value" },
  versionNotes: { type: "value" },
  versionRequests: {
    type: "list",
    config: { id: { type: "value" }, notify: { type: "value" }, bidPackageRequestId: { type: "value" } },
  },
};

export type PublishBidPackageStepProps = {
  formState: ObjectState<BidPackageFormState>;
  bidPackage: PublishBidPackagePage_BidPackageFragment;
};

function mapBidPackageToFormState(bidPackage: PublishBidPackagePage_BidPackageFragment): BidPackageFormState {
  return {
    ...bidPackage,
    // If there's an existing version request, use it, otherwise create a new one.
    versionRequests: bidPackage.activeRequests.map((r) => {
      const existingVersionRequest = bidPackage.latestVersion.requests.find((vr) => vr.bidPackageRequest.id === r.id);
      if (existingVersionRequest) {
        return {
          id: existingVersionRequest.id,
          notify: existingVersionRequest.notify,
          bidPackageRequestId: r.id,
        };
      }
      return {
        notify: true,
        bidPackageRequestId: r.id,
      };
    }),
  };
}
