import { Button, Css, useTestIds } from "@homebound/beam";
import { BidContractTradePartnerContactInfoFragment } from "src/generated/graphql-types";
import { createTradePartnerUrl } from "src/RouteUrls";
import { DateOnly, formatWithYear } from "src/utils/dates";

type TradePartnerOverviewProps = {
  tradePartner: BidContractTradePartnerContactInfoFragment;
};

export function BidContractTradePartnerOverview({ tradePartner }: TradePartnerOverviewProps) {
  const tradePartnerUrl = createTradePartnerUrl(tradePartner.id);
  const testIds = useTestIds({}, "tpCard");

  return (
    <div css={Css.wPx(440).ba.bcGray300.br16.pxPx(17).ptPx(16).mr2.$}>
      <div css={Css.baseMd.df.fdr.mbPx(11).$} {...testIds.tradePartnerOverviewHeader}>
        <div css={Css.w75.$}>{tradePartner.name}</div>
        <div css={Css.mtPx(-4).mrPx(-6).$}>
          <Button variant="tertiary" label="Manage Trade" onClick={tradePartnerUrl} />
        </div>
      </div>

      <div {...testIds.primaryContactData} css={Css.my3.$}>
        <div css={Css.df.sm.gray700.mbPx(4).$}>Primary Contact</div>
        {tradePartner.primaryForMarket ? (
          <>
            <div css={Css.smMd.asfs.mtPx(5).$}>{tradePartner.primaryForMarket.name}</div>
            <div css={Css.smMd.asfs.blue700.$}>{tradePartner.primaryForMarket.email}</div>
            <div css={Css.smMd.asfs.$}>
              {tradePartner.primaryForMarket.officePhone || tradePartner.primaryForMarket.mobilePhone}
            </div>
          </>
        ) : (
          <div>None</div>
        )}
      </div>

      <div {...testIds.signatoryContactData} css={Css.my3.$}>
        <div css={Css.df.sm.gray700.$}>Signatory</div>
        {tradePartner.signatoryForMarket ? (
          <>
            <div css={Css.smMd.asfs.mtPx(5).$}>{tradePartner.signatoryForMarket.name}</div>
            <div css={Css.smMd.asfs.blue700.$}>{tradePartner.signatoryForMarket.email}</div>
            <div css={Css.smMd.asfs.$}>
              {tradePartner.signatoryForMarket.officePhone || tradePartner.signatoryForMarket.mobilePhone}
            </div>
          </>
        ) : (
          <div>None</div>
        )}
      </div>

      <div {...testIds.tradeSinceData} css={Css.my3.$}>
        <div css={Css.df.sm.gray700.$}>Trade since</div>
        <div css={Css.smMd.asfs.$}>{formatWithYear(tradePartner.createdAt as DateOnly)}</div>
      </div>
    </div>
  );
}
