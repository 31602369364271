import {
  BoundCheckboxField,
  BoundTextField,
  Button,
  Css,
  DnDGrid,
  DnDGridItemHandle,
  FormLines,
  IconButton,
  Palette,
  Tooltip,
  useComputed,
  useDnDGridItem,
  useSuperDrawer,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import uniqueId from "lodash/uniqueId";
import { Observer } from "mobx-react";
import { useRef } from "react";
import { MADTypeBoundSelectField } from "src/components/autoPopulateSelects/MADTypeBoundSelectField";
import { UnitsOfMeasureBoundSelectField } from "src/components/autoPopulateSelects/UnitsOfMeasureBoundSelectField";
import {
  AdminItemsDrawerDocument,
  AdminItemsDrawerQuery,
  IncrementalCollectionOp,
  MaterialAttributeDimensionType,
  SaveItemsInput,
  useAdminItemsDrawerQuery,
  useSaveItemDimensionsMutation,
} from "src/generated/graphql-types";
import { useReaction } from "src/hooks";

type MaterialCatalogEditorTabProps = { itemId: string };
export function MaterialCatalogEditorTab({ itemId }: MaterialCatalogEditorTabProps) {
  const { data } = useAdminItemsDrawerQuery({ variables: { itemId } });
  const [saveItemDimensions, { loading }] = useSaveItemDimensionsMutation({
    refetchQueries: [AdminItemsDrawerDocument],
    awaitRefetchQueries: true,
  });
  const { closeDrawer } = useSuperDrawer();

  const formState = useFormState({
    config: formConfig,
    readOnly: loading,
    init: {
      input: data,
      map: mapToForm,
    },
  });

  async function onSave() {
    await saveItemDimensions({ variables: { input: mapToInput(formState, itemId) } });
  }

  const mads = useComputed(
    () => formState.mads.rows.filter((mad) => mad.op.value !== IncrementalCollectionOp.Delete),
    [formState.mads.rows],
  );

  return (
    <div css={Css.w100.$}>
      <Observer>
        {() => (
          <div css={Css.df.fdc.gap2.$}>
            <div css={Css.df.$}>
              <div css={Css.fg1.df.fdc.gap2.$}>
                <BoundTextField field={formState.materialCodePrefix} />
                <Tooltip title="To ensure a specific delimiter is always kept, wrap it in {delimiter!}">
                  <BoundTextField
                    field={formState.materialNameFormat}
                    placeholder="{width}x{height} {-!} {length} {name}"
                  />
                </Tooltip>
                {data?.items.first?.isAssemblyRelated && (
                  <Tooltip
                    title={
                      data?.items.first.canBecomeMaterialModifier.disabledReasons.map((dr) => dr.message).join("; ") ||
                      "Marks that this item modifies other lines in an assembly, such as Wood Type (e.g. Walnut), rather than constituting its own line-item. Modifiers may not be bid against or create ProjectItems."
                    }
                  >
                    <BoundCheckboxField
                      label="Combine in Assembly"
                      field={formState.isMaterialModifier}
                      disabled={!data?.items.first?.canBecomeMaterialModifier.allowed}
                    />
                  </Tooltip>
                )}
              </div>
              <div css={Css.mla.df.aic.gap2.$}>
                <Button label="Cancel" variant="tertiary" onClick={closeDrawer} />
                <Button disabled={!formState.dirty} label="Save" onClick={onSave} />
              </div>
            </div>
            <div css={Css.wPx(150).$}>
              <Button icon="plus" onClick={() => addDimension(formState, itemId)} label="New Attribute" />
            </div>
            <AttributeGrid onReorder={(data) => reorderAttributes(data, formState)}>
              {mads.nonEmpty ? (
                mads.map((row) => (
                  <MaterialAttributeDimensionView formState={formState} itemId={itemId} row={row} key={row.id.value} />
                ))
              ) : (
                <div>No Attributes</div>
              )}
            </AttributeGrid>
          </div>
        )}
      </Observer>
    </div>
  );
}

type AttributeGridProps = {
  children: React.ReactNode;
  onReorder: (ids: string[]) => void;
};

export function AttributeGrid({ children, onReorder }: AttributeGridProps) {
  const tid = useTestIds({});
  return (
    <div css={Css.maxwPx(600).$}>
      <DnDGrid
        onReorder={onReorder}
        gridStyles={Css.dg.gtc("1fr").gapPx(20).$}
        activeItemStyles={
          Css.boxShadow(
            `0 0 0 4px ${Palette.Blue700}, 0px 20px 25px -5px rgba(0,0,0,0.1), 0px 10px 10px -5px rgba(0,0,0,0.04)`,
          ).$
        }
        {...tid.materialAttributes}
      >
        {children}
      </DnDGrid>
    </div>
  );
}

type MaterialAttributeProps = {
  row: ObjectState<FormMaterialAttributeDimension>;
  formState: ObjectState<FormValue>;
  itemId: string;
};

function MaterialAttributeDimensionView(props: MaterialAttributeProps) {
  const { formState, row } = props;
  const itemRef = useRef(null);
  const { dragItemProps, dragHandleProps } = useDnDGridItem({ id: row.id.value, itemRef });

  function addValue(row: ObjectState<FormMaterialAttributeDimension>) {
    row?.materialAttributeValues.add({
      id: undefined,
      code: undefined,
      textValue: undefined,
      op: IncrementalCollectionOp.Include,
    });
  }

  /**
   * If the MAD or MAV already exists, i.e., has an ID, we set delete to true so the backend (BE) can properly remove it.
   * Otherwise, we assume the entity is new and can safely be removed from the formState.
   */
  function removeDimension(row: ObjectState<FormMaterialAttributeDimension>) {
    row.id.value ? row.set({ op: IncrementalCollectionOp.Delete }) : formState.mads.remove(row.value);
  }

  function removeValue(row: ObjectState<FormMaterialAttributeDimension>, mav: ObjectState<FormMaterialAttributeValue>) {
    mav.id.value ? mav.set({ op: IncrementalCollectionOp.Delete }) : row?.materialAttributeValues.remove(mav.value);
  }

  useReaction(
    () => [row.type.value, row.unitOfMeasureId.value],
    ([type, uom]) => {
      if (type !== MaterialAttributeDimensionType.Number && uom) {
        row.unitOfMeasureId.set(undefined);
      }
    },
    [row.type.value, row.unitOfMeasureId.value],
  );

  return (
    <Observer>
      {() => (
        <section css={Css.bgGray100.py2.px1.br12.df.gap1.$} {...dragItemProps} ref={itemRef}>
          <DnDGridItemHandle icon="drag" dragHandleProps={dragHandleProps} />
          <FormLines>
            <div css={Css.df.gap1.$}>
              <div css={Css.wPx(300).$}>
                <BoundTextField label="Attribute Name" field={row.name} />
              </div>
              <div>
                <MADTypeBoundSelectField label="Type" field={row.type} />
              </div>
              {row.type.value === MaterialAttributeDimensionType.Number && (
                <div>
                  <UnitsOfMeasureBoundSelectField field={row.unitOfMeasureId} />
                </div>
              )}
              <div css={Css.ptPx(30).ml2.$}>
                <IconButton icon="x" onClick={() => removeDimension(row)} tooltip="Remove Attribute" />
              </div>
            </div>
            <div css={Css.df.gap2.$}>
              <BoundCheckboxField label="Use in Takeoff" field={row.useInTakeoff} />
              <BoundCheckboxField label="Use in Visual Mode" field={row.useInVisualModePdf} />
            </div>
            {row.type.value !== MaterialAttributeDimensionType.Number && (
              <div>
                {/* Material Attribute Values */}
                <div>
                  {row.materialAttributeValues.rows.filter((val) => val.op.value !== IncrementalCollectionOp.Delete)
                    .nonEmpty && (
                    <div>
                      <div css={Css.mb2.smBd.$}>Attribute Values</div>
                      {row.materialAttributeValues.rows
                        .filter((val) => val.op.value !== IncrementalCollectionOp.Delete)
                        .map((attrVal, index) => {
                          return (
                            <div key={index} css={Css.df.mb1.$}>
                              <div css={Css.df.gap1.w100.$}>
                                <BoundTextField
                                  // New fields are set to default as undefined. We utilize this to autofocus the field.
                                  autoFocus={attrVal.textValue.value === undefined}
                                  key={attrVal.id.value}
                                  label="Attribute Value"
                                  field={attrVal.textValue}
                                  labelStyle="hidden"
                                />
                                <BoundTextField label="Code" field={attrVal.code} labelStyle="inline" />
                              </div>
                              <div css={Css.ptPx(8).ml2.$}>
                                <IconButton icon="x" onClick={() => removeValue(row, attrVal)} tooltip="Remove Value" />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
                {row.type.value === MaterialAttributeDimensionType.Select && (
                  <div css={Css.mb2.$}>
                    <Button variant="secondary" icon="plus" onClick={() => addValue(row)} label="Add value" />
                  </div>
                )}
              </div>
            )}
          </FormLines>
        </section>
      )}
    </Observer>
  );
}

function nextSortOrder(formState: ObjectState<FormValue>) {
  if (formState.mads.rows.isEmpty) return 0;
  return Math.max(...formState.mads.rows.map((row) => row.sortOrder.value).compact()) + 1;
}

function reorderAttributes(sortedIds: string[], formState: ObjectState<FormValue>) {
  sortedIds.forEach((id, index) => {
    const match = formState.mads.rows.find((row) => row.id.value === id);
    match?.set({ sortOrder: index });
  });
}

function addDimension(formState: ObjectState<FormValue>, itemId: string) {
  formState.mads.add({
    id: uniqueId("mavId"), // Temp ID to use for reordering until the actual ID is hydrated from the BE
    name: undefined,
    type: MaterialAttributeDimensionType.Select,
    itemId: itemId,
    unitOfMeasureId: null,
    useInTakeoff: true,
    useInVisualModePdf: false,
    sortOrder: nextSortOrder(formState),
    materialAttributeValues: [],
    op: IncrementalCollectionOp.Include,
  });
}

export type FormMaterialAttributeDimension = {
  id: string;
  itemId: string | undefined | null;
  name: string | undefined | null;
  type: MaterialAttributeDimensionType | undefined | null;
  useInTakeoff: boolean | undefined | null;
  useInVisualModePdf: boolean | undefined | null;
  unitOfMeasureId: string | undefined | null;
  sortOrder: number;
  materialAttributeValues: FormMaterialAttributeValue[];
  op: IncrementalCollectionOp;
};

type FormMaterialAttributeValue = {
  id: string | undefined | null;
  code?: string | undefined | null;
  textValue: string | undefined | null;
  op: IncrementalCollectionOp;
};

type FormValue = {
  materialCodePrefix: string | undefined | null;
  materialNameFormat: string | undefined | null;
  isMaterialModifier: boolean | undefined | null;
  mads: FormMaterialAttributeDimension[];
};

const formConfig: ObjectConfig<FormValue> = {
  materialCodePrefix: { type: "value" },
  materialNameFormat: { type: "value" },
  isMaterialModifier: { type: "value" },
  mads: {
    type: "list",
    config: {
      id: { type: "value" },
      itemId: { type: "value" },
      type: { type: "value" },
      name: { type: "value", rules: [required] },
      useInTakeoff: { type: "value" },
      useInVisualModePdf: { type: "value" },
      unitOfMeasureId: { type: "value" },
      sortOrder: { type: "value" },
      op: { type: "value" },
      materialAttributeValues: {
        type: "list",
        config: {
          id: { type: "value" },
          code: { type: "value" },
          textValue: { type: "value" },
          op: { type: "value" },
        },
      },
    },
  },
};

function mapToForm(data: AdminItemsDrawerQuery | null | undefined) {
  const [item] = data?.items ?? [];
  return {
    materialCodePrefix: item?.materialCodePrefix,
    materialNameFormat: item?.materialNameFormat,
    isMaterialModifier: item?.isMaterialModifier,
    mads: item?.materialAttributeDimensions.map((mad) => ({
      ...mad,
      type: mad.type.code,
      itemId: item?.id,
      useInTakeoff: mad.useInTakeoff,
      useInVisualModePdf: mad.useInVisualModePdf,
      unitOfMeasureId: mad.unitOfMeasure?.id,
      op: IncrementalCollectionOp.Include,
      materialAttributeValues: mad.values?.map((mav) => ({ ...mav, op: IncrementalCollectionOp.Include })) ?? [],
    })),
  } satisfies FormValue;
}

function mapToInput(formState: ObjectState<FormValue>, itemId: string) {
  return {
    items: [
      {
        id: itemId,
        materialCodePrefix: formState.changedValue.materialCodePrefix,
        materialNameFormat: formState.changedValue.materialNameFormat,
        isMaterialModifier: formState.changedValue.isMaterialModifier,
        materialAttributeDimensions: formState.value.mads.map((mad) => ({
          ...mad,
          id: mad.id.includes("mavId") ? undefined : mad.id,
        })),
      },
    ],
  } satisfies SaveItemsInput;
}
