import { useSnackbar } from "@homebound/beam";
import { useMemo } from "react";
import { useDuplicateItemsConfirmationModal_GetTlisQuery } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { count, fail, pluralize } from "src/utils";
import { StoreApi } from "zustand";
import { TakeoffsStoreState } from "../TakeoffsManagerContext";

type DuplicateItemsConfirmationModalProps = { store: StoreApi<TakeoffsStoreState> };

export function DuplicateItemsConfirmationModal({ store }: DuplicateItemsConfirmationModalProps) {
  const { triggerNotice } = useSnackbar();
  const { planPackage, copyFromPlan, getSelectedTliIds } = store.getState();
  const selectedTliIds = getSelectedTliIds();
  const tlisQuery = useDuplicateItemsConfirmationModal_GetTlisQuery({
    variables: { filter: { id: selectedTliIds, readyPlan: [planPackage.id] } },
  });
  const { allowed, numAllowed, numNotAllowed, uniqueNotAllowedMessages } = useMemo(() => {
    const [allowed, notAllowed] =
      tlisQuery.data?.takeoffLineItemsPage.items.partition((tli) => tli.canDuplicate.allowed) ?? [];
    return {
      allowed,
      numAllowed: allowed?.length ?? 0,
      numNotAllowed: notAllowed?.length ?? 0,
      uniqueNotAllowedMessages:
        notAllowed
          ?.flatMap((tli) => tli.canDuplicate.disabledReasons.map((reason) => [tli, reason.message] as const))
          .groupByObject(([, reason]) => reason) ?? [],
    };
  }, [tlisQuery.data]);

  const onDuplicate = async () => {
    const { data } = await copyFromPlan({
      targetReadyPlanId: planPackage.id ?? fail("Expected ready plan"),
      sourceItemIds: allowed?.map((tli) => tli.id),
    });
    triggerNotice({
      message: `${
        data?.copyTakeoffLineItems.copiedItems.length === 1
          ? "Item has"
          : `${data?.copyTakeoffLineItems.copiedItems.length} items have`
      } been duplicated`,
      icon: "success",
    });
  };

  return (
    <ConfirmationModal
      title="Duplicate Items"
      label="Duplicate"
      onConfirmAction={onDuplicate}
      disabled={tlisQuery.loading || numAllowed === 0}
      confirmationMessage={
        <>
          {numAllowed > 0 && <p>Are you sure you wish to duplicate {count(numAllowed, "item")}?</p>}
          {numNotAllowed > 0 && (
            <>
              <p>
                {count(numNotAllowed, "item")} cannot be duplicated for the following{" "}
                {pluralize(uniqueNotAllowedMessages.length, "reason")}:
              </p>
              <ul>
                {uniqueNotAllowedMessages.map(([message, itivs]) => (
                  <li key={message}>
                    [{itivs.length}] - {message}
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      }
    />
  );
}
