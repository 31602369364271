import { useApolloClient } from "@apollo/client";
import { currentAuthToken } from "@homebound/auth-components";
import {
  Button,
  Css,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  StaticField,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useState } from "react";
import { FileField } from "src/components";
import { baseDownloadUrl } from "src/context";
import { BidPackageDetailPageDocument } from "src/generated/graphql-types";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { openInSelf } from "src/utils/window";

type ImportPackagePricingModalProps = {
  bidContractId: string;
  bidPackageRequestId: string;
  bidPackageVersionId: string;
};

export function ImportPackagePricingModal({
  bidContractId,
  bidPackageRequestId,
  bidPackageVersionId,
}: ImportPackagePricingModalProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const client = useApolloClient();

  const formState = useFormState({
    config: formConfig,
  });
  const onDownloadTemplate = () => {
    const params = new URLSearchParams({
      type: "unitBasedBidPackage",
      bidPackageRequestId,
      bidPackageVersionId,
    });
    openInSelf(`${baseDownloadUrl()}/xlsx?${params.toString()}`);
  };

  async function onImport() {
    if (loading) return;
    setLoading(true);

    const response = await fetch(`${baseDownloadUrl()}/xlsx?type=bidContractUnitBased&bidContractId=${bidContractId}`, {
      method: "POST",
      headers: { Authorization: `Bearer ${await currentAuthToken()}` },
      body: formState.value.file,
    });

    if (response.status !== 200) {
      // Backend may have identified errors in the xlsx (Like unfilled Total Cost columns or poorly-formatted numbers)
      // so pop them into the Errors array here.
      const { message } = await response.json();
      if (message) setErrors([message]);
      setLoading(false);
      return;
    }

    // using Apollo Client directly instead of prop drilling refetch to update cache
    await client.refetchQueries({ include: [BidPackageDetailPageDocument] });
    triggerNotice({
      message: "Data imported successfully",
    });

    closeModal();
  }

  return (
    <>
      <ModalHeader>Import Pricing</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap3.$}>
          <div>
            Import items from a{" "}
            <span css={Css.blue600.cursorPointer.$} data-testid="xlsxTemplateLink" onClick={onDownloadTemplate}>
              .xlsx template
            </span>
          </div>

          <StaticField label="Upload Document">
            <div css={Css.df.fdc.w100.$}>
              <div css={Css.df.fdr.aic.gap1.mb1.$}>
                <Icon icon="error" inc={2} color={Palette.Red400} />
                <span css={Css.tiny.$}>New uploaded costs will override any existing cost entries</span>
              </div>

              <div css={Css.w100.mt("-24px").$}>
                <Observer>
                  {() => (
                    <FileField
                      label="Click or Drag to Upload"
                      allowedFileTypes={[
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.ms-excel",
                      ]}
                      file={formState.file.value}
                      setFile={(file) => {
                        formState.set({ file });
                        setErrors([]);
                      }}
                      errors={errors}
                    />
                  )}
                </Observer>
              </div>
            </div>
          </StaticField>
        </div>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
              <Button
                label="Import"
                disabled={loading || formState.errors.toString() || errors?.toString()}
                onClick={onImport}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValues = {
  file?: File | null;
};

const formConfig: ObjectConfig<FormValues> = {
  file: { type: "value", rules: [required] },
};
