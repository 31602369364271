import { Css } from "@homebound/beam";
import { ReactNode } from "react";
import {
  SaveScheduleTaskInput,
  ScheduleStatus,
  Stage,
  useStandaloneTaskDetailPaneQuery,
} from "src/generated/graphql-types";
import { ScheduleStoreProvider } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { TaskPaneState } from "src/routes/projects/schedule-v2/contexts/scheduleStoreReducer";
import { TaskDetailPane, TaskDetailPaneProps } from "src/routes/projects/schedule-v2/detail-pane/TaskDetailPane";
import { ScheduleViewType } from "src/routes/projects/schedule-v2/scheduleUtils";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { isProjectType } from "src/utils/projects";
import { queryResult } from "src/utils/queryResult";

type StandaloneTaskDetailPaneProps = Pick<TaskDetailPaneProps, "headerActions"> & {
  taskId: string;
  scheduleId: string;
  stage: Stage;
  onClose: VoidFunction;
  onSave: (input: SaveScheduleTaskInput) => Promise<void>;
  onSwitch?: (taskId: string, direction: "prev" | "next") => void;
  onJump?: (id: string) => void;
  disableQueryParams?: boolean;
  headerActions?: ReactNode;
  taskPaneState?: TaskPaneState;
};

/** A wrapped TaskDetailPane component intended to be used outside the main Schedule component */
export function StandaloneTaskDetailPane({
  taskId,
  scheduleId,
  stage,
  onClose,
  onSave,
  onSwitch,
  onJump,
  disableQueryParams = false,
  headerActions,
  taskPaneState,
}: StandaloneTaskDetailPaneProps) {
  const query = useStandaloneTaskDetailPaneQuery({ variables: { scheduleId, stage } });

  return queryResult(query, {
    data: ({ schedule, scheduleTasks }) => {
      const projectItems = isProjectType(schedule.parent) ? schedule.parent.stage.projectItems : [];
      return (
        <ScheduleStoreProvider
          scheduleType={ScheduleType.Project}
          initialStateOverride={{
            scheduleViewType: ScheduleViewType.StandaloneTaskDetailPane,
            ...(taskPaneState ? { taskPaneState } : {}),
          }}
          stage={stage}
          disableQueryParams={disableQueryParams}
        >
          <div data-testid="standaloneTaskDetailPane" css={Css.h100.oa.$}>
            <TaskDetailPane
              scheduleParentId={schedule.parent.id}
              taskId={taskId}
              tasks={scheduleTasks}
              projectItems={projectItems}
              scheduleSetting={schedule.scheduleSetting}
              scheduleIsLocked={schedule.status === ScheduleStatus.Completed}
              onJump={(id) => onJump && onJump(id)}
              onSwitch={onSwitch}
              onClose={onClose}
              onSave={(input) => {
                // Filtering out endDate as the backend does not support endDate
                // This was done on schedule mutation manager
                const { endDate, ...otherInputs } = input as any;
                return onSave(otherInputs);
              }}
              headerActions={headerActions}
            />
          </div>
        </ScheduleStoreProvider>
      );
    },
    // Since we're outside the main Schedule component, we must make an extra call to fetch task and schedule data above^
    // This means when switching between tasks, there's a longer period in which we show stale data to the user making
    // it seem like we didn't respond to the click
    showLoading: "always",
  });
}
