import {
  actionColumn,
  ButtonMenu,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridDataRow,
  GridTable,
  Icon,
  IconButton,
  LoadingSkeleton,
  MenuItem,
  numericColumn,
  Palette,
  RowStyles,
  ScrollableContent,
  selectColumn,
  SelectToggle,
  Tooltip,
  useModal,
  useToast,
} from "@homebound/beam";
import xor from "lodash/xor";
import { useCallback, useMemo, useRef } from "react";
import { emptyCellDash } from "src/components";
import { ArchivedTag } from "src/components/ArchivedTag";
import {
  AggregateVersionStatus,
  CostType,
  MaterialType,
  Order,
  PlanPackageTakeoffTable_GroupTotalsFragment,
  PlanPackageTakeoffTable_ItemsFragment,
  PlanPackageTakeoffTable_PreviousTlivFragment,
  usePlanPackageTakeoffTableQuery,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { AggregateMemberVersionChangeType } from "src/routes/libraries/design-catalog/design-package-configurator/components/DesignUpdatesAvailableButton";
import { AddItemsFromExistingPlanModal } from "src/routes/libraries/plan-package/takeoffs/components/AddItemsFromExistingPlanModal";
import { DeleteItemsConfirmationModal } from "src/routes/libraries/plan-package/takeoffs/components/DeleteItemsConfirmationModal";
import {
  TakeoffsStoreState,
  useTakeoffsManagerContext,
  useTakeoffsStore,
} from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { count } from "src/utils";
import { subFilterByOrder } from "src/utils/itemTemplateItem";
import { StoreApi } from "zustand";
import { AddEditLaborItemModal, AddEditMaterialItemModal, AddEditPlaceholderItemModal } from "../AddItems";
import { AddEditItemModalDataViewProps, AddItemModalProps } from "../AddItems/utils";
import { PlanPackageTakeoffTableEmpty } from "./PlanPackageTakeoffTableEmpty";
import { TruncatedNameListCell } from "./TruncatedNameListCell";

export function PlanPackageTakeoffTable() {
  const itemTableGroupBy = useTakeoffsStore((state) => state.itemTableGroupBy);
  const itemTableSortBy = useTakeoffsStore((state) => state.itemTableSortBy);
  const filter = useTakeoffsStore((state) => state.filter);
  const isEditable = useTakeoffsStore((state) => state.planPackage.canEditLineItems.allowed);
  const planPackageId = useTakeoffsStore((state) => state.planPackage.id);
  const version = useTakeoffsStore((state) => state.planPackage.version);
  // Get the takeoffs store to manage loading items with the correct selected state. Not using the `useTakeoffsStore` hook to avoid unnecessary reactivity.
  const takeoffsStore = useTakeoffsManagerContext();
  const { openModal } = useModal();

  const { data, loading, previousData, fetchMore } = usePlanPackageTakeoffTableQuery({
    variables: {
      versionId: version.id,
      filter: { readyPlan: [planPackageId], ...filter },
      first: 25,
      order: [
        { field: itemTableGroupBy, direction: Order.Asc },
        { field: itemTableSortBy, direction: Order.Asc },
      ],
    },
    fetchPolicy: "cache-and-network",
  });

  const onDuplicateItem = useCallback(
    (tli: PlanPackageTakeoffTable_ItemsFragment) => {
      const props = {
        takeoffsManagerStore: takeoffsStore,
        planPackageId,
        tliId: tli.id,
        asNew: true,
      } satisfies AddEditItemModalDataViewProps;

      openModal({
        content:
          tli.costType === CostType.Labor ? (
            <AddEditLaborItemModal {...props} />
          ) : tli.materialVariant?.listing.type.code === MaterialType.Placeholder ? (
            <AddEditPlaceholderItemModal {...props} />
          ) : (
            <AddEditMaterialItemModal {...props} />
          ),
      });
    },
    [openModal, planPackageId, takeoffsStore],
  );

  const onDeleteItem = useCallback(
    (tliId: string) => {
      openModal({
        content: <DeleteItemsConfirmationModal store={takeoffsStore} itemIdsToDelete={[tliId]} />,
      });
    },
    [openModal, takeoffsStore],
  );

  const addItemActions = useMemo(() => {
    return [
      {
        label: "Construction Material",
        onClick: () => {
          openModal({
            content: <AddEditMaterialItemModal takeoffsManagerStore={takeoffsStore} planPackageId={planPackageId} />,
          });
        },
      },
      {
        label: "Placeholder",
        onClick: () => {
          openModal({
            content: <AddEditPlaceholderItemModal takeoffsManagerStore={takeoffsStore} planPackageId={planPackageId} />,
          });
        },
      },
      {
        label: "Labor",
        onClick: () => {
          openModal({
            content: <AddEditLaborItemModal takeoffsManagerStore={takeoffsStore} planPackageId={planPackageId} />,
          });
        },
      },
    ];
  }, [planPackageId, openModal, takeoffsStore]);

  const onImportItems = useCallback(() => {
    const { saveTli, copyFromPlan } = takeoffsStore.getState();
    openModal({
      content: (
        <AddItemsFromExistingPlanModal planPackageId={planPackageId} copyFromPlan={copyFromPlan} saveTli={saveTli} />
      ),
    });
  }, [planPackageId, openModal, takeoffsStore]);

  const { showToast } = useToast();
  const failedGroupIds = useRef<string[]>([]);
  const fetchMoreGroupItems = useCallback(
    async (groupTotals: PlanPackageTakeoffTable_GroupTotalsFragment) => {
      if (failedGroupIds.current.includes(groupTotals.groupId)) return;
      try {
        await fetchMore({
          variables: {
            first: null,
            subFilter: subFilterByOrder(itemTableGroupBy, groupTotals.groupId),
          },
        });
      } catch (e) {
        console.error(e);
        failedGroupIds.current.push(groupTotals.groupId);
        showToast({ type: "error", message: `Failed to fetch items for group ${groupTotals.displayName}` });
      }
    },
    [fetchMore, showToast, itemTableGroupBy, failedGroupIds],
  );

  const { items = [], groupTotals = [] } = data?.takeoffLineItemsPage ?? previousData?.takeoffLineItemsPage ?? {};

  // Highlight changes only when viewing a draft or previous version
  const highlightChanges =
    version.status.code === AggregateVersionStatus.Draft || version.status.code === AggregateVersionStatus.Archived;

  const columns = useMemo(
    () =>
      createColumns(
        fetchMoreGroupItems,
        onDuplicateItem,
        onDeleteItem,
        addItemActions,
        onImportItems,
        isEditable,
        highlightChanges,
      ),
    [fetchMoreGroupItems, onDeleteItem, onDuplicateItem, addItemActions, onImportItems, isEditable, highlightChanges],
  );
  const rows = useMemo(() => createRows(items, groupTotals, takeoffsStore), [items, groupTotals, takeoffsStore]);
  const rowStyles: RowStyles<TakeoffLineItemRows> = useMemo(() => {
    return {
      header: { cellCss: Css.gray900.$ },
      item: {
        onClick: (row) => {
          // Do not allow editing of removed items
          if (row.data.changeType === AggregateMemberVersionChangeType.REMOVED) return;
          const props = {
            tliId: row.id,
            takeoffsManagerStore: takeoffsStore,
            planPackageId,
          } satisfies AddItemModalProps;
          openModal({
            content:
              row.data.costType === CostType.Labor ? (
                <AddEditLaborItemModal {...props} />
              ) : row.data.materialVariant?.listing.type.code === MaterialType.Placeholder ? (
                <AddEditPlaceholderItemModal {...props} />
              ) : (
                <AddEditMaterialItemModal {...props} />
              ),
          });
        },
      },
    };
  }, [openModal, takeoffsStore, planPackageId]);

  return (
    <ScrollableContent virtualized>
      {loading ? (
        <>
          <LoadingSkeleton rows={1} columns={1} />
          <LoadingSkeleton rows={5} columns={5} />
        </>
      ) : rows.length === 1 && Object.keys(filter).isEmpty ? (
        <PlanPackageTakeoffTableEmpty />
      ) : (
        <GridTable
          as="virtual"
          style={{ grouped: true, allWhite: true, bordered: true, rowHeight: "fixed" }}
          columns={columns}
          rows={rows}
          stickyHeader
          api={takeoffsStore.getState().itemTableApi}
          rowStyles={rowStyles}
          fallbackMessage="No items match the applied filters. Please adjust and try again"
        />
      )}
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type GroupByRow = { kind: "groupBy"; data: PlanPackageTakeoffTable_GroupTotalsFragment };
export type ItemRow = { kind: "item"; data: PlanPackageTakeoffTable_ItemsFragment; id: string };
// Add optionId on to handle the case of an ItemSlot appearing in a BaseOption groupBy row. Thus allowing the add actions to put created items into the correct options
type LoadingRow = { kind: "loading"; data: PlanPackageTakeoffTable_GroupTotalsFragment; id: string };
export type TakeoffLineItemRows = HeaderRow | GroupByRow | ItemRow | LoadingRow;

export enum Column {
  Code,
  ItemCode,
  Name,
  MaterialCode,
  GenericIcon,
  Quantity,
  UnitOfMeasure,
  Location,
  Option,
  TaskAllocation,
  Actions,
}

function createColumns(
  fetchMoreGroupItems: (groupTotals: PlanPackageTakeoffTable_GroupTotalsFragment) => Promise<void>,
  onDuplicate: (tli: PlanPackageTakeoffTable_ItemsFragment) => void,
  onDelete: (tliId: string) => void,
  addItemActions: MenuItem[],
  onImportItems: VoidFunction,
  isEditable: boolean,
  highlightChanges: boolean,
) {
  return [
    collapseColumn<TakeoffLineItemRows>({
      loading: emptyCell,
      sticky: "left",
      item: (row) => ({
        content: () => <></>,
        css: highlightChanges ? applyStyling(row) : undefined,
      }),
    }),
    selectColumn<TakeoffLineItemRows>({
      loading: emptyCell,
      sticky: "left",
      item: (row) => ({
        content: () => (
          <SelectToggle id={row.id} disabled={row.changeType === AggregateMemberVersionChangeType.REMOVED} />
        ),
        css: highlightChanges ? applyStyling(row) : undefined,
      }),
    }),
    column<TakeoffLineItemRows>({
      id: Column.Code.toString(),
      sticky: "left",
      header: "Code",
      groupBy: (row) => ({
        colspan: isEditable ? 7 : 6,
        content: () => (
          <span css={Css.xlSb.w100.$}>
            <span css={Css.dib.truncate.w50.$}>
              {row.displayName.length > 55 ? (
                <Tooltip
                  title={
                    <ul css={Css.pl2.$}>
                      {row.displayName.split(",").map((name) => (
                        <li key={name}>{name}</li>
                      ))}
                    </ul>
                  }
                >
                  {row.displayName}
                </Tooltip>
              ) : (
                row.displayName
              )}
            </span>
            <span css={Css.gray700.sm.ml3.$}>{count(row.materialsInGroup, "material")}</span>
            <span css={Css.gray700.sm.ml3.$}>{count(row.tasksInGroup, "task")}</span>
          </span>
        ),
        alignment: "left",
        css: Css.maxw("66.5vw").$,
      }),
      item: (row) => ({
        content: () => row.item.costCode.number,
        css: highlightChanges ? applyStyling(row) : undefined,
      }),
      loading: (row) => ({
        content: () => {
          fetchMoreGroupItems(row).catch(console.error);
          return (
            <>
              Loading...
              <Icon icon="refresh" />
            </>
          );
        },
      }),
      mw: "100px",
    }),
    column<TakeoffLineItemRows>({
      id: Column.ItemCode.toString(),
      sticky: "left",
      header: "Item code",
      groupBy: emptyCell,
      loading: emptyCell,
      item: (row) => ({
        content: () => (
          <Tooltip placement="top" title={row.item.name}>
            {row.item.fullCode}
          </Tooltip>
        ),
        css: highlightChanges ? applyStyling(row) : undefined,
      }),
      mw: "100px",
    }),
    column<TakeoffLineItemRows>({
      id: Column.Name.toString(),
      sticky: "left",
      header: "Name",
      groupBy: emptyCell,
      item: (row) => ({
        content: row.name,
        css: highlightChanges ? applyStyling(row, "materialVariant") : undefined,
        alignment: "left",
      }),
      loading: emptyCell,
      mw: "205px",
    }),
    column<TakeoffLineItemRows>({
      id: Column.MaterialCode.toString(),
      header: "Material Code",
      groupBy: emptyCell,
      item: (row) => {
        const attrs = row?.materialVariant?.materialAttributeValues;
        return {
          content: () => {
            if (!row.materialVariant) return emptyCellDash;
            return (
              <>
                {!attrs || attrs.isEmpty ? (
                  <Tooltip placement="top" title={row?.materialVariant?.code}>
                    <span css={Css.truncate.$}>{row?.materialVariant?.code}</span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="top"
                    title={attrs.map(({ textValue, dimension }) => (
                      <div key={textValue}>
                        {dimension.name}: {textValue} {dimension.unitOfMeasure?.abbreviation}
                      </div>
                    ))}
                  >
                    <span css={Css.truncate.$}>{row?.materialVariant?.code}</span>
                  </Tooltip>
                )}
              </>
            );
          },
          css: highlightChanges ? applyStyling(row, "materialVariant") : undefined,
          alignment: "left",
        };
      },
      loading: emptyCell,
      mw: "120px",
    }),
    column<TakeoffLineItemRows>({
      id: Column.GenericIcon.toString(),
      header: "",
      groupBy: emptyCell,
      item: (row) => ({
        content: () => (
          <>
            {row.materialVariant?.listing.type.code === MaterialType.Placeholder ? (
              <Tooltip title={`Product slot for ${row.item.designPackageLocation.name} Design Package`}>
                <Icon icon="link" inc={2} color={Palette.Gray600} />
              </Tooltip>
            ) : null}
          </>
        ),
        css: highlightChanges ? applyStyling(row) : undefined,
      }),
      loading: emptyCell,
      w: "40px",
    }),
    numericColumn<TakeoffLineItemRows>({
      id: Column.Quantity.toString(),
      header: "QTY",
      groupBy: () => emptyCell,
      item: (row) => ({
        content: row.unitOfMeasure?.useQuantity ? (row.quantity ?? "") : "N/A",
        css: highlightChanges ? applyStyling(row, "quantity") : undefined,
      }),
      loading: emptyCell,
      align: "right",
      mw: "80px",
    }),
    column<TakeoffLineItemRows>({
      id: Column.UnitOfMeasure.toString(),
      header: "UoM",
      groupBy: () => emptyCell,
      item: (row) => ({
        content: row.unitOfMeasure?.abbreviation,
        css: highlightChanges ? applyStyling(row, "unitOfMeasure") : undefined,
      }),
      loading: emptyCell,
      mw: "80px",
    }),
    column<TakeoffLineItemRows>({
      id: Column.Location.toString(),
      header: "Location",
      groupBy: () => emptyCell,
      item: (row) => ({
        content: row.location?.displayLocationPath,
        css: highlightChanges ? applyStyling(row, "location") : undefined,
      }),
      loading: emptyCell,
      mw: "140px",
    }),
    column<TakeoffLineItemRows>({
      id: Column.Option.toString(),
      header: "Option",
      groupBy: () => emptyCell,
      item: (row) => ({
        content: () => (
          <TruncatedNameListCell
            nameList={row.options.map((rpo) => (
              <ArchivedTag key={rpo.id} active={rpo.active}>
                {/* rowStyles is unable to access this nested element. Add line-through if row is REMOVED */}
                <div css={Css.if(row.changeType === AggregateMemberVersionChangeType.REMOVED).tdlt.$}>{rpo.code}</div>
              </ArchivedTag>
            ))}
          />
        ),
        css: highlightChanges ? applyStyling(row, "options") : undefined,
      }),
      loading: emptyCell,
      mw: "140px",
    }),
    column<TakeoffLineItemRows>({
      id: Column.TaskAllocation.toString(),
      header: "Task Allocation",
      groupBy: () => emptyCell,
      item: (row) => ({
        content: row.task?.name,
        css: highlightChanges ? applyStyling(row, "task") : undefined,
      }),
      loading: emptyCell,
      mw: "200px",
    }),
    ...(isEditable
      ? [
          actionColumn<TakeoffLineItemRows>({
            id: `${Column.Actions}`,
            header: () => emptyCell,
            groupBy: {
              content: () => (
                <div css={Css.df.gap1.$}>
                  <IconButton inc={3} icon="upload" onClick={() => onImportItems()} color={Palette.Blue600} />
                  <ButtonMenu trigger={{ icon: "plus", color: Palette.Blue600 }} items={addItemActions} />
                </div>
              ),
              sticky: "right",
            },
            loading: emptyCell,
            item: (row) => ({
              revealOnRowHover: true,
              content: () => (
                <>
                  {row.changeType !== AggregateMemberVersionChangeType.REMOVED ? (
                    <div css={Css.df.gap1.$}>
                      <IconButton
                        inc={2}
                        icon="duplicate"
                        onClick={() => onDuplicate(row)}
                        disabled={disableBasedOnPotentialOperation(row.canDuplicate)}
                      />
                      <IconButton inc={2} icon="trash" onClick={() => onDelete(row.id)} />
                    </div>
                  ) : null}
                </>
              ),
              css: highlightChanges ? applyStyling(row) : undefined,
            }),
            w: "80px",
          }),
        ]
      : []),
  ];
}

type Field = keyof Pick<
  PlanPackageTakeoffTable_PreviousTlivFragment,
  "options" | "quantity" | "unitOfMeasure" | "materialVariant" | "task" | "location"
>;

function fieldHasChanged(row: PlanPackageTakeoffTable_ItemsFragment, field?: Field): boolean {
  if (!row.previous || !field) return false;

  if (field === "quantity") {
    return row.previous.quantity !== row.quantity;
  }

  if (field === "options") {
    return optionsHaveChanged(row);
  }

  const previous = row.previous[field]?.id;
  const current = row[field]?.id;

  return previous !== current;
}

function applyStyling(row: PlanPackageTakeoffTable_ItemsFragment, field?: Field) {
  if (row.changeType === AggregateMemberVersionChangeType.ADDED) {
    return Css.bgYellow50.$;
  }
  if (row.changeType === AggregateMemberVersionChangeType.REMOVED) {
    return Css.bgGray50.tdlt.color(Palette.Gray500).$;
  }
  if (row.changeType === AggregateMemberVersionChangeType.UPDATED && fieldHasChanged(row, field)) {
    return Css.bgYellow50.$;
  }
}

function optionsHaveChanged(curr: PlanPackageTakeoffTable_ItemsFragment) {
  const prev = curr.previous;
  if (!prev || curr.changeType !== AggregateMemberVersionChangeType.UPDATED) return false;
  return xor(
    prev.options?.map((o) => o.id),
    curr.options?.map((o) => o.id),
  ).nonEmpty;
}

function createRows(
  items: PlanPackageTakeoffTable_ItemsFragment[],
  groupTotals: PlanPackageTakeoffTable_GroupTotalsFragment[],
  takeoffsStore: StoreApi<TakeoffsStoreState>,
): GridDataRow<TakeoffLineItemRows>[] {
  const selectedTliIds = takeoffsStore.getState().getSelectedTliIds();
  const tableApi = takeoffsStore.getState().itemTableApi;
  return [
    { kind: "header", id: "header", data: undefined },
    ...groupTotals
      .filter((gt) => gt.groupId !== "queryTotal")
      .map((gt) => {
        const itemsInGroup = items.filter((rpo) => gt.tliIdsInGroup.includes(rpo.id)) ?? [];

        const children: GridDataRow<TakeoffLineItemRows>[] = itemsInGroup.map((tli) => ({
          kind: "item" as const,
          id: tli.id,
          data: tli,
          initSelected: selectedTliIds.includes(tli.id),
          ...(tli.changeType === AggregateMemberVersionChangeType.REMOVED ? { selectable: false } : {}),
        }));

        const loadedItems = itemsInGroup.length;
        const initCollapsed = loadedItems === 0;
        const partiallyLoaded = loadedItems !== gt.tlisInGroup;
        if (partiallyLoaded) {
          // Make the loading row unselectable - so that it never appears in the selected but hidden collection
          children.push({ kind: "loading" as const, id: `loading-${gt.groupId}`, data: gt, selectable: false });

          // If we aren't loaded at all, then insert the not-loaded group row with inferSelectedState: false
          if (loadedItems === 0)
            return {
              kind: "groupBy" as const,
              initCollapsed,
              id: gt.groupId + "not-loaded",
              data: gt,
              children,
              // Set inferSelectedState to false so that we can still select the group, even though it has only unselectable loading children
              inferSelectedState: false as const,
            };
        }

        // Remove the group loading row if it exists to prevent a not-loaded row from appearing as a selected but hidden row
        if (tableApi) tableApi.deleteRows([gt.groupId + "not-loaded"]);

        return {
          kind: "groupBy" as const,
          id: gt.groupId,
          data: gt,
          children,
        };
      }),
  ];
}
