import {
  Chip,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  ModalProps,
  Palette,
  actionColumn,
  collapseColumn,
  column,
  dateColumn,
  emptyCell,
  useModal,
} from "@homebound/beam";
import { dateCell } from "src/components/gridTableCells";
import {
  BidPackageDetailRequestFragment,
  BidPackageStatus,
  PageBidPackageDetailFragment,
  useResendBidPackageRequestNotificationMutation,
  useSaveBidPackageRequestMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { isDefined } from "src/utils";
import { EditTradeContactsModal } from "./EditTradeContactsModal";

type BiddersTabProps = {
  bidPackage: PageBidPackageDetailFragment;
};

export function BiddersTab({ bidPackage }: BiddersTabProps) {
  const { openModal } = useModal();
  const [saveBidPackageRequest] = useSaveBidPackageRequestMutation();
  const [resendBidPackageRequestNotification] = useResendBidPackageRequestNotificationMutation();
  const rows: GridDataRow<Row>[] = createRows(bidPackage.requests);
  function onArchive(archive: boolean, request: BidPackageDetailRequestFragment) {
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage={
            <>
              Are you sure you want to {archive ? "archive" : "unarchive"} Trade <b>{request.tradePartner.name}</b>?{" "}
              {archive
                ? "You can unarchive them later if needed."
                : "They will be an active bidder on future versions."}
            </>
          }
          onConfirmAction={() => saveBidPackageRequest({ variables: { input: { id: request.id, archive } } })}
          title={archive ? "Archive Trade" : "Unarchive Trade"}
          label={archive ? "Archive" : "Unarchive"}
        />
      ),
    });
  }

  function onResend(request: BidPackageDetailRequestFragment) {
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage={
            <>
              Are you sure you want to resend an email to <b>{request.tradePartner.name}</b>? The email will include the
              existing bid's due and award dates.
            </>
          }
          onConfirmAction={() =>
            resendBidPackageRequestNotification({
              variables: {
                payload: {
                  bidPackageVersionId: bidPackage.latestVersion.id,
                  bidPackageRequestId: request.id,
                },
              },
            })
          }
          title="Resend Email to Trade"
          label="Resend"
        />
      ),
    });
  }
  return <GridTable columns={createColumns(bidPackage, onArchive, onResend, openModal)} rows={rows} />;
}
function createRows(requests: BidPackageDetailRequestFragment[]): GridDataRow<Row>[] {
  return [
    { kind: "header" as const, id: "header", data: undefined },
    ...requests.map((request) => {
      return {
        kind: "tradePartnerPrimary" as const,
        data: { request },
        id: request.id,
        children: request.secondaryContact
          ? [
              {
                kind: "tradePartnerSecondary" as const,
                id: request.secondaryContact.id,
                data: { request, secondary: request.secondaryContact },
              },
            ]
          : undefined,
      };
    }),
  ];
}

type HeaderRow = { kind: "header"; data: undefined };

type TradePartnerPrimaryRow = {
  kind: "tradePartnerPrimary";
  data: {
    request: BidPackageDetailRequestFragment;
  };
};

type TradePartnerSecondaryRow = {
  kind: "tradePartnerSecondary";
  data: {
    request: BidPackageDetailRequestFragment;
    secondary: NonNullable<BidPackageDetailRequestFragment["secondaryContact"]>;
  };
};

type Row = HeaderRow | TradePartnerPrimaryRow | TradePartnerSecondaryRow;
function createColumns(
  bidPackage: PageBidPackageDetailFragment,
  onArchive: (archive: boolean, request: BidPackageDetailRequestFragment) => void,
  onResend: (request: BidPackageDetailRequestFragment) => void,
  openModal: (props: ModalProps) => void,
): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({
      tradePartnerPrimary: (r) => ({
        ...emptyCell,
        css: applyStyling(r.request),
      }),
      tradePartnerSecondary: (tp) => ({
        ...emptyCell,
        css: applyStyling(tp.request),
      }),
    }),
    column<Row>({
      id: "companyName",
      header: "Company Name",
      tradePartnerPrimary: (r) => ({
        content: (
          <>
            <div css={Css.mr1.$}>{r.request.tradePartner.name}</div>
            {!isDefined(r.request.lastVersion) && !isDefined(r.request.archivedAt) && (
              <Chip type="caution" text="NEW" />
            )}
          </>
        ),
        css: applyStyling(r.request),
      }),
      tradePartnerSecondary: (r) => ({
        ...emptyCell,
        css: applyStyling(r.request),
      }),
    }),
    column<Row>({
      id: "contact",
      header: "Contact",
      tradePartnerPrimary: (r) => ({
        content: (
          <>
            {r.request.primaryContact?.name}{" "}
            <Chip
              text={<span css={Css.if(isDefined(r.request.archivedAt)).color(Palette.Gray500).$}>Primary</span>}
              type="neutral"
              xss={Css.ml1.$}
            />
          </>
        ),
        css: applyStyling(r.request),
      }),
      tradePartnerSecondary: (tp) => ({
        content: (
          <>
            {tp.secondary.name}{" "}
            <Chip
              text={<span css={Css.if(isDefined(tp.request.archivedAt)).color(Palette.Gray500).$}>Secondary</span>}
              type="neutral"
              xss={Css.ml1.$}
            />
          </>
        ),
        css: applyStyling(tp.request),
      }),
    }),
    column<Row>({
      id: "phone",
      header: "Phone",
      tradePartnerPrimary: (r) => ({
        content: r.request.primaryContact?.mobilePhone,
        css: applyStyling(r.request),
      }),
      tradePartnerSecondary: (tp) => ({
        content: tp.secondary.mobilePhone,
        css: applyStyling(tp.request),
      }),
    }),
    column<Row>({
      id: "email",
      header: "Email",
      tradePartnerPrimary: (r) => ({
        content: r.request.primaryContact?.email,
        css: applyStyling(r.request),
      }),
      tradePartnerSecondary: (tp) => ({
        content: tp.secondary.email,
        css: applyStyling(tp.request),
      }),
    }),
    dateColumn<Row>({
      w: "110px",
      id: "sent",
      header: "Invitation Sent",
      tradePartnerPrimary: (r) => ({
        ...dateCell(r.request.sentDate),
        css: applyStyling(r.request),
      }),
      tradePartnerSecondary: (r) => ({
        ...emptyCell,
        css: applyStyling(r.request),
      }),
    }),
    column<Row>({
      header: "Last Version",
      tradePartnerPrimary: (r) => ({
        content: r.request.lastVersion?.version ? `v${r.request.lastVersion.version}` : "",
        css: applyStyling(r.request),
      }),
      tradePartnerSecondary: (r) => ({
        ...emptyCell,
        css: applyStyling(r.request),
      }),
    }),
    actionColumn<Row>({
      header: emptyCell,
      tradePartnerPrimary: (r) => ({
        revealOnRowHover: true,
        content: (
          <div css={Css.df.gap1.$}>
            {r.request.archivedAt ? (
              <IconButton inc={2} icon="undoCircle" onClick={() => onArchive(false, r.request)} />
            ) : (
              <>
                <IconButton
                  inc={2}
                  icon="pencil"
                  onClick={() => openModal({ content: <EditTradeContactsModal bidRequest={r.request} /> })}
                />
                <IconButton
                  inc={2}
                  icon="email"
                  // We can only resend if the bid package is not in draft and the request is on the latest version
                  disabled={
                    // No due date means it's the first version of a historical bid package which we don't send emails for
                    !bidPackage.dueDate ||
                    bidPackage.status.code === BidPackageStatus.Draft ||
                    r.request.lastVersion?.version !== bidPackage.latestVersion.version
                  }
                  onClick={() => onResend(r.request)}
                />
                <IconButton inc={2} icon="archive" onClick={() => onArchive(true, r.request)} />
              </>
            )}
          </div>
        ),
        css: applyStyling(r.request),
      }),
      tradePartnerSecondary: (r) => ({
        ...emptyCell,
        css: applyStyling(r.request),
      }),
      w: "120px",
    }),
  ];
}

function applyStyling(row: BidPackageDetailRequestFragment) {
  if (isDefined(row.archivedAt)) {
    return Css.bgGray50.color(Palette.Gray500).$;
  }
  if (!isDefined(row.lastVersion)) {
    return Css.bgYellow50.$;
  }
}
