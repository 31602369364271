import {
  Button,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  RowStyles,
  collapseColumn,
  column,
  emptyCell,
  numericColumn,
  useModal,
  useRightPane,
} from "@homebound/beam";
import { Price, emptyCellDash, priceCell } from "src/components";
import {
  BidContractType,
  BidPackageBidItemFragment,
  BidPackageDetailActiveRequestFragment,
  BidPackageStatus,
  DisplayNamedFragment,
  PageBidPackageDetailFragment,
} from "src/generated/graphql-types";
import { fail, groupBy } from "src/utils";
import { TradeCostData, sumTradeCostData } from "./BidsTab.utils";
import { EditBidRightPane } from "./EditBidRightPane";
import { ImportBidModal } from "./ImportBidModal";

type UnitBasedBidsTableProps = {
  bidPackage: PageBidPackageDetailFragment;
  searchFilter?: string;
  requestsWithIncompleteBids: BidPackageDetailActiveRequestFragment[];
};

export function UnitBasedBidsTable({ bidPackage, searchFilter, requestsWithIncompleteBids }: UnitBasedBidsTableProps) {
  const { openModal } = useModal();
  const { openRightPane } = useRightPane();
  const isDraft = bidPackage.status.code === BidPackageStatus.Draft;

  function createBidFromXlsx() {
    openModal({
      content: (
        <ImportBidModal
          requests={requestsWithIncompleteBids}
          bidPackageName={bidPackage.name}
          latestBidContractRevisions={bidPackage.latestVersion.latestBidContractRevisions}
          bidPackageVersionId={bidPackage.latestVersion.id}
        />
      ),
    });
  }

  function onEditCost(bidItem: BidPackageBidItemFragment, tradePartnerId: string) {
    const bidPackageRequest = bidPackage.activeRequests.find((r) => r.tradePartner.id === tradePartnerId);
    const bidContractRevision = bidPackage.latestVersion.latestBidContractRevisions.find(
      (bcr) => bcr.bidContract.tradePartner?.id === tradePartnerId,
    );
    const bidContractLineItem = bidContractRevision?.lineItems.find((li) => li.bidItem?.id === bidItem.id);
    if (!bidItem || !bidPackageRequest) {
      fail("Unable to find item or bid info");
    }
    openRightPane({
      content: (
        <EditBidRightPane
          bidContractLineItem={bidContractLineItem}
          bidItem={bidItem}
          revisionId={bidContractRevision?.id}
          trade={bidPackageRequest.tradePartner}
          newBidContractParams={
            bidPackageRequest.bidContract
              ? undefined
              : {
                  bidRequestId: bidPackageRequest.id,
                  type: BidContractType.UnitBased,
                }
          }
          newBidContractRevisionParams={
            bidPackageRequest.bidContract
              ? { bidContractId: bidPackageRequest.bidContract.id, bidPackageVersionId: bidPackage.latestVersion.id }
              : undefined
          }
        />
      ),
    });
  }

  const rows: GridDataRow<Row>[] = createRows(bidPackage);
  const columns: GridColumn<Row>[] = createColumns(
    bidPackage.activeRequests,
    createBidFromXlsx,
    requestsWithIncompleteBids,
    onEditCost,
    isDraft,
  );

  return <GridTable columns={columns} filter={searchFilter} rows={rows} rowStyles={rowStyles} />;
}

const showIconButtonClass = "revealIconOnRowHover";

const cellBorderStyles = {
  // add border to split trade partner columns
  ...Css.addIn("& > div:nth-of-type(n + 5)", Css.bl.bcGray200.$).$,
  // Allow for hover showing the edit price button
  ...{
    [`.${showIconButtonClass} > *`]: Css.vh.$,
    [`:hover .${showIconButtonClass} > *`]: Css.vv.$,
  },
};

const rowStyles: RowStyles<Row> = {
  total: {
    cellCss: Css.py3.$,
  },
  head: { rowCss: cellBorderStyles },
  costCode: { rowCss: cellBorderStyles },
  item: { rowCss: cellBorderStyles },
  bidItem: { rowCss: cellBorderStyles },
};

function createRows(bidPackage: PageBidPackageDetailFragment): GridDataRow<Row>[] {
  const requests = bidPackage.activeRequests;
  const tradeIds = requests.map((r) => r.tradePartner.id);
  const previousLineItems = bidPackage.latestVersion?.previous?.lineItems ?? [];
  const bidItemRows = bidPackage.lineItems.map(({ bidItem }) => ({
    kind: "bidItem" as const,
    id: bidItem.id,
    data: {
      bidItem,
      // For each bid item line, each trade could have bid at most once on it
      costData: requests.keyBy(
        (bpr) => bpr.tradePartner.id,
        (bpr) => {
          const bidContractRevision = bidPackage.latestVersion.latestBidContractRevisions.find(
            (bcr) => bcr.bidContract.tradePartner?.id === bpr.tradePartner.id,
          );
          // find the BCLI for this bidItem
          const bcli = bidContractRevision?.lineItems.find((li) => li.bidItem?.id === bidItem.id);
          // this is the unit cost on the BCLI
          return bcli?.totalCostInCents ?? 0;
        },
      ),
      isNew: !previousLineItems.some((li) => li.bidItem?.id === bidItem.id),
      isRemoved: false,
    },
  }));

  // If the bid package is in draft status, we want to show removed items as well
  if (bidPackage.status.code === BidPackageStatus.Draft) {
    previousLineItems
      .filter((li) => !bidItemRows.some((bir) => bir.id === li.bidItem?.id))
      .forEach(({ bidItem }) => {
        bidItemRows.push({
          kind: "bidItem" as const,
          id: bidItem.id,
          data: {
            bidItem,
            costData: requests.keyBy(
              (bpr) => bpr.tradePartner.id,
              (bpr) => {
                const bidContractRevision = bidPackage.latestVersion.latestBidContractRevisions.find(
                  (bcr) => bcr.bidContract.tradePartner?.id === bpr.tradePartner.id,
                );
                // find the BCLI for this bidItem
                const bcli = bidContractRevision?.lineItems.find((li) => li.bidItem?.id === li.bidItem?.id);
                // this is the unit cost on the BCLI
                return bcli?.totalCostInCents ?? 0;
              },
            ),
            isNew: false,
            isRemoved: true,
          },
        });
      });
  }

  const itemGroupBy = groupBy(bidItemRows, (i) => i.data.bidItem.items[0].id);
  const itemRows = Object.keys(itemGroupBy).map((key) => {
    const children = itemGroupBy[key];
    const item = children[0].data.bidItem.items[0];
    return {
      kind: "item" as const,
      id: key,
      data: {
        item: item,
        costData: children.map((c) => c.data.costData).reduce(sumTradeCostData.bind(null, tradeIds), {}),
      },
      children,
    };
  });

  const costCodeGroupBy = groupBy(itemRows, (o) => o.children[0].data.bidItem.items[0].costCode.id);
  const costCodeRows = Object.keys(costCodeGroupBy).map((key, i) => {
    const children = costCodeGroupBy[key];
    return {
      kind: "costCode" as const,
      id: key,
      data: {
        costCode: children[0].data.item.costCode,
        costData: children.map((c) => c.data.costData).reduce(sumTradeCostData.bind(null, tradeIds), {}),
      },
      children: children,
    };
  });

  /*
    Not using the header/totals row type because it makes assumptions about placement, which we don't want to make.
    Using pin to prevent the total and head rows from being hidden when searching
  */
  return [
    {
      kind: "total" as const,
      id: "total",
      pin: "first",
      data: {},
    },
    { kind: "head" as const, id: "head", data: undefined, pin: "last" },
    ...costCodeRows,
  ];
}

type TotalRow = {
  kind: "total";
};
type HeaderRow = { kind: "head"; data: undefined };
type CostCodeRow = { kind: "costCode"; data: { costCode: DisplayNamedFragment; costData: TradeCostData } };
type ItemRow = { kind: "item"; data: { item: DisplayNamedFragment; costData: TradeCostData } };
type BidItemRow = {
  kind: "bidItem";
  data: { bidItem: BidPackageBidItemFragment; costData: TradeCostData; isNew: boolean; isRemoved: boolean };
};

type Row = HeaderRow | TotalRow | CostCodeRow | ItemRow | BidItemRow;

function createColumns(
  requests: BidPackageDetailActiveRequestFragment[],
  createBidFromXlsx: () => void,
  requestsWithIncompleteBids: BidPackageDetailActiveRequestFragment[],
  onEditCost: (bidItem: BidPackageBidItemFragment, tradePartnerId: string) => void,
  isDraft: boolean,
): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({
      total: () => ({
        content: (
          <div css={Css.df.w100.aic.gap2.$}>
            <div css={Css.xlSb.$}>Bid Total</div>
            {!isDraft && (
              <div css={Css.mla.$}>
                <Button
                  variant="text"
                  label="Add Bid"
                  onClick={createBidFromXlsx}
                  disabled={requestsWithIncompleteBids.length === 0 && "All bids have full scope"}
                />
              </div>
            )}
          </div>
        ),
        colspan: 6 + requests.length,
      }),
      bidItem: (data) => ({
        content: emptyCell.content,
        css: highlightChanges(isDraft, data),
      }),
    }),
    column<Row>({
      total: emptyCell,
      head: "Name",
      costCode: ({ costCode }) => ({
        content: costCode.displayName,
        css: Css.smSb.$,
      }),
      item: ({ item }) => item.displayName,
      bidItem: ({ bidItem, ...data }) => ({
        content: bidItem.parentMaterialVariant?.displayName ?? bidItem.parentTask?.name ?? bidItem.displayName,
        css: highlightChanges(isDraft, data),
      }),
    }),
    column<Row>({
      total: emptyCell,
      head: "Material Code",
      costCode: emptyCell,
      item: emptyCell,
      bidItem: ({ bidItem, ...data }) => ({
        content: bidItem?.parentMaterialVariant?.code,
        css: highlightChanges(isDraft, data),
      }),
      w: "120px",
    }),
    column<Row>({
      total: emptyCell,
      head: "UoM",
      costCode: emptyCell,
      item: emptyCell,
      bidItem: ({ bidItem, ...data }) => ({
        content: bidItem.unitOfMeasure.shortName,
        css: highlightChanges(isDraft, data),
      }),
      w: "67px",
    }),
    ...requests.map((request) => {
      return numericColumn<Row>({
        total: emptyCell,
        head: () => (
          <div css={Css.w100.df.aic.$}>
            <div css={Css.mra.$}>{request.tradePartner.name}</div>
          </div>
        ),
        costCode: ({ costData }) =>
          costData[request.tradePartner.id] ? (
            <div css={Css.sm.$}>
              <Price valueInCents={costData[request.tradePartner.id]} />
            </div>
          ) : (
            emptyCell
          ),
        item: ({ costData }) =>
          costData[request.tradePartner.id]
            ? priceCell({ valueInCents: costData[request.tradePartner.id] })
            : emptyCell,
        bidItem: ({ costData }, { row }) => ({
          content: (
            <>
              {!isDraft && (
                <div css={Css.mra.$} className={showIconButtonClass}>
                  <IconButton
                    inc={2}
                    icon="pencil"
                    onClick={() => onEditCost(row.data.bidItem, request.tradePartner.id)}
                  />
                </div>
              )}
              {costData[request.tradePartner.id] ? (
                <Price valueInCents={costData[request.tradePartner.id]} />
              ) : (
                emptyCellDash
              )}
            </>
          ),
          css: highlightChanges(isDraft, row.data),
        }),
      });
    }),
  ];
}

function highlightChanges(isDraft: boolean, { isNew, isRemoved }: { isNew: boolean; isRemoved: boolean }) {
  if (isDraft) {
    if (isNew) {
      return Css.bgYellow50.$;
    }
    if (isRemoved) {
      return Css.bgGray50.tdlt.gray500.$;
    }
  }
  return undefined;
}
