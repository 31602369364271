import {
  Button,
  Css,
  Icon,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  RadioGroupField,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { useState } from "react";
import { FileUploadProgress, UppyUploader } from "src/components";
import { UploadOptions } from "src/components/bid-contracts/BidContractHeader";
import { UploaderProvider } from "src/contexts/UploaderContext";
import { DocumentType, SaveDocumentInput, useSavePriceAgreementDocumentMutation } from "src/generated/graphql-types";
import { useDevContractWizard } from "./upload/UploadDevelopmentContractWizard";

type UploadPriceAgreementModalProps = {
  parentId: string;
  ownerId: string;
  documentType: DocumentType;
};

export function UploadPriceAgreementModal({ parentId, ownerId, documentType }: UploadPriceAgreementModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [uploadedFile, setUploadedFile] = useState<UppyFile | undefined>();
  const [mode, setMode] = useState<UploadOptions>("budgets");
  const [saveDocuments] = useSavePriceAgreementDocumentMutation();

  const bidContractRevisionId = documentType === DocumentType.PriceAgreementBidContractRevision ? ownerId : "";
  const openWizard = useDevContractWizard(bidContractRevisionId, mode);

  async function handleSaveDocument(file: UppyFile, ownerId: string) {
    const saveDocumentInput: SaveDocumentInput = {
      documentType,
      name: file.name,
      sizeInBytes: file.size,
      parentId,
      ownerId,
      asset: {
        contentType: file.type,
        fileName: file.name,
        s3Key: file.meta.s3Key as string,
        sizeInBytes: file.size,
      },
    };

    // Create an entry in the Document table
    await saveDocuments({ variables: { input: saveDocumentInput } });

    triggerNotice({
      icon: "success",
      message:
        "Document successfully uploaded! The contract PDF will be generated and attached to the file in a few minutes.",
      persistent: true,
    });

    closeModal();

    /**
     * We check for BCR ID as there are 2 other components that share this Upload Price Agreement Modal
     * i.e. DevelomentCommitmentPage, CommitmentChangeOrderPage
     */
    if (bidContractRevisionId && mode === "budgets") await openWizard();
  }

  return (
    <>
      <ModalHeader>Upload price agreement</ModalHeader>
      <ModalBody>
        <div css={Css.mb2.$}>Attach the document that outlines all pricing agreements with this trade partner.</div>
        {!uploadedFile ? (
          <UploaderProvider>
            <UppyUploader
              allowedFileTypes={["application/pdf"]}
              onFinish={(file) => setUploadedFile(file)}
              dragDropText={"Drag & drop file"}
              dragDropWidth={430}
              maxNumberOfFiles={1}
            />
            <FileUploadProgress hideOnComplete />
          </UploaderProvider>
        ) : (
          <div css={Css.ba.bsDashed.br4.pPx(4).$}>
            <div css={Css.bgGray100.smMd.gray700.df.p1.aic.jcsb.$}>
              <div css={Css.df.aic.$}>
                <Icon icon="document" />
                <div css={Css.ml1.$}>{uploadedFile.name}</div>
              </div>
              <div>
                <IconButton compact icon="x" onClick={() => setUploadedFile(undefined)} />
              </div>
            </div>
          </div>
        )}

        {bidContractRevisionId && <UploadAndManageBudgetOptions mode={mode} setMode={setMode} />}
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label="Upload" disabled={!uploadedFile} onClick={() => handleSaveDocument(uploadedFile!, ownerId)} />
      </ModalFooter>
    </>
  );
}

type UploadAndManageBudgetOptionsProps = {
  mode: UploadOptions;
  setMode: React.Dispatch<React.SetStateAction<UploadOptions>>;
};

function UploadAndManageBudgetOptions({ mode, setMode }: UploadAndManageBudgetOptionsProps) {
  return (
    <div data-testid="uploadAndManageBudgets">
      <div css={Css.smBd.pt3.pb1.$}>How do you want this contract to impact budgets?</div>
      <RadioGroupField<UploadOptions>
        label="next step selection"
        labelStyle="hidden"
        options={[
          { label: "Upload and manage budgets", value: "budgets" },
          { label: "Upload only (no budget updates)", value: "uploadOnly" },
        ]}
        value={mode}
        onChange={setMode}
      />
      <div css={Css.xs.pt2.$}>
        <div css={Css.mbPx(-8).$}>Note:</div>
        <ul css={Css.add({ paddingInlineStart: "18px" }).$}>
          <li>You can manage budgets and POs later from the contract actions menu.</li>
          <li>
            All voided and new POs will go out after this contract version is signed and the budget updates are
            approved.
          </li>
        </ul>
      </div>
    </div>
  );
}
