import { Button, Chips, Css, useTestIds } from "@homebound/beam";
import {
  BidContractRevisionFragment,
  ProjectBidContractOverviewPage_RevisionFragment,
} from "src/generated/graphql-types";
import { createBidPackagesDetailUrl } from "src/RouteUrls";
import { centsToDollars, formatNumberToString } from "src/utils";

type ContractOverviewProps = {
  selectedRevision: BidContractRevisionFragment | ProjectBidContractOverviewPage_RevisionFragment;
};

type TemplateTotalCost = Record<string, number>;

export function BidContractOverview({ selectedRevision }: ContractOverviewProps) {
  const { bidRequest } = selectedRevision.bidContract;
  const costCodes = selectedRevision.costCodes.map(({ number }) => number) || [];
  const groupedItems = selectedRevision.lineItems.groupBy((li) =>
    li.ofIti ? li.itemTemplateItem!.parent.id : li.bidItem!.id,
  );
  const templateTotalCosts = Object.values(groupedItems).reduce((totals, bclis) => {
    // Since the templates are grouped by name use the first item to get template name
    // Name from ItemTemplateItem
    if (bclis[0]!.ofIti) {
      totals[bclis[0].itemTemplateItem!.parent.displayName] = bclis.sum(
        ({ totalCostInCents }) => totalCostInCents || 0,
      );
    } else {
      const bi = bclis[0]!.bidItem;
      totals[bi!.name] = bclis.sum(({ totalCostInCents }) => totalCostInCents || 0);
    }
    return totals;
  }, {} as TemplateTotalCost);

  const tid = useTestIds({});

  return (
    <>
      <div {...tid.contractScopeCard} css={Css.wPx(440).ba.bcGray300.br16.pxPx(17).ptPx(16).pbPx(30).mr2.$}>
        <div css={Css.df.jcsb.$}>
          <div css={Css.baseMd.mbPx(11).$}>Contract Scope</div>
          {bidRequest && (
            <Button
              variant="tertiary"
              label="Bid Package"
              onClick={createBidPackagesDetailUrl(bidRequest.bidPackage.bidPackageGroup!.id, bidRequest.bidPackage.id)}
            />
          )}
        </div>
        <div css={Css.tinySb.$}>Cost Codes</div>
        <div css={Css.mbPx(26).mtPx(10).$}>
          <Chips values={costCodes} />
        </div>
        <div {...tid.templateListHeader} css={Css.mbPx(16).df.fdr.jcsb.w("100%").tinySb.gray700.$}>
          <div>Templates ({Object.keys(groupedItems).length})</div>
          <div>Total Committed</div>
        </div>
        <div {...tid.templateList} css={Css.df.fdc.$}>
          {Object.entries(templateTotalCosts).map(([templateName, totalCost], i, templates) => (
            <div
              key={templateName}
              data-testid="templateRow"
              css={
                Css.df.fdr.jcsb
                  .pyPx(8)
                  .mhPx(32)
                  .if(i + 1 !== templates.length).bb.bw1.bcGray300.$
              }
            >
              <div {...tid.templateName} css={Css.xs.$}>
                {templateName}
              </div>
              <div {...tid.totalCostInCents} css={Css.xs.$}>{` $${formatNumberToString(
                centsToDollars(totalCost),
              )}`}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
