import { useApolloClient } from "@apollo/client";
import { currentAuthToken } from "@homebound/auth-components";
import {
  BoundSelectField,
  Button,
  Css,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  StaticField,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useState } from "react";
import { FileField } from "src/components";
import { baseDownloadUrl } from "src/context";
import {
  BidContractType,
  BidPackageDetailActiveRequestFragment,
  BidPackageDetailPage_BidContractRevisionFragment,
  BidPackageDetailPageDocument,
  useCreateBidContractMutation,
  useSaveBidContractRevisionMutation,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { openInSelf } from "src/utils/window";

type ImportBidModalProps = {
  requests: BidPackageDetailActiveRequestFragment[];
  bidPackageName: string;
  latestBidContractRevisions: BidPackageDetailPage_BidContractRevisionFragment[];
  bidPackageVersionId: string;
};

export function ImportBidModal({
  bidPackageName,
  requests,
  latestBidContractRevisions,
  bidPackageVersionId,
}: ImportBidModalProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const client = useApolloClient();
  const [createBidContract] = useCreateBidContractMutation();
  const [createBidContractRevision] = useSaveBidContractRevisionMutation();

  const tradePartners = requests.map((request) => request.tradePartner);
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { tradePartner: tradePartners[0].id },
    },
  });
  const onDownloadTemplate = () => {
    const params = new URLSearchParams({
      type: "unitBasedBidPackage",
      bidPackageRequestId: requests.find((r) => r.tradePartner.id === formState.tradePartner.value)?.id ?? "",
    });
    openInSelf(`${baseDownloadUrl()}/xlsx?${params.toString()}`);
  };

  async function onImport() {
    if (loading) return;
    setLoading(true);

    let bidContractId = requests.find((r) => r.tradePartner.id === formState.tradePartner.value)?.bidContract?.id;
    const bidContractRevisionId = latestBidContractRevisions.find(
      (bcr) => bcr.bidContract.tradePartner?.id === formState.tradePartner.value,
    )?.id;

    if (!bidContractId) {
      const { data, errors } = await createBidContract({
        variables: {
          input: {
            tradePartnerId: formState.value.tradePartner,
            bidRequestId: requests.find((r) => r.tradePartner.id === formState.value.tradePartner)?.id,
            startDate: new DateOnly(new Date()),
            type: BidContractType.UnitBased,
          },
        },
        errorPolicy: "ignore",
      });

      bidContractId = data?.saveBidContract.bidContract.id;

      if (!bidContractId) {
        triggerNotice({
          message: errors?.join(",")?.toString() ?? "Bid Contract Creation failed",
        });
        setLoading(false);
        return;
      }
    } else if (!bidContractRevisionId) {
      const { errors } = await createBidContractRevision({
        variables: {
          input: {
            bidContractId,
            bidPackageVersionId,
            startDate: new DateOnly(new Date()),
          },
        },
        errorPolicy: "ignore",
      });

      if (errors) {
        triggerNotice({
          message: errors?.join(",")?.toString() ?? "Bid Contract Creation failed",
        });
        setLoading(false);
        return;
      }
    }

    const response = await fetch(`${baseDownloadUrl()}/xlsx?type=bidContractUnitBased&bidContractId=${bidContractId}`, {
      method: "POST",
      headers: { Authorization: `Bearer ${await currentAuthToken()}` },
      body: formState.value.file,
    });

    if (response.status !== 200) {
      // Backend may have identified errors in the xlsx (Like unfilled Total Cost columns or poorly-formatted numbers)
      // so pop them into the Errors array here.
      const { message } = await response.json();
      if (message) setErrors([message]);
      setLoading(false);
      return;
    }

    // using Apollo Client directly instead of prop drilling refetch to update cache
    await client.refetchQueries({ include: [BidPackageDetailPageDocument] });
    triggerNotice({
      message: "Data imported successfully",
    });

    closeModal();
  }

  return (
    <>
      <ModalHeader>Import Items: {bidPackageName}</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap3.$}>
          <div>
            Import items from a{" "}
            <span css={Css.blue600.cursorPointer.$} data-testid="xlsxTemplateLink" onClick={onDownloadTemplate}>
              .xlsx template
            </span>
          </div>
          <BoundSelectField field={formState.tradePartner} label="Select Trade:" options={tradePartners} />

          <StaticField label="Upload Document">
            <div css={Css.df.fdc.$}>
              <div css={Css.df.fdr.aic.gap1.mb1.$}>
                <Icon icon="error" inc={2} color={Palette.Red400} />
                <span css={Css.tiny.$}>
                  New uploaded costs will override any existing cost entries for the selected trade
                </span>
              </div>

              <div css={Css.w100.mt("-24px").$}>
                <Observer>
                  {() => (
                    <FileField
                      label="Click or Drag to Upload"
                      allowedFileTypes={[
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.ms-excel",
                      ]}
                      file={formState.file.value}
                      setFile={(file) => {
                        formState.set({ file });
                        setErrors([]);
                      }}
                      errors={errors}
                    />
                  )}
                </Observer>
              </div>
            </div>
          </StaticField>
        </div>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
              <Button
                label="Import"
                disabled={loading || formState.errors.toString() || errors?.toString()}
                onClick={onImport}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValues = {
  tradePartner?: string | null;
  file?: File | null;
};

const formConfig: ObjectConfig<FormValues> = {
  tradePartner: { type: "value", rules: [required] },
  file: { type: "value", rules: [required] },
};
