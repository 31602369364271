import { Banner, TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { useParams } from "react-router";
import { PageBidPackageDetailFragment, useBidPackageDetailPageQuery } from "src/generated/graphql-types";
import { BillTermsTabView } from "src/routes/bids/components/BillTermsTab";
import { queryResult } from "src/utils";
import { BidPackageParams } from "../routesDef";
import { BidPackageHeader } from "./components/BidPackageHeader";
import { BiddersTab } from "./components/BiddersTab";
import { BidsTab } from "./components/BidsTab";

export function BidPackageDetailPage() {
  const { bidPackageId } = useParams<BidPackageParams>();
  const query = useBidPackageDetailPageQuery({ variables: { id: bidPackageId } });
  return queryResult(query, ({ bidPackage }) => <BidPackageDetailView bidPackage={bidPackage} />);
}

type TabValue = "bids" | "bidders" | "billTerms";

export function BidPackageDetailView({ bidPackage }: { bidPackage: PageBidPackageDetailFragment }) {
  const [selectedTab, setSelectedTab] = useState<TabValue>("bids");
  const tradePartnerRevisions = bidPackage.activeRequests.map((request) => ({
    tradePartner: request.tradePartner,
    revision: bidPackage.latestVersion.latestBidContractRevisions.find(
      (bcr) => bcr.bidContract.tradePartner?.id === request.tradePartner.id,
    ),
  }));
  const tradesWithInvalidDrawTasks = tradePartnerRevisions
    .filter((tpr) => tpr.revision?.hasValidDrawTasks === false)
    .map((tpr) => tpr.tradePartner.name);
  const [showDrawTaskError, setShowDrawTaskError] = useState(tradesWithInvalidDrawTasks.nonEmpty);

  return (
    <>
      <BidPackageHeader bidPackage={bidPackage} />
      {showDrawTaskError && (
        <Banner
          type="error"
          message={`One or more bill terms for ${tradesWithInvalidDrawTasks.join(", ")} has a labor task not included on the version. Amend the bill terms to award.`}
          onClose={() => setShowDrawTaskError(false)}
        />
      )}
      <TabsWithContent
        tabs={[
          { name: "Bids", value: "bids", render: () => <BidsTab bidPackage={bidPackage} /> },
          { name: "Bidders", value: "bidders", render: () => <BiddersTab bidPackage={bidPackage} /> },
          {
            name: "Bill Terms",
            value: "billTerms",
            render: () => (
              <BillTermsTabView
                bidPackageId={bidPackage.id}
                entityWithMarketId={bidPackage.developments.first?.id || bidPackage.projects.first?.id!}
                tradePartnerRevisions={tradePartnerRevisions}
              />
            ),
          },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </>
  );
}
