import { Icon, Palette, RouteTabWithContent, TabsWithContent } from "@homebound/beam";
import {
  createProjectBidContractOverviewUrl,
  createProjectBidContractPlanPricingUrl,
  createProjectBidContractPurchaseOrdersUrl,
  createProjectBidContractUnitPricingUrl,
} from "src/RouteUrls";
import { BidContractProrationPage } from "src/components/bid-contracts/BidContractProrationPage";
import { BidContractPurchaseOrdersPage } from "src/components/bid-contracts/BidContractPurchaseOrdersPage";
import { BidContractUnitPricingPage } from "src/components/bid-contracts/BidContractUnitPricingPage";
import { BidContractRevisionFragment, BidContractType } from "src/generated/graphql-types";
import { useTabParam } from "src/hooks/useTabParam";
import { projectPaths } from "src/routes/routesDef";
import { ProjectBidContractOverviewPage } from "./ProjectBidContractOverviewPage";

type ContractTabsProp = {
  selectedRevision: BidContractRevisionFragment;
};

export function ProjectBidContractTabs({ selectedRevision }: ContractTabsProp) {
  const [selectedTab, setSelectedTab] = useTabParam("Overview");
  const showPlanPricing =
    !selectedRevision.bidContract.type || selectedRevision.bidContract.type.code !== BidContractType.UnitBased;
  const showUnitPricing =
    !selectedRevision.bidContract.type || selectedRevision.bidContract.type.code === BidContractType.UnitBased;
  const tabs: RouteTabWithContent[] = [
    {
      href: createProjectBidContractOverviewUrl(selectedRevision.bidContract.projects[0].id, selectedRevision.id),
      path: projectPaths.bidContractOverview,
      name: "Overview",
      render: () => <ProjectBidContractOverviewPage />,
    },
    ...(showPlanPricing
      ? [
          {
            href: createProjectBidContractPlanPricingUrl(
              selectedRevision.bidContract.projects[0].id,
              selectedRevision.id,
            ),
            path: projectPaths.bidContractPlanPricing,
            name: "Plan-based Pricing",
            ...(selectedRevision.hasOutdatedScope
              ? { endAdornment: <Icon icon="errorCircle" inc={2} color={Palette.Gray900} /> }
              : {}),
            render: () => <BidContractProrationPage />,
          },
        ]
      : []),
    ...(showUnitPricing
      ? [
          {
            href: createProjectBidContractUnitPricingUrl(
              selectedRevision.bidContract.projects[0].id,
              selectedRevision.id,
            ),
            path: projectPaths.bidContractUnitPricing,
            name: "Unit Pricing",
            render: () => <BidContractUnitPricingPage />,
          },
        ]
      : []),
    ...(!selectedRevision.bidContract.isInternalEstimate
      ? [
          {
            href: createProjectBidContractPurchaseOrdersUrl(
              selectedRevision.bidContract.projects[0].id,
              selectedRevision.id,
            ),
            path: projectPaths.bidContractPurchaseOrders,
            name: "Purchase Orders",
            render: () => <BidContractPurchaseOrdersPage />,
          },
        ]
      : []),
  ];

  return <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setSelectedTab} />;
}
