import {
  Button,
  Css,
  HbLoadingSpinner,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { useCallback } from "react";
import {
  DesignPackageConfiguratorDocument,
  DesignPackageItemSlotEditorDocument,
  DesignPackageSlotsDocument,
  useDeleteAltenratesConfirmationModalQuery,
  useDeleteDpAlternateRplMutation,
} from "src/generated/graphql-types";
import { fail } from "src/utils";
import { useDesignPackageConfiguratorContext } from "../DesignPackageConfiguratorContext";

type Props = { locationId: string; dpId: string };

function DesignPackageDeleteAlternatesConfirmationModal({ dpId, locationId }: Props) {
  // LeftNav is built on Slots and Slots & Locations have no direct ref to the RPL. We can query for the RPLs
  // by the locationId and get all the related alternates
  const { data, loading } = useDeleteAltenratesConfirmationModalQuery({
    variables: { designPackageId: dpId, locationId },
  });
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const rpls = data?.designPackage.readyPlanLocations ?? [];
  const [primaryRpl, ...altRpls] = rpls;
  const rplBeingDeleted = rpls.find((rpl) => rpl.location.id === locationId);
  const finalRpl = altRpls.last;
  const isDeletingFinal = rplBeingDeleted === finalRpl;

  const [mutate] = useDeleteDpAlternateRplMutation({
    variables: { altRplId: rplBeingDeleted?.id! },
    refetchQueries: [
      DesignPackageSlotsDocument,
      DesignPackageConfiguratorDocument,
      DesignPackageItemSlotEditorDocument,
    ],
  });

  const onSave = useCallback(async () => {
    await mutate();
    triggerNotice({ message: `Deleted ${rplBeingDeleted?.name} and the associated products` });
    const maybeSecondToLastName = altRpls.toReversed()[1]?.name;
    if (!isDeletingFinal && finalRpl && maybeSecondToLastName) {
      triggerNotice({ message: `Renamed alternates. ${finalRpl.name} is now ${maybeSecondToLastName}.` });
    }
    closeModal();
  }, [altRpls, closeModal, finalRpl, isDeletingFinal, mutate, rplBeingDeleted?.name, triggerNotice]);

  if (loading) return <HbLoadingSpinner />;
  if (!rplBeingDeleted) fail(`RPL not found for ${locationId}`);
  // Delete button doesn't even render for Primary but just in case... (could be convinced to `fail` instead)
  if (rplBeingDeleted === primaryRpl || !finalRpl) {
    return (
      <ModalBody>
        <ModalHeader>Cannot Delete</ModalHeader>
        <div>Cannot delete the Primary {primaryRpl.name}.</div>
        <ModalFooter>
          <Button label="Close" onClick={closeModal} />
        </ModalFooter>
      </ModalBody>
    );
  }
  return (
    <ModalBody>
      <ModalHeader>Confirm Delete</ModalHeader>
      <div css={Css.base.df.fdc.gap2.mb2.$}>
        {isDeletingFinal ? (
          <div>
            <strong>{rplBeingDeleted.name}</strong> assignments will be moved back to <strong>{primaryRpl.name}</strong>
          </div>
        ) : (
          <div>
            You are going to delete <strong>{rplBeingDeleted.name}</strong> and the{" "}
            <strong>associated products.</strong> All {rplBeingDeleted.name} room assignments will revert to{" "}
            <strong>{primaryRpl.name}</strong>.
            {rplBeingDeleted !== finalRpl &&
              ` All subsequent ${primaryRpl.name} Alternates will be renamed accordingly, and the last alternate (${finalRpl.name}) will be deleted.`}
          </div>
        )}
      </div>
      <ModalFooter>
        <Button label={isDeletingFinal ? "Delete" : "Confirm"} variant="danger" onClick={onSave} />
      </ModalFooter>
    </ModalBody>
  );
}

export function useConfirmDeleteAlternatesModal() {
  const { openModal } = useModal();
  const { designPackage } = useDesignPackageConfiguratorContext();
  return (locationId: string) => {
    openModal({
      content: <DesignPackageDeleteAlternatesConfirmationModal locationId={locationId} dpId={designPackage.id} />,
      size: "md",
    });
  };
}
