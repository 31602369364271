import {
  Banner,
  Button,
  Css,
  Icon,
  IconButton,
  Loader,
  LoadingSkeleton,
  Palette,
  ScrollShadows,
  useRightPane,
  useSnackbar,
} from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { createTaskDetailsPageUrl } from "src/RouteUrls";
import { CommentFeed, HistoryFeed, formatDate } from "src/components";
import {
  CommentStreamVisibility,
  DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment,
  Maybe,
  SavePlanTaskInput,
  TradePartnerAvailabilityRequestStatus,
  useDraftScheduleSidePaneQuery,
  usePlanTaskDetailsDocumentsQuery,
} from "src/generated/graphql-types";
import {
  mapToDraftPlanTaskInput,
  useDraftScheduleStore,
} from "src/routes/projects/dynamic-schedules/draft-mode/scheduleDraftStore";
import { Bills } from "src/routes/projects/dynamic-schedules/task-details/Bills";
import { CostAllocations } from "src/routes/projects/dynamic-schedules/task-details/CostAllocations";
import { PurchaseOrders } from "src/routes/projects/dynamic-schedules/task-details/PurchaseOrders";
import {
  ConstraintItemsSection,
  DelayFlagSection,
  MaterialsSection,
} from "src/routes/projects/dynamic-schedules/task-details/SchedulingCard";
import { TaskDetailCard, TaskDetailCardType } from "src/routes/projects/dynamic-schedules/task-details/TaskDetailCard";
import { VerificationChecklist } from "src/routes/projects/dynamic-schedules/task-details/VerificationChecklist";
import { TaskBillModalProvider } from "src/routes/projects/schedule-v2/contexts/TaskBillModalContext";
import { TaskDocuments } from "src/routes/projects/schedule-v2/detail-pane/TaskDocuments";
import { pluralize, queryResult } from "src/utils";

export function DynamicScheduleSidePane(props: { draftTaskId: string; onClose: () => void; scheduleParentId: string }) {
  const { draftTaskId, onClose, scheduleParentId } = props;
  const { closeRightPane } = useRightPane();
  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const isNewTask = draftTaskId.includes("WIP");
  const isDeletedTask = useMemo(
    () => draftTaskChanges.some((change) => change.id === draftTaskId && change?.delete),
    [draftTaskId, draftTaskChanges],
  );

  const query = useDraftScheduleSidePaneQuery({
    variables: {
      input: {
        scheduleParentId,
        draftTaskChanges: mapToDraftPlanTaskInput(draftTaskChanges, userAddedScheduleFlags),
        taskDetailId: draftTaskId,
      },
      taskId: draftTaskId,
    },
    skip: isNewTask || isDeletedTask,
  });

  // If a user is clicky clicky and opens the pane on a task marked for deletion immediately close it.
  useEffect(() => {
    isDeletedTask && closeRightPane();
  }, [closeRightPane, isDeletedTask]);

  return queryResult(query, {
    data: (data) => (
      <DynamicScheduleSidePaneView
        planTask={data.draftPlanSchedule.planTaskDetail}
        isNewTask={isNewTask}
        onClose={onClose}
        scheduleParentId={scheduleParentId}
        loading={query.loading}
      />
    ),
    loading: () => (
      <div css={Css.df.fdc.gap7.p3.$}>
        <LoadingSkeleton columns={1} rows={15} />
      </div>
    ),
  });
}

function DynamicScheduleSidePaneView(props: {
  planTask: Maybe<DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment>;
  isNewTask: boolean;
  onClose: () => void;
  scheduleParentId: string;
  loading: boolean;
}) {
  const { planTask, isNewTask, onClose, scheduleParentId, loading } = props;
  const history = useHistory();
  const { closeRightPane } = useRightPane();

  if (!planTask || isNewTask)
    return (
      <div css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.my2.$}>
        Task details available after publishing
      </div>
    );

  return (
    <TaskBillModalProvider>
      <div css={Css.df.fdc.h100.oa.px3.pb5.bgWhite.z0.gap1.$}>
        <div css={Css.df.jcsb.aic.sticky.pt3.top0.bgWhite.z1.$}>
          <div css={Css.df.aic.gap1.xl2Bd.pb1.$}>{planTask.name}</div>
          <div css={Css.df.gap1.$}>
            {loading ? (
              <Loader size="xs" />
            ) : (
              <>
                <IconButton
                  tooltip={isNewTask ? "Task details available after publishing" : "View full page details"}
                  icon="expand"
                  color={isNewTask ? Palette.Gray400 : Palette.Gray900}
                  disabled={isNewTask}
                  // Note: IconButton should support just a string for onclick navigation since it's built on Button, but it
                  // doesn't work
                  onClick={() => history.push(createTaskDetailsPageUrl(scheduleParentId, planTask.id))}
                />
                <IconButton
                  icon="x"
                  color={Palette.Gray900}
                  onClick={() => {
                    onClose();
                    closeRightPane();
                  }}
                />
              </>
            )}
          </div>
        </div>
        {planTask.tradePartner?.name && <div css={Css.base.gray700.$}>{planTask.tradePartner?.name}</div>}
        <div css={Css.base.gray700.$}>
          {formatDate(planTask.startDate)} - {formatDate(planTask.endDate)} (
          {planTask.knownDurationInDays ?? planTask.durationInDays}
          {pluralize(planTask.knownDurationInDays ?? planTask.durationInDays, " day")})
        </div>

        <div css={Css.df.fdc.gap7.pt2.$}>
          <TradePartnerAvailabilityRequestRescheduleSection planTask={planTask} />
          <SidePaneCommentSection planTask={planTask} />
          <PurchaseOrders projectItems={planTask.projectItems} inSidePane />
          <Bills task={planTask} inSidePane />
          <SidePaneDocumentsSection planTask={planTask} />
          <MaterialsSection task={planTask} inSidePane />
          <DelayFlagSection task={planTask} inSidePane />
          <CostAllocations projectItems={planTask.projectItems} inSidePane />
          <VerificationChecklist planTask={planTask} />
          <ConstraintItemsSection task={planTask} inSidePane type="constraints" />
          <ConstraintItemsSection task={planTask} inSidePane type="allowances" />
          <SidePaneHistoryCard planTask={planTask} />
        </div>
      </div>
    </TaskBillModalProvider>
  );
}

// We don't really need form state here, but it's easier to just bite that bullet and pray we can refactor it later to have a
// version not weighed down by old code
function SidePaneDocumentsSection({
  planTask,
}: {
  planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
}) {
  const { data } = usePlanTaskDetailsDocumentsQuery({ variables: { parentId: planTask.schedule.parent.id } });
  const formState = useFormState<SavePlanTaskInput, DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment>({
    config: {
      id: { type: "value" },
      documents: { type: "value" },
    },
    init: {
      input: planTask,
      map: (task) => ({
        id: task.id,
        documents: task.documents.map((doc) => doc.id),
      }),
    },
  });
  return (
    <TaskDocuments
      parentId={planTask.schedule.parent.id}
      documentTypes={data?.documentTypes ?? []}
      documents={data?.documents ?? []}
      formState={formState}
      task={planTask}
      onPlanTask
      taskStatus={planTask.status.code}
    />
  );
}

function SidePaneHistoryCard({ planTask }: { planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment }) {
  return (
    <TaskDetailCard noPadding cardType={TaskDetailCardType.History} maxHeight={450}>
      <h2 css={Css.lgBd.$}>History</h2>
      <ScrollShadows xss={Css.h100.oys.$}>
        <HistoryFeed historyItems={planTask?.history ?? []} renderAsCard={false} />
      </ScrollShadows>
    </TaskDetailCard>
  );
}

function SidePaneCommentSection({ planTask }: { planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment }) {
  return (
    <TaskDetailCard cardType={TaskDetailCardType.Comments} noPadding maxHeight={650}>
      <CommentFeed
        key={planTask.id}
        inlineCommentTitle={<h2 css={Css.lgBd.$}>Comments</h2>}
        showCommentTitle={false}
        showFollowers={false}
        commentable={planTask}
        maxHeight={490}
        initialTab={CommentStreamVisibility.Trades}
      />
    </TaskDetailCard>
  );
}

function TradePartnerAvailabilityRequestRescheduleSection({
  planTask,
}: {
  planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
}) {
  const { triggerNotice } = useSnackbar();
  const [showRescheduledBanner, setShowRescheduledBanner] = useState(false);
  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  // find the latest request on the task
  const latestRequest = planTask.tradePartnerAvailabilityRequests?.last;
  const tradePartnerName = planTask.tradePartner?.name;
  const rescheduleDate = latestRequest?.rescheduleDates?.last;

  useEffect(() => {
    if (
      latestRequest?.status.code === TradePartnerAvailabilityRequestStatus.RescheduleNeeded &&
      latestRequest?.rescheduleDates?.nonEmpty
    )
      setShowRescheduledBanner(true);
  }, [latestRequest]);

  const onRescheduleAction = useCallback(() => {
    setDraftTaskChanges([
      {
        id: planTask.id,
        earliestStartDate: rescheduleDate,
        isManuallyScheduled: true,
      },
    ]);
    triggerNotice({
      message: `You accepted the trade's new date of ${formatDate(rescheduleDate?.date, "monthShort")}.`,
    });
  }, [setDraftTaskChanges, planTask, triggerNotice]);
  // if we don't have a request, then don't show this section
  if (planTask.tradePartnerAvailabilityRequests?.isEmpty) return null;

  return (
    <>
      {showRescheduledBanner && (
        <Banner
          type="info"
          showIcon={false}
          message={
            <div css={Css.df.fdc.gap2.$}>
              <div>
                {`${tradePartnerName} requested to reschedule this task with a new date of ${formatDate(rescheduleDate?.date, "monthShort")}. Would you like to accept this date and notify ${tradePartnerName}?`}
              </div>
              {draftTaskChanges.isEmpty ? (
                <Button label="Confirm New Date" onClick={onRescheduleAction} variant="text" />
              ) : (
                <>
                  <div css={Css.smMd.$}>Confirmed!</div>
                  <span css={Css.df.gap1.aic.$}>
                    <Icon icon="errorCircle" color={Palette.Red600} />
                    <div css={Css.red700.smMd.$}>To save this change, click Publish above.</div>
                  </span>
                </>
              )}
            </div>
          }
          onClose={() => setShowRescheduledBanner(false)}
        />
      )}
    </>
  );
}
