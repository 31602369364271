import { createContext, PropsWithChildren, useContext } from "react";
import {
  CommunityType,
  FeatureFlagType,
  LotType,
  Named,
  ProjectFeature,
  ProjectLayoutBuildAddressFragment,
  ProjectLayoutClientFragment,
  ProjectLayoutCoverImageFragment,
  ProjectStatus,
  ProjectStatusDetail,
  Stage,
} from "src/generated/graphql-types";

export type ProjectContextProps = {
  id?: string;
  __typename?: string;
  name: string;
  buildAddress: ProjectLayoutBuildAddressFragment;
  client: ProjectLayoutClientFragment | null | undefined;
  coverImage: ProjectLayoutCoverImageFragment | null | undefined;
  features: ProjectFeature[];
  featureFlags: FeatureFlagType[];
  latestActiveStage: Stage;
  lotType?: LotType;
  communityType?: CommunityType;
  isSet: boolean;
  clientContract: string;
  clientNoun: string;
  initialBudget: string;
  shortClientNoun: string;
  status: ProjectStatusDetail;
  cohort?: Named;
  development?: Named;
  market: Named;
};

const ProjectContext = createContext<ProjectContextProps>({
  id: undefined,
  name: "",
  buildAddress: { id: "", street1: "", city: "", state: "", postalCode: "" },
  coverImage: { id: "", downloadUrl: "", previewUrl: "" },
  client: { id: "", shortName: "" },
  features: [],
  featureFlags: [],
  latestActiveStage: Stage.PreConPlanning,
  isSet: false,
  clientContract: "",
  clientNoun: "",
  initialBudget: "",
  shortClientNoun: "",
  status: { code: ProjectStatus.Active, name: "Active" },
  market: { id: "", name: "" },
});

type ProjectContextProviderProps = PropsWithChildren<ProjectContextProps>;

export function ProjectContextProvider(props: ProjectContextProviderProps) {
  const { children, ...contextValue } = props;
  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
}

export function useProjectContext() {
  return useContext(ProjectContext);
}
