import {
  Button,
  Css,
  FormHeading,
  FormLines,
  HbLoadingSpinner,
  SelectField,
  SuperDrawerContent,
  SuperDrawerHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { Fragment, useEffect, useMemo } from "react";
import {
  DesignPackageConfiguratorDocument,
  useDesignPackageRoomAlternatesDrawerQuery,
  useSaveDpRplAlternateLocationsMutation,
} from "src/generated/graphql-types";
import useSetState from "src/hooks/useSetState";
import {
  DesignPackageConfiguratorContext,
  useDesignPackageConfiguratorContext,
} from "../DesignPackageConfiguratorContext";

type DesignPackageRoomAlternatesDrawerProps = { locationId: string };

export function DesignPackageRoomAlternatesDrawer({ locationId }: DesignPackageRoomAlternatesDrawerProps) {
  const { designPackage: dp } = useDesignPackageConfiguratorContext();
  const { data, loading } = useDesignPackageRoomAlternatesDrawerQuery({
    variables: { id: dp.id, rpavId: dp.version.id, locationId },
  });

  const [ppRplToDpRpl, setPpRplToDpRpl] = useSetState<Record<string, string>>({});
  useEffect(() => {
    setPpRplToDpRpl(
      data?.designPackage.readyPlanLocations
        // each ppRpl (Bath103, Bath217) should map to a single dpRpl (Bath, Bath Alt 1, Bath Alt 2)
        .flatMap((dpRpl) => dpRpl.planLocations.map((ppRpl) => [ppRpl.id, dpRpl.id] as const))
        .toObject() ?? {},
    );
  }, [data, setPpRplToDpRpl]);

  // [{ id: DpRpl#1, planLocations: [PpRpl#1, PpRpl#2] }, ... ]
  const input = useMemo(
    () =>
      Object.entries({
        // prime { dpRplId: [] } to clear out now-empty RPLs that won't have any ppRpl to generate from
        ...data?.designPackage.readyPlanLocations.mapToObject((dpRpl) => [dpRpl.id, [] as string[]]),
        // Swap/Regroup [PpRpl, DpRpl] tuples to { DpRpl#1: [PpRpl#1, PpRpl#2], DpRpl#2: [...], ... }
        ...Object.entries(ppRplToDpRpl).groupBy(
          ([ppRplId, dpRplId]) => dpRplId,
          ([ppRplId]) => ppRplId,
        ),
      })
        // Convert { k: v } to [{ id: k, planLocations: v }] inputs for the mutation
        .map(([dpRplId, ppRplIds]) => ({ id: dpRplId, planLocations: ppRplIds })),
    [data?.designPackage.readyPlanLocations, ppRplToDpRpl],
  );

  const [mutate] = useSaveDpRplAlternateLocationsMutation({
    variables: { input },
    refetchQueries: [DesignPackageConfiguratorDocument],
    onCompleted: useSuperDrawer().closeDrawer,
  });

  if (loading) return <HbLoadingSpinner />;
  return (
    <SuperDrawerContent>
      <SuperDrawerHeader hideControls title="Alternate Room Assignment" />
      {data?.designPackage.readyPlanLocations.isEmpty && (
        <div css={Css.xlBd.red500.pb8.$}>
          Could not find any rooms/RPLs to reassign for {locationId}. Maybe a resync will fix it?
        </div>
      )}
      <FormLines width="md">
        {data?.designPackage.readyPlanLocations
          .flatMap((dpRpl) => dpRpl.planLocations)
          .groupByObject((ppRpl) => ppRpl.readyPlan.name)
          .map(([planName, ppRpls]) => (
            <Fragment key={planName}>
              <FormHeading title={planName} />
              {ppRpls
                .sortBy((ppRpl) => ppRpl.location.name) // sort Bath103 above Bath105 instead of random
                .map((ppRpl) => (
                  <SelectField
                    key={ppRpl.id}
                    label={ppRpl.location.name}
                    labelStyle="left"
                    onSelect={(altLocationId) => setPpRplToDpRpl({ [ppRpl.id]: altLocationId })}
                    options={data.designPackage.readyPlanLocations}
                    value={ppRplToDpRpl[ppRpl.id]}
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.location.name}
                  />
                ))}
            </Fragment>
          ))}
      </FormLines>
      <Button label="Save" onClick={() => mutate()} />
    </SuperDrawerContent>
  );
}

export function useDesignPackageRoomAlternatesDrawer() {
  const { openInDrawer } = useSuperDrawer();
  const ctx = useDesignPackageConfiguratorContext();
  return {
    openRoomDrawer: (locationId: string) => {
      openInDrawer({
        content: (
          <DesignPackageConfiguratorContext.Provider value={ctx}>
            <DesignPackageRoomAlternatesDrawer locationId={locationId} />
          </DesignPackageConfiguratorContext.Provider>
        ),
      });
    },
  };
}
