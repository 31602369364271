import {
  BoundMultiSelectField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { UppyUploader } from "src/components";
import {
  DocumentDetailDocumentTypeFragment,
  DocumentType,
  Maybe,
  ProjectRole,
  SaveRequiredTaskDocumentInput,
  useProjectRoleDetailsQuery,
  useSaveRequiredTaskDocumentMutation,
} from "src/generated/graphql-types";
import { sortBy } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { queryResult } from "src/utils/queryResult";

type DocumentRequirementModalProps = {
  scheduleTaskId: string;
  documentTypes: DocumentDetailDocumentTypeFragment[];
};

export function DocumentRequirementModal({ scheduleTaskId, documentTypes }: DocumentRequirementModalProps) {
  const { closeModal } = useModal();
  const [file, setFile] = useState<UppyFile>();
  const [saveRequiredTaskDocument] = useSaveRequiredTaskDocumentMutation();
  const formState = useFormState({
    config: formConfig,
  });
  const query = useProjectRoleDetailsQuery({ fetchPolicy: "cache-first" });

  async function onSaveRequiredTaskDocument(input: SaveRequiredTaskDocumentInput) {
    if (file) {
      input.sampleAsset = {
        contentType: file.type,
        fileName: file.name,
        s3Key: file.meta.s3Key as string,
        sizeInBytes: file.size,
      };
    }

    const { data } = await saveRequiredTaskDocument({
      variables: { input: { ...input, taskId: scheduleTaskId } },
    });
    const newRequiredTaskDocument = data?.saveRequiredTaskDocument.requiredTaskDocument;
    if (newRequiredTaskDocument) {
      closeModal();
    }
  }

  return (
    <>
      {queryResult(query, {
        data: ({ projectRoleDetails }) => {
          const docTypeOptions = sortBy(documentTypes, ({ name }) => name).filter((dt) => !dt.systemControlled);
          const roleOptions = sortBy(projectRoleDetails, ({ name }) => name);
          return (
            <>
              <ModalHeader>Add a Document Requirement</ModalHeader>
              <ModalBody>
                <FormLines width="full">
                  <div css={Css.gray700.fw4.$}>
                    Choose a document that will be required to mark a schedule task as 'Complete'
                  </div>
                  <BoundTextField label="Name" field={formState.name} />
                  <BoundSelectField
                    label="Type"
                    options={docTypeOptions}
                    getOptionValue={(d) => d.code}
                    getOptionLabel={(d) => d.name}
                    field={formState.documentType}
                  />
                  <BoundMultiSelectField
                    label="Watchers"
                    options={roleOptions}
                    getOptionValue={(pr) => pr.code}
                    getOptionLabel={(pr) => pr.name}
                    field={formState.watchers}
                  />
                </FormLines>
                <div css={Css.mt3.base.fw5.$}>Sample Document</div>
                <div css={Css.gray700.fw4.mt1.$}>
                  Add a sample document that can be downloaded, filled out, and re-attached by others.
                </div>
                {file && (
                  <div css={Css.df.fdc.br8.bgGray100.mt1.$}>
                    <div css={Css.df.fdr.aic.gap1.xs.p2.$}>
                      <Icon icon="fileBlank" />
                      <a
                        css={Css.truncate.$}
                        href={file.meta.downloadUrl as string}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}
                      </a>
                    </div>
                  </div>
                )}
                <div css={Css.mt2.mb2.$} data-testid="uploader">
                  <UppyUploader
                    onFinish={(file) => setFile(file)}
                    dragDropText="Drag &amp; drop files"
                    maxNumberOfFiles={1}
                    dragDropWidth={"100%"}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button label="Cancel" variant="tertiary" onClick={closeModal} />
                <Observer>
                  {() => (
                    <Button
                      variant="primary"
                      disabled={!formState.dirty || !formState.canSave()}
                      label="Add Document Requirement"
                      onClick={() => onSaveRequiredTaskDocument(formState.value)}
                    />
                  )}
                </Observer>
              </ModalFooter>
            </>
          );
        },
      })}
    </>
  );
}

type AddRequiredTaskDocumentFormValue = {
  name: Maybe<string>;
  documentType: Maybe<DocumentType>;
  watchers: Maybe<Array<ProjectRole>>;
};

const formConfig: ObjectConfig<AddRequiredTaskDocumentFormValue> = {
  name: { type: "value", rules: [required] },
  documentType: { type: "value", rules: [required] },
  watchers: { type: "value" },
};
