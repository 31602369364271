import { Css } from "@homebound/beam";
import { useParams } from "react-router-dom";
import { BidContractOverview } from "src/components/bid-contracts/BidContractOverview";
import { BidContractTradePartnerOverview } from "src/components/bid-contracts/BidContractOverviewTradePartner";
import { CommentFeed2 } from "src/components/comments2/CommentFeed";
import { useBidContractRevisionOverviewQuery } from "src/generated/graphql-types";
import { DevelopmentContractParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";

export function DevelopmentContractOverviewPage() {
  const { bidContractRevisionId, developmentId } = useParams<DevelopmentContractParams>();
  const query = useBidContractRevisionOverviewQuery({ variables: { bidContractRevisionId, developmentId } });
  return queryResult(query, ({ bidContractRevision }) => (
    <div css={Css.df.aifs.$}>
      <BidContractOverview selectedRevision={bidContractRevision} />
      {bidContractRevision.bidContract.tradePartner && (
        <BidContractTradePartnerOverview tradePartner={bidContractRevision.bidContract.tradePartner} />
      )}
      <div>
        <CommentFeed2 commentable={bidContractRevision.bidContract} />
      </div>
    </div>
  ));
}
