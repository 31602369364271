import { Css, Tag, Tooltip, useTestIds } from "@homebound/beam";
import { useHistory } from "react-router-dom";
import { createMaterialDetailsUrl } from "src/RouteUrls";
import { MaterialCatalog_MaterialVariantFragment } from "src/generated/graphql-types";
import { PRODUCT_FALLBACK_IMG_URL } from "../../product-catalog/components/product-images-viewer/ProductImageViewer";
export type MaterialCatalogListViewsProps = {
  variants: MaterialCatalog_MaterialVariantFragment[];
};

export function MaterialCatalogTileView({ variants }: MaterialCatalogListViewsProps) {
  return (
    <div css={Css.df.add("flexWrap", "wrap").gap1.$}>
      {variants.map((variant) => (
        <MaterialCatalogTile key={variant.id} variant={variant} />
      ))}
    </div>
  );
}

type MaterialCatalogTileProps = {
  variant: MaterialCatalog_MaterialVariantFragment;
};

function MaterialCatalogTile({ variant }: MaterialCatalogTileProps) {
  const tid = useTestIds({}, "material");
  const { code, displayName, modelNumber, isArchived, listing, featuredImage } = variant;
  const { brand } = listing;
  const history = useHistory();

  return (
    <button
      key={variant.id}
      {...tid.details}
      css={{
        ...Css.df.fdc.wPx(208).tal.hPx(300).bshBasic.br8.bgWhite.relative.cursorPointer.m1.p2.if(!!isArchived).bgGray400
          .$,
        ":hover": Css.bshHover.$,
      }}
      onClick={() => history.push(createMaterialDetailsUrl(variant.id))}
    >
      <div css={Css.absolute.z1.$}>
        <Tag {...tid.status} type={isArchived ? "caution" : "success"} text={isArchived ? "Archived" : "Active"} />
      </div>
      <div css={Css.df.aic.mb1.wPx(176).hPx(134).if(!featuredImage).p1.$}>
        <img
          {...tid.image}
          css={
            Css.maxw100.maxh100.ma.p1
              .if(!featuredImage)
              .br8.p4.bgGray100.w100.h100.if(isArchived)
              // Matches the gray background color for archived products
              .add("filter", "brightness(0.79)").$
          }
          src={featuredImage?.asset?.downloadUrl || PRODUCT_FALLBACK_IMG_URL}
          alt={displayName}
        />
      </div>
      <div css={Css.df.fg1.fdc.jcsb.$}>
        <span {...tid.description} css={Css.lineClamp4.$}>
          <label css={Css.smBd.$}>{brand?.name}</label>{" "}
          <Tooltip title={displayName} placement="top">
            {displayName}
          </Tooltip>
        </span>
        <div css={Css.df.fdc.mt1.gap1.wPx(176).$}>
          {modelNumber && (
            <span {...tid.model} css={Css.xs.truncate.$}>
              Model:{" "}
              <Tooltip title={modelNumber} placement="top">
                {modelNumber}
              </Tooltip>
            </span>
          )}
          <span {...tid.code} css={Css.xs.truncate.$}>
            Code:{" "}
            <Tooltip title={code} placement="top">
              {code}
            </Tooltip>
          </span>
        </div>
      </div>
    </button>
  );
}
