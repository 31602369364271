import { WizardStep, useStepperWizard } from "src/components/stepper/useStepperWizard/useStepperWizard";
import { NewDesignPackageProvider } from "./NewDesignPackageContext";
import { NewDesignPackageDetails } from "./NewDesignPackageDetails";
import { NewDesignPackageOptions } from "./NewDesignPackageOptions";
import { NewDesignPackageUgrades } from "./NewDesignPackageUpgrades";

export function useNewDesignCatalogWizard(maybeDesignPackageId?: string, maybeVersionId?: string) {
  const { openWizard } = useStepperWizard({
    steps,
    Provider: ({ children }) => (
      <NewDesignPackageProvider maybeDesignPackageId={maybeDesignPackageId} maybeVersionId={maybeVersionId}>
        {children}
      </NewDesignPackageProvider>
    ),
  });
  return openWizard;
}

const steps: WizardStep[] = [
  {
    label: "Link & Name",
    render: () => <NewDesignPackageDetails />,
  },
  {
    label: "Package Options",
    render: () => <NewDesignPackageOptions />,
  },
  {
    label: "Package Upgrades",
    render: () => <NewDesignPackageUgrades />,
  },
];
