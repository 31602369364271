import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { useProductOfferingVersionHistoryQuery, VersionDetailsCard_VersionFragment } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { VersionDetailsCard } from "src/routes/libraries/versioning/VersionDetailsCard";
import { createProductOfferingScopeUrl, createProductOfferingUrl } from "src/RouteUrls";
import { fail, queryResult } from "src/utils";
import { GeneralTlivChanges } from "../../../libraries/versioning/GeneralTlivChanges";
import { ProductTlivChanges } from "./components/ProductTlivChanges";

export function ProductOfferingVersionHistoryPage() {
  const { productOfferingId } = useParams<{ productOfferingId: string }>();
  const query = useProductOfferingVersionHistoryQuery({ variables: { filter: { readyPlanId: [productOfferingId] } } });

  return queryResult(query, ({ readyPlanAggregateVersions }) => (
    <ProductOfferingVersionHistoryView versions={readyPlanAggregateVersions} />
  ));
}

type PlanPackageVersionHistoryViewProps = {
  versions: VersionDetailsCard_VersionFragment[];
};

function ProductOfferingVersionHistoryView(props: PlanPackageVersionHistoryViewProps) {
  const { productOfferingId, developmentId, projectId } = useParams<{
    productOfferingId: string;
    developmentId: string;
    projectId: string;
  }>();
  const { versions } = props;
  // We reverse the array so that the most recent version is shown first.
  const rpavs = [...versions].reverse();
  const latestVersion = rpavs.first ?? fail("Expected at least one version");

  return (
    <>
      <PageHeader
        title="Version History"
        breadcrumb={[
          { href: createProductOfferingUrl(productOfferingId, developmentId, projectId), label: "Product Offering" },
          {
            href: createProductOfferingUrl(productOfferingId, developmentId, projectId),
            label: latestVersion.readyPlan.name,
          },
        ]}
      />
      <div css={Css.df.fdc.gap2.$}>
        {rpavs.map((rpav) => (
          <VersionDetailsCard
            key={rpav.id}
            version={rpav}
            changeDetails={<ProductOfferingVersionChanges version={rpav} />}
            versionUrl={createProductOfferingScopeUrl(productOfferingId, rpav.id, developmentId, projectId)}
          />
        ))}
      </div>
    </>
  );
}

type ProductOfferingVersionChangesProps = {
  version: VersionDetailsCard_VersionFragment;
};

function ProductOfferingVersionChanges({ version }: ProductOfferingVersionChangesProps) {
  const { tliVersions } = version;
  return (
    <>
      <GeneralTlivChanges tliVersions={tliVersions} />
      <ProductTlivChanges tliVersions={tliVersions} />
    </>
  );
}
