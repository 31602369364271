import { Css } from "@homebound/beam";
import { ApprovalSubject_ChangeRequestFragment } from "src/generated/graphql-types";
import { HeroMetric } from "../../HeroMetric";

export function ChangeRequestApprovalSummary({
  changeRequest,
}: {
  changeRequest: ApprovalSubject_ChangeRequestFragment;
}) {
  const {
    estimateBudgetImpactInCents,
    markupBasisPoints,
    estimatedBuyerOutOfPocketInCents,
    estimatedRetailPriceInCents,
    estimateMarginImpactBasisPoints,
  } = changeRequest;
  console.log({ estimateMarginImpactBasisPoints });
  return (
    <div css={Css.df.flexWrap("wrap").gap3.$}>
      <div css={Css.bgGray100.p2.br8.$}>
        <HeroMetric
          value={estimateBudgetImpactInCents}
          subTitle1="Budget Impact"
          size="xs"
          rounded={false}
          data-testid="budgetImpactMetric"
          trim={false}
        />
      </div>
      <div css={Css.bgGray100.p2.br8.$}>
        <HeroMetric
          value={(markupBasisPoints ?? 0) / 100}
          isPercent
          subTitle1="Markup"
          size="xs"
          rounded={false}
          data-testid="markupMetric"
          trim={false}
        />
      </div>
      <div css={Css.bgGray100.p2.br8.$}>
        <HeroMetric
          value={estimatedBuyerOutOfPocketInCents}
          subTitle1="Estimated Buyer Out of Pocket / Revenue Impact"
          size="xs"
          rounded={false}
          data-testid="buyerOutOfPocketMetric"
          trim={false}
        />
      </div>
      <div css={Css.bgGray100.p2.br8.$}>
        <HeroMetric
          value={estimatedRetailPriceInCents}
          subTitle1="Retail Price"
          size="xs"
          rounded={false}
          data-testid="retailPriceMetric"
          trim={false}
        />
      </div>
      <div css={Css.bgGray100.p2.br8.$}>
        <HeroMetric
          value={estimateBudgetImpactInCents}
          subTitle1="Budget Impact"
          size="xs"
          rounded={false}
          data-testid="budgetImpactMetric"
          trim={false}
        />
      </div>
      <div css={Css.bgGray100.p2.br8.$}>
        <HeroMetric
          value={(estimateMarginImpactBasisPoints ?? 0) / 100}
          isPercent
          subTitle1="Margin Impact"
          size="xs"
          rounded={false}
          data-testid="marginImpactMetric"
          trim={false}
        />
      </div>
    </div>
  );
}
