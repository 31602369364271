import {
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  Tag,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { UnitsOfMeasureBoundSelectField } from "src/components/autoPopulateSelects/UnitsOfMeasureBoundSelectField";
import {
  CostType,
  LocationType,
  Maybe,
  SaveChangeEventLineItemInput,
  UnitsOfMeasureDataFragment,
  useAddLaborItemModal_EffectiveLocationsQuery,
  useAddLaborItemModal_GlobalPlanTasksLazyQuery,
} from "src/generated/graphql-types";

type AddLaborItemModalProps = {
  onAdd: (input: AddLaborItemFormInput) => Promise<void>;
  /** Allows to assign a default location given a matching name */
  defaultLocation?: string;
};

export function AddLaborItemModal(props: AddLaborItemModalProps) {
  const { onAdd, defaultLocation } = props;
  const [loadTasks, { data: tasksData }] = useAddLaborItemModal_GlobalPlanTasksLazyQuery();
  const { data: locationsData } = useAddLaborItemModal_EffectiveLocationsQuery({
    variables: { filter: { version: [2], type: [LocationType.Scope] } },
  });
  const { closeModal } = useModal();

  const formState = useFormState({ config: formConfig });

  const onSave = useCallback(async () => {
    await onAdd({ ...formState.value, costType: CostType.Labor });
    formState.commitChanges();
    closeModal();
  }, [closeModal, onAdd, formState]);

  useEffect(() => {
    const matchingLocation = locationsData?.locations.find((l) => l.name === defaultLocation);
    if (matchingLocation) {
      formState.locationId.set(matchingLocation.id);
    }
  }, [locationsData, defaultLocation, formState]);

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>
            <span css={Css.lgSb.$}>Add Labor</span>
          </ModalHeader>
          <ModalBody>
            <FormLines labelSuffix={{ required: "*" }}>
              <div css={Css.df.fdc.gap2.$}>
                <BoundSelectField
                  label={`Task Allocation`}
                  field={formState.proposedTaskId}
                  nothingSelectedText="Please select a task"
                  options={{
                    current: undefined,
                    load: () => loadTasks({ variables: { filter: {} } }),
                    options: tasksData?.globalPlanTasks.entities,
                  }}
                  required
                />
                <BoundSelectField
                  label="Location"
                  field={formState.locationId}
                  options={locationsData?.locations ?? []}
                  getOptionMenuLabel={({ name, displayLocationPath, type }) => (
                    <span>
                      <Tag xss={Css.m0.$} type="info" text={type.name} /> {name}{" "}
                      {name !== displayLocationPath && <span css={Css.gray500.$}>{displayLocationPath}</span>}
                    </span>
                  )}
                  required
                />
              </div>
              <div css={Css.df.jcsb.gap1.$}>
                <UnitsOfMeasureBoundSelectField
                  label="UoM"
                  field={formState.unitOfMeasureId}
                  onSelect={(uomId, uom) => {
                    formState.unitOfMeasureId.set(uomId);
                    formState.unitOfMeasure.set(uom);
                  }}
                />
                <BoundNumberField
                  label="Qty"
                  field={formState.quantity}
                  disabled={
                    formState.unitOfMeasure.value &&
                    !formState.unitOfMeasure.value?.useQuantity &&
                    `${formState.unitOfMeasure.value!.name} does not uses Quantity`
                  }
                />
              </div>
            </FormLines>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
            <SubmitButton label="Save" form={formState} onClick={onSave} size="lg" />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

export type AddLaborItemFormInput = Pick<
  SaveChangeEventLineItemInput,
  "proposedTaskId" | "locationId" | "unitOfMeasureId" | "quantity"
> & {
  // store the more detailed objects for easier access
  unitOfMeasure: Maybe<UnitsOfMeasureDataFragment>;
  costType?: CostType;
};

const formConfig: ObjectConfig<AddLaborItemFormInput> = {
  locationId: { type: "value", rules: [required] },
  proposedTaskId: { type: "value", rules: [required] },
  unitOfMeasureId: { type: "value", rules: [required] },
  unitOfMeasure: { type: "value" },
  quantity: { type: "value" },
};
