import { Button, ButtonMenu, Css, MenuItem, ModalProps, useModal } from "@homebound/beam";
import { useParams } from "react-router";
import { createDevelopmentContractOverviewUrl } from "src/RouteUrls";
import { BidItemsModal, BidItemsModalOnSave } from "src/components/bid-contracts/BidItemsModal";
import { baseDownloadUrl } from "src/context";
import {
  DevelopmentProcurementPage_DevelopmentFragment,
  useDevelopmentProcurementQuery,
} from "src/generated/graphql-types";
import { DevelopmentContractsTable } from "src/routes/developments/procurement/DevelopmentContractsTable";
import { CreateDevelopmentContractButton } from "src/routes/developments/procurement/components/CreateDevelopmentContractButton";
import {
  DevelopmentTemplateItemsModal,
  DevelopmentTemplateItemsModalOnSave,
  DevelopmentTemplateItemsModalSize,
} from "src/routes/developments/procurement/components/DevelopmentTemplateItemsModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { developmentsPath } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { openInSelf } from "src/utils/window";

export function DevelopmentProcurementPage() {
  const { developmentId } = useParams<{ developmentId: string }>();
  const query = useDevelopmentProcurementQuery({ variables: { developmentId } });
  return queryResult(query, {
    data: ({ development }) => <DevelopmentProcurement development={development} />,
  });
}

type DevelopmentProcurementProp = {
  development: DevelopmentProcurementPage_DevelopmentFragment;
};

function DevelopmentProcurement({ development }: DevelopmentProcurementProp) {
  const { id, name } = development;

  return (
    <>
      <PageHeader
        title={`${name} Contracts`}
        breadcrumb={{ label: "All Developments", href: developmentsPath }}
        right={
          <div css={Css.df.gap1.$}>
            <Button
              label="View Budget Estimate"
              variant="secondary"
              onClick={createDevelopmentContractOverviewUrl(
                development.id,
                development.internalBudgetEstimate.latestRevision.id,
              )}
            />
            <CreateBidRequestButton developmentId={id} />
            <CreateDevelopmentContractButton variant="primary" />
          </div>
        }
      />
      <DevelopmentContractsTable />
    </>
  );
}

export function CreateBidRequestButton(props: { developmentId: string }) {
  const { developmentId } = props;
  const { openModal } = useModal();
  return <ButtonMenu items={bidRequestMenuItems(openModal, developmentId)} trigger={{ label: "Create Bid Request" }} />;
}

function bidRequestMenuItems(openModal: (props: ModalProps) => void, developmentId: string): MenuItem[] {
  return [
    {
      label: "Plan-based Bid Request",
      onClick: () => {
        openModal({
          content: <PlanBasedBidRequest developmentId={developmentId} />,
          size: DevelopmentTemplateItemsModalSize,
        });
      },
    },
    {
      label: "Itemized Bid Request",
      onClick: () => {
        openModal({
          content: <ItemizedBidRequestModal />,
          size: { width: "md", height: 600 },
        });
      },
    },
  ];
}

function ItemizedBidRequestModal() {
  const onSave: BidItemsModalOnSave = (bidItems, all, fileName) => {
    const bidIds = all ? "all" : bidItems.map((i) => i.id).join(",");

    const params = new URLSearchParams({
      type: "developmentBidItems",
      bidItemIds: bidIds,
      ...(fileName ? { fileName } : {}),
    });
    openInSelf(`${baseDownloadUrl()}/csv?${params.toString()}`);
  };

  return (
    <BidItemsModal
      title="Itemized Bid Request"
      confirmationButtonText="Create Bid Request"
      onSave={onSave}
      subHeader={
        <>
          <div css={Css.mt1.mb1.baseMd.$}>Select the line items to include in this bid request .csv.</div>
          <div css={Css.mb2.$}>Line items from ALL templates will be included in a single file.</div>
        </>
      }
    />
  );
}

export const PlanBasedBidRequest = ({ developmentId }: { developmentId: string }) => {
  // We get back both the ITIs + items, but if we upload all of the ITIs ids, the form get is too long
  const onSave: DevelopmentTemplateItemsModalOnSave = (items, filter, includeCost, fileName) => {
    const itemId = items.map((i) => i.item.id);

    const params = new URLSearchParams({
      type: "developmentPlanItems",
      devId: developmentId,
      includeCost: String(includeCost),
      filter: JSON.stringify({ ...filter, itemId }),
      ...(fileName ? { fileName } : {}),
    });
    openInSelf(`${baseDownloadUrl()}/csv?${params.toString()}`);
  };
  return (
    <DevelopmentTemplateItemsModal
      confirmationButtonText="Create Bid Request"
      developmentId={developmentId}
      onSave={onSave}
      headerText="Plan-based Bid Request"
      selectAllText="Export all line items"
      subHeader={
        <>
          <div css={Css.mt1.mb1.baseMd.$}>Select the line items to include in this bid request .csv.</div>
          <div css={Css.mb2.$}>
            Line items from ALL templates on this development will be included in a single file.
          </div>
        </>
      }
    />
  );
};
