import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import {
  BidPackageDetailPage_BidContractRevisionFragment,
  NamedFragment,
  useBillTermsTab_BasePlanGlobalPlanTasksQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { BillTermsTable } from "./BillTermsTable";

type BillTermsTabProps = {
  tradePartnerRevisions: {
    tradePartner: NamedFragment;
    revision: BidPackageDetailPage_BidContractRevisionFragment | undefined;
  }[];
  basePlanGlobalTasks: NamedFragment[];
};

type BillTermsTabViewProps = Pick<BillTermsTabProps, "tradePartnerRevisions"> & {
  entityWithMarketId: string;
  bidPackageId: string;
};

export function BillTermsTabView({ tradePartnerRevisions, entityWithMarketId, bidPackageId }: BillTermsTabViewProps) {
  const query = useBillTermsTab_BasePlanGlobalPlanTasksQuery({ variables: { entityWithMarketId, bidPackageId } });
  return queryResult(query, ({ bidPackage }) => {
    const basePlanGlobalPlanTasks = bidPackage.basePlanGlobalPlanTasks.map((gpt) => ({
      id: gpt.id,
      name: gpt.nameForMarket,
    }));
    return <BillTermsTab tradePartnerRevisions={tradePartnerRevisions} basePlanGlobalTasks={basePlanGlobalPlanTasks} />;
  });
}

export function BillTermsTab({ tradePartnerRevisions, basePlanGlobalTasks }: BillTermsTabProps) {
  const [selectedTab, setSelectedTab] = useState<string>(tradePartnerRevisions.first?.tradePartner.id ?? "");
  const tabs = tradePartnerRevisions.map(({ tradePartner, revision }) => ({
    name: tradePartner.name,
    value: tradePartner.id,
    render: () =>
      revision ? (
        <BillTermsTable basePlanGlobalTasks={basePlanGlobalTasks} revision={revision} />
      ) : (
        <div>No bid has been recieved for this trade</div>
      ),
  }));
  return <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setSelectedTab} />;
}
