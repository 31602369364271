import { Button, useSnackbar } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createBidPackagesDetailUrl } from "src/RouteUrls";
import { StepActions, useStepperContext } from "src/components/stepper";
import { BidPackageStatus, useSaveBidPackageMutation } from "src/generated/graphql-types";
import { BidPackageParams } from "src/routes/routesDef";
import { BidPackageFormState } from "../PublishBidPackagePage";

export function PublishBidPackageStepActions({ formState }: { formState: ObjectState<BidPackageFormState> }) {
  const { currentStep, nextStep, steps, isLastStep } = useStepperContext();
  const { bidPackageGroupId, bidPackageId } = useParams<BidPackageParams>();
  const [saveBidPackage] = useSaveBidPackageMutation();
  const currentStepIndex = steps.findIndex((step) => step.value === currentStep.value);
  const nextStepDisabled = steps[currentStepIndex + 1]?.disabled;
  const { triggerNotice } = useSnackbar();
  const history = useHistory();

  const sendToTrades = useCallback(async () => {
    await saveBidPackage({ variables: { input: { ...formState.value, status: BidPackageStatus.Open } } });
    // We need to commit the changes to where the confirm leave modal isn't triggered
    formState.commitChanges();
  }, [formState, saveBidPackage]);

  return (
    <StepActions>
      <>
        <Button
          variant="secondary"
          label="Cancel"
          size="lg"
          onClick={createBidPackagesDetailUrl(bidPackageGroupId, bidPackageId)}
        />
        {!isLastStep ? (
          <Button label="Continue" size="lg" onClick={nextStep} disabled={nextStepDisabled} />
        ) : (
          <Button
            label="Send to Trades"
            size="lg"
            onClick={async () => {
              await sendToTrades();
              history.push(createBidPackagesDetailUrl(bidPackageGroupId, bidPackageId));
              triggerNotice({ icon: "success", message: "Bid package successfully sent to trades" });
            }}
            disabled={!formState.valid}
          />
        )}
      </>
    </StepActions>
  );
}
