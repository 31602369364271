import { useParams } from "react-router";
import { useBidContractProrationRevisionQuery } from "src/generated/graphql-types";
import { ProrateBidView } from "src/routes/bids/components/ProrateBidModal";
import { queryResult } from "src/utils";

export function BidContractProrationPage() {
  const { bidContractRevisionId } = useParams<{ bidContractRevisionId: string }>();
  const query = useBidContractProrationRevisionQuery({ variables: { bidContractRevisionId } });
  return queryResult(query, ({ bidContractRevision }) => {
    return (
      <ProrateBidView
        bidContractRevisionId={bidContractRevisionId}
        scopeLineItems={bidContractRevision.bidPackageVersion?.relevantTakeoffLineItemVersions || []}
        type={bidContractRevision.bidContract.type!.code}
        existingTradeLineItems={bidContractRevision.tradeLineItems}
        prorationModalTreatment={false}
        disabled={!bidContractRevision.canEdit.allowed}
      />
    );
  });
}
