import { Css, Icon } from "@homebound/beam";
import { noCase } from "change-case";
import { ReactNode } from "react";
import { formatDate } from "src/components";
import { UserEventType } from "src/generated/graphql-types";
import { weightedStyle } from "src/routes/my-blueprint/activity-feed/utils";
import { sanitizeHtml } from "src/utils";
import { UserEventRowData } from "../../UserEventsTable";

export function Task({
  event,
  urlTag,
  projectTag,
  createdByTag,
}: {
  event: UserEventRowData;
  urlTag: string | ReactNode;
  projectTag: false | ReactNode | null | undefined;
  createdByTag: ReactNode;
}) {
  const { url, type, payload, comment } = event;

  if (type === UserEventType.Created) {
    return (
      <span>
        A new Task
        {urlTag} was created by {createdByTag} {projectTag}
      </span>
    );
  }

  if (type === UserEventType.Flagged) {
    return (
      <span>
        A{" "}
        <a css={weightedStyle} href={`${url}&scrollInto=flags`}>
          Flag
        </a>{" "}
        was added to Task{urlTag} by {createdByTag} {projectTag}
      </span>
    );
  }

  if (type === UserEventType.Commented) {
    return (
      <span>
        There was a new <a href={`${url}&tabSelected=comments`}>comment</a> by {createdByTag} {urlTag} {projectTag}
        <div css={Css.df.$}>
          <Icon inc={3} xss={Css.mt3.$} icon="comment" />
          <div css={Css.indent.$}>
            <div css={Css.mt3.$}>{createdByTag} said</div>
            <div
              css={Css.mb2.mtPx(3).wbbw.$}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(comment?.html || "") }}
            ></div>
          </div>
        </div>
      </span>
    );
  }

  if (payload.status) {
    return (
      <span>
        Task{urlTag} was moved to <span css={weightedStyle}>{noCase(payload?.status)}</span>, by {createdByTag}{" "}
        {projectTag}
      </span>
    );
  }

  if (payload.durationInDays) {
    return (
      <span>
        The duration of Task{urlTag} was changed from {payload.__originalValues.durationInDays} to{" "}
        {payload.durationInDays} days, by {createdByTag} {projectTag}
      </span>
    );
  }

  if (payload.startDate) {
    return (
      <span>
        The start date of Task{urlTag} was changed from {formatDate(payload.__originalValues.startDate, "medium")} to{" "}
        {formatDate(payload.startDate, "medium")}, by {createdByTag} {projectTag}
      </span>
    );
  }
  const { predecessorDependencies, successorDependencies } = payload;
  const dependencyType = predecessorDependencies ? "predecessor" : "successor";
  const dependencies = predecessorDependencies || successorDependencies;
  if (dependencies) {
    const [dependency] = [...(dependencies.added || dependencies.updated)];
    if (dependency.__payload?.deletedAt) {
      return (
        <span>
          A {dependencyType} task was removed from Task{urlTag} by {createdByTag} {projectTag}
        </span>
      );
    }
    if (dependency.__payload?.lagInDays) {
      return (
        <span>
          The delay between Task{urlTag} and it's {dependencyType} was changed from{" "}
          {dependency.__payload.__originalValues.lagInDays} to {dependency.__payload.lagInDays} by {createdByTag}{" "}
          {projectTag}
        </span>
      );
    }
    return (
      <span>
        A {dependencyType} task was added to Task{urlTag} by {createdByTag} {projectTag}
      </span>
    );
  }

  return (
    <span>
      Task{urlTag} was updated, by {createdByTag} {projectTag}
    </span>
  );
}
