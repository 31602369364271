import { BoundSelectField, BoundTextAreaField, BoundTextField, Css, FormLines } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import {
  NamedFragment,
  SaveProductOfferingInput,
  useOfferingDetailsStep_CommunitiesQuery,
  useOfferingDetailsStep_DevelopmentsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ProductOfferingStepActions } from "./ProductOfferingStepActions";

export function OfferingDetailsStep({
  formState,
  useProjectHierarchy,
}: Pick<OfferingDetailsStepViewProps, "formState" | "useProjectHierarchy">) {
  const developmentsQuery = useOfferingDetailsStep_DevelopmentsQuery({
    skip: useProjectHierarchy,
  });
  const communitiesQuery = useOfferingDetailsStep_CommunitiesQuery({
    skip: !useProjectHierarchy,
  });
  if (useProjectHierarchy) {
    return queryResult(communitiesQuery, ({ projects }) => (
      <OfferingDetailsStepView
        formState={formState}
        communities={projects}
        useProjectHierarchy={useProjectHierarchy}
        developments={[]}
      />
    ));
  }
  return queryResult(developmentsQuery, ({ developments }) => (
    <OfferingDetailsStepView
      formState={formState}
      developments={developments}
      useProjectHierarchy={useProjectHierarchy}
      communities={[]}
    />
  ));
}

type OfferingDetailsStepViewProps = {
  developments: NamedFragment[];
  communities: NamedFragment[];
  formState: ObjectState<SaveProductOfferingInput>;
  useProjectHierarchy: boolean;
};

function OfferingDetailsStepView({
  formState,
  communities,
  developments,
  useProjectHierarchy,
}: OfferingDetailsStepViewProps) {
  return (
    <>
      <div css={Css.df.fdc.aic.$}>
        <div css={Css.xl3Sb.$}>Create New Offering</div>
        <div css={Css.base.pt2.$}>Review the details below inherited from the base plan and modify as needed.</div>
        <div css={Css.base.pbPx(60).$}>Changes made here will not impact the base plan.</div>
        <div css={Css.w50.p4.bgWhite.bshBasic.br12.$}>
          <div css={Css.lgSb.mb2.$}>Product Offering Overview</div>
          <FormLines labelStyle="left" compact width="full">
            <BoundTextField label="Offering Name" field={formState.name} />
            {useProjectHierarchy ? (
              <BoundSelectField required label="Community" options={communities} field={formState.projectId} />
            ) : (
              <BoundSelectField required label="Development" options={developments} field={formState.developmentId} />
            )}
            <BoundTextAreaField label="Offering Description" field={formState.description} />
          </FormLines>
        </div>
      </div>
      <ProductOfferingStepActions />
    </>
  );
}
