import { useComputed, useModal } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import React, { createContext, useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import {
  DesignPackageGlobalOptionGroupFragment,
  DesignPackageItemSlotEditorDocument,
  DesignPackageLocation,
  PlanPackage_ReadyPlanOptionFragment,
  SaveDesignPackageInput,
  useDesignPackageGlobalOptionsQuery,
  useEditDesignPackageQuery,
  useMakeNewDesignPackageMutation,
  usePreselectOptionsFromPlanPackagesQuery,
} from "src/generated/graphql-types";
import { createDesignPackageUrl } from "src/RouteUrls";
import { fail } from "src/utils";

type NewDesignPackageContextValue = {
  formState: ObjectState<NewDesignPackageForm>;
  inFlight: boolean;
  save: () => Promise<void>;
  globalOptionGroups: DesignPackageGlobalOptionGroupFragment[];
  globalUpgradeGroups: DesignPackageGlobalOptionGroupFragment[];
  /** Denotes Create vs Edit. Expectation: Controls Interior/Exterior on create, which can no longer be updated. */
  isEditMode: boolean;
};

const NewDesignPackageContext = createContext<NewDesignPackageContextValue>({
  formState: undefined!, // This should init immediately. Only reason undefined would trigger is if Provider isn't actually wrapping a component when useContext is called, which should never happen
  inFlight: false,
  save: async () => {},
  globalOptionGroups: [],
  globalUpgradeGroups: [],
  isEditMode: false,
});

export const useNewDesignPackageContext = () => React.useContext(NewDesignPackageContext);

type NewDesignPackageProviderProps = React.PropsWithChildren<{
  maybeDesignPackageId: string | undefined;
  maybeVersionId: string | undefined;
}>;

export function NewDesignPackageProvider(props: NewDesignPackageProviderProps) {
  const { children, maybeDesignPackageId, maybeVersionId } = props;
  const { data: maybeDesignPackage } = useEditDesignPackageQuery({
    skip: !maybeDesignPackageId,
    variables: { id: maybeDesignPackageId!, versionId: maybeVersionId },
  });

  const formState = useFormState({
    config: formConfig,
    init: {
      input: maybeDesignPackage?.designPackage,
      // maybeDesignPackage query won't fire during the create-new flow, so map never runs. Use this as a fallback.
      ifUndefined: {
        name: "",
        planPackageIds: [],
        withGlobalOptionIds: [],
        removeGlobalOptionIds: [],
        // forces the user to pick
        location: maybeDesignPackage?.designPackage.location.code ?? undefined,
        markets: [],
      },
      map: (dp) => ({
        name: dp.name,
        // make sure we only display the active options
        withGlobalOptionIds: dp.options.filter((o) => o.active).map((o) => o.globalOption.id) ?? [],
        planPackageIds: dp.planPackages.map(({ id }) => id) ?? [],
        markets: dp.markets.map(({ id }) => id) ?? [],
      }),
    },
  });
  const [mutate, { loading: inFlight }] = useMakeNewDesignPackageMutation();
  const { closeModal } = useModal();
  const location =
    useComputed(() => formState.location.value, [formState]) ?? maybeDesignPackage?.designPackage.location.code;
  const { data, loading: optionsLoading } = useDesignPackageGlobalOptionsQuery({
    variables: {
      filter: {
        ...(location === DesignPackageLocation.Interior && { forDesignInterior: true }),
        ...(location === DesignPackageLocation.Exterior && { forDesignExterior: true }),
      },
    },
    skip: !location,
  });
  const history = useHistory();

  /** A DesignPackage should pre-select any options from PlanPackages */
  const planPackageIds = useComputed(() => formState.planPackageIds.value ?? [], [formState]);
  const preselectQuery = usePreselectOptionsFromPlanPackagesQuery({
    variables: { planPackageIds },
    skip: planPackageIds.isEmpty,
  });
  /** hydrate data from preselect query */
  useEffect(() => {
    const inactiveRPOs = maybeDesignPackage?.designPackage.options.filter((dpRpo) => !dpRpo.active) ?? [];
    formState.withGlobalOptionIds.set(
      [
        ...(formState.withGlobalOptionIds.value || []), // do not deselect existing values
        ...(preselectQuery.data?.planPackages.entities
          .flatMap((pp) => pp.options)
          // Only use active Plan Package options
          .filter((rpo) => rpo.active)
          // Filter out any plan option that is already inactive on the Design Package
          .filter((ppRpo) => !inactiveRPOs.some((dpRpo) => dpRpo.globalOption.id === ppRpo.globalOption.id))
          .filter((rpo) => isInteriorOption(rpo, location) || isExteriorOption(rpo, location) || isUpgradeOption(rpo))
          .map((rpo) => rpo.globalOption.id) ?? []),
      ].unique(),
    );
  }, [preselectQuery, location, formState, maybeDesignPackage?.designPackage.options]);

  const save = useCallback(async () => {
    const { location, ...values } = formState.value;
    const result = await mutate({
      variables: {
        input: {
          ...values,
          // Update this design package if we're editing an existing one
          ...(maybeDesignPackage && { id: maybeDesignPackageId }),
          // only touch location if it's a new design package
          ...(!maybeDesignPackage && { location }),
          // remove options that were selected but no longer are
          removeGlobalOptionIds:
            maybeDesignPackage?.designPackage.options
              // make sure we only check against active options
              .filter((o) => o.active)
              .filter((o) => !formState.value.withGlobalOptionIds!.includes(o.globalOption.id))
              .map((o) => o.globalOption.id) ?? [],
        },
      },
      refetchQueries: [DesignPackageItemSlotEditorDocument],
    });
    const newId = result.data?.saveDesignPackage.designPackage.id ?? fail();
    const versionId = result.data?.saveDesignPackage.designPackage.version.id ?? fail();
    history.push(createDesignPackageUrl(newId, versionId));
    closeModal();
  }, [maybeDesignPackage, mutate, formState.value, maybeDesignPackageId, history, closeModal]);

  const globalOptionGroups = useMemo(() => (data?.options ?? []).sortBy((gog) => gog.type.order), [data]);
  const globalUpgradeGroups = useMemo(() => (data?.upgrades ?? []).sortBy((gog) => gog.type.order), [data]);

  return (
    <NewDesignPackageContext.Provider
      value={{
        formState,
        inFlight: inFlight || optionsLoading,
        save,
        globalOptionGroups,
        globalUpgradeGroups,
        isEditMode: !!maybeDesignPackageId,
      }}
    >
      {children}
    </NewDesignPackageContext.Provider>
  );
}

/** Form State to span multiple pages */
type NewDesignPackageForm = Pick<
  SaveDesignPackageInput,
  "name" | "planPackageIds" | "withGlobalOptionIds" | "removeGlobalOptionIds" | "location" | "markets"
>;

const formConfig: ObjectConfig<NewDesignPackageForm> = {
  name: { type: "value", rules: [required] },
  planPackageIds: { type: "value", rules: [required] },
  location: { type: "value", rules: [required] },
  /**
   * [Global Option Group]   <--o2m-->       [Global Option] <-- This is what you're querying for, grouping by, selecting, selecting from, and pushing to the backend
   *      ^                                          ^
   *      |(o2o)                                (o2o)|
   *      v                                          v
   * [Ready Plan Option Group] <--o2m--> [Ready Plan Option] <-- This is ultimately what you're creating
   */
  withGlobalOptionIds: { type: "value" },
  removeGlobalOptionIds: { type: "value" },
  markets: { type: "value" },
};

function isInteriorOption(
  rpo: PlanPackage_ReadyPlanOptionFragment,
  location: DesignPackageLocation | undefined | null,
) {
  return location === DesignPackageLocation.Interior && rpo.globalOption.group.forDesignInterior;
}

function isExteriorOption(
  rpo: PlanPackage_ReadyPlanOptionFragment,
  location: DesignPackageLocation | undefined | null,
) {
  return location === DesignPackageLocation.Exterior && rpo.globalOption.group.forDesignExterior;
}

const isUpgradeOption = (rpo: PlanPackage_ReadyPlanOptionFragment) => rpo.globalOption.group.forDesignUpgrade;
