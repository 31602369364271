import { Button, ButtonMenu, Css, FullBleed, Icon, Palette, useSessionStorage, useTestIds } from "@homebound/beam";
import { Maybe } from "graphql/jsutils/Maybe";
import { ReactNode, useMemo } from "react";
import { createProjectDashboardUrl } from "src/RouteUrls";
import { FormattedDate } from "src/components";
import { ScheduleDraftModeHeader_PlanScheduleFragment } from "src/generated/graphql-types";
import { PageHeaderBreadcrumbs } from "src/routes/layout/PageHeader";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { useDraftScheduleStore } from "src/routes/projects/dynamic-schedules/draft-mode/scheduleDraftStore";
import { projectsPath } from "src/routes/routesDef";
import { isDefined } from "src/utils";
import { DateOnly } from "src/utils/dates";

export type ScheduleDraftModeHeaderProps = {
  scheduleParentId: string;
  onPublishClick: () => void;
  loadingDraftChanges: boolean;
  planSchedule: ScheduleDraftModeHeader_PlanScheduleFragment | null | undefined;
};

export function ScheduleDraftModeHeader({
  scheduleParentId,
  onPublishClick,
  loadingDraftChanges,
  planSchedule,
}: ScheduleDraftModeHeaderProps) {
  const { buildAddress } = useProjectContext();
  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const userAddedDelayFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const reset = useDraftScheduleStore((state) => state.reset);
  const removeUserAddedScheduleFlags = useDraftScheduleStore((state) => state.removeUserAddedScheduleFlags);
  const validationErrors = useDraftScheduleStore((state) => state.validationErrors);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const headerBreadcrumbs = [
    { label: "Projects", href: projectsPath },
    { label: buildAddress.street1, href: createProjectDashboardUrl(scheduleParentId) },
  ];

  const disabledReasons = useMemo(
    () => [...Object.keys(validationErrors).flatMap((v) => validationErrors[v])].unique(),
    [validationErrors],
  );

  return (
    <FullBleed>
      <header css={Css.df.aic.py2.bb.bw1.bcGray200.bgWhite.mb3.$}>
        <div css={Css.dg.gtc("1fr auto 1fr").w100.$}>
          <div css={Css.df.fdc.$}>
            <PageHeaderBreadcrumbs breadcrumb={headerBreadcrumbs} linkXss={Css.blue700.cursorPointer.truncate.$} />
            <h1 data-testid="headerTitle" css={Css.mr2.xlSb.$}>
              Schedule
            </h1>
          </div>
          <div css={Css.df.w100.jcc.fg1.$}>
            <ScheduleHeaderDetails planSchedule={planSchedule} />
          </div>
          <div css={Css.df.gap1.aic.jcfe.w100.fs1.$}>
            {(!draftTaskChanges.isEmpty || !userAddedScheduleFlags.isEmpty) && (
              <>
                <Button
                  tooltip="This resets the Draft Schedule Mode to the latest version of the schedule (example: Someone else has made updates)"
                  onClick={() => {
                    reset();
                    userAddedDelayFlags.forEach((flag) => {
                      if (flag.clientId) {
                        removeUserAddedScheduleFlags(flag.clientId);
                      }
                    });
                  }}
                  disabled={loadingDraftChanges}
                  label="Discard Changes"
                  variant="danger"
                />
                <Button
                  onClick={onPublishClick}
                  label="Publish"
                  disabled={
                    loadingDraftChanges ? "Loading update" : disabledReasons.isEmpty ? undefined : disabledReasons
                  }
                />
              </>
            )}
          </div>
        </div>
      </header>
    </FullBleed>
  );
}

type ScheduleHeaderFieldProps = {
  label: string;
  children: string | ReactNode;
  textColor?: string;
  endAdornment?: ReactNode;
};

function ScheduleHeaderField({ textColor = Palette.Gray900, children, label, endAdornment }: ScheduleHeaderFieldProps) {
  const tid = useTestIds({}, `${label}Header`);
  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.df.gap1.aic.gray600.xsMd.$}>
        {label}
        {endAdornment}
      </div>
      <div css={Css.smSb.color(`${textColor}`).$} {...tid}>
        {children}
      </div>
    </div>
  );
}

type ScheduleHeaderDetailsProps = {
  planSchedule: ScheduleDraftModeHeader_PlanScheduleFragment | null | undefined;
};

// Avoid value renames here since these will be persisted in session storage
enum CycleTimeType {
  WorkingDays = "WorkingDays",
  CalendarDays = "CalendarDays",
  CalendarDaysByMonth = "CalendarDaysByMonth",
}

function ScheduleHeaderDetails({ planSchedule }: ScheduleHeaderDetailsProps) {
  // We want this selection to be a sticky user preference across schedules, but don't need it encoded in the URL
  const [cycleTimeType, setCycleTimeType] = useSessionStorage(
    "dynamicSchedulesHeaderCycleTime",
    CycleTimeType.CalendarDaysByMonth,
  );

  const { actualCycleTime, targetCycleTime } = useMemo(
    () => displayCycleTimes(planSchedule, cycleTimeType),
    [planSchedule, cycleTimeType],
  );

  return (
    <div css={Css.df.aic.jcsa.gapPx(53).$}>
      <ScheduleHeaderField label="Ready to Start">
        <FormattedDate date={planSchedule?.readyToStartDate || undefined} dateFormatStyle="medium" />
      </ScheduleHeaderField>
      <ScheduleHeaderField label="Vertical Complete">
        <FormattedDate date={planSchedule?.verticalCompleteDate || undefined} dateFormatStyle="medium" />
      </ScheduleHeaderField>
      <ScheduleHeaderField label="Target Vertical Complete" textColor={Palette.Red600}>
        <div css={Css.df.aic.gap1.$}>
          <TargetVerticalCompleteDate
            targetEndDateProbability={planSchedule?.targetEndDateProbability}
            targetEndDate={planSchedule?.targetEndDate}
          />
          <Icon
            icon="infoCircle"
            tooltip="Based on The Ready to Start Date + Target Cycle Time."
            inc={2}
            color={Palette.Gray600}
          />
        </div>
      </ScheduleHeaderField>
      <ScheduleHeaderField label="Cycle Time">{actualCycleTime}</ScheduleHeaderField>
      <ScheduleHeaderField
        label="Target Cycle Time"
        endAdornment={
          <ButtonMenu
            data-testid="cycleTimeType"
            trigger={{ icon: "triangleDown", compact: true }}
            items={[
              {
                label: "Calendar Days (in Months)",
                onClick: () => setCycleTimeType(CycleTimeType.CalendarDaysByMonth),
              },
              { label: "Calendar Days", onClick: () => setCycleTimeType(CycleTimeType.CalendarDays) },
              { label: "Working Days", onClick: () => setCycleTimeType(CycleTimeType.WorkingDays) },
            ]}
            placement="right"
          />
        }
      >
        <div css={Css.df.aic.gap1.$}>
          <div>{targetCycleTime}</div>
          <Icon
            icon="infoCircle"
            tooltip="Based on Target Cycle Time, set in Project Settings."
            inc={2}
            color={Palette.Gray600}
          />
        </div>
      </ScheduleHeaderField>
    </div>
  );
}

function TargetVerticalCompleteDate({
  targetEndDateProbability,
  targetEndDate,
}: {
  targetEndDateProbability: number | null | undefined;
  targetEndDate: DateOnly | undefined;
}) {
  if (!isDefined(targetEndDateProbability) || !isDefined(targetEndDate)) return null;

  // Likely (more than 75%)
  if (targetEndDateProbability >= 75_00) {
    return (
      <span css={Css.green600.$}>
        <FormattedDate date={targetEndDate} dateFormatStyle="medium" />
      </span>
    );
  }

  // Somewhat likely (25-75%)
  if (targetEndDateProbability < 75_00 && targetEndDateProbability >= 25_00) {
    return (
      <span css={Css.orange600.$}>
        <FormattedDate date={targetEndDate} dateFormatStyle="medium" />
      </span>
    );
  }

  // Unlikely (less than 25%)
  if (targetEndDateProbability < 25_00)
    return (
      <span css={Css.red600.$}>
        <FormattedDate date={targetEndDate} dateFormatStyle="medium" />
      </span>
    );
}

function displayCycleTimes(
  planSchedule: Maybe<ScheduleDraftModeHeader_PlanScheduleFragment>,
  cycleTimeType: CycleTimeType,
) {
  const { cycleTimeInWorkingDays, targetCycleTimeInDays, cycleTimeInCalendarDays, targetCycleTimeInCalendarDays } =
    planSchedule ?? {};

  switch (cycleTimeType) {
    case CycleTimeType.WorkingDays:
      return {
        actualCycleTime: toDaysDisplay(cycleTimeInWorkingDays),
        targetCycleTime: toDaysDisplay(targetCycleTimeInDays),
      };
    case CycleTimeType.CalendarDays:
      return {
        actualCycleTime: toDaysDisplay(cycleTimeInCalendarDays),
        targetCycleTime: toDaysDisplay(targetCycleTimeInCalendarDays),
      };
    // Default is CalendarDaysByMonth, left explicit as the default case in the event `cycleTimeType` gets a corrupted value in session storage
    default:
      return {
        actualCycleTime: toMonthDisplay(cycleTimeInCalendarDays, 30),
        targetCycleTime: toMonthDisplay(targetCycleTimeInCalendarDays, 30),
      };
  }
}

function toDaysDisplay(days: Maybe<number>) {
  if (!isDefined(days)) return "--";
  return `${days} days`;
}

function toMonthDisplay(days: Maybe<number>, daysPerMonth: number) {
  if (!isDefined(days)) return "--";

  const months = days / daysPerMonth;
  // Return the value rounded to the nearest tenth
  return `${parseFloat(months.toFixed(1))} months`;
}
