import {
  BoundSelectField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { useCallback, useMemo } from "react";
import {
  BidPackageDetailPage_TradeFragment,
  BidPackageStatus,
  PageBidPackageDetailFragment,
  SaveBidPackageRequestInput,
  useAddTradePartnerModalQuery,
  useSaveBidPackageRequestMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { queryResult } from "src/utils";

export function AddTradePartnerModal({ bidPackage }: { bidPackage: PageBidPackageDetailFragment }) {
  const query = useAddTradePartnerModalQuery({ variables: { costCodes: bidPackage.costCodes.map((cc) => cc.id) } });
  return queryResult(query, ({ tradePartners }) => (
    <AddTradePartnerModalView trades={tradePartners} bidPackage={bidPackage} />
  ));
}

function AddTradePartnerModalView({
  trades,
  bidPackage,
}: {
  trades: BidPackageDetailPage_TradeFragment[];
  bidPackage: PageBidPackageDetailFragment;
}) {
  const { openModal, closeModal } = useModal();
  const [saveBidPackageRequest] = useSaveBidPackageRequestMutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: {
        bidPackageId: bidPackage.id,
      },
    },
  });
  const matchingTrades = useMemo(
    () =>
      trades
        .filter((tp) => bidPackage.costCodes.every((bpcc) => tp.costCodes.map((cc) => cc.id).includes(bpcc.id)))
        .filter((tp) => !bidPackage.requests.flatMap((r) => r.tradePartner.id).includes(tp.id)),
    [bidPackage.costCodes, trades, bidPackage.requests],
  );
  const contactOptions = useComputed(
    () => matchingTrades.find((t) => t.id === formState.tradePartnerId.value)?.contacts ?? [],
    [formState],
  );
  const noNewTrades = useMemo(() => matchingTrades.isEmpty, [matchingTrades.isEmpty]);

  const saveAndExit = useCallback(
    async () => {
      await saveBidPackageRequest({
        variables: {
          input: { ...formState.value },
        },
      });
      closeModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      bidPackage.id,
      formState.primaryContactId.value,
      formState.secondaryContactId.value,
      formState.tradePartnerId.value,
    ],
  );

  const handleSave = useCallback(async () => {
    if (bidPackage.status.code !== BidPackageStatus.Draft) {
      openModal({
        content: (
          <ConfirmationModal
            title="Add a New Trade"
            confirmationMessage="Adding a new trade on a package will create a new version. Are you sure you want to continue?"
            onConfirmAction={saveAndExit}
            label="Yes"
          />
        ),
      });
    } else {
      await saveAndExit();
    }
  }, [bidPackage.status.code, openModal, saveAndExit]);

  return (
    <div data-testid="addTradeModal">
      <ModalHeader>Add Trade</ModalHeader>
      <ModalBody>
        {noNewTrades ? (
          <span>No New Trades to Add</span>
        ) : (
          <FormLines>
            <BoundSelectField
              field={formState.tradePartnerId}
              label="Company Name"
              options={matchingTrades}
              // Unset primary and secondary contacts when trade partner changes
              onSelect={(tp) => formState.set({ tradePartnerId: tp, primaryContactId: null, secondaryContactId: null })}
            />
            <BoundSelectField field={formState.primaryContactId} label="Contact (Primary)" options={contactOptions} />
            <BoundSelectField
              field={formState.secondaryContactId}
              label="Contact (Secondary)"
              options={contactOptions}
            />
          </FormLines>
        )}
      </ModalBody>
      <ModalFooter>
        <>
          <Button label="Cancel" onClick={closeModal} variant="tertiary" />
          <Button label="Save" onClick={handleSave} disabled={noNewTrades} />
        </>
      </ModalFooter>
    </div>
  );
}

type BidTradesFormValue = SaveBidPackageRequestInput;
const formConfig: ObjectConfig<BidTradesFormValue> = {
  bidPackageId: { type: "value" },
  tradePartnerId: { type: "value" },
  primaryContactId: { type: "value" },
  secondaryContactId: { type: "value" },
};
