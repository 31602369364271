import {
  BoundIconCardGroupField,
  BoundMultiSelectField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  BoundTreeSelectField,
  Chip,
  Css,
  HasIdAndName,
  NestedOption,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useCallback, useMemo } from "react";
import { BoundAttachments } from "src/components/boundAttachments/BoundAttachments";
import {
  AssetTreeSelectValuesFragment,
  ChangeRequestDrawer_ChangeRequestGroupFragment,
  ChangeRequestSource,
  ChangeType,
  CreateChangeRequestMetadataQuery,
  InputMaybe,
} from "src/generated/graphql-types";
import {
  ChangeTypeToIcon,
  CreateAdditionalChangeRequestForm,
  CreateChangeRequestForm,
  getAssetTreeSelectOptions,
  getRequiredChangeSourceAssets,
} from "../CreateChangeRequestDrawer";
import { isFeeBuildProjectChangeSource, isPersonalizationChangeSource } from "../utils";
import { CustomerRequestPersonalizationInputs } from "./CustomerRequestPersonalizationInputs";
import { filterChangeRequestSources } from "./DetailsTab";
import { UrgencyField } from "./UrgencyField";

type ChangeRequestFormDetailsProps = {
  formState: ObjectState<CreateChangeRequestForm> | ObjectState<CreateAdditionalChangeRequestForm>;
  enums: CreateChangeRequestMetadataQuery["enumDetails"];
  scopeSelectValues: NestedOption<HasIdAndName>[];
  changeRequestGroups: ChangeRequestDrawer_ChangeRequestGroupFragment[];
  sortedChangeTypes: { code: ChangeType; name: string }[];
  assets: AssetTreeSelectValuesFragment[];
  currentPage: number;
  changeRequestGroupNames: InputMaybe<string>[];
};

export function ChangeRequestFormDetails({
  formState,
  enums,
  scopeSelectValues,
  changeRequestGroups,
  sortedChangeTypes,
  assets,
  currentPage,
  changeRequestGroupNames,
}: ChangeRequestFormDetailsProps) {
  const assetSelectValues = useMemo(() => getAssetTreeSelectOptions(assets), [assets]);

  // Temporally hide other sources until they are released
  const changeRequestSources = useMemo(
    () => filterChangeRequestSources(enums.changeRequestSource),
    [enums.changeRequestSource],
  );

  const onChangeRequestSourceChange = useCallback(
    (value: ChangeRequestSource | undefined) => {
      if (!isPersonalizationChangeSource(value)) {
        formState.isStandardOffering.set(undefined);
        formState.salesforceOpportunityUrl.set(undefined);
        formState.isUnderContract.set(undefined);
        formState.productSelection.set(undefined);
      }
      formState.source.set(value);
      // asset should be cleared when source changes
      formState.assets.set([]);
      if (isPersonalizationChangeSource(value) || isFeeBuildProjectChangeSource(value)) {
        // tie specific hardcoded assets to the change type of personalization or fee build project
        formState.assets.set([...(formState.assets.value ?? []), ...getRequiredChangeSourceAssets(assets, value)]);
      }
    },
    [formState],
  );

  return (
    <div css={Css.df.jcsb.px4.$} key={"cr" + (formState.title || currentPage)}>
      <div css={Css.df.fdc.wPx(322).gap3.$}>
        <div css={Css.df.jcfs.gap3.wPx(900).$}>
          <h2 css={Css.lgSb.$}>{`Request ${currentPage + 1}`}</h2>
          {changeRequestGroupNames.map((name) => (
            <Chip text={name ?? ""} key={name} compact />
          ))}
        </div>
        <BoundTextAreaField field={formState.title} label="Title" preventNewLines />
        <BoundTextAreaField field={formState.rationale} label="Description" />
        <BoundSelectField
          field={formState.source}
          label="Change Source (Where it came from)"
          options={changeRequestSources}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ code }) => code as ChangeRequestSource}
          onSelect={onChangeRequestSourceChange}
        />
        <CustomerRequestPersonalizationInputs formState={formState} />
        <BoundTreeSelectField
          label="Lots/ Developments Impacted"
          field={formState.scopes}
          options={scopeSelectValues}
          defaultCollapsed
          // Use the `root` values so if a user selects a Development, store the development selection (vs fanning out to all child projects)
          onSelect={(options) => formState.scopes.set(options.root.values)}
          disabled={currentPage > 0}
        />
        <UrgencyField field={formState.priority} options={enums.changeRequestPriority} />
        <BoundMultiSelectField
          field={formState.groups}
          options={changeRequestGroups ?? []}
          label="Add to Existing Group"
        />
        <BoundTextField field={formState.newGroup} label="Create a name for a New Group (Optional)" />
      </div>
      <div css={Css.df.fdc.gap3.wPx(575).$}>
        <div css={Css.mt6.$}>
          <BoundIconCardGroupField
            options={sortedChangeTypes.map((changeType) => ({
              icon: ChangeTypeToIcon[changeType.code] ?? "remove",
              label: changeType.name,
              value: changeType.code,
              // Selecting N/A should deselect all other options
              exclusive: changeType.code === ChangeType.Na,
            }))}
            field={formState.changeTypes}
            errorMsg={formState.changeTypes.touched ? formState.changeTypes.errors.join(" ") : undefined}
          />
        </div>
        <BoundTreeSelectField
          field={formState.assets}
          label="Individual Assets (What is affected)"
          disabledOptions={getRequiredChangeSourceAssets(assets, formState.source.value)}
          options={assetSelectValues}
          defaultCollapsed
          // Use the `leaf` values so if a user selects an asset, store the asset selection (vs storing the parent business function)
          onSelect={(options) => formState.assets.set(options.leaf.values)}
          placeholder="--"
        />
        <BoundAttachments field={formState.attachments} />
      </div>
    </div>
  );
}
