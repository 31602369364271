import {
  Css,
  FormPageLayout,
  boundSelectField,
  boundTextAreaField,
  boundTextField,
  useComputed,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import pick from "lodash/pick";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router";
import {
  BoundAttachments,
  SaveAttachmentModel,
  attachmentConfig,
} from "src/components/boundAttachments/BoundAttachments";
import { BoundSingleAttachment, formatAttachmentAssets } from "src/components/boundAttachments/BoundSingleAttachment";
import {
  InputMaybe,
  SaveGlobalOptionInput,
  useGlobalOptionFormQuery,
  useGlobalOptionFormTypesQuery,
  useSaveGlobalOptionFormMutation,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { IdOrAddParams } from "src/routes/routesDef";
import { addEntityParam, createGlobalOptionsUrl } from "src/RouteUrls";
import { globalOptionStatuses } from "./GlobalOptionsPageV2";

export function GlobalOptionForm() {
  const { idOrAdd } = useParams<IdOrAddParams>();
  const isNew = idOrAdd === addEntityParam;
  const history = useHistory();
  const { triggerNotice } = useSnackbar();
  const { data, loading } = useGlobalOptionFormQuery({ variables: { globalOptionId: idOrAdd }, skip: isNew });
  const query = useGlobalOptionFormTypesQuery();
  const [saveGlobalOptionForm] = useSaveGlobalOptionFormMutation();
  const globalOption = data?.globalOption;
  const globalOptionTypes = query.data?.globalOptionTypes ?? [];

  const formState = useFormState({
    config: formConfig,
    loading: loading,
    init: {
      input: globalOption,
      map: (go) => ({
        id: go.id,
        name: go.name,
        code: go.code,
        typeId: go.type.id,
        status: go.status,
        description: go.description,
        marketingDescription: go.marketingDescription,
        marketingImage: go.asset ? { asset: go.asset } : undefined,
        attachments: go.attachments.map((attachment) => ({ asset: attachment.asset, id: attachment.id })),
      }),
    },
  });

  const onSave = useCallback(async () => {
    const { marketingImage, shouldDeleteMarketingImage, attachments, ...changedValue } = formState.changedValue;
    const assetInput = formatAttachmentAssets(marketingImage, shouldDeleteMarketingImage, globalOption?.asset?.id);

    const attachmentsInput = attachments?.map(({ asset, ...props }) => ({
      ...props,
      asset: pick(asset, [
        // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
        "contentType",
        "fileName",
        "id",
        "s3Key",
        "sizeInBytes",
        "delete",
      ]),
    }));

    const { data } = await saveGlobalOptionForm({
      variables: {
        input: { globalOptions: [{ ...changedValue, ...assetInput, attachments: attachmentsInput }] },
      },
    });

    const go = data?.saveGlobalOptions.globalOptions.first;

    if (go) {
      triggerNotice({
        message: `Global option: ${go.name} ${isNew ? "created" : "updated"}`,
        icon: "success",
      });
      history.push(createGlobalOptionsUrl());
    }
  }, [formState, saveGlobalOptionForm, history, triggerNotice, globalOption, isNew]);

  const attachmentInputs = useComputed(
    () => formState.attachments.rows.filter((row) => !row.value.asset.delete),
    [formState.attachments],
  );

  return (
    <FormPageLayout
      formSections={[
        {
          title: "Details",
          rows: [
            {
              name: boundTextField({ placeholder: "ex. Convert Partial Unfinished" }),
              code: boundTextField({ label: "Option Code", placeholder: "ABCDEF0001" }),
            },
            {
              typeId: boundSelectField({
                options: globalOptionTypes,
                getOptionLabel: ({ name }) => name,
                getOptionValue: ({ id }) => id,
                label: "Option Type",
                placeholder: "Option Type",
                disabled: !isNew
                  ? "Cannot change option type after creation"
                  : globalOption
                    ? disableBasedOnPotentialOperation(globalOption.canEditType)
                    : undefined,
              }),
            },
            {
              status: boundSelectField({
                options: globalOptionStatuses,
                getOptionLabel: ({ name }) => name,
                getOptionValue: ({ status }) => status,
                placeholder: "Status",
              }),
            },
            { description: boundTextAreaField({ placeholder: "Enter a short description" }) },
          ],
        },
        {
          title: "Marketing Details",
          rows: [
            {
              reactNodeMarketingImage: (
                <BoundSingleAttachment
                  field={formState.marketingImage}
                  title={"Option Image"}
                  uppyUploaderProps={{ dragDropHeight: 230, dragDropWidth: 230 }}
                  shouldDeleteField={formState.shouldDeleteMarketingImage}
                />
              ),
            },

            { marketingDescription: boundTextAreaField({ placeholder: "Enter a short marketing description" }) },
          ],
        },
        {
          title: "Documents",
          rows: [
            {
              reactNodeAttachments: attachmentInputs.nonEmpty ? (
                <BoundAttachments
                  field={formState.attachments}
                  showTitle={false}
                  uppyUploaderProps={{
                    dragDropHeight: 44,
                    dragDropWidth: 230,
                    dragDropText: "Add Documents",
                  }}
                />
              ) : (
                <EmptyDocumentsState formState={formState} />
              ),
            },
          ],
        },
      ]}
      formState={formState}
      pageTitle={isNew ? "Create New Option" : `Edit ${globalOption?.name}`}
      breadCrumb={[{ label: "Options", href: createGlobalOptionsUrl() }]}
      // If the user clicks cancel, we want to go back to the global options page
      cancelAction={{ label: "Cancel", onClick: createGlobalOptionsUrl() }}
      submitAction={{
        label: isNew ? "Create" : "Save",
        onClick: onSave,
      }}
    />
  );
}

type GlobalOptionFormConfig = SaveGlobalOptionInput & {
  marketingImage?: SaveAttachmentModel;
  attachments?: InputMaybe<SaveAttachmentModel[]>;
  shouldDeleteMarketingImage?: boolean;
};

const formConfig: ObjectConfig<GlobalOptionFormConfig> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  code: { type: "value", rules: [required] },
  typeId: { type: "value", rules: [required] },
  status: { type: "value", rules: [required] },
  description: { type: "value" },
  marketingDescription: { type: "value" },
  marketingImage: { type: "value" },
  attachments: {
    type: "list",
    config: attachmentConfig,
  },
  shouldDeleteMarketingImage: { type: "value" },
};

function EmptyDocumentsState({ formState }: { formState: ObjectState<GlobalOptionFormConfig> }) {
  return (
    <div css={Css.df.fdc.aic.jcc.py3.px2.ba.bcGray200.gap2.br8.$}>
      <div css={Css.gray600.lgSb.$}>No Documents</div>
      <div css={Css.base.gray500.$}>Add documents associated to this option.</div>
      <BoundAttachments
        field={formState.attachments}
        showTitle={false}
        uppyUploaderProps={{
          dragDropHeight: 44,
          dragDropWidth: 250,
          dragDropText: "Drag & drop files",
        }}
      />
    </div>
  );
}
