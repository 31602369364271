import { Button, useSnackbar } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useHistory, useParams } from "react-router-dom";
import { createDevelopmentProductOfferingUrl, createProjectProductOfferingUrl } from "src/RouteUrls";
import {
  EditProductOfferingFragment,
  useEditProductOfferingPageQuery,
  useReviewStep_SaveProductOfferingMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProductOfferingParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";
import { formConfig } from "../add-product-offering/AddProductOfferingPage";
import { OptionsView } from "../add-product-offering/components/4.OptionsStep";

export function EditProductOfferingPage() {
  const { productOfferingId } = useParams<ProductOfferingParams>();
  const query = useEditProductOfferingPageQuery({ variables: { id: productOfferingId } });
  return queryResult(query, ({ productOffering }) => <EditProductOfferingView productOffering={productOffering} />);
}

export function EditProductOfferingView({ productOffering }: { productOffering: EditProductOfferingFragment }) {
  const [{ devId, projectId }] = useQueryParams({ devId: StringParam, projectId: StringParam });
  const { productOfferingId, productOfferingVersionId } = useParams<ProductOfferingParams>();
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const [saveProductOffering] = useReviewStep_SaveProductOfferingMutation();
  const { options } = productOffering;
  console.log({ options });

  const formState = useFormState({
    config: formConfig,
    init: {
      input: options,
      map: (options) => ({ options: options.filter((rpo) => rpo.active) }),
    },
  });

  return (
    <>
      <PageHeader
        title=""
        right={
          <>
            <Button
              size="lg"
              variant="secondary"
              label="Cancel"
              onClick={() => {
                if (devId) {
                  history.push(createDevelopmentProductOfferingUrl(devId, productOfferingId, productOfferingVersionId));
                } else if (projectId) {
                  history.push(createProjectProductOfferingUrl(projectId, productOfferingId, productOfferingVersionId));
                }
              }}
            />

            <Button
              size="lg"
              label="Save Options"
              onClick={async () => {
                if (!formState) return;
                const res = await saveProductOffering({
                  variables: {
                    input: {
                      id: productOffering.id,
                      options: formState.options.value,
                    },
                  },
                });
                if (res.data?.saveProductOffering.productOffering) {
                  const productOfferingId = res.data?.saveProductOffering.productOffering.id;
                  triggerNotice({ message: "Offering successfully saved! Procurement will review and start bidding" });
                  // TODO: route to a `global offering detail page` when offering is created from global library
                  const versionId = res.data?.saveProductOffering.productOffering.version.id;
                  if (devId) {
                    history.push(createDevelopmentProductOfferingUrl(devId, productOfferingId, versionId));
                  } else if (projectId) {
                    history.push(createProjectProductOfferingUrl(projectId, productOfferingId, versionId));
                  }
                }
              }}
            />
          </>
        }
      />
      <OptionsView formState={formState} readyPlanOptions={options} />
    </>
  );
}
